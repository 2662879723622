import { useEffect, useState } from "react";
import ReactGA from "react-ga";

// const usePageTracking = (options = {}) => {
//   const { title, path } = options;

//   useEffect(() => {
//     setTimeout(() => {
//       if (window.gtag && window.GTAG_ID) {
//         const page_title = title || document.title;
//         const page_path =
//           path || `${window.location.pathname}${window.location.search}`;
//         const data = {
//           page_title,
//           page_path,
//         };
//         window.gtag("config", window.GTAG_ID, data);
//         console.log("Logged entry to " + page_title + ': ' + page_path);
//       } else {
//         console.error("Google analytic not loaded");
//       }
//     }, 1500);
//   }, []);
// };

window.ga_initialized = false;

// const TRACKING_ID = "UA-219520719-1"; // staging
const TRACKING_ID = "UA-219573437-1"; // production

const usePageTracking = (options = {}) => {
  const { title, path } = options;
  // const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if(window.location.host.includes('localhost')) {
      console.warn('log disabled if localhost')
    } else if (!window.ga_initialized && window.location.hostname !== 'localhost') {
      ReactGA.initialize(TRACKING_ID);
      window.ga_initialized = true;
      setTimeout(() => {
        // if (window.gtag && window.GTAG_ID) {
        const page_title = title || document.title;
        const page_path =
          path || `${window.location.pathname}${window.location.search}`;
        //   const data = {
        //     page_title,
        //     page_path,
        //   };
        //   window.gtag("config", window.GTAG_ID, data);
        //   console.log("Logged entry to " + page_title + ': ' + page_path);
        // } else {
        //   console.error("Google analytic not loaded");
        // }
        ReactGA.pageview(page_path);
        console.log("Logged entry to " + page_title + ": " + page_path);
      }, 1500);
    }
  }, []);
};

// export const modalView = (title, modalName) => {
//   if (window.gtag && window.GTAG_ID) {
//     const data = {
//       page_title: title,
//       page_path: `/modal/${modalName}`,
//     };
//     window.gtag("config", window.GTAG_ID, data);
//     console.log("Logged entry to " + data.page_title + ": " + data.page_path);
//   } else {
//     console.error("Google analytic not loaded");
//   }
// };

export const modalView = (title, modalName) => {
  if(window.location.host.includes('localhost')) {
    console.warn('log disabled if localhost')
  } else {
    if(!window.ga_initialized) {
      ReactGA.initialize(TRACKING_ID);
      window.ga_initialized = true;
    }
  
    ReactGA.modalview(modalName);
    console.log("Logged entry to /modal/" + modalName);
  }
};


export const wrapComponent = (Component) => {
  return (props) => {
    usePageTracking();

    return <Component {...props} />;
  };
};

export default usePageTracking;
