import React from "react";
import Navigation from "components/Navigation";
import Footer from "components/footer";
import useGlobalStyles from "hooks/useGlobalStyles";
// import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import classNames from "classnames";
import KontakKami from "./components/KontakKami";
import Page from "components/Page";
import Breadcrumbs from "components/Breadcrumb";
import { useSelector } from "react-redux";
import get from "lodash/get";

function AboutUs() {
  const classes = useGlobalStyles();
  const { aboutus } = useSelector((state) => state.region);

  return (
    <Page
      title="Tentang Kami"
      className={classNames("py-4", classes.container)}
    >
      <Navigation />

      <Breadcrumbs
        className="xl:px-20 lg:px-18 px-6"
        links={[{ name: "Beranda", href: "/" }, { name: "Tentang Kami" }]}
      />

      <div className="flex justify-between pt-8 w-11/12 mx-auto">
        <div className="font-sans text-black-2 font-bold text-3xl">
          Tentang Kami
          <div className="border-b border-solid border-pink-header w-5/12 mt-10" />
        </div>
        <img src="/static/images/logo-LKPP-2.png" alt="" className="h-20 w-auto lg:block hidden" />
      </div>
      <div
        className="mt-8 rounded w-10/12 mx-auto"
        style={{
          boxShadow: "0px 4px 8px 4px rgba(0, 0, 0, 0.1)",
          background: "#FFFEFF",
        }}
      >
        <div className="px-10 pt-5">
          <div className="flex pb-5">
            <img
              src="/static/images/logo-vd.png"
              alt=""
              style={{ height: 70 }}
            />
          </div>
          <div className="pb-4 font-baloo2 font-bold text-base text-black-2">
            {get(aboutus, "judul")}
          </div>
          <div
            className="pb-10 font-montserrat font-semibold text-sm text-black-2 ck-content"
            dangerouslySetInnerHTML={{ __html: get(aboutus, "deskripsi", "") }}
          ></div>
        </div>
      </div>

      <div className="mt-8 mb-20 rounded w-10/12 mx-auto">
        <KontakKami />
      </div>
      <Footer />
    </Page>
  );
}

export default AboutUs;
