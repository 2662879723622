import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import * as services from './services';
import * as Yup from 'yup';

const RequestEmailForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required(),
})

export default function ForgotPasswordAction() {
  const inputRef = useRef();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [user, setUser] = useState({});
  
  const {
    handleSubmit, getFieldProps, errors, touched,
  } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: RequestEmailForgotPasswordSchema,
    onSubmit: (values) => {
      const params = {
        action: 'user',
        email: values.email
      };

      setIsLoading(true);
      
      services.checkUser(params, (status, resp) => {
        setIsLoading(false);

        if(status) {
          setShowAlert(false);
          setStep(1);
          setUser(resp.data);
        } else {
          setShowAlert(true);
        }
      })
      
    }
  })
  
  const handleSendEmail = () => {
    setIsLoading(true);
    const params = {
      email: user.email,
    }
    services.sendEmail(params, (status, resp) => {
      setIsLoading(false);
      if(status) {
        setShowAlert(false);
        setStep(2);
      } else {
        setShowAlert(true);
      }
    })
  }
  
  return (
    <div className="lg:w-1/2 w-full mt-4 mb-8 border-l border-solid border-welcome pl-6">
      <div className="pt-4  pr-6">
        <div className="font-baloo2 font-bold text-3xl text-black-2">
          Lupa Password
        </div>
      </div>
      <div className="pb-10 border-b border-solid border-pink-header w-1/2" />
      {step === 0 && (
        <>
          <div className="pr-6 font-montserrat font-medium text-xs text-black-2 pt-4">
            Silahkan ikuti langkah-langkah berikut untuk dapat masuk kembali ke akun anda
            {showAlert && (
              <div className="mt-2 p-2 rounded text-white text-xs bg-pink-header" >
                Akun tidak ditemukan
              </div>
            )}
          </div>
          <form className="pt-4">
            <div className="flex">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Masukkan Email Anda
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              ref={inputRef}
              name="email"
              {...getFieldProps('email')}
              // onChange={handleChange}
            />
            {(touched.email && errors.email) && (
              <div className="text-red-500 text-xs">{errors.email}</div>
            )}

            <button onClick={handleSubmit} disabled={isLoading} type="button" className={`mt-8 py-2 px-4 font-montserrat text-xs font-medium rounded border-solid border ${isLoading ? 'bg-gray-300' : 'text-border-on-pink bg-pink-header border-pink-header'}`}>
              Lanjutkan
            </button>
          </form>
        </>
      )}

      {step === 1 && (
        <>
          <div className="pr-6 font-montserrat font-medium text-xs text-black-2 pt-4">
            Akun anda, <b>{user.name}</b> sudah kami temukan.
          </div>
          <div className="pr-6 font-montserrat font-medium text-xs text-black-2 pt-4">
            Kami akan mengirimkan email reset password ke alamat email <b>{user.email}</b>
          </div>
          <div className="pr-6 font-montserrat font-medium text-xs text-black-2 pt-4">
            Klik tombol berikut untuk mengirim email yang berisi link untuk me-reset password Anda.
            {showAlert && (
              <div className="mt-2 p-2 rounded text-white text-xs bg-pink-header" >
                Gagal mengirim email
              </div>
            )}
          </div>
          <div className="pt-4">
            <button onClick={handleSendEmail} disabled={isLoading} type="submit" className={`mt-8 py-2 px-4 font-montserrat text-xs font-medium rounded border-solid border ${isLoading ? 'bg-gray-300' : 'text-border-on-pink bg-pink-header border-pink-header'}`}>
              Kirim email reset password
            </button>
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div className="pr-6 font-montserrat font-medium text-xs text-black-2 pt-4">
            <div className="mt-2 p-2 rounded text-white text-xs" style={{backgroundColor: '#00A65A'}}>
              <svg className="inline" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z" fill="#FEFEFE"/>
              </svg>
              Email reset password berhasil dikirim
            </div>
          </div>
        </>
      )}
    </div>
  )
}