import axios from "axios";
import endpoint from "utils/endpoint";

async function getUsahaList(callback, params) {
  const url = endpoint.dashboard;

  try {
    const { data: response } = await axios.get(url, { params });
    callback(response, null);
  } catch (error) {
    callback(null, error);
  }
}

const functions = {
  getUsahaList,
};

export default functions;
