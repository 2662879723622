import axios from 'axios';
import endpoint from 'utils/endpoint';

export async function getCaptcha(callback) {

  const url = endpoint.captcha;
  const config = {
    method: 'GET',
  };

  try {
    const response = await axios(url,config);
    callback(response.data)
  } catch (error) {
    callback(error)
  }
}

export function playAudio(captcha_code, onError) {
  const audio = new Audio(`${endpoint.captchaSound}?key=${captcha_code}`);

  audio.play();

  audio.addEventListener('error', onError);
}