import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import token from 'utils/session'

export default function ProtectRoute(props, {path}) {
  if (!token) {
    return (
      <Redirect 
        to={{
          pathname: "/",
          state: {
            prevLocation: path,
            error: "Anda Harus Login Dahulu!",
          },
        }}
      />
    )
  }

  return <Route {...props} />
}