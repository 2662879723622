import axios from 'axios';
import endpoint from 'utils/endpoint';
import { convertObjectToFormData } from 'utils/helpers';
// import token from 'utils/session';

export async function getProvinsi(params,callback) {
  const url = endpoint.provinsi;
  const config = {
    method: 'GET',
  };

  try {
    const response = await axios(url,config);
    callback(response.data)
  } catch (error) {
    console.log(error)
  }
}

export async function getKabupaten(params,callback) {
  const url = endpoint.kabupaten;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    callback(response.data)
  } catch (error) {
    console.log(error)
  }
}

export async function getKecamatan(params,callback) {
  const url = endpoint.kecamatan;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    callback(response.data)
  } catch (error) {
    console.log(error)
  }
}

export async function getKelurahan(params,callback) {
  const url = endpoint.kelurahan;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    callback(response.data)
  } catch (error) {
    console.log(error)
  }
}


export async function getPendidikan(params,callback) {
  const url = endpoint.pendidikan;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    callback(response.data)
  } catch (error) {
    console.log(error)
  }
}

export async function register(attributes, callback) {
  const url = endpoint.register;
  const formData =  convertObjectToFormData(attributes);

  const config = {
    method: 'POST',
    data: formData,
  }

  try {
    const response = await axios(url,config);
    callback(true, response.data)
    return Promise.resolve(response.data);
  } catch (error) {
    console.log(error)
    callback(false, error)
    return Promise.reject(error);
  }
}