import PropTypes from 'prop-types';
import Slick from 'react-slick';


const PrevArrow = (props) => {
  const { onClick, style } = props;

  return (
    <button onClick={onClick} style={style} className="rounded-full h-8 w-8 absolute left-0 top-0 bottom-0 my-auto ml-6 focus:outline-none z-10">
      <svg width="16" height="14" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.43733 8.99998L11.0373 15.6L9.152 17.4853L0.666668 8.99998L9.152 0.514648L11.0373 2.39998L4.43733 8.99998Z" fill="#FFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  )
}

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
};

const PrevArrowNewsDetail = (props) => {
  const { onClick, style } = props;

  return (
    <button onClick={onClick} style={style} className="rounded-full h-8 w-8 absolute left-0 top-0 bottom-0 my-auto ml-6 focus:outline-none z-10">
      <svg width="16" height="14" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.43733 8.99998L11.0373 15.6L9.152 17.4853L0.666668 8.99998L9.152 0.514648L11.0373 2.39998L4.43733 8.99998Z" fill="#ABABAB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  )
}

PrevArrowNewsDetail.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
};


const NextArrow = (props) => {
  const { onClick, style } = props;

  return (
    <button onClick={onClick} style={style} className="rounded-full h-8 w-8 absolute right-0 top-0 bottom-0 my-auto mr-6 focus:outline-none z-10">
      <svg width="16" height="14" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.56265 8.99998L0.962646 2.39998L2.84798 0.514648L11.3333 8.99998L2.84798 17.4853L0.962646 15.6L7.56265 8.99998Z" fill="#FFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  )
}

NextArrow.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
};

const NextArrowNewsDetail = (props) => {
  const { onClick, style } = props;

  return (
    <button onClick={onClick} style={style} className="rounded-full h-8 w-8 absolute right-0 top-0 bottom-0 my-auto mr-6 focus:outline-none z-10">
      <svg width="16" height="14" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.56265 8.99998L0.962646 2.39998L2.84798 0.514648L11.3333 8.99998L2.84798 17.4853L0.962646 15.6L7.56265 8.99998Z" fill="#ABABAB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  )
}

NextArrowNewsDetail.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
};


function Carousel(props) {
  const { classNameSlickDots, className, children, innerRef, newsDetail,...other } = props;
  
  return (
    <div className={""}>
      <Slick
        className={className}
        // dotsClass={classNames('slick-dots', classNameSlickDots)}
        nextArrow={newsDetail ? <NextArrowNewsDetail /> : <NextArrow />}
        prevArrow={newsDetail ? <PrevArrowNewsDetail /> : <PrevArrow />}
        ref={innerRef}
        {...other}
      >
        {children}
      </Slick>
    </div>
  );
}

Carousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  innerRef: PropTypes.any,
};

export default Carousel;