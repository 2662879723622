import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import classNames from "classnames";
import { makeStyles, createStyles } from "@material-ui/styles";
import token from "utils/session";
import axios from "axios";
import ViewRole from "components/ViewRole";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import CircularProgress from "@material-ui/core/CircularProgress";
import endpoint from "utils/endpoint";
import get from "lodash/get";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: "Baloo 2",
      fontWeight: 700,
      color: "#595959",
      fontSize: 18,
    },
  })
);

export default function TrackingPenawaran(props) {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [listStatistik, setListStatistik] = useState([]);

  const classDataYear =
    "flex flex-1 flex-col font-montserrat font-medium text-xs";

  useEffect(() => {
    const getStatistik = async () => {
      const url = endpoint.statistik_tracking;
      const { data: response } = await axios.get(url);

      setListStatistik(response);
    };

    getStatistik();
  }, []);

  const chart = React.useRef(null);

  const listForViews = get(listStatistik, [activeIndex, "data"], []);

  React.useLayoutEffect(() => {
    let x = am4core.create("tracking-penawaran", am4charts.PieChart3D);

    chart.current = x;

    x.hiddenState.properties.opacity = 0;
    x.data = listForViews;

    x.legend = new am4charts.Legend();

    let series = x.series.push(new am4charts.PieSeries3D());

    series.dataFields.value = "value";
    series.dataFields.category = "name";
    series.alignLabels = false;

    series.colors.list = [
      am4core.color("#ff9494"),
      am4core.color("#8bcaff"),
      am4core.color("#88c46d"),
      am4core.color("#6696d0"),
    ];
    series.ticks.template.events.on("ready", hideSmall);
    series.ticks.template.events.on("visibilitychanged", hideSmall);
    series.labels.template.events.on("ready", hideSmall);
    series.labels.template.events.on("visibilitychanged", hideSmall);

    function hideSmall(ev) {
      if (ev.target.dataItem && ev.target.dataItem.values.value.percent === 0) {
        ev.target.hide();
      } else {
        ev.target.show();
      }
    }

    return () => {
      x.dispose();
    };
  }, [listForViews]);

  return (
    <div className="w-full">
      <div>
        <span className={classes.title}>
          Tracking Penawaran dan Menang Pemilihan
        </span>
      </div>

      <Box boxShadow={3} className="mt-3">
        <div className="text-black-2 flex justify-center text-sm pt-4">
          Riwayat Pemilihan Pelaku Usaha
        </div>

        <div
          id="tracking-penawaran"
          style={{ width: "100%", height: 400 }}
        ></div>

        <div className="flex flex-row justify-between mt-4">
          {listStatistik?.map((option, index) => (
            <Box
              key={index}
              className={classNames(classDataYear, "px-4 py-2", {
                "text-pink-header": activeIndex === index,
                "text-black-2": activeIndex !== index,
              })}
              onClick={() => setActiveIndex(index)}
              component="button"
              sx={{
                bgcolor: activeIndex === index ? "grey.300" : "grey.400",
              }}
            >
              <p className="text-lg font-semibold">{option.year}</p>
              <p className="text-sm">{option.count}</p>
            </Box>
          ))}
        </div>
        {/* <div className="flex justify-between w-full items-center pt-5 px-4 mb-6 pb-2">
          <ViewRole>
            <div className="justify-between flex items-center space-x-3">
              <span className="font-montserrat font-medium text-sm text-black-2">
                Unduh data
              </span>

              <button onClick={downloadStatusXlsx} className="font-montserrat font-medium text-sm text-black-2 p-2 rounded bg-welcome">
                .xls
              </button>
              <button onClick={downloadStatusPdf} className="font-montserrat font-medium text-sm text-black-2 p-2 rounded bg-welcome">
                .pdf
              </button>
            </div>
          </ViewRole>
        </div> */}
        {/* {isLoading && (
          <CircularProgress color="inherit" size={20} />
        )} */}
      </Box>
    </div>
  );
}
