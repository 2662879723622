import React from "react";
import { useFormik } from "formik";
import services from "../services";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "components/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import TextFieldPassword from "components/TextField/Password";
import { Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

function ChangePassword(props) {
  const { biodata: sourceBiodata } = props;

  const [message, setMessage] = React.useState({
    open: false,
    severity: "",
    message: "",
  });

  // eslint-disable-next-line
  const { handleSubmit, getFieldProps, values, setFieldError, errors } =
    useFormik({
      initialValues: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      // validationSchema: user_id === "1" ? AddBusinessSchema : AddBusinessEntitySchema,
      onSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        services.postProfile(
          { action: "change-password", ...values },
          (isSuccess, data) => {
            if (isSuccess) {
              setMessage({
                open: true,
                severity: "success",
                message: "Edit Profile Berhasil",
              });
              window.timeout = setTimeout(() => {
                window.location.reload();
              }, 1500);
            } else {
              const { response } = data;
              if (response.status === 422) {
                const { errors } = response.data;
                if (errors) {
                  Object.keys(errors).forEach((attributeName) => {
                    setFieldError(
                      attributeName,
                      errors[attributeName].toString()
                    );
                    setMessage({
                      open: true,
                      severity: "error",
                      message: errors[attributeName].toString(),
                    });
                  });
                }
              }
            }
          },
          {
            success: (response) => {
              console.log(response);
            },
            error: (err) => {
              console.log(err);
            },
          }
        );
      },
    });

  return (
    <div>
      <div className="pt-4 self-start font-baloo2 font-bold text-base text-black-2 pb-6">
        Keamanan
      </div>
      <form onSubmit={handleSubmit} className="w-9/12">
        <table className="w-full">
          <tbody className="inline-block w-full">
            <tr className="font-montserrat font-medium text-black-2 text-sm">
              <td
                className="text-right font-bold text-black-2 text-sm label-required"
                style={{ minWidth: 250 }}
              >
                Password Lama :
              </td>
              <td className="py-2 pl-3">
                <TextFieldPassword
                  name="old_password"
                  placeholder="Masukkan password lama"
                  className="bg-white"
                  {...getFieldProps("old_password")}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="py-2 pl-3">
                <Typography
                  variant="subtitle2"
                  color="primary"
                  component={RouterLink}
                  to="/forgot_password"
                >
                  Lupa Password ?
                </Typography>
              </td>
            </tr>
            <tr className="font-montserrat font-medium text-black-2 text-sm">
              <td className="text-right font-bold text-black-2 text-sm label-required">
                Password Baru :
              </td>
              <td className="py-2 pl-3">
                <TextFieldPassword
                  name="password"
                  placeholder="Masukkan password baru"
                  className="bg-white"
                  {...getFieldProps("password")}
                />
              </td>
            </tr>

            <tr className="font-montserrat font-medium text-black-2 text-sm">
              <td className="text-right font-bold text-black-2 text-sm label-required">
                Konfirmasi Password Baru :
              </td>
              <td className="py-2 pl-3">
                <TextFieldPassword
                  className="bg-white"
                  placeholder="Konfirmasi password baru"
                  name="password_confirmation"
                  {...getFieldProps("password_confirmation")}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="pl-3 py-2 w-full">
                <button
                  type="submit"
                  className="bg-pink-header rounded px-4 py-2 text-white font-montserrat font-medium text-sm"
                >
                  Ubah Password
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <Dialog
        visible={message.open === true}
        onClose={message.open !== true}
        className="mt-3"
      >
        <Alert severity={message.severity} className="flex items-center">
          {message.message}
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setMessage({ open: false, error: "", message: "" });
            }}
          >
            <CloseIcon alt="" className="ml-3 self-center" />
          </IconButton>
        </Alert>
      </Dialog>
      {errors && (
        <Dialog
          visible={message.open === true}
          onClose={message.open !== true}
          className="mt-3"
        >
          <Alert severity={message.severity} className="flex items-center">
            {message.message}
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setMessage({ open: false, error: "", message: "" });
              }}
            >
              <CloseIcon alt="" className="ml-3 self-center" />
            </IconButton>
          </Alert>
        </Dialog>
      )}
    </div>
  );
}

export default ChangePassword;
