import React from "react";
import Footer from "components/footer";
import Header from "components/header";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Thumbnail from "assets/content.png";
import Box from "@material-ui/core/Box";
import Carousel from "components/carousel";
import * as services from "../services";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { getBacaJuga, getBeritaLainnya } from "redux/reducers/news";
import Page from "components/Page";

function NewsDetail() {
  const { id } = useParams();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    customPaging: (i) => {
      return <div></div>;
    },
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1982,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1535,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const { beritaLainya, bacaJuga } = useSelector((state) => state.news);
  const dispatch = useDispatch();
  const [detailResponse, setResponse] = React.useState([]);

  React.useEffect(() => {
    dispatch(getBacaJuga());
  }, []);

  React.useEffect(() => {
    dispatch(getBeritaLainnya(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    services.getBeritaUsahaDetail(
      {
        id: id,
      },
      (data) => setResponse(data)
    );
  }, [id]);

  return (
    <Page title={detailResponse?.judul}>
      <Header shortcutLink={true} />
      <Breadcrumb
        className="pt-5 lg:px-20 px-6"
        links={[
          { name: "Beranda", href: "/" },
          { name: "Berita Usaha", href: "goback" },
          { name: "Detail" },
        ]}
      />

      <div className="flex sm:flex-row flex-col flex-1 pt-14">
        <div className="sm:w-3/4 w-full">
          <div className="sm:mb-20 w-10/12 mx-auto bg-border-on-pink">
            <div className="font-baloo2 font-bold text-3xl text-black-2 w-11/12">
              {detailResponse?.judul}
            </div>
            <div className="pt-4 font-montserrat font-medium text-sm text-search">
              {DateTime.fromISO(detailResponse?.created_at).toFormat(
                "dd LLL yyyy"
              )}
            </div>
            <div className="w-1/12 pt-4 border-pink-header border-solid border-b-2" />
            <img
              src={`${detailResponse?.gambar_utama_url}`}
              alt=""
              className="pt-4 w-11/12"
            />
            <div
              className="font-montserrat pt-8 font-medium text-sm leading-6 text-black-2 w-11/12 text-justify ck-content"
              dangerouslySetInnerHTML={{
                __html: detailResponse?.deskripsi,
              }}
            />

            <div className="pt-16">
              <div className="font-baloo2 font-bold text-black-2 text-base">
                Lampiran
              </div>
              <div className="border-b border-solid border-welcome flex flex-col pt-2 pb-4 font-montserrat text-blue-second font-medium text-xs pl-6">
                {detailResponse?.lampiran1 !== null && (
                  <a
                    href={`${detailResponse?.lampiran1_url}`}
                    className="w-1/3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Lampiran 1
                  </a>
                )}
                {detailResponse?.lampiran2 !== null && (
                  <a
                    className="pt-1 w-1/3"
                    target="_blank"
                    rel="noreferrer"
                    href={`${detailResponse?.lampiran2_url}`}
                  >
                    Lampiran 2
                  </a>
                )}
                {detailResponse?.lampiran3 !== null && (
                  <a
                    className="pt-1 w-1/3"
                    target="_blank"
                    rel="noreferrer"
                    href={`${detailResponse?.lampiran3_url}`}
                  >
                    Lampiran 3
                  </a>
                )}
              </div>
            </div>

            <div className="font-baloo2 font-bold text-base pt-4 pb-2">
              Kontak
            </div>

            <div className="flex flex-col sm:flex-row items-start border-b border-gray-300 pb-5 w-11/12">
              <div className="sm:w-1/2 md:w-64 w-full pr-5 sm:border-r sm:border-gray-300">
                <table className="font-montserrat font-medium text-sm text-black-2">
                  <tr>
                    <td className="font-bold text-black">Narahubung</td>
                    <td className="px-6">:</td>
                    <td>{detailResponse?.user?.full_name}</td>
                  </tr>
                  <tr>
                    <td className="font-bold text-black">No telepon</td>
                    <td className="px-6">:</td>
                    <td>{detailResponse?.user?.phone}</td>
                  </tr>
                  <tr className="sm:hidden">
                    <td className="font-bold text-black">Email</td>
                    <td className="px-6">:</td>
                    <td>
                      <a
                        href={`mailto:${detailResponse?.user?.email}`}
                        className="text-blue-second"
                      >
                        {detailResponse?.user?.email}
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="sm:w-1/2 hidden sm:block w-full pl-5">
                <table className="font-montserrat font-medium text-sm text-black-2">
                  <tr>
                    <td className="font-bold text-black">Email</td>
                    <td className="px-6">:</td>
                    <td>
                      <a
                        href={`mailto:${detailResponse?.user?.email}`}
                        className="text-blue-second"
                      >
                        {detailResponse?.user?.email}
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div className="sm:pb-16 pb-10 pt-6 font-montserrat font-medium text-search text-sm">
              Artikel ini terakhir diperbarui{" "}
              {DateTime.fromISO(detailResponse?.updated_at).toFormat(
                "dd LLL yyyy"
              )}
            </div>
          </div>
        </div>

        <div className="sm:w-4/12 pb-16 sm:pb-0 w-full flex flex-col items-center sm:text-left">
          <div className="font-baloo2 text-left font-bold text-2xl text-black-2">
            Berita Populer
          </div>
          {beritaLainya.map((_item) => (
            <Box
              className="flex flex-col lg:w-8/12 w-10/12"
              component={Link}
              to={`/news_detail/${_item.id}`}
            >
              <div className="pt-4 font-montserrat font-bold text-sm text-black-2">
                {_item.judul}
              </div>
              <div className="font-montserrat font-medium text-sm text-search pb-4">
                {DateTime.fromISO(_item?.updated_at).toFormat(
                  "dd LLL yyyy HH:mm"
                )}
              </div>
              <div className="border-b border-solid border-welcome" />
            </Box>
          ))}
        </div>
      </div>

      <div className="">
        <div className="text-black-2 font-baloo2 font-bold text-2xl pl-20">
          Baca juga
        </div>
        <div className="block xl:w-11/12 w-11/12 mx-auto">
          <Carousel
            {...settings}
            dotsClass="slick-dots-home"
            className="xl:px-32"
            newsDetail={true}
          >
            {bacaJuga.map((_items) => (
              <Box
                className="px-4 py-4"
                component={Link}
                to={`/news_detail/${_items.id}`}
              >
                <Box
                  className="bg-border-on-pink p-4 lg:w-64 md:w-48 sm:w-44 w-full"
                  boxShadow={5}
                  sx={{ borderRadius: 8 }}
                >
                  <img
                    src={_items.thumbnail_url}
                    alt=""
                    className="h-40 w-full object-cover"
                  />
                  <p className="pt-2 font-baloo2 font-bold text-base text-black-2 line-clamp-2">
                    {_items.judul}
                  </p>
                  <p className="pt-3 text-search font-medium text-sm font-montserrat pb-4">
                    {DateTime.fromISO(_items?.updated_at).toFormat(
                      "dd LLL yyyy HH:mm"
                    )}
                  </p>
                  <div
                    className="border-b-2 border-solid border-pink-header"
                    style={{ width: "20%" }}
                  ></div>
                </Box>
              </Box>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="pt-40">
        <Footer />
      </div>
    </Page>
  );
}

export default NewsDetail;
