import React from "react";
import Navigation from "components/Navigation";
import Footer from "components/footer";
import Page from "components/Page";
import Box from "@material-ui/core/Box";
import useGlobalStyles from "hooks/useGlobalStyles";
import Breadcrumb from "components/Breadcrumb";
import { Link } from 'react-router-dom'
import SearchIcon from "@material-ui/icons/Search";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import MailIcon from "@material-ui/icons/Mail";
import LanguageIcon from "@material-ui/icons/Language";

export default function Trending() {
  const classes = useGlobalStyles();

  return (
    <Page title="Trending">
      <Box className={classes.container}>
        <Navigation />
        <div className="py-10 px-20">
          <Breadcrumb
            links={[
              { href: "/", name: "Beranda" },
              { href: "/search", name: "Pencarian Vendor" },
              { name: "Pelaku Usaha Trending" },
            ]}
          />

          <div className="flex justify-between pt-10">
            <div className="mb-8 font-baloo2 font-bold text-3xl text-black-2">
              Pelaku Usaha Trending
              <div className="border-b-2 border-solid border-pink-header w-6/12 mb-8 pt-10" />
            </div>
          </div>

          <div>
            <Box
              component="div"
              className="p-2 inline-flex bg-grey-3 rounded"
              boxShadow={3}
            >
              <div className="relative w-auto">
                <input
                  name="search"
                  className="w-96 rounded p-2 font-arial italic text-xs border border-gray-400 text-black-2 focus:border-pink-700"
                  placeholder="Ketikkan keyword pencarian"
                />
                <button
                  className="absolute right-0 top-1/2 bottom-1/2 my-auto w-10 h-10 flex items-center justify-center focus:outline-none"
                  type="submit"
                >
                  <SearchIcon style={{ color: "#595959" }} />
                </button>
              </div>
              <select className="p-2 pr-10 ml-2 rounded border border-gray-400 text-black-2 text-xs font-roboto form-select">
                <option value="">Semua Jenis Pengadaan</option>
              </select>
              <select className="p-2 pr-10 ml-2 rounded border border-gray-400 text-black-2 text-xs font-roboto form-select">
                <option value="">Semua Provinsi</option>
                <option>Paling Populer</option>
              </select>
              <button className="text-xs font-arial font-bold ml-2 px-4 py-2 border border-solid border-pink-header bg-pink-header rounded text-neutral-grey">
                Cari
              </button>
            </Box>
            <p className="text-black-2 font-montserrat text-base mt-5">
              Pelaku usaha yang sedang naik daun, diurut berdasarkan credit
              score tertinggi
            </p>
          </div>

          <div className="w-8/12 my-10 min-h-screen">
            <Box className="w-full flex items-start rounded-lg" sx={{ px: 2, py: 2 }} boxShadow={5}>
              <div className="w-3/12">
                <div className="border-b-2 border-pink-header pb-3 px-3">
                  <span className="font-baloo2 text-4xl font-bold text-black-2">
                    #1
                  </span>
                </div>

                <div className="pt-16">
                  <p className="font-baloo2 text-2xl text-black-2 text-center font-semibold">
                    Credit Score:
                  </p>
                  <p className="font-baloo2 text-4xl text-black-2 text-center font-semibold">
                    98,5%
                  </p>
                </div>
              </div>

              <div className="w-5/12 border-l border-r border-gray-200 px-4 mx-4">
                <img
                  alt=""
                  src="https://sc01.alicdn.com/kf/HTB1luT2gf1TBuNjy0Fjq6yjyXXaC/220034143/HTB1luT2gf1TBuNjy0Fjq6yjyXXaC.jpg"
                  className="w-full h-auto object-cover"
                />
              </div>

              <div className="w-full">
                <p className="text-lg text-black-2 font-semibold">
                  Berkat Cahaya Anugerah Mandiri
                </p>

                <div className="my-4">
                  <p className="text-lg text-black-2">
                    <span className="font-semibold">Pengalaman:</span> 5 tahun
                  </p>
                  <p className="text-lg text-black-2 font-normal">Konstruksi</p>
                </div>

                <div className="flex flex-row items-start pt-2 font-arial font-normal text-sm text-black-2">
                  <LocationOnIcon color="primary" />
                  <div className="font-medium pl-2 capitalize">
                    Jakarta Utara, DKI Jakarta
                  </div>
                </div>
                <div className="flex flex-row items-center pt-2">
                  <LocalPhoneIcon color="primary" />
                  <a
                    href="tel:02129292929"
                    className="px-2 font-arial font-normal text-sm text-black-2"
                  >
                    02129292929
                  </a>
                </div>

                <div className="flex underline flex-row items-center pt-1">
                  <MailIcon color="primary" />
                  <a
                    href="mailto:test8@gmail.com"
                    className="px-2 font-arial font-normal text-sm text-black-2"
                    style={{ color: "#8C8C8C" }}
                  >
                    test8@gmail.com
                  </a>
                </div>

                <div className="flex underline flex-row items-center pt-1">
                  <LanguageIcon color="primary" />
                  <a
                    href="https://google.co.id"
                    className="px-2 font-arial font-normal text-sm text-black-2"
                    style={{ color: "#8C8C8C" }}
                  >
                    https://google.co.id
                  </a>
                </div>

                <div className="flex items-center justify-end">
                  <Link className="text-pink-header text-base font-semibold font-montserrat" to="/">
                    Selengkapnya >
                  </Link>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <Footer />
      </Box>
    </Page>
  );
}
