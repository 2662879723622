import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import classNames from "classnames";
import { makeStyles, createStyles } from "@material-ui/styles";
import ViewRole from "components/ViewRole";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: "Baloo 2",
      fontWeight: 700,
      color: "#595959",
      fontSize: 18,
    },
  })
);
export default function PelakuMengikutiPKP(props) {
  const classes = useStyles();

  const { data, options, activeIndex, onChange } = props;
  const [isLoading, setIsLoading] = React.useState(false);

  const classDataYear =
    "flex flex-1 flex-col font-montserrat font-medium text-xs";

  return (
    <div>
      <div>
        <span className={classes.title}>Pelaku Usaha yang Mengikuti PKP</span>
      </div>
      <div
        className="rounded border-border-on-pink mt-3"
        style={{
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="text-black-2 flex justify-center text-base pt-4">
          Pelaku Usaha yang Mengikuti PKP
        </div>
        <Bar
          className="px-10 pb-12 pt-10"
          data={data}
          plugins={[ChartDataLabels]}
          options={{
            plugins: {
              legend: { display: false },
              datalabels: {
                color: "#595959",
                anchor: "end",
                align: "end",
              },
            },
          }}
        />
        <div className="flex flex-row justify-between mt-4">
          {options.map((option, index) => (
            <Box
              key={index}
              className={classNames(classDataYear, "px-4 py-2", {
                "text-pink-header": activeIndex === index,
                "text-black-2": activeIndex !== index,
              })}
              onClick={() => onChange(index)}
              component="button"
              sx={{
                bgcolor: activeIndex === index ? "grey.300" : "grey.400",
              }}
            >
              <p className="text-lg font-semibold">{option.year}</p>
              <p className="text-sm">{option.total}</p>
            </Box>
          ))}
        </div>
        <div className="flex justify-between w-full items-center pt-5 px-4 mb-6 pb-2">
          <div className="font-montserrat font-medium text-sm text-black-2">
          </div>

          <ViewRole>
            <div className="justify-between flex items-center space-x-3">
              <span className="font-montserrat font-medium text-sm text-black-2">
                Unduh data
              </span>

              <button className="font-montserrat font-medium text-sm text-black-2 p-2 rounded bg-welcome">
                .xls
              </button>
              <button className="font-montserrat font-medium text-sm text-black-2 p-2 rounded bg-welcome">
                .pdf
              </button>
            </div>
          </ViewRole>
        </div>
        {isLoading && (
          <CircularProgress color="inherit" size={20} />
        )}
      </div>
    </div>
  );
}

PelakuMengikutiPKP.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.array,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.array,
        backgroundColor: PropTypes.string,
      })
    ),
  }),
  options: PropTypes.array,
  activeIndex: PropTypes.number,
  onChange: PropTypes.func,
};

PelakuMengikutiPKP.defaultProps = {
  data: {
    labels: [],
    datasets: [],
  },
  options: [],
  activeIndex: 0,
};
