/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Footer from "components/footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import Carousel from "components/carousel";
import classnames from "classnames";
import { Link } from "react-router-dom";
import Peluang from "./components/Peluang";
import PranataLuar from "./components/PranataLuar";
import DataUsaha from "./components/DataUsaha";
import Welcome from "./components/Welcome";
import Navigation from "components/Navigation";
import classNames from "classnames";
import services from "./services";
import useParallaxHeader from "hooks/useParallaxHeader";
import get from 'lodash/get';

const settings = {
  slidesToShow: 1,
  arrows: true,
  dots: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  dotsClass: "slick-dots-home",
  infinite: true,
  customPaging: (i) => {
    return <div></div>;
  },
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: 110,
    },
    scrollMargin: {
      scrollMarginTop: "120px",
    },
    image: {
      width: "100%",
      height: 516,
      objectFit: "cover",

      [theme.breakpoints.down("lg")]: {
        height: 230,
      },
    },
  })
);

function Home() {
  const [detailResponse, setResponse] = React.useState([]);
  const [detailSettings, setResponseSettings] = React.useState([]);

  const [detailPranala, setResponsePranala] = React.useState([]);

  const [detailCount, setResponseCount] = React.useState([]);

  const [detailStatisticKriteriaUsaha, setResponseStatisticKriteriaUsaha] =
    React.useState([]);

  const [detailJenisPengadaan, setResponseJenisPengadaan] = React.useState([]);

  const [detailTerdaftarDiSikap, setResponseTerdaftarDiSikap] = React.useState(
    []
  );

  const jenisPengusaha = [
    { name: "pengadaan_langsung" },
    { name: "ukm_tender" },
    { name: "nonukm_tender" },
  ];

  const [detailData, setResponseData] = React.useState([]);

  const [detailTautanPintas, setResponseTautanPintas] = React.useState([]);

  const [yearValue, setYearValue] = React.useState(0);

  const [tahun, setTahun] = React.useState(new Date().getFullYear());

  const [bulan, setBulan] = React.useState(new Date().getMonth() + 1);

  const [value, setValue] = React.useState(0);

  const [dataYear] = React.useState([
    { year: 2021 },
    { year: 2020 },
    { year: 2019 },
  ]);

  const [typeUsaha, setTypeUsaha] = React.useState(0);

  React.useEffect(() => {
    services.getCarousel({}, (data) => setResponse(data.data));

    services.getPranala({}, (data) => setResponsePranala(data.data));

    services.getCountPelakuUsaha({}, (data) => setResponseCount(data));

    services.getStatisticKriteriaUsaha({}, (data) =>
      setResponseStatisticKriteriaUsaha(data)
    );

    services.getTerdaftarDiSikap({}, (data) =>
      setResponseTerdaftarDiSikap(data)
    );

    services.getJenisPengadaan({}, (data) => setResponseJenisPengadaan(data));

    services.getTautanPintas({}, (data) => setResponseTautanPintas(data));

    services.getSettings({}, (data) => setResponseSettings(data.data));
  }, []);

  React.useEffect(() => {
    services.getPeluangPBJP(
      {
        tahun: tahun,
        bulan: bulan,
        type: jenisPengusaha[typeUsaha].name,
      },
      (data) => setResponseData(data)
    );
  }, [bulan, tahun, typeUsaha]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  const background = useParallaxHeader()?.map((data) => data)[0]?.file_url;

  return (
    <Box className={classNames(classes.root, "font-montserrat")}>
      <Navigation />

      <div
        className={classNames(classes.scrollMargin, "w-full block bg-grey-3")}
        id="welcome"
      >
        <Carousel {...settings} classNameSlickDots="bg-grey-3">
          {detailResponse?.map(
            (item) =>
              item?.status === "1" && (
                <a
                  href={`${item?.link}`}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-black-2"
                  key={item.id}
                >
                  <img
                    src={item?.picture_url}
                    alt=""
                    className={classnames(
                      "w-full h-full object-contain",
                      classes.image
                    )}
                  />
                  <div className="flex flex-col justify-center items-start w-11/12 mx-auto">
                    <div className="font-baloo2 font-bold text-2xl text-white pt-6">
                      {item?.title}
                    </div>
                    <div
                      className="pb-10 font-montserrat text-neutral-grey font-medium text-xs pt-4"
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    ></div>
                  </div>
                </a>
              )
          )}
        </Carousel>
      </div>

      <Welcome data={detailCount} settings={detailSettings} />

      <DataUsaha
        yearValue={yearValue}
        value={value}
        setYearValue={setYearValue}
        dataYear={dataYear}
        detailStatisticKriteriaUsaha={
          detailStatisticKriteriaUsaha ? detailStatisticKriteriaUsaha : []
        }
        detailTerdaftarDiSikap={
          detailTerdaftarDiSikap ? detailTerdaftarDiSikap : []
        }
        detailJenisPengadaan={detailJenisPengadaan ? detailJenisPengadaan : []}
        className={classes.scrollMargin}
        handleChange={handleChange}
        detailTautanPintas={detailTautanPintas ? detailTautanPintas : []}
      />

      <Peluang
        className={classes.scrollMargin}
        detailData={detailData}
        setTahun={setTahun}
        setBulan={setBulan}
        typeUsaha={typeUsaha}
        setTypeUsaha={setTypeUsaha}
        settings={get(detailSettings, 'konten_dinamis')}
      />

      <div
        className={classNames(
          classes.scrollMargin,
          "bg-blue flex lg:flex-row flex-col lg:items-center items-start lg:space-y-0 space-y-4 justify-between py-12 lg:px-20 px-6"
        )}
        id="question"
        style={{
          backgroundImage: `url('${background}')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <div>
          <div className="font-montserrat text-md text-white">
            Ingin tahu cara mendaftar dan kelebihan-kelebihan menjadi pelaku
            usaha terdaftar lainnya?
          </div>
          <div className="font-montserrat text-xl font-bold text-white">
            Dapatkan jawaban terkait layanan LKPP BISA Pengadaan!
          </div>
          <div className="pt-10 border-b border-solid border-pink-header w-3/12" />
        </div>
        <div>
          <Link to="/faq">
            <button className="bg-black-2 px-4 py-2 text-neutral-grey font-montserrat font-medium text-xs rounded">
              Lihat FAQ
            </button>
          </Link>
          <Link to="/contact_us">
            <button className="bg-pink-header px-4 py-2 text-neutral-grey font-montserrat font-medium text-xs rounded ml-4">
              Hubungi Kami
            </button>
          </Link>
        </div>
      </div>

      <PranataLuar data={detailPranala} />

      <div className="pt-16">
        <Footer />
      </div>
    </Box>
  );
}

export default Home;
