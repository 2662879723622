import React from "react";
import Box from "@material-ui/core/Box";
import classNames from "classnames";
import { makeStyles, createStyles } from "@material-ui/styles";
import token from "utils/session";
import axios from "axios";
import ViewRole from "components/ViewRole";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import CircularProgress from "@material-ui/core/CircularProgress";
import endpoint from "utils/endpoint";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: "Baloo 2",
      fontWeight: 700,
      color: "#595959",
      fontSize: 18,
    },
  })
);

export default function JenisPengadaan(props) {
  const classes = useStyles();

  const { data, options, activeIndex, onChange, jenisPengadaan = [] } = props;
  const [isLoading, setIsLoading] = React.useState(false);

  const classDataYear =
    "flex flex-1 flex-col font-montserrat font-medium text-xs";

  const trueJenisPengadaan = jenisPengadaan?.map((data) => data)?.[activeIndex];

  const downloadStatusXlsx = () => {
    setIsLoading(true);
    axios({
      url: `${endpoint.exportTableData}`, //your url
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: `Bearer ${token}`,
        ContentDisposition: `attachment`,
      },
      params: {
        chart: "Jenis Pengadaan",
        year: trueJenisPengadaan?.year,
        format: "xlsx",
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Jenis Pengadaan.xlsx'); //or any other extension
      link.click();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    });  
  }

  const downloadStatusPdf = () => {
    setIsLoading(true);
    axios({
      url: `${endpoint.exportTableData}`, //your url
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: `Bearer ${token}`,
        ContentDisposition: `attachment`,
      },
      params: {
        chart: "Jenis Pengadaan",
        year: trueJenisPengadaan?.year,
        format: "pdf",
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Jenis Pengadaan.pdf'); //or any other extension
      link.click();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    });  
  }

  const dataRender = (canvas) => {
    return {
      labels: canvas?.data?.map((data) => data?.name),
      datasets: [
        {
          data: canvas?.data?.map((data) => data?.value),
          backgroundColor: [
            "rgba(244, 177, 130, 1)",
            "rgba(253, 148, 148, 1)",
            "rgba(102, 150, 208, 1)",
            "rgba(102, 208, 150, 1)",
          ],
          borderColor: [
            "rgba(54, 162, 235, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(54, 162, 235, 0.2)",
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  const chart = React.useRef(null)
  React.useLayoutEffect(() => {
    let x = am4core.create("jenis-pengadaan", am4charts.PieChart3D);

    chart.current = x;

    x.hiddenState.properties.opacity = 0;
    x.data = trueJenisPengadaan?.data?.map((_item) => ({
      category: _item.name,
      value: _item.value,
    }));

    x.legend = new am4charts.Legend();

    let series = x.series.push(new am4charts.PieSeries3D());

    series.dataFields.value = "value";
    series.dataFields.category = "category";
    series.alignLabels = false

    series.colors.list = [
      am4core.color("#ff9494"),
      am4core.color("#8bcaff"),
      am4core.color("#88c46d"),
      am4core.color("#6696d0"),
    ];
    series.ticks.template.events.on("ready", hideSmall);
    series.ticks.template.events.on("visibilitychanged", hideSmall);
    series.labels.template.events.on("ready", hideSmall);
    series.labels.template.events.on("visibilitychanged", hideSmall);
    
    function hideSmall(ev) {
      if (ev.target.dataItem && (ev.target.dataItem.values.value.percent === 0)) {
        ev.target.hide();
      }
      else {
        ev.target.show();
      }
    }

    return () => {
      x.dispose();
    };
  }, [trueJenisPengadaan]);

  return (
    <div className="w-full">
      <div>
        <span className={classes.title}>Jenis Pengadaan</span>
      </div>
      <div
        className="w-full rounded border-border-on-pink mt-2"
        style={{
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="flex flex-col items-center">
          <div className="font-montserrat font-medium text-black-2 text-sm pt-4 pb-3">
            Jenis Pengadaan
          </div>

          <div id="jenis-pengadaan" style={{ width: '100%', height: 400 }}></div>
        </div>
        <div className="flex flex-row justify-between mt-4">
          {jenisPengadaan?.map((option, index) => (
            <Box
              key={index}
              className={classNames(classDataYear, "px-4 py-2", {
                "text-pink-header": activeIndex === index,
                "text-black-2": activeIndex !== index,
              })}
              onClick={() => onChange(index)}
              component="button"
              sx={{
                bgcolor: activeIndex === index ? "grey.300" : "grey.400",
              }}
            >
              <p className="text-lg font-semibold">{option.year}</p>
              <p className="text-sm">{option.count}</p>
            </Box>
          ))}
        </div>
        <div className="flex justify-between w-full items-center pt-5 px-4 mb-6 pb-2">
          <ViewRole>
            <div className="justify-between flex items-center space-x-3">
              <span className="font-montserrat font-medium text-sm text-black-2">
                Unduh data
              </span>

              <button onClick={downloadStatusXlsx} className="font-montserrat font-medium text-sm text-black-2 p-2 rounded bg-welcome">
                .xls
              </button>
              <button onClick={downloadStatusPdf} className="font-montserrat font-medium text-sm text-black-2 p-2 rounded bg-welcome">
                .pdf
              </button>
            </div>
          </ViewRole>
        </div>
        {isLoading && (
          <CircularProgress color="inherit" size={20} />
        )}
      </div>
    </div>
  );
}
