import React from 'react'
import Footer from 'components/footer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';
import VDLogo from "assets/logo-vd.png";
import Image from 'assets/undraw_conference_call_b0w6 1.png';
import ForgotPasswordAction from './ForgotPasswordAction'
import ResetPasswordAction from './ResetPasswordAction'

function ForgetPassword(props) {
  const {type} = props;
  
  return (
    <div>
      <div className="flex pt-5 flex-col">
        <Breadcrumbs>
          <Link className="font-montserrat font-medium text-sm text-pink-header lg:pl-20 pl-8" to="/">
            Beranda
          </Link>
          <Link className="font-montserrat font-medium text-sm text-black-2" to={window.location.pathname} hash="/#">
            Lupa Password
          </Link>
        </Breadcrumbs>
      </div>
      <div className="py-5 flex w-full justify-center">
        <img src={VDLogo} alt="" style={{ height: 70 }} />
      </div>
      <div className="w-10/12 mx-auto flex lg:flex-row flex-col mb-20" style={{boxShadow: '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05'}}>
        <div className="lg:w-1/2 w-full pt-4 lg:pl-12 pl-6 pr-6 lg:pb-14 pb-6 flex justify-center">
          <img src={Image} alt="" className="pt-8" />
        </div>

        {type === 'forgot' ? (
          <ForgotPasswordAction/>
        ) : (
          <ResetPasswordAction />
        )}
      </div>
      <Footer />
    </div>
  )
}

export default ForgetPassword
