import React, { useState, useEffect } from "react";
import Questions from "assets/questions.png";
import services from "../services";

import QuestionModal from "components/modal/components/Question";
import SearchProvince from "components/modal/components/SearchProvince";
import SearchActivity from "components/modal/components/SearchActivity";

import { Box } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import classnames from 'classnames'
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 300,
    },
  })
);

function FilterLeftSide(props) {
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const [modalState, setModalState] = useState({
    questionModal: false,
    provinceModal: false,
    activityModal: false,
  });

  const questionModalHandler = () => {
    setModalState((prev) => ({
      ...prev,
      questionModal: !modalState.questionModal,
    }));
  };

  const provinceModalHandler = () => {
    setModalState((prev) => ({
      ...prev,
      provinceModal: !modalState.provinceModal,
    }));
  };

  const activityModalHandler = () => {
    setModalState((prev) => ({
      ...prev,
      activityModal: !modalState.activityModal,
    }));
  };

  const [detailResponse, setResponse] = useState({
    provinsi: [],
    aktifitas: [],
    bentuk: [],
    kriteria: [],
    jenisPengadaan: [],
    pbjp: [],
  });

  useEffect(() => {
    services.getProvinsi({}, (response, err) => {
      if (!err) {
        setResponse((prev) => ({ ...prev, provinsi: response.data }));
      }
    });

    services.getAktifitas({}, (response, err) => {
      if (!err) {
        setResponse((prev) => ({ ...prev, aktifitas: response.data }));
      }
    });

    services.getBentukUsaha({}, (response, err) => {
      if (!err) {
        setResponse((prev) => ({ ...prev, bentuk: response.data }));
      }
    });

    services.getKriteriaUsaha({}, (response, err) => {
      if (!err) {
        setResponse((prev) => ({ ...prev, kriteria: response.data }));
      }
    });

    services.getJenisPengadaan({}, (response, err) => {
      if (!err) {
        setResponse((prev) => ({ ...prev, jenisPengadaan: response.data }));
      }
    });

    services.getPBJP({}, (response, err) => {
      if (!err) {
        setResponse((prev) => ({ ...prev, pbjp: response.data }));
      }
    });
  }, []);

  const onUserInputYearFrom = (e) => {
    const textSearch = e.target.value;
    props?.setYearFrom(textSearch);

    clearTimeout(window.timeout);

    window.timeout = setTimeout(() => {
      props?.setYearFrom(textSearch);
    }, 1000);
  };

  const onUserInputYearTo = (e) => {
    const textSearch = e.target.value;
    props?.setYearTo(textSearch);

    clearTimeout(window.timeout);

    window.timeout = setTimeout(() => {
      props?.setYearTo(textSearch);
    }, 1000);
  };

  const classes = useStyles();

  const { className } = props

  return (
    <div className={classnames(className, classes.root)}>
      <Box
        className="px-6 py-6 border border-solid bg-border-on-pink rounded"
        boxShadow={5}
      >
        <div className="flex flex-col pb-5 border-b border-solid border-welcome">
          <span className="font-montserrat font-extrabold text-base text-black-2">
            Provinsi ({props?.selectedProvince?.length})
          </span>

          <div className="flex flex-col pt-5 font-montserrat font-medium text-sm text-black-2">
            {detailResponse?.provinsi?.map((data, index) => {
              // eslint-disabled-next-line
              const isChecked =
                props?.selectedProvince
                  ?.map((data) => Number(data))
                  ?.indexOf(data?.id) >= 0;

              return (
                index < 5 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={() => props?.handleProvinceChange(data)}
                        color="primary"
                      />
                    }
                    key={data.id}
                    label={titleCase(data.name)}
                  />
                )
              );
            })}

            <button
              className="ml-7 flex items-center font-montserrat font-bold text-sm text-pink-header"
              onClick={() => provinceModalHandler()}
            >
              Lainnya...
              <ExpandMoreIcon color="inherit" />
            </button>
          </div>
        </div>

        <div className="flex flex-col font-montserrat font-bold text-xs text-black-2 pt-4 pb-5 border-b border-solid border-welcome">
          <span className="font-montserrat font-extrabold text-base text-black-2">
            Bentuk Usaha ({props?.selectedBusinessType?.length})
          </span>
          <div className="flex flex-col pt-5 font-montserrat font-medium text-sm text-black-2">
            {detailResponse?.bentuk?.map((data, index) => {
              const isChecked =
                props?.selectedBusinessType
                  ?.map((data) => Number(data))
                  ?.indexOf(data?.id) >= 0;

              return (
                index < 5 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => props?.handleBusinessTypeChange(data)}
                        checked={isChecked}
                        color="primary"
                      />
                    }
                    key={data.id}
                    label={titleCase(data.name)}
                  />
                )
              );
            })}
          </div>
        </div>

        <div className="flex flex-col font-montserrat font-bold text-xs text-black-2 pt-4 pb-5 border-b border-solid border-welcome">
          <span className="font-montserrat font-extrabold text-base text-black-2">
            Aktivitas Usaha ({props?.selectedBusinessActivity?.length})
          </span>
          <div className="flex w-full flex-col pt-5 font-montserrat font-medium text-sm text-black-2">
            {detailResponse?.aktifitas?.map((data, index) => {
              const isChecked =
                props?.selectedBusinessActivity
                  ?.map((data) => Number(data))
                  ?.indexOf(data?.id) >= 0;

              return (
                index < 5 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          props?.handleBusinessActivityChange(data)
                        }
                        checked={isChecked}
                        color="primary"
                      />
                    }
                    key={data.id}
                    label={titleCase(data.name)}
                  />
                )
              );
            })}
          </div>
          <button
            className="ml-7 flex items-center font-montserrat font-bold text-sm text-pink-header"
            onClick={() => activityModalHandler()}
          >
            Lainnya...
            <ExpandMoreIcon color="inherit" />
          </button>
        </div>

        <div className="flex flex-col font-montserrat font-bold text-xs text-black-2 pt-4 pb-5 border-b border-solid border-welcome">
          <span className="font-montserrat font-extrabold text-base text-black-2">
            Tahun Pengalaman
          </span>
          <div className="flex flex-row items-center pt-4">
            <input
              onChange={(e) => onUserInputYearFrom(e)}
              value={props?.yearFrom}
              type="number"
              className="mr-auto w-5/12 form-input rounded"
              placeholder="Min"
              min="0"
            />
            <hr />
            <input
              onChange={(e) => onUserInputYearTo(e)}
              type="number"
              className="ml-auto w-5/12 form-input rounded"
              placeholder="Max"
              min="0"
              value={props?.yearTo}
            />
          </div>
        </div>

        <div className="flex flex-col pt-4 pb-5 border-b border-solid border-welcome">
          <div>
            <span className="font-montserrat font-extrabold text-base text-black-2 block">
              Pemilik Usaha
            </span>

            <div className="w-full mt-3">
              <span className="font-montserrat font-extrabold text-sm text-black-2 block">
                Orang Asli Papua
              </span>

              <select
                className="form-select w-full px-4 py-2 rounded-md border border-gray-300 mt-2"
                value={props?.isOap}
                onChange={(event) => props?.setIsOap(event.currentTarget.value)}
              >
                <option value="">Semua</option>
                <option value="1">Orang Asli Papua</option>
                <option value="0">Bukan Orang Asli Papua</option>
              </select>
            </div>

            <div className="w-full mt-2">
              <span className="font-montserrat font-extrabold text-sm text-black-2 block">
                Jenis Kelamin
              </span>

              <select
                className="form-select w-full px-4 py-2 rounded-md border border-gray-300 mt-2"
                value={props?.gender}
                onChange={(event) => props?.setGender(event.currentTarget.value)}
              >
                <option value="">Semua</option>
                <option value="male">Laki-Laki</option>
                <option value="female">Perempuan</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex flex-col font-montserrat font-bold text-xs text-black-2 pt-4 pb-5 border-b border-solid border-welcome">
          <span className="font-montserrat font-extrabold text-base text-black-2">
            Jenis Pengadaan ({props?.selectedProcurementType?.length})
          </span>
          <div className="flex flex-col pt-5 font-montserrat font-medium text-sm text-black-2">
            {detailResponse.jenisPengadaan.map((data, index) => {
              const isChecked =
                props?.selectedProcurementType
                  ?.map((data) => Number(data))
                  ?.indexOf(data?.id) >= 0;
              return (
                index < 5 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={() =>
                          props?.handleProcurementTypeChange(data)
                        }
                        color="primary"
                      />
                    }
                    key={data.id}
                    label={titleCase(data.name)}
                  />
                )
              );
            })}
          </div>
        </div>

        <div className="flex flex-col font-montserrat font-bold text-xs text-black-2 pt-4 pb-5 border-b border-solid border-welcome">
          <div className="flex justify-between items-center">
            <span className="font-montserrat font-extrabold text-base text-black-2">
              Kriteria Usaha ({props?.selectedBusinessCriteria?.length})
            </span>

            <button onClick={() => questionModalHandler()}>
              <HelpOutlineIcon color="primary" />
            </button>
          </div>

          <div className="flex flex-col pt-5 font-montserrat font-medium text-sm text-black-2">
            {detailResponse.kriteria.map((data, index) => {
              const isChecked =
                props?.selectedBusinessCriteria
                  ?.map((data) => Number(data))
                  ?.indexOf(data?.id) >= 0;

              return (
                index < 5 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          props?.handleBusinessCriteriaChange(data)
                        }
                        checked={isChecked}
                        color="primary"
                      />
                    }
                    key={data.id}
                    label={titleCase(data.name)}
                  />
                )
              );
            })}
          </div>
        </div>

        <div className="flex flex-col font-montserrat font-bold text-xs text-black-2 pt-4 pb-5 border-b border-solid border-welcome">
          <span className="font-montserrat font-extrabold text-base text-black-2">
            Pengalaman PBJP ({props?.selectedPBJP?.length})
          </span>
          <div className="flex flex-col pt-5 font-montserrat font-medium text-sm text-black-2">
            {detailResponse?.pbjp?.map((data, index) => {
              const isChecked = props?.selectedPBJP?.indexOf(data?.id) >= 0;

              return (
                index < 5 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => props?.handlePBJPChange(data)}
                        checked={isChecked}
                        color="primary"
                      />
                    }
                    key={data.id}
                    label={titleCase(data.name)}
                  />
                )
              );
            })}
          </div>
        </div>

        <div className="py-6 flex flex-col">
          <button
            onClick={props?.onHandleFilter}
            className="bg-pink-header rounded py-2 px-4 font-montserrat font-medium text-xs text-txt-white"
          >
            Filter
          </button>

          <button
            onClick={props?.onHandleReset}
            className="mt-3 border rounded py-2 px-4 border-solid box-border border-pink-header font-montserrat font-medium text-pink-header text-xs"
          >
            Reset
          </button>
        </div>
        <QuestionModal
          visible={modalState?.questionModal}
          onClose={() => questionModalHandler()}
        />

        <SearchProvince
          visible={modalState?.provinceModal}
          onClose={() => provinceModalHandler()}
          selectedProvince={props?.selectedProvince}
          setSelectedProvince={props?.setSelectedProvince}
          data={detailResponse?.provinsi}
          handleProvinceChange={props?.handleProvinceChange}
        />

        <SearchActivity
          visible={modalState?.activityModal}
          selectedBusinessActivity={props?.selectedBusinessActivity}
          setSelectedBusinessActivity={props?.setSelectedBusinessActivity}
          data={detailResponse.aktifitas}
          onClose={() => activityModalHandler()}
        />
      </Box>
    </div>
  );
}

FilterLeftSide.propTypes = {
  selectedProvince: PropTypes.array,
  handleProvinceChange: PropTypes.func,
  setSelectedProvince: PropTypes.func,
  selectedBusinessType: PropTypes.array,
  handleBusinessTypeChange: PropTypes.func,
  selectedBusinessActivity: PropTypes.array,
  handleBusinessActivityChange: PropTypes.func,
  setSelectedBusinessActivity: PropTypes.func,
  selectedBusinessCriteria: PropTypes.array,
  handleBusinessCriteriaChange: PropTypes.func,
  setSelectedBusinessCriteria: PropTypes.func,
  setSelectedProcurementType: PropTypes.func,
  selectedProcurementType: PropTypes.array,
  handleProcurementTypeChange: PropTypes.func,
  setYearFrom: PropTypes.func,
  setYearTo: PropTypes.func,
  yearFrom: PropTypes.string,
  yearTo: PropTypes.string,
  selectedPBJP: PropTypes.array,
  setselectedPBJP: PropTypes.func,
  handlePBJPChange: PropTypes.func,
  onHandleFilter: PropTypes.func,
  onHandleReset: PropTypes.func,
};

export default FilterLeftSide;
