import axios from "axios";
import endpoint from "utils/endpoint";
import token from "utils/session";
import Cookie from "js-cookie";

async function getPranala(params, callback) {
  const url = endpoint.pranala;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getCountPelakuUsaha(params, callback) {
  const url = endpoint.countPelakuUsaha;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getStatisticKriteriaUsaha(params, callback) {
  const url = `${endpoint.statisticKriteriaUsaha}`;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    await callback(response.data, null);
  } catch (error) {
    await callback([], error);
  }
}

async function postLogout(params, callback) {
  const url = endpoint.logout;
  const config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    await axios(url, config);
    Cookie.remove("SID_LKPP");
    window.location.replace("/");
  } catch (error) {
    console.log(error);
  }
}

async function getCarousel(params, callback) {
  const url = endpoint.carousel;
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url, config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    callback(error);
  }
}

async function getTerdaftarDiSikap(params, callback) {
  const url = `${endpoint.terdaftarDiSikap}`;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    await callback(response.data, null);
  } catch (error) {
    // console.log(error);
    await callback([], error);
  }
}

async function getJenisPengadaan(params, callback) {
  const url = `${endpoint.statisticJenisPengadaan}`;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    await callback(response.data, null);
  } catch (error) {
    // console.log(error);
    await callback([], error);
  }
}

async function getPengadaanLangsung(params, callback) {
  const url = `${endpoint.inaprocPengadaanLangsung}:${params?.tahunAnggaran}:${params?.tahunPemilihan}:${params?.bulanPemilihan}`;
  const config = {
    method: "GET",
    mode: "no-cors",
  };

  try {
    const response = await fetch(url, config);
    await callback(await response.json());
    console.log(await response.json());
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getPeluangPBJP(params, callback) {
  const url = `${endpoint.peluangPBJP}`;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getTautanPintas(params, callback) {
  const url = `${endpoint.tautanPintas}`;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getParallaxHeader(params, callback) {
  const url = `${endpoint.parallaxHeader}`;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getSettings(params, callback) {
  const url = `${endpoint.settings}`;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

export default {
  getPranala,
  postLogout,
  getCarousel,
  getCountPelakuUsaha,
  getStatisticKriteriaUsaha,
  getTerdaftarDiSikap,
  getJenisPengadaan,
  getPengadaanLangsung,
  getPeluangPBJP,
  getTautanPintas,
  getParallaxHeader,
  getSettings,
};
