import React from "react";
import Footer from "components/footer";
import Navigation from "components/Navigation";
import useGlobalStyles from "hooks/useGlobalStyles";
import Breadcrumb from "components/Breadcrumb";
import PetaPersebaran from "components/Statistic/PetaPersebaran";
import { useDispatch, useSelector } from "react-redux";
import {
  getLokasiUsaha,
  getLokasiGroupUsaha,
} from "redux/reducers/lokasiUsaha";
import { getKabupaten } from "redux/reducers/region";
import get from "lodash/get";
import debounce from "lodash/debounce";
import { Button, Dialog, Box, Link } from "@material-ui/core";
import Page from "components/Page";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";

export default function LokasiUsaha() {
  const classes = useGlobalStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { lokasiUsaha, lokasiPerGroup } = useSelector(
    (state) => state.lokasiUsaha
  );
  const { kabupaten } = useSelector((state) => state.region);
  const [params, setParams] = React.useState({
    q: null,
    page: 1,
    page_size: 10,
  });
  const [activities, setActivities] = React.useState({
    visible: false,
    content: [],
  });

  const totalUsaha = get(lokasiUsaha, "total", 0);
  const listUsaha = get(lokasiUsaha, "data", []);

  React.useEffect(() => {
    dispatch(getKabupaten());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch(getLokasiUsaha(params));
    dispatch(getLokasiGroupUsaha());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const columns = [
    {
      name: "No",
      cell: (row) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div>{get(row, "id")}</div>

            <button
              onClick={() => history.push(`/product_detail/${get(row, "id")}`)}
              style={{ paddingLeft: "10px" }}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.66667 0V1.33333H1.33333V10.6667H10.6667V7.33333H12V11.3333C12 11.5101 11.9298 11.6797 11.8047 11.8047C11.6797 11.9298 11.5101 12 11.3333 12H0.666667C0.489856 12 0.320286 11.9298 0.195262 11.8047C0.0702379 11.6797 0 11.5101 0 11.3333V0.666667C0 0.489856 0.0702379 0.320286 0.195262 0.195262C0.320286 0.0702379 0.489856 0 0.666667 0H4.66667ZM9.724 1.33333H6.66667V0H12V5.33333H10.6667V2.276L6 6.94267L5.05733 6L9.724 1.33333Z"
                  fill="#0073B7"
                />
              </svg>
            </button>
          </div>
        );
      },
      sortable: false,
      style: {
        fontFamily: "Roboto",
        fontSize: "12px",
      },
    },
    {
      name: "Nama Usaha",
      selector: (row) => get(row, "name"),
      sortable: true,
      grow: 1,
      style: {
        // width: "100%",
      },
    },
    {
      name: "Bentuk",
      selector: (row) => get(row, "business_type.name"),
      sortable: true,
      style: {},
    },
    {
      name: "Aktivitas Usaha",
      selector: (row) => {
        return (
          <button
            onClick={() =>
              setActivities({
                visible: true,
                content: row?.business_activities?.map((data) => data?.name),
              })
            }
          >
            {row?.business_activities?.map((data) => data?.name)}
          </button>
        );
      },
      grow: 2,
      sortable: true,
      style: {
        maxWidth: 400,
      },
      // onClick: (row) => setActivities({ visible: true, content: row?.business_activities?.map((data) => data?.name) }),
    },
    {
      name: "Kriteria Usaha",
      selector: (row) => get(row, "business_criteria.name"),
      grow: 1,
      sortable: true,
      style: {},
    },
    // {
    //   name: "KBLI",
    //   selector: (row) => row.kbli,
    //   sortable: true,
    // },
    {
      name: "Provinsi",
      selector: (row) => get(row, "province.name"),
      sortable: true,
      grow: 3,
      style: {},
    },
  ];

  return (
    <Page title="Lokasi Usaha" className={classes.container}>
      <Navigation />
      <div className="py-10 lg:px-20 px-6">
        <Breadcrumb
          links={[
            { href: "/", name: "Beranda" },
            { name: "Lokasi Pelaku Usaha" },
          ]}
        />

        <div className="flex justify-between pt-10">
          <div className="mb-8 font-baloo2 font-bold text-3xl text-black-2">
            Lokasi Pelaku Usaha
            <div className="border-b-2 border-solid border-pink-header w-6/12 mb-8 pt-10" />
          </div>
          <img
            src="/static/images/logo-LKPP-2.png"
            alt=""
            className="h-20 lg:block hidden"
          />
        </div>

        <div
          style={{
            boxShadow:
              "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
            borderRadius: "4px",
          }}
        >
          <div>
            <PetaPersebaran
              shadow={false}
              params={params}
              columns={columns}
              data={listUsaha}
              peta={lokasiPerGroup}
              hiddenTitle
              headers={{
                options: kabupaten,
                onChangeProvince: (ev) => {
                  setParams((prev) => ({
                    ...prev,
                    province_id: ev,
                    city_id: null,
                  }));
                },
                onChangeLocation: (
                  ev,
                  option = { city_id: null, province_id: null }
                ) => {
                  setParams((prev) => ({
                    ...prev,
                    city_id: option?.id,
                    province_id: option?.province_id,
                  }));
                },
                onChangeRowsPerPage: (ev) =>
                  setParams((prev) => ({
                    ...prev,
                    page_size: ev.target.value,
                    page: 1,
                  })),
                onSearch: debounce((ev) =>
                  setParams((prev) => ({ ...prev, q: ev.target.value }))
                ),
                province_id: params?.province_id,
              }}
              dataTable={{
                pagination: true,
                paginationServer: true,
                paginationTotalRows: totalUsaha,
                onChangeRowsPerPage: (perPage, newPage) =>
                  setParams((prev) => ({
                    ...prev,
                    page_size: perPage,
                    page: newPage,
                  })),
                onChangePage: (page) =>
                  setParams((prev) => ({ ...prev, page })),
              }}
            />
          </div>
          <div
            className="border-t border-gray-200 py-2 mx-auto"
            style={{ width: "97%" }}
          ></div>
          <div className="pt-4 pb-8 text-center text-black-2 w-10/12 mx-auto">
            <p className="font-arial font-semibold text-xl">
              Semakin banyak usaha di sekitar anda
            </p>
            <p className="font-arial font-semibold text-xl">
              Semakin mudah pula menemukannya
            </p>

            <p className="font-arial font-normal text-sm mt-6">
              BISA Pengadaan adalah tempat berkumpulnya pelaku usaha, sehingga
              memudahkan pencari usaha untuk menjalin kerjasama.Bergabung
              bersama pelaku usaha dan usaha lainnya dari seluruh nusantara, di
              sini.
            </p>

            <div className="mt-4">
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/register")}
              >
                Daftar Sekarang
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={activities.visible}
        maxWidth="md"
        fullWidth
        onClose={() => setActivities({ visible: false, content: [] })}
        onBackdropClick={() => setActivities({ visible: false, content: [] })}
      >
        <Box className="py-2 px-4">
          <div className="flex items-center justify-end">
            <CloseIcon
              className="cursor-pointer"
              color="grey.200"
              onClick={() => setActivities({ visible: false, content: [] })}
            />
          </div>
          <div className="p-4">
            <div className="mb-6">
              <span className="font-baloo2 font-bold text-lg text-black-2">
                Aktivitas Usaha
              </span>
            </div>

            <div className="bg-welcome p-2">
              <ol className="list-decimal pl-4 pt-4">
                {activities?.content?.map((name) => (
                  <li className="font-montserrat text-black-2 font-medium text-sm pb-1">
                    {name}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </Box>
      </Dialog>

      <Footer />
    </Page>
  );
}
