import React from "react";
import classnames from "classnames";
// import Phone from "assets/phoneFooter.png";
// import Mail from "assets/mail.png";
// import WWW from "assets/world.png";
// import Point from "assets/pinpoint.png";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";
import { SvgIcon } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import MailIcon from "@material-ui/icons/Mail";
import LanguageIcon from "@material-ui/icons/Language";
import parseObjectToString from 'utils/parseObjectToString';
// import ProfileServices from "pages/Profile/services";

const useStyles = makeStyles((theme) =>
  createStyles({
    productImage: {
      minHeight: 128,
    },
  })
);

function ProductCard(props) {
  const { ...other } = props?.data;
  const { 
    selectedBusinessActivity,
    // selectedBusinessCriteria,
    // selectedBusinessType,
    // selectedPBJP,
    selectedProcurementType,
    // selectedProvince,
    // yearFrom,
    // yearTo,
    // q
  } = props;

  let history = useHistory();

  const classes = useStyles();

  const querySearch = {
    selectedBusinessActivity: selectedBusinessActivity?.map(i => i)?.join(','),
    selectedProcurementType: selectedProcurementType?.map(i => i)?.join(','),
  }

  // const [certificate, setCertificate] = React.useState([]);

  // React.useEffect(() => {
  //   ProfileServices.getSertifikat({
  //     vendor_business_id: other?.id ? other?.id : null 
  //   }, 
  //   { 
  //     success: (data) => {
  //       setCertificate(prev => ({ ...prev, data }))
  //     },
  //     error: (err) => {
  //       console.log(err);
  //     }
  //   })
  // }, [])

  const activeQuerySearch = parseObjectToString(querySearch)
  return (
    <div>
      <div
        className="p-4 h-full rounded"
        style={{
          boxShadow: "0px 4px 8px 4px rgba(0, 0, 0, 0.1)",
          background: "#FEFEFE",
        }}
      >
        <div
          role="button"
          onClick={selectedBusinessActivity !== undefined ? () => window.open(`/product_detail/${other?.id}?${activeQuerySearch}`, '_blank') : () => history.push(`/product_detail/${other?.id}?${activeQuerySearch}`)}
        >
          <img
            src={other?.logo_picture_url}
            alt=""
            className={classnames(
              classes.productImage,
              "mx-auto w-32 object-cover"
            )}
          />
          <div className="my-4 border border-solid border-welcome" />
          <div>
            <p className="font-arial font-bold text-base text-black-2 pb-2">
              {other?.name}
            </p>

            <div className="font-arial font-medium text-sm text-black-2">
              <span className="font-arial font-bold text-sm text-black-2">
                Pengalaman
              </span>
              : {other?.tahun_pengalaman} tahun
            </div>
            <div className="font-arial font-medium text-sm text-black-2">
              <span className="font-arial font-bold text-sm text-black-2">
                Sertifikasi
              </span>
              : {other?.sertifikat_non_pkp_count} sertifikasi
            </div>
            <div className="font-arial font-medium text-sm text-black-2">
              <span className="font-arial font-bold text-sm text-black-2">
                Status
              </span>
              : {other?.status_text}
            </div>
          </div>
          <div className="flex flex-row items-start pt-2 font-arial font-normal text-sm text-black-2">
            <LocationOnIcon color="primary" />
            <div className="font-medium pl-2 capitalize">
              {other?.city?.name?.toLowerCase()},{" "}
              {other?.province?.name?.toLowerCase()}
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center pt-2">
          <LocalPhoneIcon color="primary" />
          <a
            href={`tel:${other?.phone}`}
            className="px-2 font-arial font-normal text-sm text-black-2"
          >
            {other?.phone}
          </a>
        </div>

        <div className="flex underline flex-row items-center pt-1">
          <MailIcon color="primary" />
          <a
            href={`mailto:${other?.email}`}
            className="px-2 font-arial font-normal text-sm text-black-2"
            style={{ color: "#8C8C8C" }}
          >
            {other?.email}
          </a>
        </div>

        <div className="flex underline flex-row items-center pt-1">
          <LanguageIcon color="primary" />
          <a
            href={`http://www.${other?.website}`}
            className="px-2 font-arial font-normal text-sm text-black-2"
            style={{ color: "#8C8C8C" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {other?.website}
          </a>
        </div>
        <div className="flex justify-end pt-2 pb-3">
          <Link
            to={`/product_detail/${other?.id}`}
            className="text-pink-header text-sm font-semibold text-right font-arial"
          >
            Selengkapnya &gt;
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
