import React, { useState, useEffect, useRef } from "react";
import Footer from "components/footer";
import Breadcrumbs from "components/Breadcrumb";
import { Link, useHistory } from "react-router-dom";
import VDLogo from "assets/logo-vd.png";
import ChatLogo from "assets/chat.png";
import { useParams } from "react-router-dom";
import UploadImage from "components/UploadImage";
import InputSikap from "components/InputSikap";
import { useFormik } from "formik";
import * as services from "./services";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import _ from "lodash";
import RefreshIsi from "assets/refresh.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import {
  provinsiData,
  kecamatanData,
  kelurahanData,
  kabupatenData,
  aktifitasData,
  kriteriaData,
  jenisPengadaanData,
} from "redux/reducers/region";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Captcha from "components/Captcha";
import Dialog from "components/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import DateIcon from "@material-ui/icons/Today";
import Map from "components/Map";
import useProfile from "hooks/useProfile";
import UploadFile from "components/UploadFile";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import QuestionModal from "components/modal/components/Question";
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      display: "flex",
      width: "100%",
      alignItems: "center",
    },
  })
);

function Individual() {
  const classDialog = useStyles();

  const dispatch = useDispatch();
  const {
    provinsi,
    kecamatan,
    kelurahan,
    kabupaten,
    aktifitas,
    kriteria,
    captcha,
    jenisPengadaan,
  } = useSelector((state) => state?.region);

  const history = useHistory();

  const [startDate, setStartDate] = useState(new Date());
  const startDay = startDate?.toLocaleString("default", { day: "2-digit" });
  const startMonth = startDate?.toLocaleString("default", { month: "numeric" });
  const startYear = startDate?.toLocaleString("default", { year: "numeric" });
  let startDateFormat = `${startYear}-${startMonth}-${startDay}`;
  const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
  const captchaRef = useRef();
  const { user_id, id } = useParams();

  const { user: profile } = useProfile();

  const title = {
    1: "Perseorangan",
    2: "Badan Usaha",
  };

  const [listPendapatan] = useState([
    { id: 1, name: "<1 Milyar" },
    { id: 2, name: ">1 Milyar" },
  ]);

  const [message, setMessage] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [listPBJP, setlistPBJP] = useState([]);
  const [listPendidikan, setListPendidikan] = useState([]);

  useEffect(() => {
    services.getProvinsi({}, (response, err) => {
      if (!err) {
        dispatch(provinsiData({ provinsi: response.data }));
      }
    });

    services.getAktifitas({}, (response, err) => {
      if (!err) {
        dispatch(aktifitasData({ aktifitas: response.data }));
      }
    });

    services.getKriteriaUsaha({}, (response, err) => {
      if (!err) {
        dispatch(kriteriaData({ kriteria: response.data }));
      }
    });

    // services.getKecamatan({}, (response, err) => {
    //   if(!err) {
    //     dispatch(kecamatanData({ kecamatan: response.data }))
    //   }
    // })

    // services.getKelurahan({}, (response, err) => {
    //   if(!err) {
    //     dispatch(kelurahanData({ kelurahan: response.data }))
    //   }
    // })

    services.getJenisPengadaan({}, (response, err) => {
      if (!err) {
        dispatch(jenisPengadaanData({ jenisPengadaan: response.data }));
      }
    });

    services.getPBJP({}, (response, err) => {
      if (!err) {
        setlistPBJP({ response });
      }
    });

    services.getPendidikan({}, (data) => setListPendidikan(data.data));
  }, []);

  const AddBusinessSchema = Yup.object().shape({
    // npwp: Yup.string().required(),
    nib: Yup.string()
      // .required()
      .matches(/^[0-9]+$/, "NIB harus angka")
      .min(12, "NIB Minimal 12 digit")
      .max(13, "NIB Maksimal 13 digit")
      .nullable(),
    gender: Yup.string().required("Jenis Kelamin Harus Diisi"),
    province_id: Yup.number().required("Provinsi Harus Diisi"),
    business_criteria_id: Yup.string().required('Kriteria Usaha Harus Diisi'),
    address: Yup.string().required("Alamat Harus Diisi"),
    city_id: Yup.number().required("Kota Harus Diisi"),
    phone: Yup.number().required("No Telepon Harus Diisi"),
    email: Yup.string().required("Email Harus Diisi"),
    tnc: Yup.boolean().oneOf(
      [true],
      "Anda Harus Mencentang Syarat dan Ketentuan"
    ),
  });

  const AddBusinessEntitySchema = Yup.object().shape({
    // npwp: Yup.string().required(),
    nib: Yup.string()
      // .required()
      .matches(/^[0-9]+$/, "NIB harus angka")
      .min(12, "NIB Minimal 12 digit")
      .max(13, "NIB Maksimal 13 digit")
      .nullable(),
    province_id: Yup.number().required("Provinsi Harus Diisi"),
    business_criteria_id: Yup.string().required('Kriteria Usaha Harus Diisi'),
    address: Yup.string().required("Alamat Harus Diisi"),
    city_id: Yup.number().required("Kota Harus Diisi"),
    phone: Yup.number().required("No Telepon Harus Diisi"),
    email: Yup.string().required("Email Harus Diisi"),
    tnc: Yup.boolean().oneOf(
      [true],
      "Anda Harus Mencentang Syarat dan Ketentuan"
    ),
  });

  // eslint-disable-next-line
  const {
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
    errors,
    touched,
    isSubmitting,
    handleChange,
    setFieldError,
    setValues,
  } = useFormik({
    initialValues: {
      user_id: id,
      business_type_id: user_id === "1" ? 1 : 2,
      npwp: "",
      npwp_file: "",
      nib: "",
      name: "",
      business_activity_id: "",
      business_criteria_id: "",
      date_established: startDateFormat,
      procurement_type_id: "",
      description: "",
      monthly_income: "",
      number_branch_office: "",
      number_employee: "",
      experience_pbjp_id: "",
      address: "",
      latitude: "",
      longitude: "",
      province_id: "",
      city_id: "",
      district_id: "",
      subdistrict_id: "",
      postal_code: "",
      phone: "",
      whatsapp: "",
      website: "",
      email: "",
      gender: "",
      degree: "",
      last_education: "",
      // address: "",
      status: 1,
      logo_picture: "",
      profile_file: "",
      product_1: "",
      product_2: "",
      product_3: "",
      captcha: "",
      captcha_key: captcha?.key,
      tnc: false,
      "pengalaman_e-katalog_status": "",
      "pengalaman_e-katalog_count": "",
      "pengalaman_e-katalog_nominal": "",
      pengalaman_toko_daring_status: "",
      pengalaman_toko_daring_count: "",
      pengalaman_toko_daring_nominal: "",
      pengalaman_pbj_internasional_status: "",
      pengalaman_pbj_internasional_count: "",
      pengalaman_pbj_internasional_nominal: "",
    },
    validationSchema:
      user_id === "1" ? AddBusinessSchema : AddBusinessEntitySchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      services.createVendor({ ...values }, (isSuccess, data) => {
        captchaRef.current.reloadCaptcha();
        setFieldValue("captcha", "");
        if (isSuccess) {
          setMessage({
            open: true,
            severity: "success",
            message: "Berhasil Memasukkan Usaha!",
          });
          window.timeout = setTimeout(() => {
            history.push("/profile");
          }, 10000);
        } else {
          const { response } = data;
          if (response.status === 422) {
            const { errors } = response.data;
            if (errors) {
              Object.keys(errors).forEach((attributeName) => {
                setFieldError(attributeName, errors[attributeName].toString());
              });
              const firstMessage = Object.keys(errors)
                .map((attrName) => errors[attrName].toString())
                .toString();
              setMessage({
                open: true,
                severity: "error",
                message: firstMessage,
              });
            } else if (response?.data?.message) {
              setMessage({
                open: true,
                severity: "error",
                message: response.data.message,
              });
            }
          }
        }
      });
    },
  });

  const callbackSikap = (res) => {
    const { attributes } = res.data;
    setFieldValue("npwp", attributes.npwp);
    setFieldValue("name", attributes.name);
    setFieldValue("province_id", attributes.province_id);
    setFieldValue("city_id", attributes.city_id);
    setFieldValue("number_branch_office", attributes.number_branch_office);
    setFieldValue("number_employee", attributes.number_employee);
    setFieldValue("website", attributes.website);
    setFieldValue("address", attributes.address);
    setFieldValue("phone", attributes.phone);
    setFieldValue("nib", attributes.nib);
  };

  const errorHandler = () => {
    if (Object.keys(errors).length !== 0) {
      Object.keys(errors).forEach((attributeName) => {
        setFieldError(attributeName, errors[attributeName].toString());
      });
      const firstMessage = Object.keys(errors)
        .map((attrName) => errors[attrName].toString())
        .toString();
      setMessage({
        open: true,
        severity: "error",
        message: firstMessage,
      });
    }
  };

  const textTerms = `1. Data yang diisikan dalam Form Aplikasi merupakan tanggung jawab pengguna aplikasi;
  2. Pihak LKPP tidak bertanggung jawab terhadap keabsahan data yang diisi pengguna.`;
  function addDots(nStr) {
    nStr += "";
    let x = nStr?.split(".");
    let x1 = x?.[0];
    let x2 = x?.length > 1 ? "." + x?.[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx?.test?.(x1)) {
      // eslint-disable-next-line
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  }
  const onIsiOtomatis = () => {
    setFieldValue("name", _.get(profile, "full_name"));
    setFieldValue("gender", _.get(profile, "gender"));
    setFieldValue("last_education", _.get(profile, "last_education"));
  };

  useEffect(() => {
    if (values.province_id) {
      services.getKabupaten(
        { province_id: Number(values.province_id) },
        (data) => dispatch(kabupatenData({ kabupaten: data.data }))
      );
    }
  }, [values.province_id]);

  useEffect(() => {
    if (values.city_id) {
      services.getKecamatan({ city_id: Number(values.city_id) }, (data) =>
        dispatch(kecamatanData({ kecamatan: data.data }))
      );
    }
  }, [values.city_id]);

  const [modalState, setModalState] = useState({
    questionModal: false,
  });

  const questionModalHandler = () => {
    setModalState((prev) => ({
      ...prev,
      questionModal: !modalState.questionModal,
    }));
  };

  const max = 2000000;
  return (
    <div>
      <div className="py-10 lg:px-20 px-6 pt-5">
        <Breadcrumbs
          links={[
            { href: "/", name: "Beranda" },
            { name: "Daftarkan Usaha Baru", href: `/add_new_business/${id}` },
            { name: "Bentuk Usaha", href: `/add_new_business/${id}` },
            { name: user_id === "1" ? "Perseorangan" : "Badan Usaha" },
          ]}
        />
      </div>
      <div className="py-5 flex w-full justify-center">
        <img src={VDLogo} alt="" style={{ height: 70 }} />
      </div>
      <div
        className="w-10/12 mx-auto flex lg:flex-row flex-col mb-20"
        style={{
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05",
        }}
      >
        <div className="lg:w-1/2 w-full pt-4 lg:pl-12 pl-6 pr-6">
          <div className="font-baloo2 font-bold text-3xl text-black-2">
            Perluas kesempatan bekerja sama untuk usaha anda
          </div>
          <div className="pb-10 border-b border-solid border-pink-header w-1/2" />
          <div className="font-montserrat font-medium text-xs text-black-2 pt-4">
            Daftarkan {user_id === "1" ? "" : "perusahaan"} anda agar lebih
            mudah ditemukan dan membuka peluang baru.
          </div>
          <img
            src={
              user_id === "1"
                ? "/static/images/perseorangan.png"
                : "/static/images/badan_usaha.png"
            }
            alt=""
            className="pt-8"
          />
        </div>

        <div className="lg:w-1/2 w-full mt-4 mb-8 border-l border-solid border-welcome pl-6">
          <div className="w-11/12">
            <h3 className="font-baloo2 whitespace-pre font-bold text-base text-black-2">
              Daftar Usaha
            </h3>
            <span className="font-baloo2 whitespace-pre font-bold text-2xl text-black-2">
              {title[user_id]}
            </span>
          </div>
          <div className="pt-5 border-b border-solid border-welcome w-11/12" />
          <span className="font-montserrat font-bold text-sm text-black-2 pt-5">
            Cek jika usaha anda sudah terdaftar di SIKap
          </span>
          <form onSubmit={handleSubmit} className="pt-4">
            <div className="input-container">
              <div className="flex mt-3">
                <div className="font-montserrat font-bold text-sm text-black-2">
                  NPWP {user_id === "1" ? "pribadi" : "badan usaha"}
                </div>
              </div>
              <InputMask
                mask="99.999.999.9-999.999"
                alwaysShowMask
                {...getFieldProps("npwp")}
              >
                {(inputProps) => {
                  return (
                    <InputSikap
                      type="usaha"
                      field="npwp"
                      className="w-11/12"
                      inputProps={{
                        className:
                          "w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                        name: "npwp",
                        ...inputProps,
                      }}
                      callback={callbackSikap}
                    />
                  );
                }}
              </InputMask>
              {touched.npwp && errors.npwp && (
                <div className="text-red-500 text-xs">{errors.npwp}</div>
              )}
            </div>

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Upload NPWP (maks 250kb)
              </div>
              {/* <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div> */}
            </div>
            <UploadImage
              inputProps={{
                name: "npwp_file",
                onChange: (e) =>
                  setFieldValue(
                    "npwp_file",
                    e.target.files.length ? e.target.files[0] : null
                  ),
              }}
              byteSize={250000}
              byteSizeName={"250 kb"}
            />
            {/* {touched.gender && errors.gender && (
              <div className="text-red-500 text-xs">{errors.gender}</div>
            )} */}
            <div className="input-container">
              <div className="flex mt-3">
                <div className="font-montserrat font-bold text-sm text-black-2">
                  NIB
                </div>
              </div>
              <InputSikap
                type="usaha"
                field="nib"
                className="w-11/12"
                inputProps={{
                  className:
                    "w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                  name: "nib",
                  ...getFieldProps("nib"),
                  // type: "number",
                  id: "my-input-number",
                }}
                // onChange={NibOnchange()}
                callback={callbackSikap}
              />
              {touched.nib && errors.nib && (
                <div className="text-red-500 text-xs">{errors.nib}</div>
              )}
            </div>
            <div className="pt-4 border-b border-solid border-welcome mb-5 w-11/12" />

            {user_id === "1" && (
              <div className="flex flex-col">
                <span className="font-montserrat font-bold text-sm text-black-2 pb-3">
                  Isi otomatis beberapa isian di bawah menggunakan biodata dari
                  akun BISA Pengadaan anda
                </span>
                <button
                  type="button"
                  className="rounded flex items-center mb-3 justify-center w-1/5 py-2 bg-neon-green text-xs font-medium text-white"
                  onClick={() => onIsiOtomatis()}
                >
                  <img src={RefreshIsi} alt="" />
                  <div className="pl-2">Isi Otomatis</div>
                </button>
              </div>
            )}

            <div className="flex">
              <div className="font-montserrat font-bold text-sm text-black-2">
                {user_id === "1" ? "Nama Lengkap" : "Nama perusahaan"}
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="name"
              {...getFieldProps("name")}
            />
            {/* {(touched.name && errors.name) && (
              <div className="text-red-500 text-xs">{errors.name}</div>
            )} */}
            {user_id === "1" && (
              <>
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Jenis kelamin
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <select
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="gender"
                  {...getFieldProps("gender")}
                >
                  <option value="" disabled>
                    Pilih Jenis Kelamin
                  </option>
                  <option value="male">Laki-Laki</option>
                  <option value="female">Perempuan</option>
                </select>
                {touched.gender && errors.gender && (
                  <div className="text-red-500 text-xs">{errors.gender}</div>
                )}
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Gelar
                  </div>
                  {/* <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div> */}
                </div>
                <input
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="degree"
                  {...getFieldProps("degree")}
                />
                {/* {(touched.degree && errors.degree) && (
                  <div className="text-red-500 text-xs">{errors.degree}</div>
                )} */}
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Pendidikan terakhir
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <select
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="last_education "
                  {...getFieldProps("last_education")}
                >
                  <option value="" disabled>
                    Pilih Pendidikan Terakhir
                  </option>
                  {listPendidikan.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </>
            )}
            {/* {(touched.last_education && errors.last_education) && (
              <div className="text-red-500 text-xs">{errors.last_education}</div>
            )} */}

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Alamat Lengkap {user_id === "1" ? "usaha" : "perusahaan"}
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <textarea
              className="w-11/12 h-28 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="address"
              {...getFieldProps("address")}
            />
            {touched.address && errors.address && (
              <div className="text-red-500 text-xs">{errors.address}</div>
            )}
            <div className="border-b border-solid border-welcome py-4 w-11/12" />
            <div className="flex mt-3 font-montserrat font-bold text-sm text-black-2">
              <div>Koordinat Lokasi Perusahaan</div>
            </div>
            <div className="flex w-11/12 flex-row">
              <div className="flex flex-col w-1/2">
                <div className="flex items-center">
                  <div className="mt-3 font-montserrat font-bold text-sm text-black-2">
                    Latitude
                  </div>
                  {/* <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div> */}
                </div>
                <input
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="latitude"
                  disabled
                  {...getFieldProps("latitude")}
                />
                {/* {(touched.latitude && errors.latitude) && (
                  <div className="text-red-500 text-xs">{errors.latitude}</div>
                )} */}
              </div>
              <div className="flex flex-col w-1/2">
                <div className="flex items-center">
                  <div className="mt-3 font-montserrat font-bold text-sm text-black-2">
                    Longitude
                  </div>
                  {/* <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div> */}
                </div>
                <input
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="longitude"
                  disabled
                  {...getFieldProps("longitude")}
                />
                {/* {(touched.longitude && errors.longitude) && (
                  <div className="text-red-500 text-xs">{errors.longitude}</div>
                )} */}
              </div>
            </div>
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Cari Alamat
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <div className="w-11/12">
              <Map
                lat={values.latitude}
                long={values.longitude}
                onChange={(option) => {
                  setFieldValue("latitude", option.y);
                  setFieldValue("longitude", option.x);
                }}
              />
            </div>

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Provinsi
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            {/* <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="province_id "
              {...getFieldProps("province_id")}
              onChange={(ev) => {
                // setSelectedProvinceID(prev => ({...prev, id: Number(ev.target.value)}))
                setFieldValue("city_id", "");
                setFieldValue("province_id", Number(ev.target.value));
              }}
              // value={selectedProvinceID.id}
            >
              <option value="" disabled>
                Pilih Provinsi
              </option>
              {provinsi.map((provinsi) => (
                <option key={provinsi.id} value={provinsi.id}>
                  {provinsi.name}
                </option>
              ))}
            </select> */}
            <Autocomplete
              options={provinsi}
              onChange={(ev, value) => {
                setFieldValue("city_id", "");
                setFieldValue("province_id", value.id);
              }}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              getOptionSelected={(option, value) => option.id === value.id}
              openOnFocus
              key={provinsi}
              renderInput={(props) => (
                <TextField
                  {...props}
                  variant="outlined"
                  placeholder="Pilih Kota / Kabupaten"
                  style={{ width: "91.666667%" }}
                  size="small"
                />
              )}
            />
            {touched.province_id && errors.province_id && (
              <div className="text-red-500 text-xs">{errors.province_id}</div>
            )}

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Kota/Kabupaten
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="city_id"
              {...getFieldProps("city_id")}
              onChange={(ev) => {
                setFieldValue("district_id", "");
                setFieldValue("city_id", Number(ev.target.value));
              }}
            >
              <option value="" disabled>
                Pilih Kabupaten
              </option>
              {kabupaten.map((kabupaten) => (
                <option key={kabupaten.id} value={kabupaten.id}>
                  {kabupaten.name}
                </option>
              ))}
            </select>
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Kecamatan
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="district_id"
              {...getFieldProps("district_id")}
              onChange={(ev) => {
                services.getKelurahan(
                  { district_id: Number(ev.target.value) },
                  (data) => dispatch(kelurahanData({ kelurahan: data.data }))
                );
                setFieldValue("subdistrict_id", "");
                setFieldValue("district_id", Number(ev.target.value));
              }}
            >
              <option value="" disabled>
                Pilih Kecamatan
              </option>
              {kecamatan.map((kecamatan) => (
                <option key={kecamatan.id} value={kecamatan.id}>
                  {kecamatan.name}
                </option>
              ))}
            </select>
            {/* {(touched.district_id && errors.district_id) && (
              <div className="text-red-500 text-xs">{errors.district_id}</div>
            )} */}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Kelurahan
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            {/* <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="subdistrict_id"
              {...getFieldProps('subdistrict_id')}
            /> */}
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="subdistrict_id"
              {...getFieldProps("subdistrict_id")}
            >
              <option value="" disabled>
                Pilih Kelurahan
              </option>
              {kelurahan.map((kelurahan) => (
                <option key={kelurahan.id} value={kelurahan.id}>
                  {kelurahan.name}
                </option>
              ))}
            </select>
            {/* {(touched.subdistrict_id && errors.subdistrict_id) && (
              <div className="text-red-500 text-xs">{errors.subdistrict_id}</div>
            )} */}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Kode Pos
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="postal_code"
              {...getFieldProps("postal_code")}
            />
            {/* {(touched.postal_code && errors.postal_code) && (
              <div className="text-red-500 text-xs">{errors.postal_code}</div>
            )} */}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Nomor telepon
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="phone"
              type="number"
              pattern="[0-9]*"
              id="my-input-number"
              {...getFieldProps("phone")}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Nomor WhatsApp
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="whatsapp"
              type="number"
              pattern="[0-9]*"
              id="my-input-number"
              {...getFieldProps("whatsapp")}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Website (setelah www.)
              </div>
              {/* <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div> */}
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="website"
              {...getFieldProps("website")}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Email
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="email"
              {...getFieldProps("email")}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Aktifitas Usaha
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <Box
              sx={{
                "& .MuiChip-root": {
                  background: "#F2F2F2",
                  border: "1px solid #CCCCCC",
                  boxSizing: "border-box",
                  borderRadius: "4px",
                },
                "& .MuiInputBase-root": {
                  background: "#f2f2f2",
                  border: "1px solid #CCCCCC",
                  boxSizing: "border-box",
                  borderRadius: "4px",
                  width: "92%",
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  color: "rgba(89, 89, 89, var(--tw-text-opacity))",
                },
                '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
                  {
                    padding: "1px",
                  },
              }}
            >
              <Autocomplete
                multiple
                options={aktifitas}
                getOptionLabel={(option) => option?.name}
                filterSelectedOptions
                onChange={(event, value) =>
                  setFieldValue(
                    "business_activity_id",
                    value?.map((i) => i.id).join(",")
                  )
                }
                getOptionSelected={(option, value) => option.id === value.id}
                // value={selectedBusinessActivity}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Aktivitas Usaha"
                  />
                )}
              />
            </Box>
            {/* {user_id === "2" && ( */}
            <>
              <div className="flex mt-3">
                <div className="font-montserrat font-bold text-sm text-black-2">
                  Kriteria Usaha
                </div>
                <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                  *
                </div>
                <button
                  onClick={() => questionModalHandler()}
                  className="pl-3 -mt-2"
                >
                  <HelpOutlineIcon color="primary" fontSize="small" />
                </button>
              </div>
              <select
                className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                name="business_criteria_id"
                {...getFieldProps("business_criteria_id")}
              >
                <option value="" disabled>
                  Pilih Kriteria Usaha
                </option>
                {kriteria.map((kriteria) => (
                  <option key={kriteria.id} value={kriteria.id}>
                    {kriteria.name}
                  </option>
                ))}
              </select>
            </>
            {(touched.business_criteria_id && errors.business_criteria_id) && (
              <div className="text-red-500 text-xs">{errors.business_criteria_id}</div>
            )}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Jenis Pengadaan
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="procurement_type_id"
              {...getFieldProps("procurement_type_id")}
            >
              <option value="" disabled>
                Pilih Jenis Pengadaan
              </option>
              {jenisPengadaan.map((jenis) => (
                <option key={jenis.id} value={jenis.id}>
                  {jenis.name}
                </option>
              ))}
            </select>
            {/* <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Komoditas Usaha
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <Box
              sx={{
                '& .MuiChip-root': {
                  background: '#F2F2F2',
                  border: '1px solid #CCCCCC',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                },
                '& .MuiInputBase-root': {
                  background: '#f2f2f2',
                  border: '1px solid #CCCCCC',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                  width: '92%',
                  fontWeight: '500',
                  fontFamily: "Montserrat",
                  fontSize: '0.75rem',
                  lineHeight: '1rem',
                  color: 'rgba(89, 89, 89, var(--tw-text-opacity))',
                }, 
                '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                  padding: '1px',
                }
              }}
            >
              <Autocomplete
                multiple
                options={kriteria}
                getOptionLabel={(option) => (option?.name)}
                filterSelectedOptions
                onChange={(event, value) => handleBusinessCriteriaChange(value)}
                getOptionSelected={(option, value) => option.id === value.id}
                value={selectedBusinessCriteria}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Komoditas Usaha"
                  />
                )}
              />
            </Box> */}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Informasi perusahaan
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <textarea
              className="w-11/12 h-28 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="description"
              {...getFieldProps("description")}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Jumlah kantor cabang
              </div>
              {user_id === "2" && (
                <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                  *
                </div>
              )}
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="number_branch_office"
              type="number"
              min="0"
              {...getFieldProps("number_branch_office")}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                {user_id === "1" ? "Tanggal Mulai Usaha" : "Tanggal Berdiri"}
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                const startDay = date?.toLocaleString("default", {
                  day: "2-digit",
                });
                const startMonth = date?.toLocaleString("default", {
                  month: "numeric",
                });
                const startYear = date?.toLocaleString("default", {
                  year: "numeric",
                });
                let startDateFormated = `${startYear}-${startMonth}-${startDay}`;
                setStartDate(date);
                setFieldValue("date_established", startDateFormated);
              }}
              dateFormat="dd-MM-yyyy"
              maxDate={new Date()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={
                <div className="flex flex-row justify-between items-center bg-neutral-grey rounded-lg py-2 pr-3 font-semibold text-base font-custom w-11/12">
                  <input
                    type="text"
                    id="custom"
                    placeholder={`${startDateFormat}`}
                    className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border-0"
                  />
                  <DateIcon className="text-black-2" />
                </div>
              }
            />
            {user_id === "2" && (
              <>
                {" "}
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Jumlah Karyawan
                  </div>
                  {user_id === "2" && (
                    <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                      *
                    </div>
                  )}
                </div>
                <input
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="number_employee"
                  type="number"
                  min="0"
                  {...getFieldProps("number_employee")}
                />{" "}
              </>
            )}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Penghasilan Perbulan
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="monthly_income"
              {...getFieldProps("monthly_income")}
              onClick={(ev) => setFieldValue("monthly_income", ev.target.value)}
            >
              <option value="" disabled>
                Pilih Pendapatan
              </option>
              {listPendapatan.map((pendapatan) => (
                <option key={pendapatan.id} value={pendapatan.name}>
                  {pendapatan.name}
                </option>
              ))}
            </select>
            {/* <div className="border-b border-solid border-welcome py-4 w-11/12" />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pengalaman E-katalog
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="monthly_income"
              {...getFieldProps("monthly_income")}
              onClick={(ev) => setFieldValue("monthly_income", ev.target.value)}
            >
              <option value="" disabled>
                Pilih Pendapatan
              </option>
              {listPendapatan.map((pendapatan) => (
                <option key={pendapatan.id} value={pendapatan.name}>
                  {pendapatan.name}
                </option>
              ))}
            </select>

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Jumlah Melakukan Transaksi / Kontrak
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="monthly_income"
              {...getFieldProps("monthly_income")}
              onClick={(ev) => setFieldValue("monthly_income", ev.target.value)}
            >
              <option value="" disabled>
                Pilih Pendapatan
              </option>
              {listPendapatan.map((pendapatan) => (
                <option key={pendapatan.id} value={pendapatan.name}>
                  {pendapatan.name}
                </option>
              ))}
            </select>

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Nilai Akumulasi kontrak dalam 1 tahun (Rp)
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="monthly_income"
              {...getFieldProps("monthly_income")}
              onClick={(ev) => setFieldValue("monthly_income", ev.target.value)}
            >
              <option value="" disabled>
                Pilih Pendapatan
              </option>
              {listPendapatan.map((pendapatan) => (
                <option key={pendapatan.id} value={pendapatan.name}>
                  {pendapatan.name}
                </option>
              ))}
            </select>

            <div className="border-b border-solid border-welcome py-4 w-11/12" />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pengalaman Toko Daring
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="monthly_income"
              {...getFieldProps("monthly_income")}
              onClick={(ev) => setFieldValue("monthly_income", ev.target.value)}
            >
              <option value="" disabled>
                Pilih Pendapatan
              </option>
              {listPendapatan.map((pendapatan) => (
                <option key={pendapatan.id} value={pendapatan.name}>
                  {pendapatan.name}
                </option>
              ))}
            </select>

            <div className="border-b border-solid border-welcome py-4 w-11/12" />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pengalaman PBJ Internasional
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="monthly_income"
              {...getFieldProps("monthly_income")}
              onClick={(ev) => setFieldValue("monthly_income", ev.target.value)}
            >
              <option value="" disabled>
                Pilih Pendapatan
              </option>
              {listPendapatan.map((pendapatan) => (
                <option key={pendapatan.id} value={pendapatan.name}>
                  {pendapatan.name}
                </option>
              ))}
            </select>

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Jumlah Melakukan Transaksi / Kontrak
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="monthly_income"
              {...getFieldProps("monthly_income")}
              onClick={(ev) => setFieldValue("monthly_income", ev.target.value)}
            >
              <option value="" disabled>
                Pilih Pendapatan
              </option>
              {listPendapatan.map((pendapatan) => (
                <option key={pendapatan.id} value={pendapatan.name}>
                  {pendapatan.name}
                </option>
              ))}
            </select>

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Nilai Akumulasi kontrak dalam 1 tahun (Rp)
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="monthly_income"
              {...getFieldProps("monthly_income")}
              onClick={(ev) => setFieldValue("monthly_income", ev.target.value)}
            >
              <option value="" disabled>
                Pilih Pendapatan
              </option>
              {listPendapatan.map((pendapatan) => (
                <option key={pendapatan.id} value={pendapatan.name}>
                  {pendapatan.name}
                </option>
              ))}
            </select>

            <div className="border-b border-solid border-welcome py-4 w-11/12" /> */}
            <div className="border-b border-solid border-welcome py-4 w-11/12" />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pengalaman E-katalog
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="pengalaman_e-katalog_status"
              {...getFieldProps("pengalaman_e-katalog_status")}
              onChange={(ev) =>
                setFieldValue(
                  "pengalaman_e-katalog_status",
                  Number(ev.target.value)
                )
              }
            >
              <option value="" disabled>
                Pilih Pengalaman
              </option>
              <option value={1}>Pernah</option>
              <option value={0}>Tidak Pernah</option>
            </select>

            {values["pengalaman_e-katalog_status"] !== 0 && (
              <>
                {" "}
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Jumlah Melakukan Transaksi / Kontrak (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <input
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="pengalaman_e-katalog_count"
                  {...getFieldProps("pengalaman_e-katalog_count")}
                  onChange={(ev) =>
                    setFieldValue("pengalaman_e-katalog_count", ev.target.value)
                  }
                  type="number"
                />
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Nilai Akumulasi kontrak dalam 1 tahun (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <NumberFormat
                  value={values?.["pengalaman_e-katalog_nominal"]}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value, props) => (
                    <input
                      className="w-11/12 no-spinner font-montserrat p-3 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                      name="pengalaman_e-katalog_nominal"
                      {...props}
                      value={value}
                      onChange={(ev) =>
                        setFieldValue(
                          "pengalaman_e-katalog_nominal",
                          ev.target.value.replaceAll(",", "")
                        )
                      }
                    />
                  )}
                />
              </>
            )}
            <div className="border-b border-solid border-welcome py-4 w-11/12" />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pengalaman Toko Daring
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="pengalaman_toko_daring_status"
              {...getFieldProps("pengalaman_toko_daring_status")}
              onChange={(ev) =>
                setFieldValue(
                  "pengalaman_toko_daring_status",
                  Number(ev.target.value)
                )
              }
            >
              <option value="" disabled>
                Pilih Pengalaman
              </option>
              <option value={1}>Pernah</option>
              <option value={0}>Tidak Pernah</option>
            </select>

            {values.pengalaman_toko_daring_status !== 0 && (
              <>
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Jumlah Melakukan Transaksi / Kontrak (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <input
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="pengalaman_toko_daring_count"
                  {...getFieldProps("pengalaman_toko_daring_count")}
                  onChange={(ev) =>
                    setFieldValue(
                      "pengalaman_toko_daring_count",
                      ev.target.value
                    )
                  }
                  type="number"
                />

                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Nilai Akumulasi kontrak dalam 1 tahun (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <NumberFormat
                  value={values?.pengalaman_toko_daring_nominal}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value, props) => (
                    <input
                      className="w-11/12 no-spinner font-montserrat p-3 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                      name="pengalaman_toko_daring_nominal"
                      {...props}
                      value={value}
                      onChange={(ev) =>
                        setFieldValue(
                          "pengalaman_toko_daring_nominal",
                          ev.target.value.replaceAll(",", "")
                        )
                      }
                    />
                  )}
                />
              </>
            )}

            <div className="border-b border-solid border-welcome py-4 w-11/12" />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pengalaman PBJ Internasional
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="pengalaman_pbj_internasional_status"
              {...getFieldProps("pengalaman_pbj_internasional_status")}
              onChange={(ev) =>
                setFieldValue(
                  "pengalaman_pbj_internasional_status",
                  ev.target.value.replaceAll(",", "")
                )
              }
            >
              <option value="" disabled>
                Pilih Pengalaman
              </option>
              <option value={1}>Pernah</option>
              <option value={0}>Tidak Pernah</option>
            </select>

            {values.pengalaman_pbj_internasional_status !== 0 && (
              <>
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Jumlah Melakukan Transaksi / Kontrak (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <input
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="pengalaman_pbj_internasional_count"
                  {...getFieldProps("pengalaman_pbj_internasional_count")}
                  onChange={(ev) =>
                    setFieldValue(
                      "pengalaman_pbj_internasional_count",
                      ev.target.value
                    )
                  }
                  type="number"
                />

                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Nilai Akumulasi kontrak dalam 1 tahun (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <NumberFormat
                  value={values?.["pengalaman_pbj_internasional_nominal"]}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value, props) => (
                    <input
                      className="w-11/12 no-spinner font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                      name="pengalaman_pbj_internasional_nominal"
                      {...getFieldProps("pengalaman_pbj_internasional_nominal")}
                      {...props}
                      value={value}
                      onChange={(ev) =>
                        setFieldValue(
                          "pengalaman_pbj_internasional_nominal",
                          ev.target.value.replaceAll(",", "")
                        )
                      }
                    />
                  )}
                />
              </>
            )}

            <div className="border-b border-solid border-welcome py-4 w-11/12" />
            {/* <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pengalaman PBJP
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div> */}
            {/* <div className="inline-block relative w-full"> */}
            {/* <select
                className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                name="experience_pbjp_id"
                {...getFieldProps("experience_pbjp_id")}
              >
                {listPBJP?.response?.data?.map((pbjp) => (
                  <option key={pbjp.id} value={pbjp.id}>
                    {pbjp.name}
                  </option>
                ))}
              </select> */}
            {/* <input
                className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                name="experience_pbjp_id"
                type="number"
                min="0"
                {...getFieldProps('experience_pbjp_id')}
              />
              <span id="my-suffix" className="font-montserrat text-xs">Tahun</span> */}
            {/* </div> */}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Upload Company Profile (maks 2 MB)
              </div>
            </div>
            <UploadFile
              message="File Lebih Besar Dari 2 MB!"
              max={max}
              inputProps={{
                name: "profile_file",
                onChange: (e) =>
                  setFieldValue(
                    "profile_file",
                    e.target.files.length && e.target.files[0].size < max
                      ? e.target.files[0]
                      : null
                  ),
              }}
              classWidth="w-full lg:w-9/12 2xl:w-10/12"
              classContainer="pr-6 lg:pr-0"
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Upload logo (maks 500kb)
              </div>
              {user_id === "2" && (
                <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                  *
                </div>
              )}
            </div>
            <UploadImage
              inputProps={{
                name: "logo_picture",
                onChange: (e) =>
                  setFieldValue(
                    "logo_picture",
                    e.target.files.length ? e.target.files[0] : null
                  ),
              }}
              byteSize={500000}
              byteSizeName={"500 kb"}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Produk 1 (maks 500kb)
              </div>
            </div>
            <UploadImage
              inputProps={{
                name: "product_1",
                onChange: (e) =>
                  setFieldValue(
                    "product_1",
                    e.target.files.length ? e.target.files[0] : null
                  ),
              }}
              byteSize={500000}
              byteSizeName={"500 kb"}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Produk 2 (maks 500kb)
              </div>
            </div>
            <UploadImage
              inputProps={{
                name: "product_2",
                onChange: (e) => {
                  setFieldValue(
                    "product_2",
                    e.target.files.length ? e.target.files[0] : null
                  );
                },
              }}
              byteSize={500000}
              byteSizeName={"500 kb"}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Produk 3 (maks 500kb)
              </div>
            </div>
            <UploadImage
              inputProps={{
                name: "product_3",
                onChange: (e) =>
                  setFieldValue(
                    "product_3",
                    e.target.files.length ? e.target.files[0] : null
                  ),
              }}
              byteSize={500000}
              byteSizeName={"500 kb"}
            />
            <div className="pt-3">
              <Captcha
                ref={captchaRef}
                classContainer="lg:pr-0 pr-6"
                inputProps={{
                  className:
                    "w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                  ...getFieldProps("captcha"),
                }}
                callback={(option) => {
                  setFieldValue("captcha_key", option.key);
                }}
              />
            </div>
            <div className="pt-5 whitespace-pre-line font-medium font-montserrat text-xs text-black-2">
              {textTerms}
            </div>

            <div className="py-5 flex">
              <input
                className="font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                type="checkbox"
                {...getFieldProps("tnc")}
              />
              <label className="pl-4 font-montserrat font-medium text-xs text-black-2">
                Dengan ini saya telah menyetujui
                <a
                  href="/syarat_dan_ketentuan"
                  className="text-pink-header px-1"
                >
                  Syarat dan Ketentuan
                </a>
                yang berlaku
              </label>
            </div>
            {/* <div className="w-11/12"> */}
            <button
              onClick={errorHandler}
              type="submit"
              className="w-11/12 py-2 font-montserrat text-xs font-medium text-border-on-pink bg-pink-header rounded border-solid border border-pink-header"
            >
              Daftarkan Perusahaan
            </button>
            {/* </div> */}
          </form>
          <Dialog
            visible={message.open === true}
            onClose={message.open !== true}
            className="mt-3"
          >
            {message.severity === "error" && (
              <Alert severity={message.severity} className={classDialog.dialog}>
                {message.message}
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setMessage({ open: false, error: "", message: "" });
                  }}
                >
                  <CloseIcon alt="" className="ml-3 self-center" />
                </IconButton>
              </Alert>
            )}
            {message.severity === "success" && (
              <>
                <div className="flex items-center justify-end">
                  <button
                    onClick={() => {
                      setMessage({ open: false, error: "", message: "" });
                    }}
                    className="px-4 py-2"
                  >
                    <CloseIcon />
                  </button>
                </div>

                <div className="bg-white px-6 pb-4 pt-2">
                  <div className="border-b border-gray-200 block pb-3">
                    <h5 className="font-baloo2 font-bold text-2xl text-black-2">
                      Pendaftaran Usaha Terkirim!
                    </h5>
                    <p className="font-baloo2 font-bold text-lg text-black-2 py-6">
                      Silahkan cek "Pesan Masuk" di halaman profile anda dan
                      tunggu 1x24 jam di hari kerja untuk melihat status
                      verifikasi usaha anda.
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      component={"div"}
                      onClick={() => {
                        setMessage({ open: false, error: "", message: "" });
                        history.push("/profile");
                      }}
                      fullWidth
                    >
                      Tutup
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Dialog>
          {/* {errors && <Collapse className="w-11/12 pt-4" in={message.open}>
            <Alert
              severity={message.severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setMessage({ open: false, error: '', message: '' });
                  }}
                >
                  <img src={CloseIcon} alt="" />
                </IconButton>
              }
            >
              {message.message}
            </Alert>
          </Collapse>}
          {JSON.stringify(errors, null, 2)} */}
        </div>
      </div>
      <QuestionModal
        visible={modalState?.questionModal}
        onClose={() => questionModalHandler()}
      />
      <Footer />
    </div>
  );
}

export default Individual;
