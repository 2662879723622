import { useEffect } from "react";
import Home from "pages/Home";
import AboutUs from "pages/AboutUs";
import News from "pages/News";
import NewsDetail from "pages/News/pages/NewsDetail";
import InfoPBJP from "pages/InfoPBJP";
import FAQ from "pages/FAQ";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import history from "./history";
import ContactUs from "pages/ContactUs";
import Register from "pages/Register";
import RegisterVerification from "pages/RegisterVerification";
import Verification from "pages/Verification";
import Statistics from "pages/Statistics";
import Search from "pages/Search";
import ProductDetail from "pages/ProductDetail";
import CompanyEdit from "pages/CompanyEdit";
import ForgetPassword from "pages/ForgetPassword";
import Profile from "pages/Profile";
import EditProfile from "pages/Profile/components/EditProfile";
import AddNewBusiness from "pages/AddNewBusiness";
import Individual from "pages/AddNewBusiness/Individual";
import ModalLogin from "components/modal/components/Login";
import LokasiUsaha from "pages/LokasiUsaha";
import KalendarKegiatan from "pages/KalendarKegiatan";
import NotFound from "pages/NotFound";
import KabarPelakuUsaha from "pages/KabarPelakuUsaha";
import TrendingSearch from "pages/Search/pages/Trending";
import SyaratDanKetentuan from "pages/SyaratDanKetentuan";
import KegiatanNonPKP from "pages/KegiatanNonPKP";
import AbsensiKegiatan from "pages/Absence";
import ViewBoard from 'pages/ViewBoard';

import { Helmet, HelmetProvider } from "react-helmet-async";
import useScrollTop from "hooks/useScrollTop";
import { useIdleTimer } from "react-idle-timer";
import Cookie from "js-cookie";
import token from "utils/session";
import { useDispatch } from "react-redux";
import { user_login } from "redux/reducers/users";
import { autoSignIn } from "redux/reducers/auth";
import DaftarKegiatanPKP from "pages/KalendarKegiatan/pages/DaftarKegiatanPKP";
import BuatDaftarKegiatan from "pages/KalendarKegiatan/pages/BuatDaftarKegiatan";
import Pesan from "pages/Pesan";
import ProtectRoute from "components/ProtectRoute";
import Chat from "components/Chat";
import { getTentangKami } from "redux/reducers/region";
import { getVisitor } from "redux/reducers/region";
import Sidebar from "components/Navigation/Sidebar";
import BeforeAlert from "components/modal/components/BeforeProfile";
import { actionsGlobal } from "redux/reducers/global";
import services from "components/header/services";
import { useSelector } from "react-redux";
import { wrapComponent } from "utils/usePageTracking";

const Additions = () => {
  const dispatch = useDispatch();
  const { rememberMe } = useSelector((state) => state.auth);

  useEffect(() => {
    if (token) {
      dispatch(user_login({ status: true }));
      dispatch(autoSignIn({ accessToken: token }));
      if (rememberMe === true) {
        Cookie.set("LKPP_TOKEN", token);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    dispatch(getTentangKami());
    dispatch(getVisitor());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScrollTop();

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: () => {
      if (token) {
        dispatch(user_login({ status: false }));
        dispatch(autoSignIn({ accessToken: null }));
        dispatch(actionsGlobal.openAlert());
        Cookie.remove("LKPP_TOKEN");
      }
    },
  });

  return <div></div>;
};

function App() {
  let route = (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <BrowserRouter>
          <Helmet
            titleTemplate="%s - BISA Pengadaan"
            defaultTitle="BISA Pengadaan"
          >
            <meta name="description" content="BISA Pengadaan" />
          </Helmet>

          <Switch>
            <Route
              exact
              history={history}
              path="/"
              component={wrapComponent(Home)}
            />
            <Route
              exact
              history={history}
              path="/dashboard_beranda"
              component={wrapComponent(ViewBoard)}
            />
            <Route
              exact
              history={history}
              path="/about_us"
              component={wrapComponent(AboutUs)}
            />
            <Route
              exact
              history={history}
              path="/news"
              component={wrapComponent(News)}
            />
            <Route
              exact
              history={history}
              path="/info_pbjp"
              component={wrapComponent(InfoPBJP)}
            />
            <Route
              exact
              history={history}
              path="/faq"
              component={wrapComponent(FAQ)}
            />
            <Route
              exact
              history={history}
              path="/contact_us"
              component={wrapComponent(ContactUs)}
            />
            <Route
              exact
              history={history}
              path="/register"
              component={wrapComponent(Register)}
            />
            <Route
              exact
              history={history}
              path="/register/verification"
              component={wrapComponent(RegisterVerification)}
            />
            <Route
              exact
              history={history}
              path="/verification/:verificationCode"
              component={wrapComponent(Verification)}
            />
            <Route
              exact
              history={history}
              path="/forgot-password"
              component={wrapComponent((props) => (
                <ForgetPassword type="forgot" {...props} />
              ))}
            />
            <Route
              exact
              history={history}
              path="/reset-password/:code"
              component={wrapComponent((props) => (
                <ForgetPassword type="reset" {...props} />
              ))}
            />
            <Route
              exact
              history={history}
              path="/news_detail/:id"
              component={wrapComponent(NewsDetail)}
            />
            <Route
              exact
              history={history}
              path="/statistics"
              component={wrapComponent(Statistics)}
            />
            <Route
              exact
              history={history}
              path="/search"
              component={wrapComponent(Search)}
            />
            <Route
              exact
              history={history}
              path="/search/trending"
              component={wrapComponent(TrendingSearch)}
            />
            <Route
              exact
              history={history}
              path="/product_detail/:id/:profile?/:procurement_type_id?/:business_activity_id?"
              component={wrapComponent(ProductDetail)}
            />
            <Route
              exact
              history={history}
              path="/lokasi_usaha"
              component={wrapComponent(LokasiUsaha)}
            />
            <Route
              exact
              history={history}
              path="/kalendar"
              component={wrapComponent(KalendarKegiatan)}
            />
            <Route
              exact
              history={history}
              path="/kalendar/daftar/non_pkp"
              component={wrapComponent(KegiatanNonPKP)}
            />
            <ProtectRoute
              history={history}
              path="/kalendar/daftar"
              component={wrapComponent(DaftarKegiatanPKP)}
            />
            <Route
              history={history}
              path="/absensi/kegiatan"
              component={wrapComponent(AbsensiKegiatan)}
            />
            <Route
              exact
              history={history}
              path="/kabar_pelaku_usaha"
              component={wrapComponent(KabarPelakuUsaha)}
            />
            <Route
              exact
              history={history}
              path="/syarat_dan_ketentuan"
              component={wrapComponent(SyaratDanKetentuan)}
            />

            <ProtectRoute
              exact
              history={history}
              path="/add_new_business/:user_id"
              component={wrapComponent(AddNewBusiness)}
            />
            <ProtectRoute
              exact
              history={history}
              path="/register_business/:user_id/:id"
              component={wrapComponent(Individual)}
            />
            <ProtectRoute
              exact
              history={history}
              path="/company_edit/:id"
              component={wrapComponent(CompanyEdit)}
            />
            <ProtectRoute
              exact
              history={history}
              path="/profile/:id?"
              component={wrapComponent(Profile)}
            />
            <ProtectRoute
              exact
              history={history}
              path="/edit_profile"
              component={wrapComponent(EditProfile)}
            />
            <ProtectRoute
              exact
              history={history}
              path="/kalendar/daftar_usaha"
              component={wrapComponent(BuatDaftarKegiatan)}
            />
            <ProtectRoute
              exact
              history={history}
              path="/pesan"
              component={wrapComponent(Pesan)}
            />
            <Route component={wrapComponent(NotFound)} />
          </Switch>

          <BeforeAlert />
          <ModalLogin />
          <Additions />
          <Sidebar />
          {/* <Chat /> */}
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  );

  return <>{route}</>;
}

export default App;
