import { configureStore } from "@reduxjs/toolkit";
import user from "./reducers/users";
import register from "./reducers/register";
import auth from "./reducers/auth";
import region from "./reducers/region";
import lokasiUsaha from "./reducers/lokasiUsaha"
import kabarUsaha from "./reducers/kabarUsaha"
import kalendar from "./reducers/kalendarKegiatan"
import news from './reducers/news'
import global from './reducers/global'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { 
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  whitelist: ['auth']
};

const reducers = combineReducers({
  user,
  register,
  auth,
  region,
  lokasiUsaha,
  kabarUsaha,
  kalendar,
  news,
  global,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: { warnAfter: 128 },
    serializableCheck: { 
      warnAfter: 128,
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
});
