import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { format,parseISO } from "date-fns";
import { id } from "date-fns/locale";
import { DateTime } from "luxon";

export default function KabarUsahaCard(props) {
  const { updatedAt: updated_at, thumbnailUrl, profileUrl, name, history, onShowView } =
    props;

  const updatedAt = DateTime.fromISO(
    updated_at
  ).toFormat("dd LLL yyyy, HH:mm", { locale: "id" });

  return (
    <Box
      className="px-4 py-3 text-left"
      boxShadow={4}
      sx={{ borderRadius: 4 }}
      component="button"
      onClick={onShowView}
    >
      <Box>
        <Typography variant="caption" className="text-gray-500 text-left">
          {updatedAt} WIB
        </Typography>
      </Box>

      <Box className="w-full my-2">
        <img
          className="w-full object-contain"
          src={props?.data?.picture}
          alt="preview-product"
          style={{ height: 200 }}
        />
      </Box>

      <div className="flex items-start my-4">
        <img
          className="w-16 h-16 object-contain border-r border-gray-200 pr-2"
          src={props?.data?.logo}
          alt="preview-profile"
        />

        <Box className="pl-2 py-2">
          <Typography variant="subtitle1" className="text-black-2">
            {name}
          </Typography>
        </Box>
      </div>

      <Box
        className="px-4 py-2 mt-2 border border-grey-3"
        sx={{ bgcolor: "grey.200", borderRadius: 4 }}
      >
        <ul className="list-disc px-4">
          {history.map((_item) => (
            <li className="text-sm font-montserrat text-pink-header">
              {_item}
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
}

KabarUsahaCard.propTypes = {
  updatedAt: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  profileUrl: PropTypes.string,
  name: PropTypes.string,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    })
  ),
};

KabarUsahaCard.defaultProps = {
  history: [],
};
