import React from "react";
import Dialog from "components/Dialog";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { actionsGlobal } from "redux/reducers/global";
import services from "components/header/services";

export default function BeforeProfile() {
  const dispatch = useDispatch();
  const { isAlertOpen } = useSelector((state) => state.global);

  const handleClose = () => {
    dispatch(actionsGlobal.closeOpenAlert());
    dispatch(services.postLogout())
  };

  return (
    <Dialog visible={isAlertOpen} onClose={handleClose} maxWidth="lg">
      {/* <DialogTitle id="alert-dialog-title">
        
      </DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Maaf, sesi anda telah kadaluarsa. Silakan login lagi untuk melanjutkan
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  );
}
