import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import { LocationOn, Phone, Mail } from "@material-ui/icons";
import classnames from "classnames";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import token from "utils/session";
import { useDispatch, useSelector } from "react-redux";
import { displayModalSiginIn } from "redux/reducers/auth";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import endpoint from "utils/endpoint";
import axios from "axios";

export default function Ticket(props) {
  const { classLeft, classRight, options, daftarKegiatanPKP, kegiatanNonPkp } =
    props;

  const waktuMulai = get(options, "start", new Date());
  const waktuAkhir = get(options, "end", new Date());
  const [code, setCode] = React.useState(null);
  const [result, setResult] = React.useState(null);
  const [dialogBox, setDialogBox] = React.useState(null);
  const [nonPkp, setNonPkp] = React.useState(null);
  // const { accessToken } = useSelector((state) => state.auth);
  const { profile: user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const checkLogin = () => {
    let optionSpread = { ...options, value: nonPkp, kode_undangan: code };
    if (!token && !nonPkp) {
      dispatch(displayModalSiginIn());
    } else if (!nonPkp && user?.type !== "klpd") {
      history.push({ pathname: "/kalendar/daftar", state: optionSpread });
    } else {
      history.push({
        pathname: "/kalendar/daftar/non_pkp",
        state: optionSpread,
      });
    }
  };

  function handleChangeFile(e) {
    setCode(e.target.value);
    setDialogBox(false);
  }

  const checkCode = async () => {
    const url = endpoint.checkKodeKegiatan;
    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        type: "kode_undangan",
        code: code,
        kegiatan_id: options.id,
      },
    };

    try {
      const response = await axios(url, config);
      setDialogBox(response?.data?.data?.result ? false : true);
      setResult(response?.data?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="w-full flex lg:flex-row flex-col items-start py-4">
        <div
          className={classnames(
            classLeft,
            "space-y-4 lg:pr-4 lg:pb-0 pb-4 lg:border-b-0 border-b border-gray-200"
          )}
        >
          {options?.sisa_kuota > 0 && options?.status_kegiatan !== "terdaftar" && (
            <Box
              className={`flex items-center space-x-3 px-4 py-2 bg-black-2 rounded-lg`}
            >
              <ConfirmationNumberOutlinedIcon className="text-white" />

              <Typography
                variant="body1"
                className="font-bold block text-white"
              >
                Kuota tersedia
              </Typography>
            </Box>
          )}

          {options?.sisa_kuota <= 0 &&
            options?.status_kegiatan !== "terdaftar" && (
              <Box
                className={`flex items-center space-x-3 px-4 py-2 bg-neon-red rounded-lg`}
              >
                <ConfirmationNumberOutlinedIcon className="text-white" />

                <Typography
                  variant="body1"
                  className="font-bold block text-white"
                >
                  Kuota tidak tersedia
                </Typography>
              </Box>
            )}

          {options?.status_kegiatan === "terdaftar" && (
            <Box
              className={`flex items-center space-x-3 px-4 py-2 bg-blue-second rounded-lg`}
            >
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.3 9.63295C9.9526 8.98029 10.397 8.14877 10.577 7.24354C10.7571 6.33831 10.6646 5.40002 10.3114 4.54733C9.95817 3.69463 9.36003 2.96583 8.59261 2.45307C7.82519 1.94031 6.92296 1.66663 6 1.66663C5.07704 1.66663 4.17481 1.94031 3.40739 2.45307C2.63997 2.96583 2.04183 3.69463 1.68861 4.54733C1.33539 5.40002 1.24294 6.33831 1.42297 7.24354C1.603 8.14877 2.04741 8.98029 2.7 9.63295L6 12.933L9.3 9.63295ZM6 14.8183L1.75734 10.5756C0.918228 9.7365 0.346791 8.66741 0.115286 7.50352C-0.11622 6.33964 0.00260456 5.13324 0.456732 4.03689C0.91086 2.94054 1.6799 2.00347 2.66659 1.34418C3.65328 0.6849 4.81332 0.333008 6 0.333008C7.18669 0.333008 8.34672 0.6849 9.33342 1.34418C10.3201 2.00347 11.0891 2.94054 11.5433 4.03689C11.9974 5.13324 12.1162 6.33964 11.8847 7.50352C11.6532 8.66741 11.0818 9.7365 10.2427 10.5756L6 14.8183ZM6.66667 6.33296H9.33333V7.66629H5.33334V2.99962H6.66667V6.33296Z"
                  fill="#FEFEFE"
                />
              </svg>

              <Typography
                variant="body1"
                className="font-bold block text-white"
              >
                Terdaftar
              </Typography>
            </Box>
          )}

          {waktuMulai && waktuAkhir && (
            <div className="flex items-center justify-between border-b border-gray-200 pb-3">
              <div className="text-black-2">
                <span className="font-montserrat font-extrabold text-base block">
                  {format(waktuMulai, "eeee", { locale: id })}
                </span>
                <span className="font-montserrat font-extrabold text-base block">
                  {format(waktuMulai, "dd MMM yyyy", { locale: id })}
                </span>
                <span className="font-montserrat font-extrabold text-xl block">
                  {format(waktuMulai, "HH:mm", { locale: id })}
                </span>
              </div>
              <div className="text-black-2">
                <span className="font-montserrat font-extrabold text-xl block">
                  s/d
                </span>
              </div>
              <div className="text-black-2">
                <span className="font-montserrat font-extrabold text-base block">
                  {format(waktuAkhir, "eeee", { locale: id })}
                </span>
                <span className="font-montserrat font-extrabold text-base block">
                  {format(waktuAkhir, "dd MMM yyyy", { locale: id })}
                </span>
                <span className="font-montserrat font-extrabold text-xl block">
                  {format(waktuAkhir, "HH:mm", { locale: id })}
                </span>
              </div>
            </div>
          )}
          {get(options, "bentuk") === "online" &&
            user &&
            options?.status_kegiatan === "terdaftar" && (
              <Box className="flex items-start space-x-2">
                <LocationOn color="primary" />
                <div>
                  <p className="font-montserrat font-bold text-sm block">
                    Online
                  </p>
                  <a
                    className="font-montserrat font-normal text-sm block text-blue-second"
                    href={get(options, "conference_url")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {get(options, "conference_url")}
                  </a>
                </div>
              </Box>
            )}
          {get(options, "bentuk") === "offline" && (
            <Box className="flex items-start space-x-2">
              <LocationOn color="primary" />
              <div>
                {/* <p className="font-montserrat font-bold text-sm block">Online</p> */}
                <p className="font-montserrat font-normal text-sm block">
                  {get(options, "alamat")}
                </p>
              </div>
            </Box>
          )}
        </div>
        <div
          className={classnames(
            classRight,
            "lg:pl-4 pl-0 lg:py-0 py-3 lg:border-l"
          )}
        >
          {get(options, "jenis_kegiatan") === "pkp" ? (
            <div
              className={`p-4 w-max border-solid border-4 border-pink-header text-pink-header font-montserrat font-semibold text-sm rounded`}
            >
              Kegiatan PKP
            </div>
          ) : (
            <div
              className={`p-4 w-max border-solid border-4 border-blue-second text-blue-second
             font-montserrat font-semibold text-sm rounded `}
            >
              Kegiatan Non-PKP
            </div>
          )}
          <div className="border-b border-gray-200 pb-5">
            <h5 className="font-baloo2 font-extrabold text-2xl text-black-2">
              {get(options, "nama", "")}
            </h5>
          </div>
          <Box className="py-3">
            <div className="pb-3">
              <span className="font-baloo2 font-bold text-xl text-black-2 block">
                Kontak narahubung
              </span>
            </div>
            <Box className="grid lg:grid-cols-2 grid-cols-1 gap-3 items-start">
              {[...Array(4)].map((_item, index) => {
                const itemId = index === 0 ? "" : index + 1;
                const isHaveNara = get(
                  options,
                  `narahubung${itemId}_nama`,
                  false
                );

                if (!isHaveNara) {
                  return null;
                }

                return (
                  <div className="flex-1" key={itemId}>
                    <div className="flex space-x-2 items-center">
                      <Phone color="primary" />
                      <div className="font-montserrat font-medium text-sm text-black-2">
                        {get(options, `narahubung${itemId}_telepon`)} (
                        {get(options, `narahubung${itemId}_nama`)})
                      </div>
                    </div>

                    <div className="flex space-x-2 items-center pt-2">
                      <Mail color="primary" />
                      <a
                        href={`mailto::${get(
                          options,
                          `narahubung${itemId}_email`
                        )}`}
                        className="font-montserrat font-medium text-sm text-blue-second underline"
                      >
                        {get(options, `narahubung${itemId}_email`)}
                      </a>
                    </div>
                  </div>
                );
              })}
            </Box>
          </Box>
        </div>
      </div>
      {options?.status_kegiatan !== "terdaftar" &&
        options?.jenis_kegiatan !== "pkp" &&
        !token &&
        !kegiatanNonPkp && (
          <Box
            sx={{
              "& .MuiFormControlLabel-label": { width: "15%" },
              "& .MuiFormControlLabel-root": {
                width: "98.5%",
                display: "flex",
              },
            }}
          >
            <FormControlLabel
              value="top"
              labelPlacement="start"
              label="Mendaftar Sebagai"
              control={
                <select
                  className="border border-gray-200 rounded-lg py-1 lg:w-full form-select self-end"
                  onChange={(e) => setNonPkp(e.target.value)}
                  value={nonPkp}
                >
                  <option value="0">Pelaku Usaha</option>
                  <option value="1">K/L/PD</option>
                  <option value="2">Umum</option>
                </select>
              }
            />
          </Box>
        )}
      {options?.status_kegiatan !== "terdaftar" &&
        options?.is_invitation === 1 &&
        !daftarKegiatanPKP &&
        !kegiatanNonPkp && (
          <Box
            sx={{
              "& .MuiFormControlLabel-label": { width: "15%" },
              "& .MuiFormControlLabel-root": {
                width: "98.5%",
                display: "flex",
              },
            }}
          >
            <FormControlLabel
              value="top"
              labelPlacement="start"
              label="Kode Undangan"
              control={
                <div className="flex w-full">
                  <div className="flex w-10.5/12 overflow-x-auto whitespace-nowrap font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded">
                    <input
                      className="bg-neutral-grey w-full"
                      type="text"
                      onChange={handleChangeFile}
                    />
                  </div>
                  <div className="">
                    <button
                      onClick={checkCode}
                      type="button"
                      className="text-white p-2 px-5 rounded bg-pink-header"
                    >
                      Cek Kode
                    </button>
                  </div>
                </div>
              }
            />
          </Box>
        )}
      {dialogBox && (
        <Typography
          variant="body1"
          className="font-bold block text-pink-header pt-3 pl-4"
        >
          Kode Salah!
        </Typography>
      )}
      <div className="mt-5">
        {options?.status_kegiatan !== "terdaftar" &&
          !daftarKegiatanPKP &&
          options?.sisa_kuota > 0 &&
          user?.type !== "klpd" && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              component={"div"}
              onClick={checkLogin}
              disabled={!options?.is_invitation ? false : result ? false : true}
            >
              Daftar
            </Button>
          )}
        {options?.status_kegiatan !== "terdaftar" &&
          !daftarKegiatanPKP &&
          options?.sisa_kuota > 0 &&
          user?.type === "klpd" &&
          options?.jenis_kegiatan === "nonpkp" && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              component={"div"}
              onClick={checkLogin}
              disabled={!options?.is_invitation ? false : result ? false : true}
            >
              Daftar
            </Button>
          )}
      </div>
    </React.Fragment>
  );
}

Ticket.propTypes = {
  classLeft: PropTypes.string,
  classRight: PropTypes.string,
};

Ticket.defaultProps = {
  classLeft: "lg:w-4/12 w-full",
  classRight: "lg:w-8/12 w-full",
  options: {
    waktu_mulai: new Date(),
    waktu_akhir: new Date(),
  },
};
