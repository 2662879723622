import axios from "axios";
import endpoint from "utils/endpoint";
import token from "utils/session";
import { convertObjectToFormData } from "utils/helpers";

async function getProfile(params, callback) {
  const url = endpoint.profile;
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url, config);
    callback.success(response.data);
  } catch (error) {
    callback.error(error);
  }
}

async function getVendorDetail(params, callback) {
  const url = `${endpoint.search}?user_id=${params.id}`;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    // console.log(response)
    callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

async function postProfile(params, callback) {
  const formData = convertObjectToFormData(params);

  const url = endpoint.profile;
  const config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  };

  try {
    const response = await axios(url, config);
    callback(true, response.data);
    return Promise.resolve(response.data);
  } catch (error) {
    callback(false, error);
    return Promise.reject(error);
  }
}

async function getSertifikat(params, callback) {
  const url = endpoint.sertifikat;
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  try {
    const response = await axios(url, config);
    callback.success(response.data);
  } catch (error) {
    callback.error(error);
  }
}

async function postSertifikat(params, callback) {
  const formData = convertObjectToFormData(params);

  const url = endpoint.sertifikat;
  const config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  };

  try {
    const response = await axios(url, config);
    callback(true, response.data);
    return Promise.resolve(response.data);
  } catch (error) {
    callback(false, error);
    return Promise.reject(error);
  }
}

async function deleteSertifikat(params, callback) {
  const formData = convertObjectToFormData(params);
  const url = `${endpoint.sertifikat}/${params.id}`;

  const config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  };

  try {
    const response = await axios(url, config);
    callback(true, response.data);
  } catch (error) {
    console.log(error);
    callback(false, error);
  }
}

async function getKegiatanTerdaftar(params, callback) {
  const url = `${endpoint.listkalendar}`;

  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  try {
    const response = await axios(url, config);
    callback.success(response.data);
  } catch (error) {
    callback.error(error);
  }
}

export default {
  getProfile,
  getVendorDetail,
  postProfile,
  getSertifikat,
  postSertifikat,
  deleteSertifikat,
  getKegiatanTerdaftar,
};

// const url = `${endpoint.sertifikat}/${params.id}`;

// const config = {
//   method: 'POST',
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
//   data: {
//     vendor_business_id: data?.vendor_business_id,
//     jenis_sertifikat: data?.jenis_sertifikat,
//     lembaga_penerbit: data?.lembaga_penerbit,
//     berlaku_mulai: data?.berlaku_mulai,
//     berlaku_hingga: data?.berlaku_hingga,
//     is_selamanya: data?.is_selamanya,
//   }
// };

// try {
//   const response = await axios(url,config);
//   setMessage({
//     open: true,
//     severity: "success",
//     message: "Berhasil Menghapus Sertifikat!"
//   })
// } catch (error) {
//   setMessage({
//     open: true,
//     severity: "error",
//     message: error?.data?.message
//   })
// }
