import axios from 'axios';
import endpoint from 'utils/endpoint';

export async function getBagianPbjp(params,callback) {
  const url = endpoint.bagianPbjp;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

export async function getInfoPbjp(params,callback) {
  const url = endpoint.infoPbjp;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}