import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
  name: "global",
  initialState: {
    isSidebarOpen: false,
    isSearchOpen: false,
    isAlertOpen: false,
  },
  reducers: {
    openSidebar: (state) => {
      state.isSidebarOpen = true;
    },
    closeSidebar: (state) => {
      state.isSidebarOpen = false;
    },
    openSearchSidebar: (state) => {
      state.isSearchOpen = true
    },
    closeSearchSidebar: (state) => {
      state.isSearchOpen = false
    },
    openAlert: (state) => {
      state.isAlertOpen = true
    },
    closeOpenAlert: (state) => {
      state.isAlertOpen = false
    }
  },
});

export const actionsGlobal = globalSlice.actions;

export default globalSlice.reducer;
