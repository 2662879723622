import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = makeStyles(() =>
  createStyles({
    navItem: {
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: 'normal',
      paddingBottom: 7,
      paddingTop: 7,
      paddingRight: 10,
      paddingLeft: 10,
    },
    navItemActive: {
      fontWeight: 700,
      borderBottom: "4px solid #DE1B51",
      paddingBottom: "14px",
    },
  })
);

export default function Link(props) {
  const { children, href, ...other } = props;
  const classes = useStyles();

  const classLink = React.useMemo(
    () =>
      classnames({
        [classes.navItemActive]: href.replace('/', '') === window.location.hash,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.href]
  );

  return (
    <a href={href} className={classes.navItem} {...other}>
      <span className={classLink}>{children}</span>
    </a>
  );
}

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};
