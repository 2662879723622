import React from "react";
import VDLogo from "assets/VD.png";
import VDText from "assets/VendorDirektori.png";
import LKPPLogo from "assets/logo-LKPP-1.png";
import SearchLogo from "assets/Vector.png";
import QuestionLogo from "assets/question.png";
import { NavLink, useLocation, useHistory, Link as RouterLink } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch } from "react-redux";
import { actionsGlobal } from "../../redux/reducers/global";
import SearchIcon from '@material-ui/icons/Search';

function Header(props) {
  const history = useHistory();

  const dispatch = useDispatch();

  const [params, setParams] = React.useState({
    q: "",
  });

  const onEnterSearch = (e) => {
    const textSearch = e.target.value;
    const params = new URLSearchParams({ q: textSearch });

    if (e.key === "Enter") {
      if (window.location.pathname !== "/search") {
        history.push(`/search?${params}`);
      } else {
        history.replace(`/search?${params}`);
      }
    }
  };

  const onButtonSearch = () => {
    const testParams = params.q;
    const userParams = new URLSearchParams({ q: testParams });

    if (window.location.pathname !== "/search") {
      history.push(`/search?${userParams}`);
    } else {
      history.replace(`/search?${userParams}`);
    }
  };

  return (
    <header>
      <div className="w-full flex lg:justify-start justify-between font-montserrat bg-pink-header">
        <div className="pl-8 flex items-center py-2 bg-neutral-grey">
          <div className="flex items-center border-r border-solid border-home-header-bg pr-2">
            <NavLink to="/">
              <img src="/static/images/logo-vd.png" className="h-10 w-auto" alt="" />
            </NavLink>
          </div>
          <NavLink to="/">
            <img src={LKPPLogo} alt="" className="h-10 w-auto pl-2" />
          </NavLink>
        </div>
        <div className="bg-pink-header lg:hidden flex items-center justify-end">
          <IconButton component={RouterLink} to="/search">
            <SearchIcon className="text-white" />
          </IconButton>
          <IconButton onClick={() => dispatch(actionsGlobal.openSidebar())}>
            <MenuIcon className="text-white" />
          </IconButton>
        </div>
        <div className="bg-pink-header lg:flex hidden flex-1 justify-between">
          <div className="font-montserrat flex">
            <NavLink
              to="/"
              activeClassName="font-bold"
              className="py-4 text-sm text-neutral-grey border-r border-solid px-2 border-border-on-pink"
              style={
                useLocation().pathname === "/"
                  ? { background: "rgba(89, 89, 89, 0.2)" }
                  : { background: "" }
              }
            >
              <div>Beranda</div>
            </NavLink>
            <NavLink to="/search" activeClassName="font-bold">
              <div
                className="py-4 text-sm text-neutral-grey border-r border-solid px-2 border-border-on-pink"
                style={
                  useLocation().pathname === "/search"
                    ? { background: "rgba(89, 89, 89, 0.2)" }
                    : { background: "" }
                }
              >
                Cari pelaku usaha
              </div>
            </NavLink>
            <NavLink to="/statistics" activeClassName="font-bold">
              <div
                className="py-4 text-sm text-neutral-grey border-r border-solid px-2 border-border-on-pink"
                style={
                  useLocation().pathname === "/statistics"
                    ? { background: "rgba(89, 89, 89, 0.2)" }
                    : { background: "" }
                }
              >
                Statistik
              </div>
            </NavLink>
            <NavLink to="/info_pbjp" activeClassName="font-bold">
              <div
                className="py-4 text-sm text-neutral-grey border-r border-solid px-2 border-border-on-pink"
                style={
                  useLocation().pathname === "/info_pbjp"
                    ? { background: "rgba(89, 89, 89, 0.2)" }
                    : { background: "" }
                }
              >
                Informasi PBJP
              </div>
            </NavLink>
            <NavLink
              activeClassName="font-bold"
              to="/about_us"
              className="py-4 text-sm text-neutral-grey px-2"
              style={
                useLocation().pathname === "/about_us"
                  ? { background: "rgba(89, 89, 89, 0.2)" }
                  : { background: "" }
              }
            >
              <div>Tentang Kami</div>
            </NavLink>
          </div>
          <div className="flex items-center">
            <div className="relative">
              <input
                name="search"
                className="flex w-64 rounded p-2 font-montserrat text-xs border border-gray-400 text-black-2 italic focus:border-pink-700 placeholder-gray-500"
                placeholder="Cari pelaku usaha"
                onChange={(e) =>
                  setParams((prev) => ({ ...prev, q: e.target.value }))
                }
                onKeyDown={onEnterSearch}
              />
              <button
                className={
                  "absolute right-0 top-1/2 bottom-1/2 my-auto w-10 h-10 flex items-center justify-center focus:outline-none"
                }
                // type="submit"
                onClick={onButtonSearch}
              >
                <img src={SearchLogo} alt="" />
              </button>
            </div>
            <NavLink to="/faq">
              <img src={QuestionLogo} alt="" className="pr-5 pl-2" />
            </NavLink>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
