import React, { useState } from "react";
import Footer from "components/footer";
import Box from "@material-ui/core/Box";
import Breadcrumb from "components/Breadcrumb";
import Ticket from "../components/Ticket";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import PlusIcon from "@material-ui/icons/Add";
import { ThemeProvider, createTheme } from "@material-ui/core";
import theme from "theme";
import get from "lodash/get";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import Pagination from "components/pagination";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "components/Dialog";
import CloseIcon from "@material-ui/icons/Close";

import { useDispatch, useSelector } from "react-redux";
import { getMyVendor } from "redux/reducers/users";
import useProfile from "hooks/useProfile";
import UsahaCard from "components/UsahaCard";
import { useLocation } from "react-router-dom";
import usePagination from "utils/usePagination";
import endpoint from "utils/endpoint";
import token from "utils/session";
import axios from "axios";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function DaftarKegiatanPKP() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { vendor } = useSelector((state) => state.user);
  const options = location.state;
  const totalVendor = React.useMemo(() => get(vendor, "total", 0), [vendor]);
  const listVendor = React.useMemo(() => get(vendor, "data", []), [vendor]);
  const [total, setPagination] = usePagination();
  const [page, setPage] = useState(1);
  const [selectedUsaha, setSelectedUsaha] = useState(null);
  const [tnc, setTnc] = useState(false);
  const history = useHistory();
  const [dialogBox, setDialogBox] = useState({
    success: false,
    failed: false,
    text: "",
  });
  const { user } = useProfile();
  const onSelectUsaha = (usaha) => {
    setSelectedUsaha(usaha);
  };

  React.useEffect(() => {
    dispatch(
      getMyVendor({ user_id: get(user, "id"), page: page, page_size: 2 })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, page]);

  React.useEffect(() => {
    setPagination(2, vendor.total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor, page]);

  async function registerActivity(params) {
    const url = endpoint.registerKegiatan;
    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    };

    try {
      const response = await axios(url, config);
      setDialogBox((prev) => ({
        ...prev,
        success: response?.data?.message === "Berhasil" ? true : false,
      }));
    } catch (error) {
      setDialogBox((prev) => ({
        ...prev,
        failed:
          error?.response?.data?.message === "Already registered"
            ? true
            : false,
        text:
          error?.response?.data?.message === "Already registered"
            ? "Anda Telah Mendaftar!"
            : "Berhasil Mendaftar",
      }));
    }
  }
  return (
    <Box>
      <Box className="w-9/12 mx-auto py-10">
        <Breadcrumb
          links={[
            { href: "/", name: "Beranda" },
            { href: "/kalendar", name: "Kalendar Kegiatan" },
            { name: "Daftar Kegiatan" },
          ]}
        />

        {/* <Box className="min-h-screen"> */}
        <div className="flex items-center justify-center">
          <img src="/static/images/logo-vd.png" alt="" style={{ height: 70 }} />
        </div>

        {/* <Box className="px-6 py-4 my-4 rounded-lg" boxShadow={3}> */}
        <div>
          <span className="text-black-2 font-baloo2 text-4xl font-bold">
            Tingkat Credit Score Usaha Anda!
          </span>

          <div className="border-b-2 border-pink-header w-4/12 mt-10"></div>
        </div>
        <div className="my-4">
          <p className="text-black-2 font-baloo2 text-lg">
            Peningkatan Kapasitas Pelaku Usaha merupakan kegiatan untuk
            memberikan wawasan dan pelatihan kepada pelaku usaha dalam Pengadaan
            Barang/Jasa Pemerintah.
          </p>
        </div>

        <Ticket options={options} daftarKegiatanPKP />

        {/* <Box> */}
        <div className="py-5 border-t border-gray-200 flex items-center justify-between">
          <div>
            <span className="text-lg text-black-2 font-baloo2 font-bold">
              Punya usaha yang belum terdaftar?
            </span>
          </div>
          <ThemeProvider
            theme={createTheme({
              ...theme,
              palette: { primary: { main: "#00A65A" } },
            })}
          >
            <Button
              startIcon={<PlusIcon />}
              variant="contained"
              color="primary"
              component={Link}
              to={`/add_new_business/${get(user, "id")}`}
            >
              Daftarkan Perusahaan Baru
            </Button>
          </ThemeProvider>
        </div>

        {/* <Box
              className="border-t-2 border-b-2 border-gray-200 space-y-3"
              sx={{ px: 4, py: 4, bgcolor: "grey.200" }}
            > */}
        <div
          className={classNames(
            "bg-neutral-grey p-6 flex-col items-center justify-center",
            {
              hidden: totalVendor > 0,
              flex: totalVendor === 0,
            }
          )}
          style={{ minHeight: 300 }}
        >
          <p className="font-bold font-arial text-2xl text-search">
            Belum ada usaha yang didaftarkan
          </p>
        </div>

        {listVendor.map((_item, index) => (
          // <Box
          //   className="w-full rounded-lg overflow-hidden focus:ring-1 focus:ring-pink-header"
          //   component="button"
          // >
          <div
            onClick={() => onSelectUsaha(_item)}
            className={`
              ${
                selectedUsaha?.id === _item?.id
                  ? "ring-1 ring-pink-header my-3"
                  : "my-3"
              }
              bg-neutral-grey
            `}
          >
            <UsahaCard data={_item} key={index} disabledLink />
          </div>
          // </Box>
        ))}
        {/* </Box> */}
        <Box
          className="border-b-2 border-gray-200 flex items-center justify-center"
          sx={{ px: 4, py: 4 }}
        >
          <Pagination
            count={total}
            page={page}
            onChange={(event, page) => setPage(page)}
          />
        </Box>
        <Box className="flex items-end">
          <div className="flex-1">
            <FormControlLabel
              value="end"
              control={<Checkbox color="primary" />}
              label={
                <div>
                  Dengan ini saya telah menyetujui{" "}
                  <a
                    href="/syarat_dan_ketentuan"
                    className="text-pink-header mr-2"
                  >
                    Syarat dan Ketentuan
                  </a>
                  yang berlaku
                </div>
              }
              labelPlacement="end"
              onChange={() => setTnc(!tnc)}
            />
          </div>
          <div className="flex-1 flex items-end justify-end">
            {/* <FormControlLabel
              value="top"
              labelPlacement="top"
              label="Pernah mengikuti kegiatan PKP oleh LKPP ?"
              aria-required
              control={
                <select className="border border-gray-200 rounded-lg w-full px-4 py-1 form-select">
                  <option value="1">Ya</option>
                  <option value="0">Tidak</option>
                </select>
              }
            /> */}

            <Button
              onClick={() =>
                tnc && selectedUsaha
                  ? registerActivity({
                      kegiatan_id: options.id,
                      vendor_business_id: selectedUsaha.id,
                      kode_undangan: options?.kode_undangan,
                    })
                  : setDialogBox((prev) => ({
                      ...prev,
                      failed: true,
                    }))
              }
              variant="contained"
              color="primary"
            >
              Pilih dan daftar PKP
            </Button>
          </div>
        </Box>
        {/* </Box> */}
        {/* </Box> */}
        {/* </Box> */}
      </Box>
      <Dialog
        visible={dialogBox.success}
        onClose={() => {
          setDialogBox((prev) => ({
            ...prev,
            success: !dialogBox.success,
          }));
        }}
      >
        <div className="flex items-center justify-end">
          <button
            onClick={() => {
              setDialogBox((prev) => ({
                ...prev,
                success: !dialogBox.success,
              }));
            }}
            className="px-4 py-2"
          >
            <CloseIcon />
          </button>
        </div>

        <div className="bg-white px-6 pb-4 pt-2">
          <div className="border-b border-gray-200 block pb-3">
            <h5 className="font-baloo2 font-bold text-2xl text-black-2">
              Pendaftaran Kegiatan Berhasil!
            </h5>
            <p className="font-baloo2 font-bold text-lg text-black-2 py-6">
              Silahkan cek inbox email anda dan buka email tiket kegiatan dari
              LKPP untuk melihat info detail kegiatan
            </p>
            <Button
              variant="contained"
              color="primary"
              component={"div"}
              onClick={() => {
                setDialogBox((prev) => ({
                  ...prev,
                  success: !dialogBox.success,
                }));
                history.push({
                  pathname: "/kalendar",
                  state: "kalendar",
                });
              }}
              fullWidth
            >
              Tutup
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={dialogBox.failed}
        onClose={() => {
          setDialogBox((prev) => ({
            ...prev,
            failed: false,
          }));
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Maaf,{" "}
            {dialogBox.text === "Anda Telah Mendaftar!"
              ? dialogBox.text
              : !selectedUsaha
              ? "Anda Belum Memilih Usaha!"
              : "Anda Belum Mencentang Syarat dan Ketentuan"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogBox((prev) => ({
                ...prev,
                failed: false,
              }));
            }}
            color="primary"
            autoFocus
          >
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </Box>
  );
}
