import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormHelperText } from "@material-ui/core";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { useMapEvents } from "react-leaflet";
import debounce from "lodash/debounce";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  })
);

function TracktoLocation(props) {
  const { setPosition, position, onChange } = props;
  const map = useMapEvents({
    click(e) {
      onChange(e, { x: e.latlng.lng, y: e.latlng.lat });
      setPosition(e.latlng);
      map.flyTo(e.latlng);
    },
    locationfound(e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  React.useEffect(() => {
    map.flyTo(position, map.getZoom());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  return position.lat === 0 ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
}

export default function Map(props) {
  const { onChange: onChangeForm, lat, long } = props;

  const refOpenStreet = React.useRef(new OpenStreetMapProvider());

  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [position, setPosition] = React.useState({
    lat: 0,
    lng: 0,
  });

  const latt = React.useMemo(() => lat, [lat]);
  const lang = React.useMemo(() => long, [long]);

  React.useEffect(() => {
    setPosition({ lat: latt, lng: lang });
  }, [latt, lang]);

  async function onInputChange(ev, text) {
    setIsLoading(true);

    try {
      const results = await refOpenStreet.current.search({ query: text });
      await setOptions(results);
      await setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function onChange(ev, option) {
    if (option) {
      setPosition({
        lat: option?.y,
        lng: option?.x,
      });
      if (typeof onChangeForm === "function") {
        onChangeForm(option);
      }
    }
  }

  return (
    <div className="mt-2">
      <FormHelperText>Masukan kelurahan dan kecamatan</FormHelperText>      
      <Autocomplete
        options={options.map((ix) => ({ value: ix.raw.place_id, ...ix }))}
        loading={isLoading}
        getOptionLabel={(option) => option.label}
        onInputChange={debounce(onInputChange, 1000)}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Ketik alamat"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />

      <MapContainer
        style={{ height: "200px", width: "100%", marginTop: 30 }}
        center={{ lat: 0, lng: 0 }}
        zoom={13}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <TracktoLocation
          position={position}
          setPosition={setPosition}
          onChange={onChange}
        />
      </MapContainer>
    </div>
  );
}

Map.propTypes = {
  onChange: PropTypes.func,
  lat: PropTypes.string,
  long: PropTypes.string,
};
