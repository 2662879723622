import React, { useState, useEffect, useRef } from "react";
import Footer from "components/footer";
import Breadcrumbs from "components/Breadcrumb";
import { useLocation, useHistory } from "react-router-dom";
import VDLogo from "assets/logo-vd.png";
import { useFormik } from "formik";
import * as services from "./services";
import * as Yup from "yup";
// import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  provinsiData,
  kabupatenData,
  getOrganisasi,
  getViewOrganisasi,
} from "redux/reducers/region";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Captcha from "components/Captcha";
import Dialog from "components/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Ticket from "pages/KalendarKegiatan/components/Ticket";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      display: "flex",
      width: "100%",
      alignItems: "center",
    },
  })
);

function KegiatanNonPKP() {
  const classDialog = useStyles();

  const dispatch = useDispatch();
  const { provinsi, kabupaten, instansi, organisasi } = useSelector(
    (state) => state?.region
  );

  const location = useLocation();
  const history = useHistory();
  const options = location.state;
  const captchaRef = useRef();
  const { profile: user } = useSelector((state) => state.user);

  const [message, setMessage] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [listPendidikan, setListPendidikan] = useState([]);

  useEffect(() => {
    services.getProvinsi({}, (response, err) => {
      if (!err) {
        dispatch(provinsiData({ provinsi: response.data }));
      }
    });

    services.getPendidikan({}, (data) => setListPendidikan(data.data));
  }, []);

  const klpdUserSchema = Yup.object().shape({
    province_id: Yup.number().required("Provinsi Harus Diisi").nullable(),
    city_id: Yup.number().required("Kota Harus Diisi").nullable(),
    email: Yup.string().required("Email Harus Diisi").nullable(),
    tnc: Yup.boolean().oneOf(
      [true],
      "Anda Harus Mencentang Syarat dan Ketentuan"
    ),
    tipe_user: "",
    nama_lengkap: Yup.string().required("Nama Lengkap Harus Diisi").nullable(),
    jenis_kelamin: Yup.string()
      .required("Jenis Kelamin Harus Diisi")
      .nullable(),
    no_telepon: Yup.number().required("No Telepon Harus Diisi").nullable(),
    jabatan: Yup.string().required("Jabatan Harus Diisi").nullable(),
    instansi_id: Yup.string().required("Instansi Harus Diisi").nullable(),
    nip: Yup.string()
      .required("NIP Harus Diisi")
      .min(18, "NIP harus memiliki 18 digit")
      .max(18, "NIP harus memiliki 18 digit")
      .nullable(),
    kode_undangan: "",
  });

  const umumUserSchema = Yup.object().shape({
    province_id: Yup.number().required("Provinsi Harus Diisi"),
    city_id: Yup.number().required("Kota Harus Diisi"),
    email: Yup.string().required("Email Harus Diisi"),
    tnc: Yup.boolean().oneOf(
      [true],
      "Anda Harus Mencentang Syarat dan Ketentuan"
    ),
    nama_lengkap: Yup.string().required("Nama Lengkap Harus Diisi"),
    jenis_kelamin: Yup.string().required("Jenis Kelamin Harus Diisi"),
    no_telepon: Yup.number().required("No Telepon Harus Diisi"),
    pendidikan_terakhir: Yup.string().required(
      "Pendidikan Terakhir Harus Diisi"
    ),
    is_oap: Yup.string().when("province_id", {
      is: (val) => /^(91|94)$/.test(val),
      then: Yup.string().required("Orang Asli Papua Harus Diisi"),
    }),
    kode_undangan: "",
    afiliasi: Yup.string().required("Afiliasi Harus Diisi"),
  });

  // eslint-disable-next-line
  const {
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
    errors,
    touched,
    setFieldError,
  } = useFormik({
    initialValues: {
      tnc: false,
      tipe_user:
        Number(options?.value) === 1 || user?.type === "klpd" ? "klpd" : "umum",
      kegiatan_id: options?.id,
      nama_lengkap: "",
      jenis_kelamin: "",
      no_telepon: "",
      province_id: "",
      city_id: "",
      jabatan: "",
      instansi_id: "",
      nip: "",
      captcha: "",
      captcha_key: "",
      kode_undangan: "",
      email: "",
      pendidikan_terakhir: "",
      is_oap: "",
      afiliasi: "",
    },
    validationSchema:
      Number(options?.value) === 1 || user?.type === "klpd"
        ? klpdUserSchema
        : umumUserSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      services.registerKegiatanNonLogin({ ...values }, (isSuccess, data) => {
        captchaRef.current.reloadCaptcha();
        setFieldValue("captcha", "");
        if (isSuccess) {
          setMessage({
            open: true,
            severity: "success",
            message: "Berhasil Mendaftar!",
          });
          window.timeout = setTimeout(() => {
            history.push("/kalendar");
          }, 1500);
        } else {
          const { response } = data;
          if (response.status === 422) {
            const { errors } = response.data;
            if (errors) {
              Object.keys(errors).forEach((attributeName) => {
                setFieldError(attributeName, errors[attributeName].toString());
              });
              const firstMessage = Object.keys(errors)
                .map((attrName) => errors[attrName].toString())
                .toString();
              setMessage({
                open: true,
                severity: "error",
                message: firstMessage,
              });
            } else if (response?.data?.message) {
              setMessage({
                open: true,
                severity: "error",
                message: response.data.message,
              });
            }
          }
          return setMessage({
            open: true,
            severity: "error",
            message:
              response?.data?.message === "Already registered"
                ? "Sudah Mendaftar"
                : response?.data?.message,
          });
        }
      });
    },
  });

  useEffect(() => {
    if (values.province_id) {
      services.getKabupaten(
        { province_id: Number(values.province_id) },
        (data) => dispatch(kabupatenData({ kabupaten: data.data }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.province_id]);

  useEffect(() => {
    if (user) {
      setFieldValue("nama_lengkap", user?.full_name);
      setFieldValue("email", user?.email);
      setFieldValue("jenis_kelamin", user?.gender);
      setFieldValue("no_telepon", user?.phone);
      setFieldValue("organisasi_id", user?.instansi);
      setFieldValue("instansi_id", Number(user?.instansi_bagian?.id));
      dispatch(getViewOrganisasi(user?.instansi_bagian?.type));
      setFieldValue("nip", user?.nip);
      setFieldValue("city_id", user?.city_id);
      setFieldValue("province_id", user?.province_id);
      services.getKabupaten(
        { province_id: Number(user?.province_id) },
        (data) => dispatch(kabupatenData({ kabupaten: data.data }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  // console.log(user);

  useEffect(() => {
    dispatch(getOrganisasi());
    dispatch(getViewOrganisasi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const textTerms = `1. Data yang diisikan dalam Form Aplikasi merupakan tanggung jawab pengguna aplikasi;
  2. Pihak LKPP tidak bertanggung jawab terhadap keabsahan data yang diisi pengguna.`;

  return (
    <div>
      <div className="py-10 lg:px-20 px-6 pt-5">
        <Breadcrumbs
          links={[
            { href: "/", name: "Beranda" },
            { name: "Kalendar Kegiatan", href: `/kalendar` },
            {
              name: "Daftar Kegiatan Non-PKP",
              href: `/kalendar/daftar/non_pkp`,
            },
          ]}
        />
      </div>
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      <div className="py-5 flex w-full justify-center">
        <img src={VDLogo} alt="" style={{ height: 70 }} />
      </div>
      <div
        className="w-10/12 mx-auto flex lg:flex-row flex-col mb-20"
        style={{
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05",
        }}
      >
        <div className="lg:w-1/2 w-full pt-4 lg:pl-12 pl-6 pr-6">
          <div className="font-baloo2 font-bold text-3xl text-black-2">
            Daftar Kegiatan untuk{" "}
            {Number(options?.value) === 1 || user?.type === "klpd"
              ? "K/L/PD"
              : "Umum"}
          </div>
          <div className="pb-10 border-b border-solid border-pink-header w-1/2" />
          <div className="font-montserrat font-medium text-xs text-black-2 pt-4">
            Peningkatan Kapasitas Pelaku Usaha merupakan kegiatan untuk
            memberikan wawasan dan pelatihan kepada pelaku usaha dalam Pengadaan
            Barang/Jasa Pemerintah.
          </div>
          <Ticket
            options={options}
            daftarKegiatanPKP
            kegiatanNonPkp
            classRight="lg:w-6/12 w-full"
            classLeft="lg:w-6/12 w-full"
          />
        </div>

        <div className="lg:w-1/2 w-full mt-4 mb-8 border-l border-solid border-welcome pl-6">
          <div className="w-11/12">
            <h2 className="font-baloo2 whitespace-pre font-bold text-base text-black-2">
              Formulir Daftar Kegiatan
            </h2>
            <span className="font-baloo2 whitespace-pre font-bold text-2xl text-black-2"></span>
          </div>
          <div className="pt-5 w-11/12" />
          <h1 className="font-montserrat font-bold text-sm text-black-2 pt-5">
            Kegiatan Non-PKP
          </h1>
          <form onSubmit={handleSubmit} className="pt-4">
            <div className="pt-4 border-b border-solid border-welcome mb-5" />

            <div className="flex">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Nama Lengkap
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="nama_lengkap"
              {...getFieldProps("nama_lengkap")}
            />
            {touched.nama_lengkap && errors.nama_lengkap && (
              <div className="text-red-500 text-xs">{errors.nama_lengkap}</div>
            )}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Jenis kelamin
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded form-select"
              name="jenis_kelamin"
              {...getFieldProps("jenis_kelamin")}
            >
              <option value="" disabled>
                Pilih Jenis Kelamin
              </option>
              <option value="male">Laki-Laki</option>
              <option value="female">Perempuan</option>
            </select>
            {touched.jenis_kelamin && errors.jenis_kelamin && (
              <div className="text-red-500 text-xs">{errors.jenis_kelamin}</div>
            )}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Email
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="email"
              {...getFieldProps("email")}
            />
            {touched.email && errors.email && (
              <div className="text-red-500 text-xs">{errors.email}</div>
            )}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                No Telepon
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="no_telepon"
              type="number"
              pattern="[0-9]*"
              {...getFieldProps("no_telepon")}
            />
            {touched.no_telepon && errors.no_telepon && (
              <div className="text-red-500 text-xs">{errors.no_telepon}</div>
            )}
            {(Number(options?.value) === 2 || user?.type !== "klpd") && (
              <React.Fragment>
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Pendidikan terakhir
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <select
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded form-select"
                  name="pendidikan_terakhir "
                  {...getFieldProps("pendidikan_terakhir")}
                >
                  <option value="" disabled>
                    Pilih Pendidikan Terakhir
                  </option>
                  {listPendidikan.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
                {touched.pendidikan_terakhir && errors.pendidikan_terakhir && (
                  <div className="text-red-500 text-xs">
                    {errors.pendidikan_terakhir}
                  </div>
                )}
              </React.Fragment>
            )}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Provinsi
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            {/* <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded form-select"
              name="province_id "
              {...getFieldProps("province_id")}
              onChange={(ev) => {
                // setSelectedProvinceID(prev => ({...prev, id: Number(ev.target.value)}))
                setFieldValue("city_id", "");
                setFieldValue("province_id", Number(ev.target.value));
              }}
              // value={selectedProvinceID.id}
            >
              <option value="" disabled>
                Pilih Provinsi
              </option>
              {provinsi.map((provinsi) => (
                <option key={provinsi.id} value={provinsi.id}>
                  {provinsi.name}
                </option>
              ))}
            </select> */}
            <Autocomplete
              options={provinsi}
              onChange={(ev, value) => {
                setFieldValue("city_id", "");
                setFieldValue("province_id", value.id);
              }}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              getOptionSelected={(option, value) => option.id === value.id}
              key={provinsi}
              renderInput={(props) => (
                <TextField
                  {...props}
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  variant="outlined"
                  placeholder="Pilih Kota / Kabupaten"
                  style={{ width: "91.666667%" }}
                  size="small"
                />
              )}
            />
            {touched.province_id && errors.province_id && (
              <div className="text-red-500 text-xs">{errors.province_id}</div>
            )}

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Kota/Kabupaten
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded form-select"
              name="city_id"
              {...getFieldProps("city_id")}
              onChange={(ev) => {
                setFieldValue("district_id", "");
                setFieldValue("city_id", Number(ev.target.value));
              }}
            >
              <option value="" disabled>
                Pilih Kabupaten
              </option>
              {kabupaten.map((kabupaten) => (
                <option key={kabupaten.id} value={kabupaten.id}>
                  {kabupaten.name}
                </option>
              ))}
            </select>
            {touched.city_id && errors.city_id && (
              <div className="text-red-500 text-xs">{errors.city_id}</div>
            )}
            {(Number(options?.value) === 1 || user?.type === "klpd") && (
              <React.Fragment>
                {" "}
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Organisasi
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <select
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded form-select"
                  name="organisasi_id"
                  defaultValue=""
                  onChange={(ev) => {
                    dispatch(getViewOrganisasi(ev.currentTarget.value));
                  }}
                >
                  <option value="" disabled>
                    Pilih Organisasi
                  </option>
                  {Object.keys(organisasi).map((_item, index) => {
                    if (_item === "kementrian") {
                      return (
                        <option value={_item} key={index}>
                          Kementerian
                        </option>
                      );
                    }

                    return (
                      <option value={_item} key={index}>
                        {organisasi[_item]}
                      </option>
                    );
                  })}
                </select>
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Instansi
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <select
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded form-select"
                  name="instansi_id"
                  {...getFieldProps("instansi_id")}
                  onChange={(ev) => {
                    setFieldValue("instansi_id", Number(ev.target.value));
                  }}
                  disabled={instansi.length === 0 ? true : false}
                >
                  <option value="" disabled>
                    Pilih Instansi
                  </option>
                  {instansi.map((instansi) => (
                    <option key={instansi.id} value={instansi.id}>
                      {instansi.name}
                    </option>
                  ))}
                </select>
                {touched.instansi_id && errors.instansi_id && (
                  <div className="text-red-500 text-xs">
                    {errors.instansi_id}
                  </div>
                )}
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Jabatan
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <input
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="jabatan"
                  {...getFieldProps("jabatan")}
                />
                {touched.jabatan && errors.jabatan && (
                  <div className="text-red-500 text-xs">{errors.jabatan}</div>
                )}
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    NIP
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <input
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="nip"
                  type="number"
                  pattern="[0-9]*"
                  {...getFieldProps("nip")}
                />
                {touched.nip && errors.nip && (
                  <div className="text-red-500 text-xs">{errors.nip}</div>
                )}
              </React.Fragment>
            )}

            {/* {(Number(options?.value) === 2 || user?.type !== "klpd") && (
              <React.Fragment>
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Orang Asli Papua (OAP)
                  </div>
                </div>
                <select
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded form-select"
                  name="is_oap"
                  disabled={
                    values?.province_id !== 91 && values?.province_id !== 94
                  }
                  {...getFieldProps("is_oap")}
                  onChange={(ev) => {
                    setFieldValue("is_oap", Number(ev.target.value));
                  }}
                >
                  <option value="" disabled>
                    Pilih
                  </option>
                  <option value={1}>Orang Asli Papua</option>
                  <option value={0}>Bukan</option>
                </select>
                {touched.is_oap && errors.is_oap && (
                  <div className="text-red-500 text-xs">{errors.is_oap}</div>
                )}
              </React.Fragment>
            )} */}

            {user === null && options?.value !== "1" && (
              <React.Fragment>
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Afiliasi
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <input
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="afiliasi"
                  type="text"
                  {...getFieldProps("afiliasi")}
                />
                <p className="font-medium font-montserrat text-sm pt-2 text-black-2">
                  Isikan dengan Akademisi, Wartawan, Mahasiswa, LSM/NGO, dll
                </p>
                {touched.afiliasi && errors.afiliasi && (
                  <div className="text-red-500 text-xs">{errors.afiliasi}</div>
                )}
              </React.Fragment>
            )}

            <div className="pt-3">
              <Captcha
                ref={captchaRef}
                classContainer="lg:pr-0 pr-6"
                inputProps={{
                  className:
                    "w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                  ...getFieldProps("captcha"),
                }}
                callback={(option) => {
                  setFieldValue("captcha_key", option.key);
                }}
              />
            </div>
            {touched.captcha && errors.captcha && (
              <div className="text-red-500 text-xs">{errors.captcha}</div>
            )}
            <div className="pt-5 whitespace-pre-line font-medium font-montserrat text-xs text-black-2">
              {textTerms}
            </div>
            <div className="py-5 flex">
              <input
                className="font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                type="checkbox"
                {...getFieldProps("tnc")}
              />
              <label className="pl-4 font-montserrat font-medium text-xs text-black-2">
                Dengan ini saya telah menyetujui
                <a
                  href="/syarat_dan_ketentuan"
                  className="text-pink-header px-1"
                >
                  Syarat dan Ketentuan
                </a>
                yang berlaku
              </label>
            </div>
            <button
              type="submit"
              className="w-11/12 py-2 font-montserrat text-xs font-medium text-border-on-pink bg-pink-header rounded border-solid border border-pink-header"
            >
              Daftar Kegiatan
            </button>
            {/* </div> */}
          </form>
          <Dialog
            visible={message.open === true}
            onClose={message.open !== true}
            className="mt-3"
          >
            <Alert severity={message.severity} className={classDialog.dialog}>
              {message.message}
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMessage({ open: false, error: "", message: "" });
                }}
              >
                <CloseIcon alt="" className="ml-3 self-center" />
              </IconButton>
            </Alert>
          </Dialog>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default KegiatanNonPKP;
