import React from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import get from "lodash/get";
import ViewRole from "components/ViewRole";
import axios from "axios";
import token from "utils/session";
import endpoint from "utils/endpoint";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { getKabupaten } from "redux/reducers/region";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMediaQuery, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontFamily: "Baloo 2",
      fontWeight: 700,
      color: "#595959",
      fontSize: 18,
    },
    map: {
      display: "block",
      height: "450px",
      width: "100%",
      zIndex: 1,

      [theme.breakpoints.down("md")]: {
        height: 230,
      },
    },
  })
);

export default function PetaPersebaran(props) {
  const {
    columns,
    data,
    hiddenTitle,
    dataTable,
    headers,
    peta,
    params,
    shadow = true,
  } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();
  const { isLoad } = useSelector((state) => state.lokasiUsaha);

  const downloadStatusXlsx = () => {
    setIsLoading(true);
    axios({
      url: endpoint.exportLokasi, //your url
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
        ContentDisposition: `attachment`,
      },
      params: {
        province_id: get(params, "province_id"),
        city_id: get(params, "city_id"),
        format: "xlsx",
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Peta Persebaran.xlsx"); //or any other extension
      link.click();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    });
  };

  const listCity = React.useMemo(() => get(headers, "options", []), [headers]);

  const { onChangeLocation, onChangeRowsPerPage, onSearch } = headers;

  const onOpenMarker = (provinceId) => {
    dispatch(getKabupaten({ province_id: provinceId }));

    if (typeof headers.onChangeProvince === "function") {
      headers.onChangeProvince(provinceId);
    }
  };

  const onCloseMarker = () => {
    dispatch(getKabupaten({ province_id: null }));

    if (typeof headers.onChangeProvince === "function") {
      headers.onChangeProvince(null);
    }
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      {!hiddenTitle && (
        <div>
          <span className={classes.title}>Peta Persebaran</span>
        </div>
      )}
      <div
        className="p-4 rounded border-border-on-pink bg-white mt-2"
        style={
          shadow
            ? {
                boxShadow:
                  "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
                borderRadius: "4px",
              }
            : {}
        }
      >
        <div>
          <MapContainer
            className={classes.map}
            center={[-3.1877921, 116.6951512]}
            zoom={isMobile ? 1 : 5}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {peta?.map((_item) => (
              <Marker
                eventHandlers={{
                  // click: () => onOpenMarker(get(_item, "province_id")),
                  popupclose: () => onCloseMarker(),
                  popupopen: () => onOpenMarker(get(_item, "province_id")),
                }}
                position={[get(_item, "latitude"), get(_item, "longitude")]}
                key={_item.id}
              >
                <Popup>
                  <p className="font-bold">{get(_item, "name")}</p>
                  <p>Jumlah Usaha: {get(_item, "jumlah_usaha")}</p>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>

        <div className="mt-2">
          <div className="flex lg:flex-row flex-col pb-2 border-b border-solid border-light-gray items-center space-x-4">
            <div className="font-roboto font-normal text-sm text-black-2">
              Kota / Kabupaten
            </div>
            <Autocomplete
              options={listCity}
              onChange={onChangeLocation}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              getOptionSelected={(option, value) => option.id === value.id}
              openOnFocus
              key={listCity}
              renderInput={(props) => (
                <TextField
                  {...props}
                  variant="outlined"
                  placeholder="Pilih Kota / Kabupaten"
                  style={{ width: 300 }}
                />
              )}
            />
          </div>
          <div className="py-2 flex lg:flex-row flex-col lg:space-y-0 space-y-3 lg:items-start items-center justify-between">
            <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-3 flex-1">
              <div className="flex items-center space-x-3">
                <button className="font-roboto font-normal text-sm text-black-2">
                  Show
                </button>

                <select
                  onChange={onChangeRowsPerPage}
                  className="px-8 py-2 border border-solid border-light-gray rounded font-roboto font-normal text-sm text-black-2 form-select focus:ring-1 focus:ring-blue-second"
                >
                  <option value="">Pilih</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={25}>25</option>
                  <option value={30}>30</option>
                </select>

                <span className="pl-1 font-roboto font-normal text-sm text-black-2">
                  entries
                </span>
              </div>

              <ViewRole>
                <div className="flex items-center lg:px-3 space-x-2">
                  <span className="font-roboto font-normal text-sm text-black-2">
                    Unduh data
                  </span>

                  <button
                    onClick={downloadStatusXlsx}
                    className="font-roboto font-normal text-sm text-black-2 p-2 rounded bg-welcome"
                  >
                    .xls
                  </button>
                </div>
              </ViewRole>
            </div>

            <div className="font-roboto font-normal text-sm text-black-2 space-x-2">
              <span>Search</span>
              <input
                type="text"
                className="ml-1 px-4 py-2 border border-solid border-light-gray rounded"
                onChange={onSearch}
              />
            </div>
          </div>
          {isLoading && <CircularProgress color="inherit" size={20} />}
          <DataTable
            columns={columns}
            data={data}
            {...dataTable}
            progressPending={isLoad}
          />
        </div>
      </div>
    </div>
  );
}

PetaPersebaran.propTypes = {
  hiddenTitle: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  headers: PropTypes.object,
  pagination: PropTypes.object,
  peta: PropTypes.array,
};

PetaPersebaran.defaultProps = {
  hiddenTitle: false,
  columns: [],
  data: [],
  peta: [],
  headers: {
    city: [],
    onChangeProvince: () => {},
    onChangeCity: () => {},
    onChangeLocation: () => {},
    onChangeRowsPerPage: () => {},
    onSearch: () => {},
  },
};
