import axios from 'axios';
import endpoint from 'utils/endpoint';
import token from "utils/session";

export async function getBroadCast(params,callback) {
  const url = `${endpoint.broadcast}`;
  const config = {
    method: 'GET',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url,config);
    await callback.success(response.data);
  } catch (error) {
    console.log(error);
    await callback.error(error);
  }
}

export async function getBroadCastDetail(params,callback) {
  const url = `${endpoint.broadcast}/${params?.id}`;
  const config = {
    method: 'GET',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url,config);
    await callback.success(response.data);
  } catch (error) {
    console.log(error);
    await callback.error(error);
  }
}

export async function getBroadCastCount(params,callback) {
  const url = `${endpoint.broadcastCount}`;
  const config = {
    method: 'GET',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url,config);
    await callback.success(response.data);
  } catch (error) {
    console.log(error);
    await callback.error(error);
  }
}

export async function postBroadCastRead(params,callback) {
  const url = `${endpoint.broadcast}/read`;
  const config = {
    method: 'POST',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url,config);
    await callback.success(response.data);
  } catch (error) {
    console.log(error);
    await callback.error(error);
  }
}

export async function deleteBroadCast(params,callback) {
  const url = `${endpoint.broadcast}/${params?.id}`;
  const config = {
    method: 'DELETE',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url,config);
    await callback.success(response.data);
  } catch (error) {
    console.log(error);
    await callback.error(error);
  }
}