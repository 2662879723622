import React, { useRef, useState } from "react";
import Footer from "components/footer";
import { useLocation, useHistory } from "react-router-dom";
import VDLogo from "assets/logo-vd.png";
import Image from "assets/undraw_conference_call_b0w6 1.png";
import { useFormik } from "formik";
import * as services from "./services";
import * as Yup from "yup";
import Breadcrumbs from "components/Breadcrumb";
import parseQueryString from "utils/parseQueryString";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import useProfile from "hooks/useProfile";

const CheckAttendanceSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  // code: Yup.string().required(),
});

function Absence() {
  const inputRef = useRef();
  const { user } = useProfile();
  const params = parseQueryString(useLocation().search);
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [errorString, setErrorString] = useState("");
  const [showSucc, setShowSucc] = useState(false);

  const { handleSubmit, getFieldProps, errors, touched, values } = useFormik({
    initialValues: {
      email: user?.email,
      code: "",
    },
    validationSchema: CheckAttendanceSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      services.checkKegiatan(
        { ...values, kegiatan_id: Number(params.kegiatan_id) },
        (status, resp) => {
          if (resp?.response?.status === 404) {
            setShowAlert(true);
            setErrorString("Email tidak terdaftar di kegiatan");
          } else if (
            resp?.response?.status !== 200 &&
            resp?.message !== "Absen Berhasil"
          ) {
            setShowAlert(true);
            setErrorString(resp?.response?.data?.message);
          } else if (resp?.message === "Absen Berhasil") {
            setShowSucc(true);
          }
        }
      );
    },
  });

  return (
    <div>
      <div className="py-3 lg:px-20 px-6 pt-5">
        <Breadcrumbs
          links={[
            { href: "/", name: "Beranda" },
            { name: "Kalendar Kegiatan", href: `/kalendar` },
            {
              name: "Absensi",
              href: `/kalendar/absensi`,
            },
          ]}
        />
      </div>
      <div className="py-5 flex w-full justify-center">
        <img src={VDLogo} alt="" style={{ height: 70 }} />
      </div>
      <div
        className="w-10/12 mx-auto flex lg:flex-row flex-col mb-20"
        style={{
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05",
        }}
      >
        <div className="lg:w-1/2 w-full pt-4 lg:pl-12 pl-6 pr-6 lg:pb-14 pb-6 flex justify-center">
          <img src={Image} alt="" className="pt-8" />
        </div>

        {!showSucc && (
          <div className="lg:w-1/2 w-full mt-4 mb-8 border-l border-solid border-welcome pl-6">
            <div className="pt-4  pr-6">
              <div className="font-baloo2 font-bold text-3xl text-black-2">
                Absensi Kegiatan
              </div>
            </div>
            <div className="pb-10 border-b border-solid border-pink-header w-1/2" />
            {/* <div>Kegiatan: </div> */}
            <div className="pr-6 font-montserrat font-medium text-sm text-black-2 pt-4">
              Silahkan absen dengan memasukkan kode absensi yang anda dapatkan
              untuk dapat mengunduh sertifikat nantinya.
            </div>
            <form className="pt-4">
              <div className="flex">
                <div className="font-montserrat font-bold text-sm text-black-2">
                  Email Anda
                </div>
                <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                  *
                </div>
              </div>
              <input
                className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                // ref={inputRef}
                name="email"
                {...getFieldProps("email")}
                value={values?.email}
                // onChange={handleChange}
              />
              {touched.email && errors.email && (
                <div className="text-red-500 text-xs">{errors.email}</div>
              )}

              {/* <div className="flex pt-3">
                <div className="font-montserrat font-bold text-sm text-black-2">
                  Kode Absensi
                </div>
                <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                  *
                </div>
              </div>
              <input
                className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                ref={inputRef}
                name="code"
                {...getFieldProps("code")}
                // onChange={handleChange}
              />
              {touched.code && errors.code && (
                <div className="text-red-500 text-xs">{errors.code}</div>
              )} */}
              {showAlert && (
                <div
                  className={`p-2 rounded text-white text-sm mt-3 w-11/12 ${
                    errorString !== "Email tidak terdaftar di kegiatan"
                      ? "bg-sunset-orange"
                      : "bg-pink-header"
                  }`}
                >
                  {errorString === "Email tidak terdaftar di kegiatan" ? (
                    <CloseIcon alt="" className="mr-3 self-center" />
                  ) : (
                    <ErrorOutlineIcon alt="" className="mr-3 self-center" />
                  )}
                  {errorString}
                </div>
              )}
              <button
                onClick={handleSubmit}
                type="button"
                className="mt-8 py-2 px-4 font-montserrat text-xs font-medium rounded border-solid border text-border-on-pink bg-pink-header border-pink-header"
              >
                Lanjutkan
              </button>
            </form>
          </div>
        )}
        {showSucc && (
          <div className="lg:w-1/2 w-full mt-4 mb-8 border-l border-solid border-welcome pl-6">
            <div className="pt-4  pr-6">
              <div className="font-baloo2 font-bold text-3xl text-black-2">
                Absensi Kegiatan Berhasil!
              </div>
            </div>
            <div className="pb-10 border-b border-solid border-pink-header w-1/2" />
            <div className="font-montserrat font-medium text-sm text-black-2 pt-4">
              Selamat, anda berhasil absen pada kegiatan!
            </div>
            <div className="pr-6 font-montserrat font-medium text-sm text-black-2 pt-4">
              Kami akan mengirimkan link unduh sertifikat kegiatan saat sudah
              tersedia nanti ke alamat email:
            </div>
            <div className="font-montserrat font-medium text-sm  pt-4">
              {values?.email}
            </div>
            <div className="pt-3 font-montserrat font-medium text-sm text-black-2">
              Jika anda Pelaku Usaha, anda juga bisa mengunduh sertifikat di
              Bisa Pengadaan dengan cara masuk ke Profile anda, lalu akses tab
              Kegiatan dan Sertifikasi dan megunduh sertifikat pada kegiatan
              ini.
            </div>
            <button
              onClick={() => {
                history.push("/");
              }}
              className="mt-8 py-2 px-4 font-montserrat text-xs font-medium rounded border-solid border bg-gray-300"
            >
              Kembali
            </button>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Absence;
