import React from "react";
import { Drawer } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import FilterLeft from "./FilterLeftSide";
import { actionsGlobal } from "redux/reducers/global";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 350,
      height: "100vh",
      maxHeight: "100vh",
    },
    sidebar: {
      maxWidth: 350,
    }
  })
);

export default function SidebarSearch(props) {
  const dispatch = useDispatch();

  const { isSearchOpen } = useSelector((state) => state.global);

  const classes = useStyles();

  return (
    <Drawer
      open={isSearchOpen}
      anchor="left"
      onClose={() => dispatch(actionsGlobal.closeSearchSidebar())}
    >
      <div className={classes.root}>
        <FilterLeft className={classes.sidebar} {...props} />
      </div>
    </Drawer>
  );
}
