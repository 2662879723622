import React from "react";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/styles";
import ViewRole from "components/ViewRole";
import debounce from "lodash/debounce";
import token from "utils/session";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import endpoint from "utils/endpoint";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: "Baloo 2",
      fontWeight: 700,
      color: "#595959",
      fontSize: 18,
    },
  })
);

export default function TabelChart(props) {
  const { data, columns, dataTable, headers } = props;
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    dataChart,
    filterChart,
    tahunChart,
    onSearch,
    onChangeChart,
    onChangeFilter,
    onChangeYear,
    params,
  } = headers;

  const classes = useStyles();

  const downloadStatusXlsx = () => {
    setIsLoading(true);
    axios({
      url: `${endpoint.exportTableData}`, //your url
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
        ContentDisposition: `attachment`,
      },
      params: {
        chart: params?.chart,
        year: params?.year,
        filter: params?.filter,
        format: "xlsx",
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Tabel Data Chart.xlsx"); //or any other extension
      link.click();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    });
  };

  const downloadStatusPdf = () => {
    setIsLoading(true);
    axios({
      url: `${endpoint.exportTableData}`, //your url
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
        ContentDisposition: `attachment`,
      },
      params: {
        chart: params?.chart,
        year: params?.year,
        filter: params?.filter,
        format: "pdf",
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Tabel Data Chart.pdf"); //or any other extension
      link.click();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    });
  };

  return (
    <div>
      <div>
        <span className={classes.title}>Tabel Data Chart</span>
      </div>

      <div
        className="p-4 rounded border-border-on-pink bg-white mt-2"
        style={{
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
          borderRadius: "4px",
        }}
      >
        <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-3 pb-2 border-b border-solid border-light-gray lg:items-center items-start">
          <div className="font-roboto font-normal text-xs text-black-2">
            Pilih Chart
          </div>
          <select
            onChange={onChangeChart}
            className="p-2 xl:w-2/12 lg:w-3/12 w-full border border-solid border-light-gray rounded lg:ml-6 font-roboto font-normal text-xs text-black-2 form-select focus:ring-1 focus:ring-blue-second"
          >
            <option selected disabled value="">
              Pilih Jenis Chart
            </option>
            {dataChart?.map((data, index) => (
              <option value={data?.value} key={index}>
                {data?.name}
              </option>
            ))}
          </select>
          <select
            onChange={onChangeFilter}
            className="p-2 xl:w-2/12 lg:w-3/12 w-full border border-solid border-light-gray rounded lg:ml-6 font-roboto font-normal text-xs text-black-2 form-select focus:ring-1 focus:ring-blue-second"
          >
            <option selected disabled value="">
              Pilih Filter Chart
            </option>
            {filterChart?.map((data, index) => (
              <option value={data?.value} key={index}>
                {data?.name}
              </option>
            ))}
          </select>
          <select
            onChange={onChangeYear}
            className="p-2 xl:w-2/12 lg:w-3/12 w-full border border-solid border-light-gray rounded lg:ml-6 font-roboto font-normal text-xs text-black-2 form-select focus:ring-1 focus:ring-blue-second"
          >
            <option selected disabled value="">
              Pilih Tahun
            </option>
            {tahunChart?.map((data, index) => (
              <option value={data?.value} key={index}>
                {data?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="py-2 flex lg:flex-row flex-col lg:justify-between lg:items-start items-center lg:space-y-0 space-y-3">
          <div className="flex lg:flex-row flex-col flex-1 lg:space-y-0 space-y-3">
            <div className="flex items-center">
              <button className="font-roboto font-normal text-xs text-black-2">
                Show
              </button>

              <select
                className="px-8 py-2 border border-solid border-light-gray rounded ml-6 font-roboto font-normal text-xs text-black-2 form-select focus:ring-1 focus:ring-blue-second"
                onChange={(ev) =>
                  dataTable.onChangeRowsPerPage(ev.target.value, 1)
                }
              >
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
              </select>
              <div className="pl-1 font-roboto font-normal text-xs text-black-2">
                entries
              </div>
            </div>

            <ViewRole>
              <div className="flex items-center px-3 space-x-2">
                <span className="font-roboto font-normal text-xs text-black-2">
                  Unduh data
                </span>

                <button
                  onClick={downloadStatusXlsx}
                  className="font-montserrat font-medium text-sm text-black-2 p-2 rounded bg-welcome"
                >
                  .xls
                </button>
                <button
                  onClick={downloadStatusPdf}
                  className="font-montserrat font-medium text-sm text-black-2 p-2 rounded bg-welcome"
                >
                  .pdf
                </button>
              </div>
            </ViewRole>
          </div>

          <div className="font-roboto font-normal text-xs text-black-2">
            Search
            <input
              type="text"
              className="ml-1 px-4 py-2 border border-solid border-light-gray rounded"
              onChange={debounce(onSearch, 1000)}
            />
          </div>
        </div>
        {isLoading && <CircularProgress color="inherit" size={20} />}
        <DataTable columns={columns} data={data} {...dataTable} />
      </div>
    </div>
  );
}

TabelChart.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  headers: PropTypes.any,
};

TabelChart.defaultProps = {
  data: [],
  columns: [],
  headers: {
    dataChart: [],
    filterChart: [],
    tahunChart: [],
    onChangeChart: () => {},
    onChangeFilter: () => {},
    onChangeYear: () => {},
    onSearch: () => {},
    params: [],
  },
};
