import React from "react";
import PropTypes from "prop-types";
import Dialog from "components/Dialog";
import { Typography, Button, Box } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { DateTime } from "luxon";
import classnames from "classnames";
import get from "lodash/get";

export default function KabarView(props) {
  const { visible, onClose, data: dataArray, id } = props;
  const dataProps = React.useMemo(() => dataArray, [dataArray]);

  const data = dataProps.filter((data) => data.id === id)[0];

  const listChanges = get(data, "listChanges", []);

  const classBorderActive = (className, typeChange) => {
    return classnames(className, {
      "border-2 rounded-lg border-pink-header": listChanges.indexOf(typeChange) >= 0,
    });
  };

  return (
    <Dialog visible={visible} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="flex items-center justify-end">
        <button onClick={onClose} className="px-4 py-2">
          <CloseIcon />
        </button>
      </div>
      <div className="bg-white w-full px-6 py-4">
        <div className="block space-y-3">
          <div className="border-b border-gray-200 block pb-3">
            <h5 className="font-baloo2 font-bold text-2xl text-black-2">
              Detail Kabar
            </h5>
          </div>
          <Typography variant="caption" className="text-gray-500 block pt-3">
            {DateTime.fromISO(data?.updated_at).toFormat("dd LLL yyyy, HH:mm", {
              locale: "id",
            })}
          </Typography>
        </div>
        {data?.is_new === false && <div className="w-full flex items-center py-3 divide-x divide-gray-200">
          <div className="w-1/2 pr-2">
            <Typography variant="h5" className="font-bold block">
              Sebelum
            </Typography>

            <Box>
              <img
                className="w-52 h-52 object-cover"
                src={data?.old_picture}
                alt="preview-product"
              />
            </Box>

            <div className="flex items-start my-4">
              <img
                className="w-16 h-16 object-contain border-r border-gray-200 pr-2"
                src={data?.logo}
                alt="preview-profile"
              />

              <Box className="pl-2 py-2">
                <Typography variant="subtitle1" className="text-black-2">
                  {data?.old_name}
                </Typography>
              </Box>
            </div>
          </div>
          <div className="w-1/2 pl-2">
            <Typography variant="h5" className="font-bold block">
              Sesudah
            </Typography>

            <Box>
              <img
                className={classBorderActive(
                  "w-52 h-52 object-cover",
                  "Picture"
                )}
                src={data?.picture}
                alt="preview-product"
              />
            </Box>

            <div className="flex items-start my-4">
              <img
                className={classBorderActive(
                  "w-16 h-16 object-contain border-r border-gray-200 pr-2",
                  "Logo"
                )}
                src={data?.logo}
                alt="preview-profile"
              />

              <Box className="pl-2 py-2 text-black-2">
                <Typography
                  variant="subtitle1"
                  className={classnames({
                    "text-pink-header": listChanges.indexOf("Nama Usaha") >= 0,
                  })}
                >
                  {data?.name}
                </Typography>
              </Box>
            </div>
          </div>
        </div>}
        {data?.is_new === true && <div className="w-full flex-1 justify-center items-center flex py-3 divide-x divide-gray-200">
          <div className="w-full flex flex-col items-center justify-center">
            <Typography variant="h5" className="font-bold block">
              Baru
            </Typography>

            <Box>
              <img
                className={classBorderActive(
                  "w-52 h-52 object-cover",
                  "Picture"
                )}
                src={data?.picture}
                alt="preview-product"
              />
            </Box>

            <div className="flex items-start my-4">
              <img
                className={classBorderActive(
                  "w-16 h-16 object-contain border-r border-gray-200 pr-2",
                  "Logo"
                )}
                src={data?.logo}
                alt="preview-profile"
              />

              <Box className="pl-2 py-2 text-black-2">
                <Typography
                  variant="subtitle1"
                  className={classnames({
                    "text-pink-header": listChanges.indexOf("Nama Usaha") >= 0,
                  })}
                >
                  {data?.name}
                </Typography>
              </Box>
            </div>
          </div>
        </div>}
        <div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            component={RouterLink}
            to={`/product_detail/${id}`}
          >
            Lihat detail perusahaan
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

KabarView.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.array,
  id: PropTypes.number,
};
