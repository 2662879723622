import axios from 'axios';
import endpoint from 'utils/endpoint';
// import token from 'utils/session';
import Cookie from 'js-cookie';

async function userLogin(params,callback) {

  const url = endpoint.login;
  const config = {
    method: 'POST',
    data: {
      email: params.email,
      password: params.password,
      type: params.type,
      captcha: params.captcha,
      captcha_key: params.captcha_key
    }
  };

  try {
    const response = await axios(url,config);
    const expires = 80 * 80 * 1000;
    const inTwoHour = new Date(new Date().getTime() + expires);
    if (response.data.token === undefined) {
      callback.success(response.data)
    } else {
      Cookie.set('SID_LKPP', response.data.token, inTwoHour);
      callback.success(response.data)
    }
  } catch (error) {
    callback.error(error)
  }
}

async function getCaptcha(params,callback) {

  const url = endpoint.captcha;
  const config = {
    method: 'GET',
  };

  try {
    const response = await axios(url,config);
    callback(response.data)
  } catch (error) {
    callback(error)
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userLogin,
  getCaptcha
};