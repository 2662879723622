import React from "react";
import { Tabs, Tab, Paper, useMediaQuery } from "@material-ui/core";
import { makeStyles, createStyles, useTheme } from "@material-ui/styles";
import classNames from "classnames";
import get from "lodash/get";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const useStyles = makeStyles((theme) =>
  createStyles({
    chart: {
      height: 500,

      [theme.breakpoints.down("lg")]: {
        height: 350,
      },
    },
  })
);

export default function Peluang(props) {
  const { typeUsaha, setTypeUsaha, detailData, settings } = props;

  const jenisPengusaha = [
    { name: "PU Kecil Pengadaan Langsung" },
    { name: "PU Kecil Tender" },
    { name: "PU Non-Kecil Tender" },
  ];

  const { className } = props;

  const classes = useStyles();

  const date = new Date().getFullYear();

  const chart = React.useRef(null);
  React.useLayoutEffect(() => {
    let x = am4core.create("peluang-statistik", am4charts.XYChart3D);

    chart.current = x;

    x.colors.list = [
      am4core.color("#ff9494"),
      am4core.color("#8bcaff"),
      am4core.color("#88c46d"),
      am4core.color("#6696d0"),
    ];
    x.hiddenState.properties.opacity = 0;
    x.data = get(detailData, "data", []);

    let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.labels.template.rotation = -25;
    categoryAxis.renderer.labels.template.hideOversized = true;
    categoryAxis.renderer.labels.template.truncate = true;
    categoryAxis.renderer.labels.template.maxWidth = 200;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.tooltip.visible = false;
    // categoryAxis.tooltip.label.horizontalCenter = "right";
    // categoryAxis.tooltip.label.verticalCenter = "middle";

    let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Total";
    valueAxis.title.fontWeight = "bold";

    // Create series
    var series = x.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "name";
    series.name = "Total";
    series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.8;

    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.stroke = am4core.color("#FFFFFF");

    columnTemplate.events.on("hit", () => {
      window.open(get(settings, "link_peluang_pasar_pbjp.value"), "_blank");
    });

    columnTemplate.adapter.add("fill", function (fill, target) {
      return x.colors.getIndex(target.dataItem.index);
    });

    columnTemplate.adapter.add("stroke", function (stroke, target) {
      return x.colors.getIndex(target.dataItem.index);
    });

    x.cursor = new am4charts.XYCursor();
    x.cursor.lineX.strokeOpacity = 0;
    x.cursor.lineY.strokeOpacity = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData?.data]);

  const listMonth = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      className={classNames(className, "xl:px-20 lg:px-18 px-6 pb-8")}
      id="chance"
    >
      <div className="font-baloo2 font-bold text-3xl text-black-2 pt-10">
        Peluang Pasar PBJP
      </div>
      <div className="pt-10 border-b border-solid border-pink-header w-2/12" />
      <div className="pt-8 w-full mx-auto">
        <Paper>
          <Tabs
            value={typeUsaha}
            onChange={(ev, newVal) => setTypeUsaha(newVal)}
            centered={!isMobile}
            scrollButtons="auto"
            variant={isMobile ? "scrollable" : "standard"}
            allowscrollbuttonsmobile="true"
          >
            {jenisPengusaha.map((item, index) => (
              <Tab label={item.name} key={index} />
            ))}
          </Tabs>
          <div
            className="p-4 bg-neutral-grey"
            style={{ borderRadius: "0px 0px 4px 4px" }}
          >
            <div className="bg-white px-6 mx-auto border-solid border-light-gray box-border">
              <div className="pt-3 pb-8 flex lg:flex-row md:flex-row flex-col">
                <div className="flex lg:w-1/2 w-full mr-2 flex-col font-montserrat font-bold text-sm text-black-2">
                  Tahun Anggaran & Pemilihan Penyedia
                  <select
                    onChange={(e) => props?.setTahun(Number(e.target.value))}
                    className="form-select w-full border border-solid border-light-gray box-border rounded p-2 font-montserrat font-medium text-xs text-black-2"
                    defaultValue={date}
                  >
                    <option value="" selected disabled>
                      Pilih Tahun
                    </option>
                    <option value={date}>{date}</option>
                    <option value={date - 1}>{date - 1}</option>
                    <option value={date - 2}>{date - 2}</option>
                  </select>
                </div>

                <div className="flex lg:w-1/2 w-full flex-col font-montserrat font-bold text-sm text-black-2">
                  Bulan Pemilihan Penyedia
                  <select
                    onChange={(e) => props?.setBulan(Number(e.target.value))}
                    className="form-select w-full border border-solid border-light-gray box-border rounded p-2 font-montserrat font-medium text-xs text-black-2"
                    defaultValue={new Date().getMonth() + 1}
                  >
                    <option value="" selected disabled>
                      Pilih Bulan
                    </option>
                    {listMonth.map((_item, index) => (
                      <option value={index + 1}>{_item}</option>
                    ))}
                  </select>
                </div>
              </div>
              <span className="flex justify-center font-montserrat text-black-2 font-medium text-sm pb-3">
                {typeUsaha === 0
                  ? "Pelaku Usaha Non-Kecil dengan Pengadaan Langsung"
                  : typeUsaha === 1
                  ? "Pelaku Usaha Kecil dengan Pengadaan Tender"
                  : "Pelaku Usaha Non-Kecil dengan Pengadaan Tender"}
              </span>
              <div id="peluang-statistik" className={classes.chart}></div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
}
