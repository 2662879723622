import axios from "axios";
import endpoint from "utils/endpoint";
import token from "utils/session";

export async function getVendorDetail(params, callback) {
  const url = `${endpoint.search}/${params.id}`;
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url, config);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
}

export async function getVendorSerupa(params, callback) {
  const url = endpoint.search;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
}

export async function getStatistikaPemilihan(params, callback) {
  const url = `${endpoint.search}/${params?.id}/statistikaPemilihan`;
  const config = {
    method: "GET",
  };

  try {
    const response = await axios(url, config);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
}

export async function getSertifikatKegiatan(params, callback) {
  const url = `${endpoint.sertifikatKegiatan}`;
  const config = {
    method: "GET",
    params,
  };

  try {
    const response = await axios(url, config);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
}
