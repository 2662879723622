import React from "react";
import { Link } from "react-router-dom";
import CalendarIcon from "@material-ui/icons/Today";
// import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import { LocationOn, Phone, Mail } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import DownloadIcon from "@material-ui/icons/GetApp";
import Dialog from "components/Dialog";
import usePagination from "utils/usePagination";
import Pagination from "components/pagination";
import ProfileServices from "../services";
import get from "lodash/get";

import { format } from "date-fns";
import { id } from "date-fns/locale";

function PKPdanSertifikasi() {
  const [detailResponse, setResponse] = React.useState({
    data: [],
  });

  const [params, setParams] = React.useState({
    page: 1,
    limit: 3,
    total: 10,
  });

  React.useEffect(() => {
    ProfileServices.getKegiatanTerdaftar(
      {
        page_size: params.limit,
        page: params.page,
      },
      {
        success: (data) => {
          setResponse((prev) => ({ ...prev, data: data?.data }));
          setPagination(3, data.total);
        },
        error: (err) => {
          console.log(err);
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.page]);

  const [total, setPagination] = usePagination();
  function titleCase(str) {
    var splitStr = str?.toLowerCase()?.split(" ");
    for (var i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr?.[i]?.charAt(0)?.toUpperCase() + splitStr[i]?.substring(1);
    }
    return splitStr?.join(" ");
  }

  return (
    <div>
      <div className="bg-white py-12 px-12 flex w-full items-center justify-between">
        <div className="font-baloo2 font-bold text-base text-black-2">
          Ingin mendapatkan sertifikasi?
        </div>
        <Link to={`/kalendar`}>
          <button className="items-center flex space-x-2 py-2 px-4 rounded font-montserrat font-medium text-xs text-border-on-pink bg-neon-green">
            <CalendarIcon />
            <span>Daftar Kegiatan</span>
          </button>
        </Link>
      </div>

      <div className="bg-neutral-grey flex flex-col p-6">
        {get(detailResponse, "data", "")
          ?.filter((data) => data?.status === 1)
          // ?.sort((a, b) => {
          //   const waktuMulai = new Date(get(a, "waktu_akhir", new Date()));
          //   const waktuAkhir = new Date(get(b, "waktu_akhir", new Date()));
          //   return waktuAkhir - waktuMulai;
          // })
          ?.map((data) => {
            const waktuMulai = new Date(get(data, "waktu_mulai", new Date()));
            const waktuAkhir = new Date(get(data, "waktu_akhir", new Date()));
            console.log(data?.status_kegiatan === "kegiatan_selesai");
            return (
              <div
                key={data?.id}
                className="w-full bg-white flex items-start py-4 px-3 my-4"
              >
                <div className="w-4/12 space-y-4 pr-4 border-r border-gray-200">
                  <Box
                    className={`flex items-center space-x-3 px-4 py-2 ${
                      data?.status_kegiatan === "sudah_mengikuti"
                        ? "bg-neon-green"
                        : data?.status_kegiatan === "terdaftar"
                        ? "bg-blue-second"
                        : "bg-black-2"
                    } rounded-lg`}
                  >
                    {data?.status_kegiatan === "terdaftar" && (
                      <svg
                        width="12"
                        height="15"
                        viewBox="0 0 12 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.3 9.63296C9.9526 8.98029 10.397 8.14877 10.577 7.24354C10.7571 6.33831 10.6646 5.40002 10.3114 4.54733C9.95817 3.69463 9.36003 2.96583 8.59261 2.45307C7.82519 1.94031 6.92296 1.66663 6 1.66663C5.07704 1.66663 4.17481 1.94031 3.40739 2.45307C2.63997 2.96583 2.04183 3.69463 1.68861 4.54733C1.33539 5.40002 1.24294 6.33831 1.42297 7.24354C1.603 8.14877 2.04741 8.98029 2.7 9.63296L6 12.933L9.3 9.63296ZM6 14.8183L1.75734 10.5756C0.918228 9.7365 0.346791 8.66741 0.115286 7.50352C-0.11622 6.33964 0.00260456 5.13324 0.456732 4.03689C0.91086 2.94054 1.6799 2.00347 2.66659 1.34419C3.65328 0.6849 4.81332 0.333008 6 0.333008C7.18669 0.333008 8.34672 0.6849 9.33342 1.34419C10.3201 2.00347 11.0891 2.94054 11.5433 4.03689C11.9974 5.13324 12.1162 6.33964 11.8847 7.50352C11.6532 8.66741 11.0818 9.7365 10.2427 10.5756L6 14.8183ZM6.66667 6.33296H9.33333V7.66629H5.33334V2.99962H6.66667V6.33296Z"
                          fill="#FEFEFE"
                        />
                      </svg>
                    )}

                    {data?.status_kegiatan === "sudah_mengikuti" && (
                      <svg
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.73402 6.17366L8.67535 7.115L14.3193 1.47099L15.262 2.41366L8.67535 9.00033L4.43268 4.75766L5.37535 3.81499L6.79202 5.23166L7.73402 6.17299V6.17366ZM7.73535 4.28833L11.0367 0.986328L11.9767 1.92633L8.67535 5.22833L7.73535 4.28833ZM5.85068 8.05833L4.90868 9.00033L0.666016 4.75766L1.60868 3.81499L2.55068 4.75699L2.55002 4.75766L5.85068 8.05833Z"
                          fill="#FEFEFE"
                        />
                      </svg>
                    )}

                    {data?.status_kegiatan === "kegiatan_selesai" && (
                      <CheckIcon className="text-white" />
                    )}

                    <Typography
                      variant="body1"
                      className="font-bold block text-white"
                    >
                      {titleCase(data?.status_kegiatan.replaceAll("_", " "))}
                    </Typography>
                  </Box>

                  {waktuMulai && waktuAkhir && (
                    <div className="flex items-center justify-between border-b border-gray-200 pb-3">
                      <div className="text-black-2">
                        <span className="font-montserrat font-extrabold text-base block">
                          {format(waktuMulai, "eeee", { locale: id })}
                        </span>
                        <span className="font-montserrat font-extrabold text-base block">
                          {format(waktuMulai, "dd MMM yyyy", { locale: id })}
                        </span>
                        <span className="font-montserrat font-extrabold text-xl block">
                          {format(waktuMulai, "HH:mm", { locale: id })}
                        </span>
                      </div>
                      <div className="text-black-2">
                        <span className="font-montserrat font-extrabold text-xl block">
                          s/d
                        </span>
                      </div>
                      <div className="text-black-2">
                        <span className="font-montserrat font-extrabold text-base block">
                          {format(waktuAkhir, "eeee", { locale: id })}
                        </span>
                        <span className="font-montserrat font-extrabold text-base block">
                          {format(waktuAkhir, "dd MMM yyyy", { locale: id })}
                        </span>
                        <span className="font-montserrat font-extrabold text-xl block">
                          {format(waktuAkhir, "HH:mm", { locale: id })}
                        </span>
                      </div>
                    </div>
                  )}

                  <Box className="flex items-start space-x-2">
                    <LocationOn color="primary" />
                    <div>
                      <p className="font-montserrat font-bold text-sm block">
                        {titleCase(data?.bentuk)}
                      </p>
                      <p className="font-montserrat font-normal text-sm block">
                        {data?.bentuk === "online"
                          ? data?.conference_url
                          : data?.alamat}
                      </p>
                    </div>
                  </Box>
                </div>
                <div className="w-9/12 pl-4">
                  <div className="border-b border-gray-200 pb-5">
                    <h5 className="font-baloo2 font-bold text-2xl text-black-2 pb-4">
                      {data?.nama}
                    </h5>

                    {/* <div>
                    <h1 className="font-montserrat font-bold text-sm">
                      Terdaftar sebagai:
                    </h1>
                    <h1 className="font-montserrat font-bold text-sm">
                      Berkat Cahaya Anugerah Mandiri
                    </h1>
                  </div> */}
                  </div>

                  <div className="py-3 flex flex-row">
                    <Box className="flex flex-row w-full justify-between">
                      <Box>
                        <div className="pb-3">
                          <span className="font-baloo2 font-bold text-xl text-black-2 block">
                            Kontak narahubung
                          </span>
                        </div>

                        <Box className="flex items-start">
                          {data?.narahubung_telepon !== null && (
                            <div className="flex-1">
                              <div className="flex space-x-2 items-center">
                                <Phone color="primary" />
                                <div className="font-montserrat font-medium text-sm text-black-2">
                                  {data?.narahubung_telepon} (
                                  {data?.narahubung_nama})
                                </div>
                              </div>

                              <div className="flex space-x-2 items-center pt-2">
                                <Mail color="primary" />
                                <a
                                  href="mailto:arif.sanusi@lkpp.com"
                                  className="font-montserrat font-medium text-sm text-blue-second underline"
                                >
                                  {data?.narahubung_email}
                                </a>
                              </div>
                            </div>
                          )}

                          {data?.narahubung2_telepon && (
                            <div className="flex-1">
                              <div className="flex space-x-2 items-center">
                                <Phone color="primary" />
                                <div className="font-montserrat font-medium text-sm text-black-2">
                                  {data?.narahubung2_telepon} (
                                  {data?.narahubung2_nama})
                                </div>
                              </div>

                              <div className="flex space-x-2 items-center pt-2">
                                <Mail color="primary" />
                                <a
                                  href="mailto:annisa@lkpp.com"
                                  className="font-montserrat font-medium text-sm text-blue-second underline"
                                >
                                  {data?.narahubung2_email}
                                </a>
                              </div>
                            </div>
                          )}
                        </Box>
                      </Box>
                      <Box>
                        {data?.meta?.link_sertifikat && (
                          <Link
                            to={{ pathname: `${data?.meta?.link_sertifikat}` }}
                            target="_blank"
                          >
                            <button className="items-center flex space-x-2 py-2 px-4 rounded font-montserrat font-medium text-xs text-border-on-pink bg-neon-green">
                              <DownloadIcon />
                              <span>Download Sertifikat</span>
                            </button>
                          </Link>
                        )}

                        {!data?.meta?.link_sertifikat &&
                          data?.status_kegiatan === "kegiatan_selesai" && (
                            <Link
                              to={`/absensi/kegiatan?kegiatan_id=${data?.id}`}
                            >
                              <button className="items-center flex space-x-2 py-2 px-4 rounded font-montserrat font-medium text-sm text-border-on-pink bg-blue-second">
                                <CheckIcon className="text-white" />
                                <span>Absensi</span>
                              </button>
                            </Link>
                          )}
                      </Box>
                    </Box>
                  </div>
                </div>
              </div>
            );
          })}

        <Box className="flex items-center justify-center" sx={{ py: 3 }}>
          <Pagination
            count={total}
            onChange={(event, page) => {
              setParams((prev) => ({ ...prev, page: page }));
            }}
          />
        </Box>
      </div>
    </div>
  );
}

export default PKPdanSertifikasi;
