/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import Footer from "components/footer";
import Navigation from "components/Navigation";
import Breadcrumb from "components/Breadcrumb";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Pagination from "components/pagination";
import Box from "@material-ui/core/Box";
import useGlobalStyles from "hooks/useGlobalStyles";
import Page from "components/Page";
import useParallaxHeader from "hooks/useParallaxHeader";
import usePagination from "utils/usePagination";
import * as services from "./services";

function InfoPBJP() {
  const classes = useGlobalStyles();

  const [tab, setTab] = useState(0);
  const [value, setValue] = useState(8);

  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  const [detailResponse, setResponse] = useState({
    data: [],
    bagian: [],
  });

  const [total, setPagination] = usePagination();

  React.useEffect(() => {
    services.getBagianPbjp({}, (data) =>
      setResponse((prev) => ({ ...prev, bagian: data.data }))
    );
  }, [value, params]);

  React.useEffect(() => {
    if (value) {
      services.getInfoPbjp(
        {
          page_size: params.limit,
          page: params.page,
          bagian_id: value,
        },
        (data) => (
          setResponse((prev) => ({ ...prev, data: data })),
          setPagination(params.limit, data?.total)
        )
      );
    }
  }, [params, value]);

  // console.log(detailResponse?.bagian?.[value]?.id)

  const handleChange = (newValue) => {
    setValue(newValue);

    setParams((prev) => ({ ...prev, page: 1 }));
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  }

  // eslint-disable-next-line
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const background = useParallaxHeader()?.map((data) => data)[1]?.file_url;

  return (
    <Page title="Informasi PBJP" className={classes.container}>
      <Navigation />

      <div
        className="flex flex-col pt-4"
        style={{
          backgroundImage: `url('${background}')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          scrollMarginTop: "120px",
        }}
      >
        <Breadcrumb
          className="lg:px-20 px-6"
          classLast="text-white"
          links={[{ name: "Beranda", href: "/" }, { name: "Informasi PBJP" }]}
        />
        <div className="w-2/12 mb-8 border-b-2 border-solid border-pink-header pb-10 lg:mx-20 mx-6 pt-8 font-baloo2 font-bold text-3xl text-border-on-pink">
          Informasi PBJP
        </div>
      </div>
      <div
        className="lg:w-9/12 w-11/12 mx-auto mt-10"
        style={{
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Box>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            allowscrollbuttonsmobile="true"
            aria-label="scrollable force tabs example"
          >
            {detailResponse?.bagian?.map((data) => {
              if (data?.id === 8) {
                return <Tab label={data?.nama} key={data?.id} onClick={() => handleChange(data?.id)} />;
              }
            })}

            {detailResponse?.bagian?.map((data) => {
              if (data?.id === 8) {
                return;
              }

              return <Tab label={data?.nama} key={data?.id} onClick={() => handleChange(data?.id)} />;
            })}
          </Tabs>
        </Box>
        <div className="bg-neutral-grey px-6 flex flex-col pt-6 rounded">
          {detailResponse?.data?.data?.map((item, index) => (
            <div
              key={index}
              className="flex lg:flex-row flex-col border-b border-solid border-welcome py-6 lg:space-y-0 space-y-3"
            >
              <iframe
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowfullscreen
                src={item?.embed_link}
                className="lg:w-1/4 w-full video-pbjp"
              ></iframe>
              <div className="flex flex-col lg:pl-6">
                <div className="font-baloo2 font-bold text-blackk text-lg">
                  {item?.title}
                </div>
                <div
                  className="font-montserrat font-medium text-sm text-blackk"
                  dangerouslySetInnerHTML={{
                    __html: `<div class="ck-content">${item?.description}</div>`,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-1 bg-white items-center justify-center py-6 mb-20">
          <Pagination
            count={total}
            onChange={(event, page) =>
              setParams((prev) => ({ ...prev, page: page }))
            }
          />
        </div>
      </div>
      <Footer />
    </Page>
  );
}

export default InfoPBJP;
