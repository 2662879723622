import React from "react";
import { Box, Tab, Tabs } from "@material-ui/core";
import Navigation from "components/Navigation";
import Footer from "components/footer";
import Page from "components/Page";
import Breadcrumb from "components/Breadcrumb";
import useGlobalStyles from "hooks/useGlobalStyles";
import ListPesan from "./components/ListPesan";
import ViewPesan from "./components/ViewPesan";
import { useLocation, useHistory } from "react-router-dom";
import parseQueryString from "utils/parseQueryString";
import * as services from "./services";
import usePagination from "utils/usePagination";

export default function Pesan() {
  const classes = useGlobalStyles();
  const history = useHistory()

  const [tabIndex, setTabIndex] = React.useState(0);

  const urlSearch = useLocation().search;
  const { tab = "all", messageId = null } = React.useMemo(() => {
    return parseQueryString(urlSearch);
  }, [urlSearch]);
  const [total, setPagination] = usePagination();

  const option = {
    all: 0,
    read: 2,
    not_read: 1,
  };

  const [response, setResponse] = React.useState({
    data: {}
  })

  const [params, setParams] = React.useState({
    page: 1,
    q: "",
    type: "",
  })

  React.useEffect(() => {
    setTabIndex(option[tab]);

    services.getBroadCast({
      status: tab,
      page: params.page,
      page_size: 5,
      q: params.q,
      type: params.type
    }, {
      success: (response) => {
        setResponse(prev => ({...prev, data: response}))
        setPagination(5, response.total)
      }, 
      error: (err) => {
        console.log(err);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, params, urlSearch]);

  const handleChange = (ev, value) => {
    setTabIndex(value)
    const tabs = {
      0: 'all',
      2: 'read',
      1: 'not_read',
    }
    const search = new URLSearchParams({ tab: tabs[value] })

    history.replace(`/pesan?${search}`)
    setParams(prev => ({...prev, page: 1}))
  }

  return (
    <Page title="Pesan Masuk">
      <Navigation />
      <Box className={classes.container}>
        <div
          className="flex flex-col py-4 px-20 w-full"
          style={{
            backgroundImage: 'url("/static/images/info.jpeg")',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            backgroundAize: "cover",
          }}
        >
          <Breadcrumb
            classLast="text-white"
            links={[{ href: "/", name: "Beranda" }, { name: "Pesan Masuk" }]}
          />

          <div className="mt-10">
            <div className="mb-8 font-baloo2 font-bold text-3xl text-white">
              Pesan Masuk
              <div className="border-b-2 border-solid border-pink-header w-1/12 mb-8 pt-10" />
            </div>
          </div>
        </div>

        <Box className="mx-auto w-9/12 my-10" boxShadow={6}>
          <Tabs value={tabIndex} onChange={handleChange}>
            <Tab label="Semua" />
            <Tab label="Belum Dibaca" />
            <Tab label="Sudah Dibaca" />
          </Tabs>

          {messageId && 
            <ViewPesan 
              params={params}
              tab={tab}
              setResponse={setResponse}
              setPagination={setPagination}
            />
          }
          {!messageId && 
            <ListPesan
              data={response?.data}
              totalPagination={total}
              page={params.page}
              setParams={setParams}
              tab={tab}
              params={params}
              setResponse={setResponse}
              setPagination={setPagination}
            />
          }
        </Box>
      </Box>
      <Footer />
    </Page>
  );
}
