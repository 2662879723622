import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles"
import classnames from 'classnames'
import Header from "../header"
import Menus from './Menus'

const useStyles = makeStyles(theme => createStyles({
  root: {
    zIndex: 99,
  }
}))

export default function Navigation() {
  const classes = useStyles()

  return (
    <Box className={classnames(classes.root, 'fixed top-0 left-0 right-0')} boxShadow={2}>
      <Header />
      <Menus />
    </Box>
  );
}
