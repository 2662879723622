import { createSlice } from "@reduxjs/toolkit";
import endpoint from "utils/endpoint";
import axios from "axios";

const initialState = {
  isLoad: false,
  isError: false,
  error: null,
  provinsi: [],
  kecamatan: [],
  kelurahan: [],
  kabupaten: [],
  aktifitas: [],
  kriteria: [],
  captcha: [],
  jenisPengadaan: [],
  organisasi: [],
  instansi: [],
  pranalaLuar: [],
  aboutus: null,
  visitor: [],
  usaha: [],
  news: 0,
};

export const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoad = true;
    },
    hasError: (state, { payload }) => {
      state.isLoad = false;
      state.isError = true;
      state.error = payload;
    },
    provinsiData: (state, { payload }) => {
      state.provinsi = payload.provinsi;
    },
    kecamatanData: (state, { payload }) => {
      state.kecamatan = payload.kecamatan;
    },
    kabupatenData: (state, { payload }) => {
      state.kabupaten = payload.kabupaten;
    },
    kelurahanData: (state, { payload }) => {
      state.kelurahan = payload.kelurahan;
    },
    aktifitasData: (state, { payload }) => {
      state.aktifitas = payload.aktifitas;
    },
    kriteriaData: (state, { payload }) => {
      state.kriteria = payload.kriteria;
    },
    captchaData: (state, { payload }) => {
      state.captcha = payload.captcha;
    },
    jenisPengadaanData: (state, { payload }) => {
      state.jenisPengadaan = payload.jenisPengadaan;
    },
    getOrganisaiSuccess: (state, { payload }) => {
      state.organisasi = payload;
    },
    getViewOrganisasiSuccess: (state, { payload }) => {
      state.instansi = payload;
    },
    getPralanaLuarSuccess: (state, { payload }) => {
      state.pranalaLuar = payload;
    },
    getTentangKamiSuccess: (state, { payload }) => {
      state.aboutus = payload;
    },
    visitorData: (state, { payload }) => {
      state.visitor = payload;
    },
    usahaData: (state, { payload }) => {
      state.usaha = payload;
    },
    newsTab: (state, { payload }) => {
      state.news = payload;
    },
  },
});

export const {
  provinsiData,
  kecamatanData,
  kelurahanData,
  kabupatenData,
  aktifitasData,
  kriteriaData,
  captchaData,
  jenisPengadaanData,
  usahaData,
  newsTab,
} = regionSlice.actions;

export default regionSlice.reducer;

export function getOrganisasi() {
  return async (dispatch) => {
    const url = endpoint.organisasi;

    try {
      const response = await axios.get(url);
      dispatch(regionSlice.actions.startLoading());
      dispatch(regionSlice.actions.getOrganisaiSuccess(response.data.data));
    } catch (error) {
      dispatch(regionSlice.actions.hasError(error.response));
    }
  };
}

export function getViewOrganisasi(typeOrganisasi) {
  return async (dispatch) => {
    const url = endpoint[typeOrganisasi];

    try {
      const response = await axios.get(url);
      dispatch(regionSlice.actions.startLoading());
      dispatch(
        regionSlice.actions.getViewOrganisasiSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(regionSlice.actions.hasError(error.response));
    }
  };
}

export function getProvinsi() {
  return async (dispatch) => {
    const url = endpoint.provinsi;

    dispatch(regionSlice.actions.startLoading());

    try {
      const response = await axios.get(url);
      dispatch(
        regionSlice.actions.provinsiData({ provinsi: response.data.data })
      );
    } catch (error) {
      dispatch(regionSlice.actions.hasError(error.response));
    }
  };
}

export function getKabupaten(params) {
  return async (dispatch) => {
    const url = endpoint.kabupaten;

    dispatch(regionSlice.actions.startLoading());

    try {
      const response = await axios.get(url, { params });
      dispatch(
        regionSlice.actions.kabupatenData({ kabupaten: response.data.data })
      );
    } catch (error) {
      dispatch(regionSlice.actions.hasError(error.response));
    }
  };
}

export function getPranalaLuar() {
  return async (dispatch) => {
    const url = endpoint.pranala;

    dispatch(regionSlice.actions.startLoading());
    try {
      const response = await axios.get(url);
      dispatch(regionSlice.actions.getPralanaLuarSuccess(response.data.data));
    } catch (error) {
      dispatch(regionSlice.actions.hasError(error.response));
    }
  };
}

export function getTentangKami() {
  return async (dispatch) => {
    const url = endpoint.aboutUs;
    dispatch(regionSlice.actions.startLoading());

    try {
      const response = await axios.get(url);
      dispatch(regionSlice.actions.getTentangKamiSuccess(response.data.data));
    } catch (error) {
      dispatch(regionSlice.actions.hasError(error.response));
    }
  };
}

export function getVisitor() {
  return async (dispatch) => {
    const url = endpoint.visitor;
    dispatch(regionSlice.actions.startLoading());

    try {
      const response = await axios.get(url);
      dispatch(regionSlice.actions.visitorData(response.data.data));
    } catch (error) {
      dispatch(regionSlice.actions.hasError(error.response));
    }
  };
}
