import React from "react";
import PropTypes from "prop-types";
import Dialog from "components/Dialog";
import { Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import Ticket from "./Ticket";

export default function KabarView(props) {
  const { visible, onClose, options } = props;

  return (
    <Dialog visible={visible} onClose={onClose} maxWidth="lg" fullWidth>
      <div className="flex items-center justify-end">
        <button onClick={onClose} className="px-4 py-2">
          <CloseIcon />
        </button>
      </div>

      <div className="bg-white w-full px-6 pb-4 pt-2">
        <div className="border-b border-gray-200 block pb-3">
          <h5 className="font-baloo2 font-bold text-2xl text-black-2">
            Detail Kegiatan
          </h5>
        </div>

        <Ticket options={options} />

        {/* <div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/kalendar/daftar?eventId=20"
          >
            Daftar
          </Button>
        </div> */}
      </div>
    </Dialog>
  );
}

KabarView.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
