import React from "react";
import { Box } from "@material-ui/core";
import { Mail, Phone, LocationOn, Print } from "@material-ui/icons";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

export default function KontakKami({ showMap = false }) {
  const { aboutus } = useSelector((state) => state.region);

  return (
    <Box boxShadow={4}>
      {showMap && (
        <div>
          <div className="font-baloo2 font-bold text-2xl text-black-2 border-b border-solid border-welcome py-4 px-10">
            Lokasi Kami
          </div>

          <div>
            {aboutus && (
              <MapContainer
                className="block"
                style={{ height: 400, width: "100%", zIndex: 1 }}
                center={[get(aboutus, "latitude"), get(aboutus, "longitude")]}
                zoom={12}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  position={[
                    get(aboutus, "latitude"),
                    get(aboutus, "longitude"),
                  ]}
                >
                  <Popup>Lokasi Kami</Popup>
                </Marker>
              </MapContainer>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-col pb-10 px-10 pt-4">
        <div className="font-baloo2 font-bold text-2xl text-black-2 border-b border-solid border-welcome pb-4">
          Kontak Kami
        </div>
        <div className="flex flex-row items-center py-4 font-montserrat font-medium text-sm text-black-2">
          <LocationOn color="primary" />
          <div className="font-bold text-black-2 px-2">
            {get(aboutus, "judul_alamat")}
            <div className="font-medium" dangerouslySetInnerHTML={{ __html: get(aboutus, "alamat_lengkap") }}></div>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <Phone color="primary" />
          <div className="px-2 font-montserrat font-medium text-sm text-pink-header">
            Phone:
          </div>
          <div className="font-montserrat font-medium text-sm text-black-2">
            {get(aboutus, "phone")}
          </div>
        </div>

        <div className="flex flex-row items-center pt-2">
          <Print color="primary" />
          <div className="px-2 font-montserrat font-medium text-sm text-pink-header">
            Fax:
          </div>
          <div className="font-montserrat font-medium text-sm text-black-2">
            {get(aboutus, "fax")}
          </div>
        </div>

        <div className="flex flex-row items-center pt-2">
          <Mail color="primary" />
          <div className="px-2 font-montserrat font-medium text-sm text-pink-header">
            Email:
          </div>
          <div className="font-montserrat font-medium text-sm text-black-2">
            {get(aboutus, "email")}
          </div>
        </div>
      </div>
    </Box>
  );
}
