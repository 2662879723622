import React, { useState } from "react";
import Footer from "components/footer";
import classNames from "classnames";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import Tutorial from "assets/faq.png";
import Navigation from "components/Navigation";
import useGlobalStyles from "hooks/useGlobalStyles";
import Page from "components/Page";
import * as services from "./services";
import get from "lodash/get";

const useStyles = makeStyles((theme) =>
  createStyles({
    faqItem: {
      display: "block",
      paddingLeft: 40,
      marginBottom: 28,

      "&::before": {
        counterIncrement: "faqs",
        content: "counter(faqs)",
        position: "absolute",
        left: 0,
        top: 0,
        width: 24,
        height: 24,
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontFamily: "Montserrat",
        fontWeight: "700",
        background: theme.palette.primary.main,
      },

      "&::after": {
        content: "''",
        position: "absolute",
        left: 11,
        top: 24,
        width: 2,
        height: "calc(100% + 10px)",
        background: theme.palette.primary.main,
      },
    },

    faqList: {
      counterReset: "faqs",

      "& li": {
        "&:last-child": {
          "&::after": {
            display: "none",
          },
        },
      },
    },
  })
);

function FAQ() {
  const popular = [
    {
      id: 1,
      title: "Bagaimana cara mendaftar di situs Vendor Directory?",
      desc: `Untuk dapat menampilkan daftar bisnis anda di Vendor Directory maka anda harus mendaftarkan usaha anda. Silahkan mengunjungi halaman Pendaftaran lalu isi semua field yang ada disana, kemudian submit data dan pastikan anda mendapatkan pesan sukses setelah submit data.

      Setelah berhasil mendaftarkan diri silahkan menambahkan data usaha anda pada halaman Tambah Usaha, sama seperti pada halaman pendaftaran member ikuti petunjuk yang ada, kemudian submit dan pastikan anda mendapatkan pesan sukses setelah melakukan submit data.`,
    },
    {
      id: 2,
      title:
        "Keuntungan apa yang saya dapat jika saya mendaftarkan bisnis saya di Vendor Directory?",
      desc: `Untuk dapat menampilkan daftar bisnis anda di Vendor Directory maka anda harus mendaftarkan usaha anda. Silahkan mengunjungi halaman Pendaftaran lalu isi semua field yang ada disana, kemudian submit data dan pastikan anda mendapatkan pesan sukses setelah submit data.

      Setelah berhasil mendaftarkan diri silahkan menambahkan data usaha anda pada halaman Tambah Usaha, sama seperti pada halaman pendaftaran member ikuti petunjuk yang ada, kemudian submit dan pastikan anda mendapatkan pesan sukses setelah melakukan submit data.`,
    },
    {
      id: 3,
      title: "Apakah saya bisa mengupdate data usaha saya?",
      desc: `Untuk dapat menampilkan daftar bisnis anda di Vendor Directory maka anda harus mendaftarkan usaha anda. Silahkan mengunjungi halaman Pendaftaran lalu isi semua field yang ada disana, kemudian submit data dan pastikan anda mendapatkan pesan sukses setelah submit data.

      Setelah berhasil mendaftarkan diri silahkan menambahkan data usaha anda pada halaman Tambah Usaha, sama seperti pada halaman pendaftaran member ikuti petunjuk yang ada, kemudian submit dan pastikan anda mendapatkan pesan sukses setelah melakukan submit data.`,
    },
    {
      id: 4,
      title: "Bagaimana mempromosikan bisnis saya lewat Vendor Directory?",
      desc: `Untuk dapat menampilkan daftar bisnis anda di Vendor Directory maka anda harus mendaftarkan usaha anda. Silahkan mengunjungi halaman Pendaftaran lalu isi semua field yang ada disana, kemudian submit data dan pastikan anda mendapatkan pesan sukses setelah submit data.

      Setelah berhasil mendaftarkan diri silahkan menambahkan data usaha anda pada halaman Tambah Usaha, sama seperti pada halaman pendaftaran member ikuti petunjuk yang ada, kemudian submit dan pastikan anda mendapatkan pesan sukses setelah melakukan submit data.`,
    },
    {
      id: 5,
      title:
        "Jika saya adalah K/L/PD yang ingin mencari tahu informasi perkembangan tentang para pelaku usaha yang terdaftar di BISA Pengadaan, bagaimana caranya?",
      desc: `Untuk dapat menampilkan daftar bisnis anda di Vendor Directory maka anda harus mendaftarkan usaha anda. Silahkan mengunjungi halaman Pendaftaran lalu isi semua field yang ada disana, kemudian submit data dan pastikan anda mendapatkan pesan sukses setelah submit data.

      Setelah berhasil mendaftarkan diri silahkan menambahkan data usaha anda pada halaman Tambah Usaha, sama seperti pada halaman pendaftaran member ikuti petunjuk yang ada, kemudian submit dan pastikan anda mendapatkan pesan sukses setelah melakukan submit data.`,
    },
  ];

  const jenisPengusaha = [
    { name: "Pengunjung", key: "pengunjung" },
    { name: "Pelaku Usaha", key: "pelaku_usaha" },
    { name: "K/L/PD", key: "klpd" },
  ];

  const [expanded, setExpanded] = React.useState("");

  const [detailResponse, setResponse] = React.useState([]);
  const [listTutorial, setListTutorial] = React.useState(null);

  const tutorial = React.useMemo(
    () => get(listTutorial, "data", []),
    [listTutorial]
  );
  const ilustration = React.useMemo(
    () => get(listTutorial, "illustrasi_url"),
    [listTutorial]
  );

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [faqType, setFaqType] = useState(0);

  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  React.useEffect(() => {
    services.getFAQ({}, (data) => setResponse(data.data));
  }, []);

  React.useEffect(() => {
    services.getTutorialSystem(
      (response, error) => {
        if (!error) {
          setListTutorial(response);
        }
      },
      { tab: get(jenisPengusaha[faqType], "key") }
    );
  }, [faqType]);

  return (
    <Page title="FAQ" className={globalStyles.container}>
      <Navigation />

      <div className="flex flex-col pt-4">
        <Breadcrumbs>
          <Link
            className="font-montserrat font-medium text-sm text-pink-header pl-20"
            to="/"
          >
            Beranda
          </Link>
          <Link
            className="font-montserrat font-medium text-sm text-black-2"
            to={window.location.pathname}
            hash="/#"
          >
            FAQ
          </Link>
        </Breadcrumbs>
        <div className="xl:w-4/12 lg:w-3/12 mb-8 ml-20 pt-8 font-baloo2 font-bold text-3xl text-black-2">
          Frequently Asked Question (FAQ)
        </div>
        <div className="border-b-2 border-solid border-pink-header w-2/12 ml-20 mb-10" />
      </div>

      <div className="w-10/12 mx-auto">
        <div className="mb-16">
          {detailResponse?.map((blog, index) => (
            <Accordion
              square
              expanded={expanded === "panel" + blog.id}
              onChange={handleChange("panel" + blog.id)}
            >
              <AccordionSummary
                expandIcon={
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99889 6.3668L13.7739 0.591797L15.4236 2.24146L7.99889 9.66613L0.574219 2.24146L2.22389 0.591797L7.99889 6.3668Z"
                      fill={
                        expanded === "panel" + blog.id ? "#DE1B51" : "#595959"
                      }
                    />
                  </svg>
                }
              >
                <div className="font-sans font-bold text-sm text-black-2">
                  {blog?.question}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  className="font-sans font-normal text-sm text-black-2 ck-content"
                  dangerouslySetInnerHTML={{ __html: `${blog?.answer}` }}
                ></div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>

      <div className="w-10/12 mx-auto">
        <div className="font-baloo2 ml-5 font-bold text-3xl text-black-2 pb-8">
          Cara Menggunakan BISA Pengadaan
        </div>

        <Box boxShadow={3}>
          <Tabs
            value={faqType}
            centered
            onChange={(ev, newVal) => setFaqType(newVal)}
          >
            {jenisPengusaha.map((jenis, index) => (
              <Tab label={jenis.name} key={index} />
            ))}
          </Tabs>

          <div className="w-full bg-neutral-grey py-12 flex flex-row mb-20">
            <img
              src={ilustration}
              alt=""
              className="w-4/12 h-1/2 ml-auto pr-12"
            />

            <ul
              className={classNames(
                classes.faqList,
                "flex flex-col w-6/12 border-l border-solid border-welcome pl-10"
              )}
            >
              {tutorial.map((_item, index) => (
                <li
                  className={classNames(classes.faqItem, "relative")}
                  key={index}
                >
                  <div>
                    <div className="w-10/12 font-baloo2 font-bold text-base text-pink-header">
                      {get(_item, "judul")}
                    </div>
                    <div
                      className="w-6/12 pt-3 font-montserrat font-medium text-xs text-blackk"
                      dangerouslySetInnerHTML={{
                        __html: get(_item, "deskripsi"),
                      }}
                    ></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Box>
      </div>
      <Footer />
    </Page>
  );
}

export default FAQ;
