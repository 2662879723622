import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import services from "./services";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { user_login } from "redux/reducers/users";
// import Cookies from 'js-cookie';
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
// import Collapse from "@material-ui/core/Collapse";
// import IconButton from "@material-ui/core/IconButton";
import { signIn, autoSignIn, hideModalSignIn, rememberMeButton } from "redux/reducers/auth";
import token from "utils/session";
import { Tabs, Tab, IconButton, } from "@material-ui/core";
import Dialog from "../../Dialog";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "components/TextField";
import TextFieldPassword from "components/TextField/Password";
import Captcha from "components/Captcha";
import { user_email } from "redux/reducers/register";
import { modalView } from "utils/usePageTracking";

const newTicketValidation = Yup.object().shape({
  email: Yup.string().email("Invalid email").required(),
  password: Yup.string().required(),
  captcha: Yup.string().required(),
});

function Login(props) {
  // eslint-disable-next-line
  const captchaRef = useRef();
  const history = useHistory();

  const { displaySiginIn: visible } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if(visible) {
      modalView('Login', 'login');
    }
  }, [visible])
  
  const [authType, setAuthType] = useState(0);

  const [message, setMessage] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const { rememberMe } = useSelector((state) => state.auth);

  const onClose = () => {
    dispatch(hideModalSignIn());
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      type: "pelaku_usaha",
      captcha: "",
      captcha_key: "",
      message: "",
    },
    validationSchema: newTicketValidation,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { email, password, captcha, captcha_key } = values;

      const userType = {
        0: "pelaku_usaha",
        1: "klpd",
      };

      setSubmitting(true);
      services.userLogin(
        {
          email,
          password,
          type: userType[authType],
          captcha,
          captcha_key: captcha_key,
        },
        {
          success: (response) => {
            if (response.status === "nonactive") {
              setMessage({
                open: true,
                severity: "error",
                message: response.message,
              });
            } else {
            const accessTokenPrivacy = "rahasia";
            resetForm();
            dispatch(user_login({ status: true }));
            dispatch(signIn({ accessTokenPrivacy }));
            dispatch(autoSignIn({ accessToken: token }));
            setMessage({
              open: true,
              severity: "success",
              message: "Berhasil Masuk!",
            });

            window.timeout = setTimeout(() => {
              onClose();
              window.location.href = "/profile";
            }, 1500);

            captchaRef.current.reloadCaptcha();
          }},
          error: (err) => {
            let message = "Gagal Masuk";
            const resp = err.response?.data?.errors;

            if(resp) {
              let listMessages = Object.keys(resp);
              message = resp[listMessages[0]][0]
            } else {
              if(err.response?.data?.message) {
                message = err.response?.data?.message

                if (err.response?.data?.status === "notverified") {
                  dispatch(hideModalSignIn())
                  dispatch(user_email(email))
                  history.push('/register/verification?resend=true')
                }
              }
            }

            setErrors({
              message: message,
            });
            setMessage({
              open: true,
              severity: "error",
              message: message,
            });
            
            captchaRef.current.reloadCaptcha();
            setFieldValue("captcha", "");
          },
        }
      );
    },
  });

  const {
    handleSubmit,
    values,
    handleChange,
    setErrors,
    getFieldProps,
    setFieldValue,
    errors,
    setFieldError
  } = formik;

  const errorHandler = () => {
    if (Object.keys(errors).length !== 0) {
      Object.keys(errors).forEach((attributeName) => {
        setFieldError(
          attributeName,
          errors[attributeName].toString()
        );
      });
      const firstMessage = Object.keys(errors)
        .map((attrName) => errors[attrName].toString())
        .toString();
      setMessage({
        open: true,
        severity: "error",
        message: firstMessage,
      });
    }
  }

  const handleChangeAuth = (ev, newVal) => {
    setAuthType(newVal);
  };

  const userType = {
    0: "Pelaku Usaha",
    1: "K/L/PD",
  };

  return (
    <Dialog visible={visible} onClose={onClose}>
      <div>
        <div className="flex items-center justify-end">
          <button onClick={onClose} className="px-4 py-2">
            <CloseIcon />
          </button>
        </div>

        <Tabs variant="fullWidth" value={authType} onChange={handleChangeAuth}>
          <Tab label="Pelaku Usaha" />
          <Tab label="K/L/PD" />
        </Tabs>

        <div className="py-4 px-4 flex items-center justify-between border-b border-t border-solid border-welcome">
          <div className="font-baloo2 font-bold text-base text-black-2">
            Masuk sebagai {userType[authType]}
          </div>
          {userType[authType] === "Pelaku Usaha" && (<Link to="/register" onClick={onClose}>
            <button
              type="button"
              className="font-montserrat font-bold text-xs text-pink-header"
            >
              Daftar
            </button>
          </Link>)}
        </div>

        <form className="px-4 py-2" onSubmit={handleSubmit}>
          <TextField
            label="Email"
            required
            value={values.email}
            name="email"
            type="email"
            onChange={handleChange}
          />

          <TextFieldPassword
            label="Password"
            required
            value={values.password}
            name="password"
            type="password"
            onChange={handleChange}
          />

          <div className="flex items-center justify-between pt-3">
            <div className="flex items-center">
              <input
                type="checkbox"
                value="remember"
                // id="remember"
                checked={rememberMe}
                onChange={() => dispatch(rememberMeButton())}
              />
              <label
                htmlFor="remember"
                className="pl-3 font-montserrat font-medium text-xs text-black-2"
              >
                Ingat Saya
              </label>
            </div>
            <Link to="/forgot-password" onClick={onClose}>
              <button
                type="button"
                className="text-xs font-medium font-montserrat text-pink-header"
              >
                Lupa Password?
              </button>
            </Link>
          </div>
          <div className="pt-3">
            <Captcha
              ref={captchaRef}
              inputProps={{
                className:
                  "w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                ...getFieldProps("captcha"),
              }}
              callback={(option) => {
                setFieldValue("captcha_key", option.key);
              }}
            />
          </div>

          <Dialog visible={message.open === true} onClose={message.open !== true} className="mt-3">
            <Alert
              severity={message.severity}
              className="flex items-center"
            >
              {message.message}
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMessage({ open: false, error: "", message: "" });
                }}
              >
                <CloseIcon alt="" className="ml-3 self-center" />
              </IconButton>
            </Alert>
          </Dialog>

          <div className="py-4">
            <button
              type="submit"
              onClick={errorHandler}
              className="py-4 block w-full font-montserrat text-xs font-medium text-border-on-pink bg-pink-header rounded border-solid border border-pink-header"
            >
              Masuk
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}

export default Login;
