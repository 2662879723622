import { createTheme } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";
import palette from "./palette";
import shadows from "./shadows";
import typography from "./typograpy";

const Icon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3V3Z"
      fill="#ABABAB"
    />
  </svg>
);

const theme = createTheme({
  palette,
  shadows,
  typography,
  props: {
    MuiCheckbox: {
      icon: <Icon />,
    },
    MuiFormControlLabel: {
      className: 'text-black-2',
    },
    MuiTabs: {
      indicatorColor: 'primary'
    }
  },
  overrides: {
    MuiTabs: {
      indicator: {
        height: 4,
      },
    },
    MuiTab: {
      root: {
        fontSize: 14,
      },
    },
    MuiCheckbox: {
      root: {
        fontFamily: "Montserrat",
        fontSize: 12,
        textTransform: "capitalize",
        fontWeight: 600,
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: "start",
      },
      label: {
        marginTop: 10,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 500,

        "&:hover": {
          boxShadow: "none",
        },
      },

      // contained
      containedInherit: {
        boxShadow: "none",
      },

      containedPrimary: {
        boxShadow: "none",
      },
      containedSecondary: {
        boxShadow: "none",
      },
      containedInfo: {
        boxShadow: "none",
      },
      containedSuccess: {
        boxShadow: "none",
      },
      containedWarning: {
        boxShadow: "none",
      },
      containedError: {
        boxShadow: "none",
      },
    },
    MuiChip: {
      label: {
        textTransform: 'capitalize'
      }
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
