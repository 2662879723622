import React from "react";
import Box from "@material-ui/core/Box";
import { Dialog } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DataKriteriaUsaha from "components/Statistic/DataKriteriaUsaha";
import PelakuMengikutiPKP from "components/Statistic/PelakuMengikutiPKP";
import StatusTerdaftar from "components/Statistic/StatusTerdaftar";
import JenisPengadaan from "components/Statistic/JenisPengadaan";
import TabelChart from "components/Statistic/TabelChart";
import PetaPersebaran from "components/Statistic/PetaPersebaran";
import { useDispatch, useSelector } from "react-redux";
import services from "../../Statistics/services";
import {
  getLokasiUsaha,
  getLokasiGroupUsaha,
} from "redux/reducers/lokasiUsaha";
import { getKabupaten } from "redux/reducers/region";
import get from "lodash/get";
import debounce from "lodash/debounce";

export default function DashboadPelakuUsaha(props) {
  const { kriteriaUsaha, terdaftarDiSikap, jenisPengadaan } = props;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState({
    jenisChart: [],
    filterChart: [],
    yearChart: [],
  });
  const [listChart, setListChart] = React.useState([]);
  const [activities, setActivities] = React.useState({
    visible: false,
    content: [],
  });

  const { lokasiUsaha, lokasiPerGroup } = useSelector(
    (state) => state.lokasiUsaha
  );
  const { kabupaten } = useSelector((state) => state.region);
  const [params, setParams] = React.useState({
    q: "",
    page: 1,
    page_size: 10,
  });
  const [paramChart, setParamChart] = React.useState({
    chart: "Kriteria Usaha",
    filter: 1,
    year: "2021",
    q: null,
    page: 1,
    page_size: 10,
    order_column: null,
    order_direction: null,
  });

  const totalUsaha = get(lokasiUsaha, "total", 0);
  const listUsaha = get(lokasiUsaha, "data", []);

  React.useEffect(() => {
    dispatch(getKabupaten());
    dispatch(getLokasiGroupUsaha());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch(getLokasiUsaha(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  React.useEffect(() => {
    services.getTableDataOption({}, (data) =>
      setOptions((prev) => ({
        ...prev,
        jenisChart: data,
      }))
    );

    services.getTableDataYear({}, (data) =>
      setOptions((prev) => ({
        ...prev,
        yearChart: data,
      }))
    );
  }, []);

  React.useEffect(() => {
    if (paramChart.chart !== "") {
      services.getTableDataFilter(
        {
          chart: paramChart.chart,
        },
        (data) => setOptions((prev) => ({ ...prev, filterChart: data }))
      );
    }
  }, [paramChart.chart]);

  React.useEffect(() => {
    setIsLoading(true);
    services.getTableData(
      {
        chart: paramChart.chart,
        filter: paramChart.filter,
        year: paramChart.year,
        q: paramChart.q,
        page: paramChart.page,
        page_size: paramChart.page_size,
        order_column: paramChart.order_column,
        order_direction: paramChart.order_direction,
      },
      (data) => {
        setIsLoading(false);
        setListChart(data);
      }
    );
  }, [paramChart]);

  const [tabPelaku, setTabPelaku] = React.useState(0);
  const [tabStatusTerdaftar, setTabStatusTerdaftar] = React.useState(0);
  const [tabJenisPengadaan, setTabJenisPengadaan] = React.useState(0);

  const columns = [
    {
      name: "No",
      selector: (row) => get(row, "id"),
      sortable: false,
      style: {
        fontFamily: "Roboto",
        fontSize: "12px",
      },
    },
    {
      name: "Nama Usaha",
      selector: (row) => get(row, "name"),
      sortable: true,
      grow: 3,
      style: {
        width: "100%",
      },
    },
    {
      name: "Bentuk",
      selector: (row) => get(row, "business_type.name"),
      sortable: true,
      style: {},
    },
    {
      name: "Aktivitas Usaha",
      selector: (row) => {
        return (
          <button
            onClick={() =>
              setActivities({
                visible: true,
                content: row?.business_activities?.map((data) => data?.name),
              })
            }
          >
            {row?.business_activities?.map((data) => data?.name)}
          </button>
        );
      },
      grow: 3,
      sortable: true,
      style: {
        maxWidth: 400,
      },
    },
    {
      name: "Kriteria Usaha",
      selector: (row) => get(row, "business_criteria.name"),
      grow: 3,
      sortable: true,
      style: {},
    },
    // {
    //   name: "KBLI",
    //   selector: (row) => row.kbli,
    //   sortable: true,
    // },
    {
      name: "Provinsi",
      selector: (row) => get(row, "province.name"),
      sortable: true,
      style: {},
    },
  ];

  var sumTotal = kriteriaUsaha?.reduce((sum, data) => sum + data.value, 0);

  return (
    <Box className="px-6 py-6 space-y-4" sx={{ bgcolor: "grey.200" }}>
      {kriteriaUsaha?.length !== 0 && (
        <DataKriteriaUsaha
          data={[
            {
              label: kriteriaUsaha?.map((data) => data)?.[0]?.label,
              total: kriteriaUsaha?.map((data) => data)?.[0]?.value,
              percent: (
                Number(kriteriaUsaha?.map((data) => data)?.[0]?.value * 100) /
                sumTotal
              )?.toFixed(2),
            },
            {
              label: kriteriaUsaha?.map((data) => data)?.[1]?.label,
              total: kriteriaUsaha?.map((data) => data)?.[1]?.value,
              percent: (
                Number(kriteriaUsaha?.map((data) => data)?.[1]?.value * 100) /
                sumTotal
              )?.toFixed(2),
            },
            {
              label: kriteriaUsaha?.map((data) => data)?.[2]?.label,
              total: kriteriaUsaha?.map((data) => data)?.[2]?.value,
              percent: (
                Number(kriteriaUsaha?.map((data) => data)?.[2]?.value * 100) /
                sumTotal
              )?.toFixed(2),
            },
            {
              label: kriteriaUsaha?.map((data) => data)?.[3]?.label,
              total: kriteriaUsaha?.map((data) => data)?.[3]?.value,
              percent: (
                Number(kriteriaUsaha?.map((data) => data)?.[3]?.value * 100) /
                sumTotal
              )?.toFixed(2),
            },
          ]}
        />
      )}

      {/* <PelakuMengikutiPKP
        data={exampleData}
        options={[
          { year: 2019, total: 1000 },
          { year: 2020, total: 1000 },
          { year: 2021, total: 1000 },
        ]}
        activeIndex={tabPelaku}
        onChange={(index) => setTabPelaku(index)}
      /> */}

      <Box className="flex lg:flex-row flex-col items-center space-x-3 w-full">
        <StatusTerdaftar
          terdaftarDiSikap={terdaftarDiSikap}
          activeIndex={tabStatusTerdaftar}
          onChange={(index) => setTabStatusTerdaftar(index)}
        />
        <JenisPengadaan
          jenisPengadaan={jenisPengadaan}
          activeIndex={tabJenisPengadaan}
          onChange={(index) => setTabJenisPengadaan(index)}
        />
      </Box>

      <TabelChart
        data={listChart?.data}
        columns={listChart?.columns?.map((_item) => ({
          name: _item?.label,
          selector: (row) => row[_item.key],
          sortKey: _item.column,
          sortable: true,
          style: {
            fontFamily: "Roboto",
            fontSize: "12px",
          },
        }))}
        headers={{
          dataChart: get(options, "jenisChart", []),
          filterChart: get(options, "filterChart", []),
          tahunChart: get(options, "yearChart", []),
          onSearch: (ev) =>
            setParamChart((prev) => ({ ...prev, q: ev.target.value, page: 1 })),
          onChangeChart: (ev) =>
            setParamChart((prev) => ({
              ...prev,
              chart: ev.target.value,
              q: null,
              filter: "",
              year: "",
            })),
          onChangeFilter: (ev) =>
            setParamChart((prev) => ({ ...prev, filter: ev.target.value })),
          onChangeYear: (ev) =>
            setParamChart((prev) => ({ ...prev, year: ev.target.value })),
        }}
        dataTable={{
          progressPending: isLoading,
          pagination: true,
          paginationServer: true,
          sortServer: true,
          paginationTotalRows: get(listChart, "total", 0),
          onSort: (column, sortDirection) =>
            setParamChart((prev) => ({
              ...prev,
              order_column: column.sortKey,
              order_direction: sortDirection,
            })),
          onChangeRowsPerPage: (perPage, newPage) => {
            setParamChart((prev) => ({
              ...prev,
              page_size: perPage,
              page: newPage,
            }));
          },
          onChangePage: (page) => setParamChart((prev) => ({ ...prev, page })),
        }}
      />

      <PetaPersebaran
        params={params}
        columns={columns}
        data={listUsaha}
        peta={lokasiPerGroup}
        headers={{
          options: kabupaten,
          onChangeProvince: (ev) => {
            setParams((prev) => ({
              ...prev,
              province_id: ev,
              city_id: null,
            }));
          },
          onChangeLocation: (
            ev,
            option = { city_id: null, province_id: null }
          ) => {
            setParams((prev) => ({
              ...prev,
              city_id: option?.id,
              province_id: option?.province_id,
            }));
          },
          onChangeRowsPerPage: (ev) =>
            setParams((prev) => ({
              ...prev,
              page_size: ev.target.value,
              page: 1,
            })),
          onSearch: debounce((ev) =>
            setParams((prev) => ({ ...prev, q: ev.target.value }))
          ),
          province_id: params?.province_id,
        }}
        dataTable={{
          pagination: true,
          paginationServer: true,
          paginationTotalRows: totalUsaha,
          onChangeRowsPerPage: (perPage, newPage) =>
            setParams((prev) => ({
              ...prev,
              page_size: perPage,
              page: newPage,
            })),
          onChangePage: (page) => setParams((prev) => ({ ...prev, page })),
        }}
        profileKLPD={true}
      />

      <Dialog
        open={activities.visible}
        maxWidth="md"
        fullWidth
        onClose={() => setActivities({ visible: false, content: [] })}
        onBackdropClick={() => setActivities({ visible: false, content: [] })}
      >
        <Box className="py-2 px-4">
          <div className="flex items-center justify-end">
            <CloseIcon
              className="cursor-pointer"
              color="grey.200"
              onClick={() => setActivities({ visible: false, content: [] })}
            />
          </div>
          <div className="p-4">
            <div className="mb-6">
              <span className="font-baloo2 font-bold text-lg text-black-2">
                Aktivitas Usaha
              </span>
            </div>

            <div className="bg-welcome p-2">
              <ol className="list-decimal pl-4 pt-4">
                {activities?.content?.map((name) => (
                  <li className="font-montserrat text-black-2 font-medium text-sm pb-1">
                    {name}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </Box>
      </Dialog>
    </Box>
  );
}
