import React from "react";
import Dialog from "components/Dialog";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      width: "100%",

      [theme.breakpoints.down("md")]: {
        width: 886,
      },
    },
  })
);

function Question(props) {
  const { visible, onClose } = props;

  const classes = useStyles();

  return (
    <Dialog visible={visible} onClose={onClose} maxWidth="md">
      <div className="px-4 py-2">
        <div className="mb-6">
          <span className="font-baloo2 font-bold text-lg text-black-2">
            Detail Kriteria Usaha
          </span>
        </div>

        <CloseIcon
          className="cursor-pointer absolute right-0 top-0 mt-3 mr-3"
          color="grey.200"
          onClick={onClose}
        />
      </div>

      <Box className="px-4 py-2">
        <div className="px-6 py-4 lg:overflow-visible overflow-x-scroll">
          <table className={classnames(classes.table, "bg-white mx-auto")}>
            <thead style={{ background: "#FAFAFA" }}>
              <tr
                className="font-roboto font-bold text-sm text-black-2 border-b-2 border-solid border-black-2"
                style={{ color: "#272833" }}
                height="50"
              >
                <th className="text-left px-10">Kriteria Usaha</th>
                <th className="text-left px-5">Kekayaan Bersih/Modal Usaha</th>
                <th className="text-left px-10">Hasil Penjualan Tahunan </th>
              </tr>
            </thead>
            <tbody className="font-roboto text-xs text-black-2">
              <tr
                className="border-b border-solid border-welcome"
                style={{ background: "#F0F0F0" }}
              >
                <td className="text-left px-10">Mikro</td>
                <td className="text-left px-5 py-2">
                  Paling Banyak 1 miliar (di luar tanah bangunan tempat usaha)
                  ditulis: &lt;/=1M (di luar tanah bangunan tempat usaha)
                </td>
                <td className="text-left px-10">
                  Paling banyak 2 miliar ditulis: &lt;/=2M
                </td>
              </tr>

              <tr className="border-b border-solid border-welcome">
                <td className="text-left px-10">Kecil</td>
                <td className="text-left px-5 py-2">
                  Lebih dari 1 miliar s.d paling banyak 5 miliar (di luar tanah
                  bangunan tempat usaha) ditulis: 1M - 5M (di luar tanah
                  bangunan tempat usaha)
                </td>
                <td className="text-left px-10">
                  Lebih dari 2 miliar - Paling banyak 15 miliar ditulis: 2M =
                  15M
                </td>
              </tr>

              <tr
                className="border-b border-solid border-welcome"
                style={{ background: "#F0F0F0" }}
              >
                <td className="text-left px-10">Menengah</td>
                <td className="text-left px-5 py-2">
                  Lebih dari 5 miliar s.d paling banyak 10 miliar (di luar tanah
                  bangunan tempat usaha) ditulis: &gt;=5M - 10M (di luar tanah
                  bangunan tempat usaha)
                </td>
                <td className="text-left px-10">
                  Lebih dari 15 miliar - Paling banyak 50 miliar ditulis: 15M -
                  50M
                </td>
              </tr>

              <tr className="border-b border-solid border-welcome">
                <td className="text-left px-10">Besar</td>
                <td className="text-left px-5 py-2 pb-4">
                  Lebih dari 10 miliar (di luar tanah bangunan tempat usaha)
                  ditulis: &gt;10M (di luar tanah bangunan tempat usaha)
                </td>
                <td className="text-left px-10">
                  Lebih dari 50 miliar ditulis: &gt;50M
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    </Dialog>
  );
}

export default Question;
