import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { SvgIcon } from "@material-ui/core";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { useSelector } from 'react-redux'

export default function Sidebar(props) {
  const { totalUsaha, totalSertifikat } = props;
  const { profile: user } = useSelector((state) => state.user);

  const [currentTime, setCurrentTime] = React.useState(new Date());

  const userType = {
    pelaku_usaha: "Pelaku Usaha",
    klpd: "K/L/PD",
  };

  const currentUserType = React.useMemo(
    () => userType[get(user, "type", "pelaku_usaha")],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  React.useEffect(() => {
    window.currentTime = setInterval(() => {
      setCurrentTime(new Date());
    }, [1000]);

    return () => clearInterval(window.currentTime);
  }, []);

  return (
    <div className="2xl:w-2/12 xl:w-3/12">
      <div
        className="w-full rounded inline-block divide-y-2 divide-gray-300"
        style={{
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="flex items-start w-full px-4 pt-4">
          <img
            src={get(user, "profile_picture_url", "")}
            alt=""
            className="w-8 h-8 rounded-full mr-2"
          />
          <div className="w-full">
            <span className="w-full font-baloo2 font-bold text-base text-black-2 border-b border-welcome pb-2 block">
              {get(user, "full_name", "")}
            </span>

            <div className="flex flex-col py-4">
              <span className="font-montserrat font-medium text-sm text-black-2 pb-1">
                {currentUserType}
              </span>

              {get(user, "type") === "pelaku_usaha" && (
                <span className="flex pb-5 items-center font-montserrat font-medium text-sm text-pink-header">
                  <svg
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.83343 5.85086L9.19543 0.488281L10.0208 1.31311L3.83343 7.50053L0.121094 3.7882L0.945927 2.96336L3.83343 5.85086Z"
                      fill="#DE1B51"
                    />
                  </svg>
                  <div className="ml-1">Akun terverifikasi</div>
                </span>
              )}

              {get(user, "type") === "klpd" && (
                <div className="flex items-start">
                  <SvgIcon>
                    <path
                      d="M11.6673 3.5H13.4173V4.66667H12.834V11.0833H13.4173V12.25H0.583984V11.0833H1.16732V4.66667H0.583984V3.5H2.33398V2.33333C2.33398 2.17862 2.39544 2.03025 2.50484 1.92085C2.61424 1.81146 2.76261 1.75 2.91732 1.75H11.084C11.2387 1.75 11.3871 1.81146 11.4965 1.92085C11.6059 2.03025 11.6673 2.17862 11.6673 2.33333V3.5ZM11.6673 4.66667H2.33398V11.0833H4.08398V7H5.25065V11.0833H6.41732V7H7.58398V11.0833H8.75065V7H9.91732V11.0833H11.6673V4.66667ZM3.50065 2.91667V3.5H10.5007V2.91667H3.50065Z"
                      fill="#DE1B51"
                    />
                  </SvgIcon>

                  <div>
                    <p className="font-montserrat font-medium text-xs text-pink-header capitalize">
                      {get(user, "instansi")}
                    </p>
                    <p className="font-montserrat font-medium text-xs text-pink-header">
                      {get(user, "instansi_bagian.name")}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-neutral-grey py-4 flex justify-around">
          <div className="w-3/12 font-montserrat font-bold text-xs text-black-2">
            <p>{format(currentTime, "eeee", { locale: id })}</p>
            <p>{format(currentTime, "dd MMM yyyy", { locale: id })}</p>
          </div>
          <div className="font-baloo2 font-bold text-2xl">
            {format(currentTime, "HH:mm", { locale: id })}
          </div>
        </div>

        {currentUserType === "Pelaku Usaha" && (
          <div className="flex items-center space-x-4 px-4 py-4">
            <div className="flex flex-col w-full bg-neutral-grey rounded border-b-4 border-solid border-pink-header">
              <div className="pb-1 text-center border-b border-solid border-welcome">
                <div className="font-baloo2 font-bold text-2xl text-black-2">
                  {totalUsaha}
                </div>
                <div className="font-baloo2 font-bold text-base text-black-2">
                  Usaha
                </div>
              </div>
              <div className="font-montserrat font-medium text-xs text-black-2 px-4 py-2 text-center">
                Terdaftar
              </div>
            </div>

            <div className="flex flex-col w-full bg-neutral-grey rounded border-b-4 border-solid border-pink-header">
              <div className="pb-1 text-center border-b border-solid border-welcome">
                <div className="font-baloo2 font-bold text-2xl text-black-2">
                  {totalSertifikat}
                </div>
                <div className="font-baloo2 font-bold text-base text-black-2">
                  Sertifikat
                </div>
              </div>
              <div className="font-montserrat font-medium text-xs text-black-2 px-4 py-2 text-center">
                Diterima
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  totalUsaha: PropTypes.number,
  totalSertifikat: PropTypes.number,
};

Sidebar.defaultProps = {
  totalUsaha: 0,
  totalSertifikat: 0,
};
