import { useEffect, useState } from "react";
import endpoint from "utils/endpoint";
import axios from "axios";

export default function useProfile() {
  async function getParallaxHeader(callback) {
    const url = `${endpoint.parallaxHeader}`;
    const config = {
      method: "GET",
    };

    try {
      const response = await axios(url, config);
      await callback(response.data, null);
    } catch (error) {
      await callback(null, error);
    }
  }

  const [detailResponse, setResponse] = useState([]);

  useEffect(() => {
    getParallaxHeader((data, error) => {
      if (!error) {
        setResponse(data);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return detailResponse;
}
