import React, { useEffect, useImperativeHandle, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {getCaptcha, playAudio} from './services';
import {get} from 'lodash';
import classNames from 'classnames';

const Captcha = forwardRef(function Captcha(props, ref) {
  const {callback, classContainer} = props;
  const [isFetch, setIsFetch] = useState(true);
  const [data, setData] = useState({});
  
  useEffect(() => {
    if(isFetch) {
      getCaptcha(captcha => {
        setData(captcha)
        setIsFetch(false);

        if(typeof callback == 'function') {
          callback(captcha);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetch]);

  const reloadCaptcha = () => {
    setIsFetch(true);
  }
  
  useImperativeHandle(ref, () => ({
    reloadCaptcha,
  }))
  
  const inputProps = {
    className: 'py-2 px-2 w-full forms-select font-montserrat font-medium text-xs text-welcome border border-solid border-blue-second rounded',
    name: 'captcha',
    ...props.inputProps,
  }
  
  const handleClickAudioButton = () => {
    const key = get(data, 'key');

    if(key) {
      playAudio(key, () => {
        // if expire refresh captcha
        setIsFetch(true);
      });
    }
  }
  
  return (
    <>
      <div className={classNames(classContainer, "flex flex-row pt-3")}>
        <img src={get(data, 'img')} alt="" className="w-80" style={{height: '100px'}} />
        <div className="pl-4">
          <button className="block" type="button" onClick={() => handleClickAudioButton()}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 7.22002L6.603 10H3V14H6.603L10 16.78V7.22002ZM5.889 16H2C1.73478 16 1.48043 15.8947 1.29289 15.7071C1.10536 15.5196 1 15.2652 1 15V9.00002C1 8.73481 1.10536 8.48045 1.29289 8.29291C1.48043 8.10538 1.73478 8.00002 2 8.00002H5.889L11.183 3.66802C11.2563 3.60797 11.3451 3.56996 11.4391 3.55841C11.5331 3.54687 11.6284 3.56227 11.714 3.60282C11.7996 3.64337 11.872 3.7074 11.9226 3.78745C11.9732 3.86751 12.0001 3.9603 12 4.05502V19.945C12.0001 20.0397 11.9732 20.1325 11.9226 20.2126C11.872 20.2926 11.7996 20.3567 11.714 20.3972C11.6284 20.4378 11.5331 20.4532 11.4391 20.4416C11.3451 20.4301 11.2563 20.3921 11.183 20.332L5.89 16H5.889ZM19.406 20.134L17.99 18.718C18.938 17.8745 19.6964 16.8397 20.2152 15.6817C20.734 14.5237 21.0015 13.2689 21 12C21.0012 10.6661 20.7054 9.34867 20.1339 8.14339C19.5624 6.93811 18.7296 5.87526 17.696 5.03202L19.116 3.61202C20.3345 4.64357 21.3132 5.9285 21.9841 7.37722C22.6549 8.82593 23.0016 10.4035 23 12C23 15.223 21.614 18.122 19.406 20.134ZM15.863 16.591L14.441 15.169C14.9265 14.7957 15.3196 14.3158 15.5899 13.7663C15.8602 13.2167 16.0006 12.6124 16 12C16 10.57 15.25 9.31502 14.12 8.60802L15.559 7.16902C16.3165 7.72621 16.9321 8.45387 17.3562 9.29314C17.7802 10.1324 18.0008 11.0597 18 12C18 13.842 17.17 15.49 15.863 16.591Z" fill="#595959"/>
            </svg>
          </button>
          <button className="block mt-2" type="button" onClick={() => setIsFetch(true)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.463 4.43301C7.27756 2.86067 9.59899 1.99666 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 14.136 21.33 16.116 20.19 17.74L17 12H20C20.0001 10.4316 19.5392 8.89781 18.6747 7.58927C17.8101 6.28072 16.5799 5.25517 15.1372 4.64013C13.6944 4.0251 12.1027 3.84771 10.56 4.13003C9.0172 4.41234 7.59145 5.14191 6.46 6.22801L5.463 4.43301ZM18.537 19.567C16.7224 21.1393 14.401 22.0034 12 22C6.477 22 2 17.523 2 12C2 9.86401 2.67 7.88401 3.81 6.26001L7 12H4C3.99987 13.5684 4.46075 15.1022 5.32534 16.4108C6.18992 17.7193 7.42007 18.7449 8.86282 19.3599C10.3056 19.9749 11.8973 20.1523 13.44 19.87C14.9828 19.5877 16.4085 18.8581 17.54 17.772L18.537 19.567Z" fill="#595959"/>
            </svg>
          </button>
        </div>
      </div>
      <div className="pt-3 flex">
        <div className="font-montserrat font-bold text-xxs text-black-2">
          Ketik Text
        </div>
        <div className="pl-1 font-sans font-bold text-xs text-pink-header">
          *
        </div>
      </div>
      <div className="relative">
        <input {...inputProps}/>
      </div>
    </>
  )
})

Captcha.defaultProps = {
  inputProps: {}
}

Captcha.propTypes = {
  inputProps: PropTypes.object,
}

export default Captcha;
