import React from "react";
import { Box } from "@material-ui/core";
import Navigation from "components/Navigation";
import Footer from "components/footer";
import Page from "components/Page";
import Breadcrumb from "components/Breadcrumb";
import useGlobalStyles from "hooks/useGlobalStyles";
import classNames from "classnames";

function SyaratDanKetentuan() {
  const classes = useGlobalStyles();

  const text = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam vel quam elementum pulvinar etiam non quam. Mattis molestie a iaculis at erat. Et malesuada fames ac turpis. Vitae tempus quam pellentesque nec nam aliquam sem et tortor. Luctus accumsan tortor posuere ac ut consequat semper viverra nam.

  Odio tempor orci dapibus ultrices in iaculis nunc. At quis risus sed vulputate odio ut enim blandit. Eget arcu dictum varius duis at consectetur lorem donec. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus quam. Erat velit scelerisque in dictum. Ultricies mi quis hendrerit dolor.
  
  Quis eleifend quam adipiscing vitae proin. Scelerisque varius morbi enim nunc. Ac felis donec et odio pellentesque diam volutpat commodo. In arcu cursus euismod quis viverra nibh. Nisl vel pretium lectus quam id leo in vitae. Nulla malesuada pellentesque elit eget gravida cum. Tempor id eu nisl nunc mi ipsum. Suspendisse potenti nullam ac tortor vitae.
  
  Est lorem ipsum dolor sit amet consectetur adipiscing elit. Ante in nibh mauris cursus.
  Risus nullam eget felis eget nunc lobortis mattis aliquam faucibus.
  Tellus id interdum velit laoreet id donec.
  At quis risus sed vulputate odio ut enim.
  
  Convallis tellus id interdum velit laoreet id donec. Urna et pharetra pharetra massa. Nisi lacus sed viverra tellus in hac habitasse platea dictumst. Ornare aenean euismod elementum nisi quis. Id aliquet risus feugiat in ante metus. Turpis egestas integer eget aliquet. Lacus laoreet non curabitur gravida arcu ac tortor dignissim. Egestas maecenas pharetra convallis posuere morbi leo urna.
  
  Morbi enim nunc faucibus a pellentesque. Arcu ac tortor dignissim convallis aenean et tortor.
  Vitae justo eget magna fermentum iaculis eu non. Tortor condimentum lacinia quis vel eros donec ac odio.
  Ac orci phasellus egestas tellus rutrum tellus.
  Est velit egestas dui id ornare arcu odio ut sem. Id ornare arcu odio ut.
  Magna ac placerat vestibulum lectus mauris ultrices.
  Risus at ultrices mi tempus imperdiet nulla.
  
  Viverra accumsan in nisl nisi scelerisque eu. Sit amet consectetur adipiscing elit duis tristique sollicitudin nibh sit. Amet luctus venenatis lectus magna fringilla urna porttitor rhoncus. Sit amet mauris commodo quis. Facilisis gravida neque convallis a cras semper auctor neque vitae.
  
  Blandit massa enim nec dui nunc mattis enim ut tellus. Commodo ullamcorper a lacus vestibulum sed arcu non. Viverra aliquet eget sit amet tellus. Risus at ultrices mi tempus imperdiet nulla malesuada pellentesque elit. Ornare aenean euismod elementum nisi quis eleifend quam adipiscing.
  
  Consectetur adipiscing elit duis tristique sollicitudin nibh. Quisque egestas diam in arcu. Arcu non odio euismod lacinia. Risus in hendrerit gravida rutrum. Nulla aliquet enim tortor at auctor urna nunc id cursus. Egestas tellus rutrum tellus pellentesque eu tincidunt tortor aliquam. Faucibus interdum posuere lorem ipsum dolor sit amet consectetur. Sed augue lacus viverra vitae congue eu.
  
  Amet volutpat consequat mauris nunc congue. Elit duis tristique sollicitudin nibh sit amet commodo. At lectus urna duis convallis convallis tellus id interdum velit. Hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper.`;

  return (
    <Page title="Syarat dan Ketentuan">
      <Navigation />
      <Box className={classNames('min-h-screen', classes.container)}>
        <div className="flex flex-col py-4 px-20" style={{}}>
          <Breadcrumb
            classLast="text-black-2"
            links={[
              { href: "/", name: "Beranda" },
              { name: "Syarat & Ketentuan" },
            ]}
          />
        </div>
        <div className="flex justify-center w-full">
          <img src="/static/images/logo-vd.png" alt="" style={{ height: 70 }} />
        </div>
        <Box
          className="mx-auto whitespace-pre-line w-9/12 mt-10 mb-20 p-5 text-black-2"
          boxShadow={3}
        >
          <div>
            <div className="pb-4 font-baloo2 font-bold text-3xl border-b border-solid border-welcome">
              Syarat & Ketentuan (Pendaftaran)
            </div>
            <div className="py-4 font-baloo2 font-bold text-2xl">
              BISA (Business Information and Supplier plAtform) Pengadaan
            </div>
            <div className="space-y-4">
              <p className="text-base font-montserrat">
                Anda setuju untuk menggunakan aplikasi ini hanya untuk keperluan
                sesuai dengan tujuan yang telah ditetapkan dibangunnya aplikasi
                ini. Anda dilarang memposting atau mengirim ke atau melalui
                aplikasi ini segala materi yang bersifat melanggar hukum,
                membahayakan, mengancam, bersifat kasar, melecehkan, mencemarkan
                nama baik, vulgar, tidak sopan, mengeluarkan kata-kata tidak
                senonoh, memicu kebencian, atau materi lain yang dapat
                menimbulkan tanggung jawab perdata maupun pidana sesuai hukum.
                Kami akan melakukan verifikasi data yang telah Anda isi dan
                informasi yang telah ada unggah.
              </p>
              <p className="text-base font-montserrat">
                Anda juga dapat melihat, mencari dan mengunduh data dalam
                aplikasi ini untuk keperluan yang telah ditentukan. Anda
                dilarang menyalahgunakan data dan informasi yang tersedia dan
                diperoleh dari aplikasi ini.
              </p>
              <p className="text-base font-montserrat">
                Dengan menyetujui syarat dan ketentuan maka Anda harus patuh dan
                bertanggung jawab dalam penggunaan aplikasi ini.
              </p>

              <div>
                <p className="text-base font-montserrat font-bold">Salam Pengadaan,</p>
                <p className="text-base font-montserrat font-bold">Direktorat Pengembangan Iklim Usaha dan Kerjasama Internasional, LKPP</p>
              </div>
            </div>
          </div>
        </Box>
      </Box>
      <Footer />
    </Page>
  );
}

export default SyaratDanKetentuan;
