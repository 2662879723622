import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

export default function ViewRole(props) {
  const { accept, children } = props
  const { profile } = useSelector(state => state.user)

  if (!profile) {
    return null
  }

  return children
}

ViewRole.propTypes = {
  accept: PropTypes.oneOf(['pelaku_usaha', 'klpd']),
}