import React, { useState } from "react";
import spikedPhoto from "assets/down-s-line.png";
import classNames from "classnames";

function Cards(props) {
  const [isOpen, setIsOpen] = useState(true);
  const toggling = () => setIsOpen(!isOpen);
  const { children, childrenName } = props;
  const onOpen = classNames({
    "transform rotate-180": !isOpen,
  });

  return (
    <div
      className="p-4 mb-10 w-full"
      style={{
        boxShadow: "0px 4px 8px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
      }}
    >
      <div>
        <div onClick={toggling} className="flex flex-row justify-between py-4">
          <div className="font-baloo2 font-bold text-lg text-black-2">
            {childrenName}
          </div>
          <img src={spikedPhoto} alt="" className={onOpen} />
        </div>
        {isOpen && <div className="w-full px-8 pt-3">{children}</div>}
      </div>
    </div>
  );
}

export default Cards;
