/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Navigation from "components/Navigation";
import Footer from "components/footer";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import "../../index.css";
import useGlobalStyles from "hooks/useGlobalStyles";
import DataKriteriaUsaha from "components/Statistic/DataKriteriaUsaha";
import PelakuMengikutiPKP from "components/Statistic/PelakuMengikutiPKP";
import StatusTerdaftar from "components/Statistic/StatusTerdaftar";
import JenisPengadaan from "components/Statistic/JenisPengadaan";
import TabelChart from "components/Statistic/TabelChart";
import Breadcrumb from "components/Breadcrumb";
import Page from "components/Page";
import services from "./services";
import get from "lodash/get";
import DataUsaha from "components/Statistic/DataUsaha";
import DataStatistikKalendar from "components/Statistic/DataStatistikKalendar";
import JenisKelaminPU from "components/Statistic/JenisKelaminPU";
import PUOrangAsli from "components/Statistic/PUOrangAsli";
import TrackingPenawaran from "components/Statistic/TrackingPenawaran";
import PengalamanBPJP from "components/Statistic/PengalamanBPJP";

function Statistics() {
  const classes = useGlobalStyles();

  const [tabPelaku, setTabPelaku] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tabStatusTerdaftar, setTabStatusTerdaftar] = React.useState(0);
  const [tabJenisPengadaan, setTabJenisPengadaan] = React.useState(0);
  const [params, setParams] = React.useState({
    chart: "Kriteria Usaha",
    filter: 1,
    year: "2021",
    q: null,
    page: 1,
    page_size: 10,
    order_column: null,
    order_direction: null,
  });

  const examplePie = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [15, 20, 19, 15, 12, 13],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  let data5 = [9, 5, 3, 5, 6, 7];
  let labels = ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"];
  let customLabels = labels.map((label, index) => `${label}: ${data5[index]}`);
  const chartdata = {
    labels: customLabels,
    datasets: [
      {
        label: "Markets Monitored",
        backgroundColor: [
          "#83ce83",
          "#959595",
          "#f96a5d",
          "#00A6B4",
          "#6800B4",
          "#FFA500",
        ],
        data: data5,
      },
    ],
  };

  const [detailResponse, setResponse] = React.useState({
    kriteriaUsaha: [],
    terdaftarDiSikap: [],
    jenisPengadaan: [],
    tableDataOption: [],
    tableDataYear: [],
    tableDataFilter: [],
    data: [],
  });

  React.useEffect(() => {
    services.getCountUsahaByKriteriaUsaha({}, (data) =>
      setResponse((prev) => ({
        ...prev,
        kriteriaUsaha: data,
      }))
    );

    services.getTerdaftarDiSikap({}, (data) =>
      setResponse((prev) => ({
        ...prev,
        terdaftarDiSikap: data,
      }))
    );

    services.getJenisPengadaan({}, (data) =>
      setResponse((prev) => ({
        ...prev,
        jenisPengadaan: data,
      }))
    );

    services.getTableDataOption({}, (data) =>
      setResponse((prev) => ({
        ...prev,
        tableDataOption: data,
      }))
    );

    services.getTableDataYear({}, (data) =>
      setResponse((prev) => ({
        ...prev,
        tableDataYear: data,
      }))
    );
  }, []);

  React.useEffect(() => {
    if (params.chart !== "") {
      services.getTableDataFilter(
        {
          chart: params.chart,
        },
        (data) =>
          setResponse((prev) => ({
            ...prev,
            tableDataFilter: data,
          }))
      );
    }
  }, [params.chart]);

  React.useEffect(() => {
    setIsLoading(true);
    services.getTableData(
      {
        chart: params.chart,
        filter: params.filter,
        year: params.year,
        q: params.q,
        page: params.page,
        page_size: params.page_size,
        order_column: params.order_column,
        order_direction: params.order_direction,
      },
      (data) => {
        setIsLoading(false);
        setResponse((prev) => ({
          ...prev,
          data: data,
        }));
      }
    );
  }, [params]);

  var sumTotal = detailResponse?.kriteriaUsaha?.reduce(
    (sum, data) => sum + data.value,
    0
  );

  return (
    <Page title="Statistik" className={classes.container}>
      <Navigation />

      <div className="flex flex-col pt-4">
        <Breadcrumb
          links={[{ name: "Beranda", href: "/" }, { name: "Statistik" }]}
          className="lg:px-20 px-6"
        />
      </div>

      <div className="lg:mx-20 mx-6 py-10">
        <div className="flex justify-between">
          <div className="mb-8 font-baloo2 font-bold text-3xl text-black-2">
            Statistik
            <div className="border-b-2 border-solid border-pink-header w-6/12 mb-8 pt-10" />
          </div>
          {/* <img src={LKPPLogo} alt="" className="h-20" /> */}
        </div>

        <Box className="lg:px-10 px-0 py-6 space-y-4">
          {detailResponse?.kriteriaUsaha?.length !== 0 && (
            <DataKriteriaUsaha
              data={[
                {
                  label: detailResponse?.kriteriaUsaha?.map((data) => data)?.[0]
                    ?.label,
                  total:
                    detailResponse?.kriteriaUsaha?.map((data) => data)?.[0]
                      ?.value !== 0
                      ? detailResponse?.kriteriaUsaha?.map((data) => data)?.[0]
                          ?.value
                      : 0,
                  percent:
                    detailResponse?.kriteriaUsaha?.map((data) => data)?.[0]
                      ?.value !== 0
                      ? (
                          Number(
                            detailResponse?.kriteriaUsaha?.map(
                              (data) => data
                            )?.[0]?.value * 100
                          ) / sumTotal
                        )?.toFixed(2)
                      : "0.00%",
                },
                {
                  label: detailResponse?.kriteriaUsaha?.map((data) => data)?.[1]
                    ?.label,
                  total:
                    detailResponse?.kriteriaUsaha?.map((data) => data)?.[1]
                      ?.value !== 0
                      ? detailResponse?.kriteriaUsaha?.map((data) => data)?.[1]
                          ?.value
                      : 0,
                  percent:
                    detailResponse?.kriteriaUsaha?.map((data) => data)?.[1]
                      ?.value !== 0
                      ? (
                          Number(
                            detailResponse?.kriteriaUsaha?.map(
                              (data) => data
                            )?.[1]?.value * 100
                          ) / sumTotal
                        )?.toFixed(2)
                      : "0.00%",
                },
                {
                  label: detailResponse?.kriteriaUsaha?.map((data) => data)?.[2]
                    ?.label,
                  total:
                    detailResponse?.kriteriaUsaha?.map((data) => data)?.[2]
                      ?.value !== 0
                      ? detailResponse?.kriteriaUsaha?.map((data) => data)?.[2]
                          ?.value
                      : 0,
                  percent:
                    detailResponse?.kriteriaUsaha?.map((data) => data)?.[2]
                      ?.value !== 0
                      ? (
                          Number(
                            detailResponse?.kriteriaUsaha?.map(
                              (data) => data
                            )?.[2]?.value * 100
                          ) / sumTotal
                        )?.toFixed(2)
                      : "0.00%",
                },
                {
                  label: detailResponse?.kriteriaUsaha?.map((data) => data)?.[3]
                    ?.label,
                  total:
                    detailResponse?.kriteriaUsaha?.map((data) => data)?.[3]
                      ?.value !== 0
                      ? detailResponse?.kriteriaUsaha?.map((data) => data)?.[3]
                          ?.value
                      : 0,
                  percent:
                    detailResponse?.kriteriaUsaha?.map((data) => data)?.[3]
                      ?.value !== 0
                      ? (
                          Number(
                            detailResponse?.kriteriaUsaha?.map(
                              (data) => data
                            )?.[3]?.value * 100
                          ) / sumTotal
                        )?.toFixed(2)
                      : "0.00%",
                },
              ]}
            />
          )}

          {/* <PelakuMengikutiPKP
            data={exampleData}
            options={[
              { year: 2019, total: 1000 },
              { year: 2020, total: 1000 },
              { year: 2021, total: 1000 },
            ]}
            activeIndex={tabPelaku}
            onChange={(index) => setTabPelaku(index)}
          /> */}

          <Box className="w-full">
            <DataUsaha />
          </Box>

          <Box className="w-full">
            <DataStatistikKalendar />
          </Box>

          <Box className="flex lg:flex-row flex-col items-center space-x-3 w-full">
            <JenisKelaminPU />
            <PUOrangAsli />
          </Box>

          <Box className="flex lg:flex-row flex-col items-center space-x-3 w-full">
            <TrackingPenawaran />
            <PengalamanBPJP />
          </Box>

          <Box className="flex lg:flex-row flex-col items-center space-x-3 w-full">
            <StatusTerdaftar
              data={chartdata}
              terdaftarDiSikap={detailResponse?.terdaftarDiSikap}
              options={[
                { year: 2019, total: 1000 },
                { year: 2020, total: 1000 },
                { year: 2021, total: 1000 },
              ]}
              activeIndex={tabStatusTerdaftar}
              onChange={(index) => setTabStatusTerdaftar(index)}
            />
            <JenisPengadaan
              data={examplePie}
              jenisPengadaan={detailResponse?.jenisPengadaan}
              options={[
                { year: 2019, total: 1000 },
                { year: 2020, total: 1000 },
                { year: 2021, total: 1000 },
              ]}
              activeIndex={tabJenisPengadaan}
              onChange={(index) => setTabJenisPengadaan(index)}
            />
          </Box>

          <TabelChart
            data={detailResponse?.data?.data}
            columns={detailResponse?.data?.columns?.map((_item) => ({
              name: _item?.label,
              selector: (row) => row[_item.key],
              sortable: true,
              style: {
                fontFamily: "Roboto",
                fontSize: "12px",
              },
            }))}
            headers={{
              dataChart: detailResponse?.tableDataOption,
              filterChart: detailResponse?.tableDataFilter,
              tahunChart: detailResponse?.tableDataYear,
              onSearch: (ev) =>
                setParams((prev) => ({ ...prev, q: ev.target.value, page: 1 })),
              onChangeChart: (ev) =>
                setParams((prev) => ({
                  ...prev,
                  chart: ev.target.value,
                  // q: null,
                  // filter: "",
                  // year: "",
                })),
              onChangeFilter: (ev) =>
                setParams((prev) => ({
                  ...prev,
                  filter: Number(ev.target.value),
                })),
              onChangeYear: (ev) =>
                setParams((prev) => ({
                  ...prev,
                  year: Number(ev.target.value),
                })),
              params: params,
            }}
            dataTable={{
              progressPending: isLoading,
              pagination: true,
              paginationServer: true,
              sortServer: true,
              paginationTotalRows: get(detailResponse, "data.total", 0),
              onSort: (column, sortDirection) =>
                setParams((prev) => ({
                  ...prev,
                  order_column: column.sortKey,
                  order_direction: sortDirection,
                })),
              onChangeRowsPerPage: (perPage, newPage) => {
                setParams((prev) => ({
                  ...prev,
                  page_size: perPage,
                  page: newPage,
                }));
              },
              onChangePage: (page) => setParams((prev) => ({ ...prev, page })),
            }}
          />
        </Box>
      </div>

      <Footer />
    </Page>
  );
}

export default Statistics;
