/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import services from "./services";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Footer from "components/footer";
import classnames from "classnames";
import { get } from "lodash";

import Navigation from "components/Navigation";
import Sidebar from "./components/Sidebar";
import useGlobalStyles from "hooks/useGlobalStyles";
import usePagination from "utils/usePagination";
import BiodataDiri from "./components/BiodataDiri";
import BiodataUsaha from "./components/BiodataUsaha";
import DashboardPelakuUsaha from "./components/DashboardPelakuUsaha";
import ListUploadSertifikat from "./components/ListUploadSertifikat";
import Page from "components/Page";
import statisticServices from "pages/Statistics/services";
import useParallaxHeader from "hooks/useParallaxHeader";
import { usahaData } from "redux/reducers/region";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import parseQueryString from "utils/parseQueryString";
import { useSelector } from "react-redux";
import PKPdanSertifikasi from "./components/PKPdanSertifikasi";
// import UploadSertifikat from './components/UploadSertifikat';

function Profile() {
  const urlSearch = useLocation().search;
  const location = useLocation();
  const optionsState = location.state;
  const dispatch = useDispatch();
  const [detailResponse, setResponse] = useState({
    data: [],
    vendor: [],
    kriteriaUsaha: [],
    terdaftarDiSikap: [],
    jenisPengadaan: [],
    sertifikat: [],
  });

  const { profile: user } = useSelector((state) => state.user);
  const userType = get(user, "type");

  const [value, setValue] = React.useState(0);

  // const pageAccessedByReload = (
  //   (window.performance.navigation && window.performance.navigation.type === 1) ||
  //     window.performance
  //       .getEntriesByType('navigation')
  //       .map((nav) => nav.type)
  //       .includes('reload')
  // );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [params, setParams] = useState({
    page: 1,
    limit: 3,
    total: 10,
  });

  const [total, setPagination] = usePagination();

  const backgroundPelakuUsaha = useParallaxHeader()?.map((data) => data)[4]
    ?.file_url;

  const backgroundKlpd = useParallaxHeader()?.map((data) => data)[5]?.file_url;

  useEffect(() => {
    if (userType === "klpd") {
      statisticServices.getCountUsahaByKriteriaUsaha({}, (data) =>
        setResponse((prev) => ({
          ...prev,
          kriteriaUsaha: data,
        }))
      );

      statisticServices.getTerdaftarDiSikap({}, (data) =>
        setResponse((prev) => ({
          ...prev,
          terdaftarDiSikap: data,
        }))
      );

      statisticServices.getJenisPengadaan({}, (data) =>
        setResponse((prev) => ({
          ...prev,
          jenisPengadaan: data,
        }))
      );
    }
    // eslint-disable-next-line
  }, [userType]);

  useEffect(() => {
    services.getVendorDetail(
      {
        user_id: user?.id,
        page: params.page,
        page_size: params.limit,
      },
      {
        success: (response) => {
          setResponse((prev) => ({ ...prev, vendor: response }));
          setPagination(3, response.total);
          dispatch(usahaData({ usaha: response?.data }));
        },
      },
      {
        error: (err) => {
          console.log(err);
        },
      }
    );
    // eslint-disable-next-line
  }, [value, params, user]);

  useEffect(() => {
    statisticServices.getCountUsahaByKriteriaUsaha({}, (data) =>
      setResponse((prev) => ({
        ...prev,
        kriteriaUsaha: data,
      }))
    );

    statisticServices.getTerdaftarDiSikap({}, (data) =>
      setResponse((prev) => ({
        ...prev,
        terdaftarDiSikap: data,
      }))
    );

    statisticServices.getJenisPengadaan({}, (data) =>
      setResponse((prev) => ({
        ...prev,
        jenisPengadaan: data,
      }))
    );

    setValue(urlSearch === "" ? 0 : Number(parseQueryString(urlSearch)?.tab));
    setValue(optionsState === "kalendar" ? 2 : 0);

    // eslint-disable-next-line
  }, [optionsState]);

  useEffect(() => {
    services.getSertifikat(
      {
        page_size: params.limit,
        page: params.page,
        user_id: user?.id,
      },
      {
        success: (data) => {
          setResponse((prev) => ({ ...prev, sertifikat: data }));
        },
      }
    );
  }, [user, params]);

  const classes = useGlobalStyles();

  const tabs = {
    pelaku_usaha: [
      {
        label: "Biodata Diri",
        component: <BiodataDiri biodata={user} />,
      },
      {
        label: "Biodata Usaha",
        component: (
          <BiodataUsaha
            biodata={user}
            listUsaha={detailResponse?.vendor?.data}
            count={total}
            onChange={(event, page) => {
              setParams((prev) => ({ ...prev, page: page }));
            }}
          />
        ),
      },
      {
        label: "Kegiatan dan Sertifikasi",
        component: <PKPdanSertifikasi biodata={user} />,
      },
      {
        label: "Upload Sertifikat",
        component: <ListUploadSertifikat />,
      },
    ],
    klpd: [
      {
        label: "Dashboard Pelaku Usaha",
        component: (
          <DashboardPelakuUsaha
            kriteriaUsaha={detailResponse?.kriteriaUsaha}
            terdaftarDiSikap={detailResponse?.terdaftarDiSikap}
            jenisPengadaan={detailResponse?.jenisPengadaan}
          />
        ),
      },
      {
        label: "Biodata Diri",
        component: <BiodataDiri biodata={user} />,
      },
    ],
  };

  const options = {
    pelaku_usaha: {
      breadcrumb: "Pelaku Usaha",
      title: "Pelaku Usaha",
    },
    klpd: {
      breadcrumb: "K-L-PD",
      title: "user K/L/PD",
    },
  };

  return (
    <Page title="Profile" className={classnames(classes.container)}>
      <Navigation />

      <div
        className="flex flex-col pt-4"
        style={{
          backgroundImage:
            userType === "pelaku_usaha"
              ? `url('${backgroundPelakuUsaha}')`
              : `url('${backgroundKlpd}')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <Breadcrumbs className="lg:px-20 px-6">
          <Link
            className="font-montserrat font-medium text-sm text-pink-header"
            to="/"
          >
            Beranda
          </Link>
          <Link
            className="font-montserrat font-medium text-sm text-border-on-pink"
            to={window.location.pathname}
            hash="/#"
          >
            Profile {options[userType]?.breadcrumb}
          </Link>
        </Breadcrumbs>
        <div className="mb-8 lg:mx-20 mx-6 pt-8 font-baloo2 font-bold text-3xl text-border-on-pink">
          Selamat Datang, {options[userType]?.title}!
          <div className="w-2/12 border-b-2 border-solid border-pink-header pb-10" />
        </div>
      </div>

      <div className="pt-10 lg:px-20 px-6 flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 min-h-screen pb-10">
        <Sidebar
          totalUsaha={user?.vendor_business_count}
          totalSertifikat={detailResponse?.sertifikat?.total}
        />

        <div className="2xl:w-10/12 xl:w-9/12 lg:w-9/12 w-11/12 mx-auto mb-32">
          <div
            style={{
              boxShadow:
                "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              allowscrollbuttonsmobile="true"
              aria-label="scrollable force tabs example"
              className="border-b-2 border-grey-300"
              indicatorColor="primary"
            >
              {tabs[userType]?.map((item, index) => (
                <Tab label={item.label} key={index} />
              ))}
            </Tabs>

            {tabs[userType]?.map((item, index) => {
              if (index === value) {
                return item.component;
              }
            })}
          </div>
        </div>
      </div>

      <Footer />
    </Page>
  );
}

export default Profile;
