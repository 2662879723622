import React from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@material-ui/core'

export default function Modal(props) {
  const { visible, onClose, children, ...others } = props

  return (
    <Dialog open={visible} onClose={onClose} {...others}>
      {children}
    </Dialog>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
}