import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userEmail: "",
}

const register = createSlice({
  name: 'REGISTER',
  initialState,
  reducers: {
    user_email: (state, action) => {
      state.userEmail = action.payload
    },
  }
})

export const { user_email } = register.actions

export default register.reducer