import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const TextField = React.forwardRef((props, ref) => {
  const { label, required, name, error, errorText, className, ...others } = props;

  const classInput = classnames(
    "py-2 px-2 w-full font-montserrat font-medium text-sm border border-solid focus:border-blue-second rounded",
    className,
  );

  return (
    <div>
      <div className="pt-4 flex">
        <div className="font-montserrat font-bold text-sm text-black-2">
          {label}
        </div>
        {required && (
          <div className="pl-1 font-sans font-bold text-xs text-pink-header">
            *
          </div>
        )}
      </div>

      <input name={name} className={classInput} ref={ref} min={others?.min} {...others} />

      {error && <div className="text-red-500 text-xs">{errorText}</div>}
    </div>
  );
});

export default TextField;

TextField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};
