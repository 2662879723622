import PropTypes from "prop-types";
import { usePagination } from "@material-ui/lab";
import classnames from "classnames";

function Pagination(props) {
  const { count, page, onChange, onProductDetail } = props;
  const { items } = usePagination({
    page,
    onChange,
    count,
  });

  return (
    <ul
      className={classnames(
        `flex items-center ${onProductDetail ? "xl:w-full" : "space-x-2"}`
      )}
    >
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null;

        if (type === "start-ellipsis" || type === "end-ellipsis") {
          children = "...";
        } else if (type === "page") {
          const classActive = classnames(
            "transition duration-150 font-montserrat font-medium focus:outline-none text-xs",
            {
              "text-search ease-in": !selected,
              "text-pink-header ease-out": selected,
            }
          );
          onProductDetail
            ? (children = (
                <button className={classActive} {...item}>
                  <img
                    src={props?.image?.[page - 1]}
                    className="w-14 px-2"
                    alt=""
                  />
                </button>
              ))
            : (children = (
                <>
                  <button
                    className={classnames(classActive, "px-4 py-2 rounded-sm")}
                    {...item}
                    style={{ backgroundColor: "#FAFAFA" }}
                  >
                    {page}
                  </button>
                </>
              ));
        } else if (type === "previous") {
          const { disabled } = { ...item };
          const classPrev = classnames(
            "p-2 items-center flex transition duration-150 ease-in-out focus:outline-none text-base font-custom font-semibold rounded",
            {
              "bg-pink-header": !disabled,
              "bg-welcome": disabled,
            }
          );
          const classPrevProductDetail = classnames(
            "p-2 items-center flex transition duration-150 ease-in-out focus:outline-none text-base font-custom font-semibold rounded-3xl",
            {
              "bg-pink-header": !disabled,
              "bg-welcome": disabled,
            }
          );
          onProductDetail
            ? (children = (
                <button
                  type="button"
                  className={classPrevProductDetail}
                  {...item}
                >
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 12 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.43733 8.99998L11.0373 15.6L9.152 17.4853L0.666668 8.99998L9.152 0.514648L11.0373 2.39998L4.43733 8.99998Z"
                      fill="#FEFEFE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              ))
            : (children = (
                <button className={classPrev} type="button" disabled={disabled} {...item}>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 12 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.43733 8.99998L11.0373 15.6L9.152 17.4853L0.666668 8.99998L9.152 0.514648L11.0373 2.39998L4.43733 8.99998Z"
                      fill="#FFFF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              ));
        } else if (type === "next") {
          const { disabled } = { ...item };
          const classNext = classnames(
            "p-2 flex items-center transition duration-150 ease-in-out focus:outline-none text-base font-custom font-semibold rounded",
            {
              "bg-pink-header": !disabled,
              "bg-welcome": disabled,
            }
          );
          onProductDetail
            ? (children = (
                <button
                  type="button"
                  className="bg-pink-header p-2 items-center flex transition duration-150 ease-in-out focus:outline-none text-base font-custom font-semibold rounded-3xl"
                  {...item}
                >
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 12 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.56265 8.99998L0.962646 2.39998L2.84798 0.514648L11.3333 8.99998L2.84798 17.4853L0.962646 15.6L7.56265 8.99998Z"
                      fill="#FEFEFE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              ))
            : (children = (
                <button className={classNext} type="button" {...item}>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 12 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.56265 8.99998L0.962646 2.39998L2.84798 0.514648L11.3333 8.99998L2.84798 17.4853L0.962646 15.6L7.56265 8.99998Z"
                      fill="#FFFF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              ));
        }

        return <li key={index}>{children}</li>;
      })}
    </ul>
  );
}

Pagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  variant: PropTypes.string,
  onChange: PropTypes.func,
};

Pagination.defaultProps = {
  onChange: () => {},
};

export default Pagination;
