export function convertObjectToFormData(planObject) {
  const formData = new FormData();

  Object.keys(planObject).forEach(name => {
    let value = planObject[name];

    if(value === null || value === undefined) {
      value = "";
    }
    
    formData.append(name, value);
  })

  return formData;
}