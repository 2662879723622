import React from "react";
import PropTypes from 'prop-types'
import { Helmet } from "react-helmet-async";

export default function Page(props) {
  const { children, title, description, ...others } = props;

  return (
    <div {...others}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}