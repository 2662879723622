import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import classnames from "classnames";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) =>
  createStyles({
    navItem: {
      fontSize: 10,
      fontFamily: "Montserrat",
      fontWeight: "normal",
      paddingBottom: 7,
      paddingTop: 7,
      paddingRight: 10,
      paddingLeft: 10,
    },
    navItemActive: {
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
  })
);

export default function Menu(props) {
  const classes = useStyles();
  const { children, content, className } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const classItem = classnames(classes.navItem, "flex items-center", {
    [classes.navItemActive]: anchorEl,
  });

  return (
    <div className={className}>
      <button
        className={classItem}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(ev) => setAnchorEl(ev.currentTarget)}
      >
        {children}
        <ArrowDropDownIcon
          className={classnames("transform", {
            "rotate-180": anchorEl,
            "rotate-0": !anchorEl,
          })}
        />
      </button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={0}
      >
        {content}
      </Popover>
    </div>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
};
