/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as services from "./services";
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
import { CenterModal } from "components/modal";
import LoginModal from "components/modal/components/Login";
import { useDispatch } from "react-redux";
import { displayModalSiginIn } from "redux/reducers/auth";

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string().required(),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null])
    .required(),
});

export default function ResetPasswordAction() {
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { code } = useParams();
  const dispatch = useDispatch()

  useEffect(() => {
    const params = {
      action: "token",
      code,
    };
    services.checkUser(params, (status, resp) => {
      if (status) {
        setShowAlert(false);
        setIsLoading(false);
      } else {
        setShowAlert(true);
        setAlertMessage("Link expired, silahkan request ulang");
      }
    });
  }, []);

  const { handleSubmit, getFieldProps, errors, touched } = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: (values) => {
      const params = {
        code,
        ...values,
      };

      setIsLoading(true);

      services.changePassword(params, (status, resp) => {
        setIsLoading(false);

        if (status) {
          setShowAlert(false);
          setStep(1);
        } else {
          setShowAlert(true);
          setAlertMessage("Gagal reset password");
        }
      });
    },
  });

  const [modalState, setModalState] = useState({
    loginModal: false,
  });

  const loginHandler = () => {
    setModalState((prev) => ({
      ...prev,
      loginModal: !modalState.loginModal,
    }));
  };

  return (
    <div className="lg:w-1/2 w-full mt-4 mb-8 border-l border-solid border-welcome pl-6">
      <div className="pt-4 pr-6">
        <div className="font-baloo2 font-bold text-3xl text-black-2">
          Reset Password
        </div>
      </div>
      <div className="pb-10 border-b border-solid border-pink-header w-1/2" />
      {step === 0 && (
        <>
          <div className="pr-6 font-montserrat font-medium text-xs text-black-2 pt-4">
            Silahkan reset password anda dengan memasukkan password yang baru
            untuk dapat masuk kembali ke akun anda
            {showAlert && (
              <div className="mt-2 p-2 rounded text-white text-xs bg-pink-header">
                {alertMessage}
              </div>
            )}
          </div>
          <form className="pt-4">
            <div className="flex">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Password Baru
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="password"
              type="password"
              {...getFieldProps("password")}
              // onChange={handleChange}
            />
            {touched.password && errors.password && (
              <div className="text-red-500 text-xs">{errors.password}</div>
            )}

            <div className="flex mt-4">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Ketik ulang password baru
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="password_confirmation"
              type="password"
              {...getFieldProps("password_confirmation")}
              // onChange={handleChange}
            />
            {touched.password_confirmation && errors.password_confirmation && (
              <div className="text-red-500 text-xs">
                {errors.password_confirmation}
              </div>
            )}

            <button
              onClick={handleSubmit}
              disabled={isLoading}
              type="button"
              className={`mt-8 py-2 px-4 font-montserrat text-xs font-medium rounded border-solid border ${
                isLoading
                  ? "bg-gray-300"
                  : "text-border-on-pink bg-pink-header border-pink-header"
              }`}
            >
              Reset password
            </button>
          </form>
        </>
      )}

      {step === 1 && (
        <>
          <div className="pr-6 font-montserrat font-medium text-xs text-black-2 pt-4">
            Selamat, password anda berhasil diubah!
          </div>
          <div className="pr-6 font-montserrat font-medium text-xs text-black-2 pt-4">
            Masuk ke BISA Pengadaan bersama jutaan pelaku usaha berpengalaman
            di bidangnya.
          </div>
          <div className="mt-5">
            <Link to="/">
              <button
                type="button"
                className="px-3 py-2 bg-black rounded text-white"
              >
                Ke Beranda
              </button>
            </Link>

            <button
              onClick={() => dispatch(displayModalSiginIn())}
              type="button"
              className="ml-3 px-3 py-2 bg-red-500 rounded text-white"
            >
              Masuk ke BISA Pengadaan
            </button>
          </div>
        </>
      )}
    </div>
  );
}
