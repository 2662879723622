import { makeStyles, createStyles } from "@material-ui/styles";

const useGlobalStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      marginTop: 100,
    },
  });
});

export default useGlobalStyles;
