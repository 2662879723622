// export const api = 'https://vd.demo-kota.com/api';
// export const base_url = 'https://vd.demo-kota.com';
export const base_url = process.env.REACT_APP_API_URL;
// export const base_url = 'http://localhost:8000';
export const api = `${base_url}/api`;
export const apiStatistics = `${base_url}/api/statistic`;
export const api_homepage = "http://localhost:8000/api/homepage";
export const reCaptcha =
  "$2y$10$qmagQNReBigQlx.WB6jhEu1.OkL4WQ4qVQeQFpcwIyi.CtwwwAkAi";

// export const apiInaprocPengadaanLangsung = `https://inaproc.lkpp.go.id/isb/api/4da0c697-b1d9-4133-8865-aae675bc8530/json/678387789/D13VDrekapRUPukmPL/tipe/4:4:4/parameter/`;
// export const apiInaprocPUKecil = `https://inaproc.lkpp.go.id/isb/api/678b138f-0e56-4e54-aa0b-7239b4244279/json/678386607/D13VDrekapRUPukmTender/tipe/4:4:4/parameter/`;
// export const apiInaprocPUNonKecil = `https://inaproc.lkpp.go.id/isb/api/c9e21e2d-7b5a-43bf-8cd6-474d34612ec3/json/678382673/D13VDrekapRUPTenderNonUKM/tipe/4:4:4/parameter/`;
// export const apiInaprocPUNonKecil = `https://inaproc.lkpp.go.id/isb/api/c9e21e2d-7b5a-43bf-8cd6-474d34612ec3/json/678382673/D13VDrekapRUPTenderNonUKM/tipe/4:4:4/parameter/`;

const endpoint = {
  // User Statistics
  // userStats: `${api_homepage}/get_data_visitor_statistics`,

  //Pranala
  // pranala: `${api_homepage}/get_data_pranala`,

  // services
  cekSikap: `${api}/services/sikap/check`,

  //Login (Auth)
  login: `${api}/auth/login`,
  register: `${api}/auth/register`,
  verification: `${api}/auth/verification`,
  resendVerification: `${api}/auth/verification/resend`,

  forgotPasswordCheck: `${api}/auth/forgot-password/check`,
  forgotPasswordSend: `${api}/auth/forgot-password/send`,
  forgotPasswordReset: `${api}/auth/forgot-password/reset`,

  //Logout (Auth)
  logout: `${api}/auth/logout`,

  //Profile
  profile: `${api}/auth/profile`,

  //Captcha
  captcha: `${base_url}/captcha/api/default`,
  captchaSound: `${base_url}/captcha/media/sound`,

  // Search
  search: `${api}/vendor`,

  //Provinsi
  provinsi: `${api}/wilayah/provinsi`,
  kabupaten: `${api}/wilayah/kabupaten`,
  kecamatan: `${api}/wilayah/kecamatan`,
  kelurahan: `${api}/wilayah/kelurahan`,

  // Pendidikan
  pendidikan: `${api}/options/pendidikan`,

  //Aktifitas Usaha
  aktifitas: `${api}/usaha/aktifitasUsaha`,

  //Bentuk Usaha
  usaha: `${api}/usaha/bentukUsaha`,

  //Kriteria Usaha
  kriteria: `${api}/usaha/kriteriaUsaha`,

  //Jenis Pengadaa
  pengadaan: `${api}/usaha/jenisPengadaan`,

  //Pengalaman PBJP
  pbjp: `${api}/usaha/pengalamanPBJP`,

  // Instansi & Lainnya
  organisasi: `${api}/instansi/organisasi`,
  kementrian: `${api}/instansi/kementrian`,
  lembaga: `${api}/instansi/lembaga`,
  pemda: `${api}/instansi/pemda`,
  allInstansi: `${api}/instansi/all`,

  // Kabar Usaha
  kabarUsaha: `${api}/kabar_usaha`,

  // Kalendar Kegiatan
  kalendar: `${api}/kegiatan-pkp`,
  listkalendar: `${api}/kegiatan/list`,
  registerKegiatan: `${api}/kegiatan/register`,
  checkKodeKegiatan: `${api}/kegiatan/check`,
  checkKegiatanAttendance: `${api}/kegiatan/attendance`,
  sertifikatKegiatan: `${api}/sertifikat-kegiatan`,

  //Carousel
  carousel: `${api}/carousel/homepage`,
  carouselBeritaUsaha: `${api}/carousel/berita-usaha`,

  //Pranala Luar
  pranala: `${api}/pranala-luar`,

  //Statistic
  countPelakuUsaha: `${apiStatistics}/countPelakuUsaha`,
  statisticJenisPengadaan: `${apiStatistics}/jenisPengadaan`,
  terdaftarDiSikap: `${apiStatistics}/registeredSikap`,
  statisticKriteriaUsaha: `${apiStatistics}/kriteriaUsaha`,
  countUsahaByKriteriaUsaha: `${apiStatistics}/countUsahaByKriteriaUsaha`,
  tableDataOption: `${apiStatistics}/tableData/options?type=chart`,
  tableDataYear: `${apiStatistics}/tableData/options?type=year`,
  tableDataFilter: `${apiStatistics}/tableData/options?type=filter`,
  tableData: `${apiStatistics}/tableData`,
  exportTableData: `${apiStatistics}/tableData/export`,

  peluangPBJP: `${apiStatistics}/peluangPbjp`,

  // Video PBJP
  infoPbjp: `${api}/video-informasi-pbjp`,
  bagianPbjp: `${api}/video-informasi-pbjp/bagian`,

  //Statistik INAPROC
  // inaprocPengadaanLangsung: `${apiInaprocPengadaanLangsung}`,
  // inaprocPUKecil: `${apiInaprocPUKecil}`,
  // inaprocPUNonKecil: `${apiInaprocPUNonKecil}`,

  //Tautan Pintas
  tautanPintas: `${api}/tautan-pintas`,

  //FAQ
  faq: `${api}/faq`,

  //Parallax Header
  parallaxHeader: `${api}/parallax-header`,

  beritaUsaha: `${api}/berita-usaha`,
  // Lokasi usaha per group
  lokasiUsahaGroup: `${api}/vendor/groupByLocation`,

  // Hubungi Kami
  contactUs: `${api}/email-ke-kami`,

  // tentang-kami
  aboutUs: `${api}/tentang-kami`,

  // tutorial system
  tutorialSystem: `${api}/tutorial`,

  // Lokasi
  exportLokasi: `${api}/vendor/export`,

  // Visitor
  visitor: `${api}/statistic/visitor`,

  // Kabar Pelaku Usaha
  kabarPelakuUsaha: `${api}/kabar-pelaku-usaha`,

  // Broadcast
  broadcast: `${api}/broadcast`,
  broadcastCount: `${api}/broadcast/count`,

  //Sertifikat
  sertifikat: `${api}/sertifikat-non-pkp`,

  //Settings
  settings: `${api}/settings`,

  // table dashboard
  dashboard: `${api}/statistic/tableData`,
  statistik_kegiatan: `${api}/statistic/statistik_kegiatan`,
  statistik_jenis_kelamin: `${api}/statistic/jns-kelamin-pu`,
  statistik_oap: `${api}/statistic/statistic-oap`,
  statistik_tracking: `${api}/statistic/tracking_penawaran`,
  statistik_pengalaman: `${api}/statistic/pengalaman_bpjp`,
};

export default endpoint;
