/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { parseISO, format } from "date-fns";
import { id } from "date-fns/locale";
import * as services from "../../News/services";
export default function DataUsaha(props) {
  const { className, detailTautanPintas } = props;
  const [listNews, setListNews] = useState([]);

  useEffect(() => {
    services.getBeritaUsaha(
      { page_size: 3, sort: "random", jenis_peluang: "Beranda" },
      ({ data }) => {
        setListNews(data);
      }
    );
  }, []);

  return (
    <div
      className={classnames(
        className,
        "pt-10 xl:px-20 lg:px-18 px-6 pb-12 flex xl:flex-row flex-col xl:space-y-0 space-y-3"
      )}
      id="data"
    >
      <div className="xl:w-1/2 w-full xl:border-r border-solid border-welcome xl:pr-6">
        <div className="flex justify-between">
          <div className="font-baloo2 font-bold text-3xl text-black-2">
            Berita Usaha
          </div>
          <Link to="/news">
            <button className="bg-pink-header xl:px-4 xl:py-2 px-1 text-neutral-grey font-montserrat font-medium text-xs rounded ml-4">
              Selengkapnya
            </button>
          </Link>
        </div>
        <div className="pt-10 border-b border-solid border-pink-header w-2/12" />

        <Box className="mt-3">
          {listNews.map((item) => {
            const dateParse = item?.updated_at && parseISO(item?.updated_at);

            return (
              <Link to={`/news_detail/${item?.id}`}>
                <div className="py-6 border-b border-solid border-welcome flex lg:flex-row flex-col w-full">
                  <img
                    src={item?.thumbnail_url}
                    alt=""
                    className="h-auto lg:w-64 w-full"
                  />
                  <div className="lg:pl-6 pl-0 lg:pt-0 pt-4 flex flex-col">
                    <div className="font-baloo2 line-clamp-3 font-bold text-base text-black-2">
                      {item?.judul}
                    </div>
                    <div className="font-montserrat font-medium text-sm text-search pt-2">
                      {format(dateParse, "dd MMM yyyy H:mm", {
                        locale: id,
                      })}
                    </div>
                    <div className="pt-2 border-b-2 border-solid border-pink-header w-2/12" />
                    <div className="font-montserrat font-medium text-xs text-black-2 pt-3 line-clamp-1">
                      {item?.short_deskripsi}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </Box>
      </div>

      <div className="xl:pl-6 xl:w-1/2 w-full">
        <div className="flex justify-between">
          <div className="font-baloo2 font-bold text-3xl text-black-2">
            Tautan Pintas
          </div>
        </div>
        <div className="pt-10 border-b border-solid border-pink-header w-3/12" />

        {detailTautanPintas?.map((data, index) => {
          if (index === 3) {
            return;
          }

          return (
            <Box
              key={index}
              component={Link}
              to={`${
                data?.id === 1
                  ? "/kabar_pelaku_usaha"
                  : data?.id === 2
                  ? "/lokasi_usaha"
                  : data?.id === 3
                  ? "/kalendar"
                  : "/news"
              }`}
              className="font-baloo2 font-bold text-base text-black-2 flex mt-8 items-center border rounded pl-4 w-full py-5 container-inner group"
              style={{
                boxShadow:
                  "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
                background: "#FFFEFF",
              }}
            >
              <img
                src={data?.file_url}
                className="border-2 border-solid border-pink-header box-border rounded-special p-3 w-14 circle transform transition duration-200 scale-100 group-hover:scale-125"
                alt=""
              />
              <div className="pl-4">{data?.description}</div>
            </Box>
          );
        })}
      </div>
    </div>
  );
}

DataUsaha.propTypes = {
  detailStatisticKriteriaUsaha: PropTypes.array,
  detailTerdaftarDiSikap: PropTypes.array,
  detailJenisPengadaan: PropTypes.array,
  detailTautanPintas: PropTypes.array,
};

DataUsaha.defaultProps = {
  detailStatisticKriteriaUsaha: [],
  detailTerdaftarDiSikap: [],
  detailJenisPengadaan: [],
  detailTautanPintas: [],
};
