import React from "react";
import { Box, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Link, useLocation,useHistory } from 'react-router-dom'
import parseQueryString from "utils/parseQueryString";
import { DateTime } from "luxon";
import * as services from "../services";
import PropTypes from "prop-types";

export default function ViewPesan(props) {
  const {
    params,
    tab,
    setResponse: setResponseData,
    setPagination,
  } = props;
  const urlId = Number(parseQueryString(useLocation().search)?.messageId);
  const [response, setResponse] = React.useState({
    data: []
  })
  const history = useHistory()
  const urlSearch = useLocation().search;

  React.useEffect(() => {

    services.getBroadCastDetail({
      id: urlId,
    }, {
      success: (response) => {
        setResponse(prev => ({...prev, data: response}))
      }, 
      error: (err) => {
        console.log(err);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    services.postBroadCastRead({
      id: urlId,
      status: response?.data?.data?.status
    }, {
      success: (response) => {
        console.log(response)
      }, 
      error: (err) => {
        console.log(err);
      }
    })
  }, [response?.data?.data?.status])

  const onDeleteMessage = () => {
    services.deleteBroadCast({
      id: urlId,
    } , {
      success: (response) => {
        console.log(response);
      },
      error: (err) => {
        console.log(err);
      }
    })

    services.getBroadCast({
      status: tab,
      page: params.page,
      page_size: 5,
      q: params.q,
      type: params.type
    }, {
      success: (response) => {
        setResponseData(prev => ({...prev, data: response}));
        setPagination(5, response.total);
      }, 
      error: (err) => {
        console.log(err);
      }
    })

    history.replace(`/pesan?tab=${parseQueryString(urlSearch)?.tab}`)
  }

  return (
    <Box sx={{ px: 4, py: 3 }}>
      <Box className="flex items-center justify-between mb-3 space-x-4 sm:space-x-1">
        <Button
          startIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M5.828 7l2.536 2.536L6.95 10.95 2 6l4.95-4.95 1.414 1.414L5.828 5H13a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H5.828z" />
            </svg>
          }
          variant="outlined"
          component={Link}
          to="/pesan?tab=all"
        >
          Kembali
        </Button>

        <Button onClick={onDeleteMessage} startIcon={<CloseIcon />} variant="outlined" color="primary">
          Hapus
        </Button>
      </Box>

      <Box>
        <div>
          <span className="text-xl font-semibold font-baloo2 text-black-2">
            {response?.data?.data?.subject}
          </span>

          <div className="flex items-center space-x-3 divide-x divide-gray-200 py-3 border-b border-gray-200">
            <div className="flex items-center space-x-3">
              <img
                src={response?.data?.data?.created_by?.profile_picture_url}
                className="w-8 h-8 rounded-full"
                alt=""
              />
              <span className="text-sm text-search font-montserrat">
                {response?.data?.data?.created_by?.full_name}
              </span>
            </div>

            <p className="text-sm text-search pl-2 font-montserrat">
              {DateTime.fromISO(response?.data?.data?.published_at).toFormat("dd LLLL yyyy", {locale: "id"})}
            </p>
          </div>

          <div className="font-montserrat text-black-2 text-sm py-3">
            <div dangerouslySetInnerHTML={{__html: `${response?.data?.data?.body}`}} className="ck-content">
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}

ViewPesan.propTypes = {
  params: PropTypes.object,
  tab: PropTypes.number,
  setResponse: PropTypes.func,
  setPagination: PropTypes.func,
};

ViewPesan.defaultProps = {
  params: {},
  tab: 0,
  setResponse: () => {},
  setPagination: () => {},
};