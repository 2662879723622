import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import MailIcon from "@material-ui/icons/Mail";
import LanguageIcon from "@material-ui/icons/Language";
import LaunchIcon from "@material-ui/icons/Launch";
import CreateIcon from "@material-ui/icons/Create";
import { ThemeProvider, createTheme } from "@material-ui/core";
import theme from "theme";

export default function UsahaCard(props) {
  const { data, disabledLink, profileLink } = props;

  const WithLink = ({ isDisabled, children, isProfile }) => {
    if (isDisabled) {
      return children;
    } else if (isProfile) {
      return (
        <Link to={`/product_detail/${data?.id}/${isProfile}`}>{children}</Link>
      );
    }

    return <Link to={`/product_detail/${data?.id}`}>{children}</Link>;
  };

  const urlToDetail = React.useMemo(() => {
    if (profileLink) {
      return `/product_detail/${data?.id}/${profileLink}`
    }

    return `/product_detail/${data?.id}`
  }, [data?.id, profileLink])

  const urlToEdit = `/company_edit/${data?.id}`

  return (
    <div className="flex flex-col items-center">
      <div className="bg-neutral-grey p-6 w-full fk flex-1">
        <div className="bg-white p-6 flex lg:flex-row flex-col">
          <img
            src={data?.logo_picture_url}
            alt=""
            className="pr-4 w-32 h-28 object-cover lg:text-left text-center"
          />
          <div className="flex lg:flex-row flex-col justify-between w-full lg:mt-0 mt-6">
            <div className="flex flex-col lg:pl-4 pl-0 border-l border-none lg:border-solid border-welcome lg:mb-0 mb-6">
              <WithLink isDisabled={disabledLink} isProfile={profileLink}>
                <div className="font-montserrat font-extrabold text-black-2 text-lg pb-2">
                  {data?.name}
                </div>
                <div className="table pr-1 pb-4">
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pb-2 font-arial font-bold text-black-2 text-sm">
                      Bentuk
                    </span>
                    :<span className="pl-2">{data?.business_type?.name}</span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pb-2 font-arial font-bold text-black-2 text-sm">
                      Aktivitas
                    </span>
                    :
                    <span
                      className="pl-2 -mt-5"
                      style={{ overflowWrap: "break-word", wordWrap: "break-word" }}
                    >
                      {data?.business_activities
                        ?.map((data) => data.name)
                        .join(", ")}
                    </span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pb-2 font-arial font-bold text-black-2 text-sm">
                      KBLI
                    </span>
                    :<span className="pl-2">-</span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell	pb-2 font-arial font-bold text-black-2 text-sm">
                      Kriteria
                    </span>
                    :
                    <span className="pl-2">
                      {data?.business_criteria?.name}
                    </span>
                  </div>
                  <div className="table-row	font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pb-2	font-arial font-bold text-black-2 text-sm">
                      Pengalaman
                    </span>
                    :
                    <span className="pl-2">{data?.tahun_pengalaman} tahun</span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pr-2 pb-2 font-arial font-bold text-black-2 text-sm">
                      Jenis Pengadaan
                    </span>
                    :
                    <span className="pl-2">{data?.procurement_type?.name}</span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pb-2 font-arial font-bold text-black-2 text-sm">
                      Komoditas
                    </span>
                    :<span className="pl-2">-</span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-sm text-neon-blue">
                    <span className="table-cell pb-2	font-arial font-bold text-black-2 text-sm">
                      Sertifikasi
                    </span>
                    :<span className="pl-2">{data?.sertifikat_non_pkp_count} sertifikasi</span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pb-2 font-arial font-bold text-black-2 text-sm">
                      Status
                    </span>
                    :<span className="pl-2">{data?.status_text}</span>
                  </div>
                </div>
              </WithLink>

              <div className="flex flex-row items-start pt-2 font-arial font-normal text-sm text-black-2">
                <LocationOnIcon color="primary" />
                <div className="font-medium pl-2 capitalize">
                  {data?.city?.name?.toLowerCase()},{" "}
                  {data?.province?.name?.toLowerCase()}
                </div>
              </div>
              <div className="flex flex-row items-center pt-2">
                <LocalPhoneIcon color="primary" />
                <a
                  href={`tel:${data?.phone}`}
                  className="px-2 font-arial font-normal text-sm text-black-2"
                >
                  {data?.phone}
                </a>
              </div>

              <div className="flex underline flex-row items-center pt-1">
                <MailIcon color="primary" />
                <a
                  href={`mailto:${data?.email}`}
                  className="px-2 font-arial font-normal text-sm text-black-2"
                  style={{ color: "#8C8C8C" }}
                >
                  {data?.email}
                </a>
              </div>

              <div className="flex underline flex-row items-center pt-1">
                <LanguageIcon color="primary" />
                <a
                  href={`${data?.website}`}
                  className="px-2 font-arial font-normal text-sm text-black-2"
                  style={{ color: "#8C8C8C" }}
                >
                  {data?.website}
                </a>
              </div>
            </div>
            <div className="flex flex-col space-y-3">
              <ThemeProvider
                theme={createTheme({
                  ...theme,
                  palette: { primary: { main: "#0073B7" } },
                })}
              >
                <Button
                  startIcon={<LaunchIcon />}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={urlToDetail}
                >
                  Detail
                </Button>
              </ThemeProvider>
              <Button
                startIcon={<CreateIcon />}
                variant="contained"
                color="secondary"
                component={Link}
                to={urlToEdit}
              >
                Ubah
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

UsahaCard.propTypes = {
  data: PropTypes.object,
  disabledLink: PropTypes.bool,
};

UsahaCard.defaultProps = {
  disabledLink: false,
};
