import React from "react";
import { Box } from "@material-ui/core";
import Breadcrumb from "components/Breadcrumb";
import Footer from "components/footer";
import Navigation from "components/Navigation";
import useGlobalStyles from "hooks/useGlobalStyles";
import KabarUsahaCard from "components/KabarUsahaCard";
import Pagination from "components/pagination";
import { getKabarUsaha } from "redux/reducers/kabarUsaha";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import Page from 'components/Page'
import KabarView from "./components/KabarView";
import useParallaxHeader from "hooks/useParallaxHeader";
import usePagination from "utils/usePagination";

export default function KabarPelakuUsaha() {
  const classes = useGlobalStyles();

  const { kabarUsaha } = useSelector((state) => state.kabarUsaha);
  const [openKabarView, setOpenKabarView] = React.useState({
    visible: false,
    options: { id: null },
  });

  const [total, setPagination] = usePagination();

  const [params, setParams] = React.useState({
    page: 0,
  })

  const totalList = get(kabarUsaha, "total", 0);
  const currentPage = get(kabarUsaha, "current_page", 1);
  const usahaList = get(kabarUsaha, "data", []);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getKabarUsaha());
    setPagination(9, totalList)
    setParams(prev => ({...prev, page: currentPage}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const background = useParallaxHeader()?.map(data => data)[2]?.file_url;

  return (
    <Page title="Kabar Pelaku Usaha">
      <Box className={classes.container}>
        <Navigation />

        <div
          className="flex flex-col py-4"
          style={{
            backgroundImage: `url('${background}')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
          }}
        >
          <Breadcrumb
            classLast="text-white"
            className="lg:px-20 px-6"
            links={[
              { href: "/", name: "Beranda" },
              { name: "Kabar Pelaku Usaha" },
            ]}
          />

          <div className="mt-10 lg:px-20 px-6">
            <div className="mb-8 font-baloo2 font-bold text-3xl text-white">
              Kabar Pelaku Usaha
              <div className="border-b-2 border-solid border-pink-header lg:w-1/12 w-4/12 mb-8 pt-10" />
            </div>
          </div>
        </div>

        <div className="lg:w-9/12 w-10/12 mx-auto py-10 min-h-screen">
          <div className="pb-10 grid 2xl:grid-cols-4 xl:grid-cols-3 gap-4">
            {usahaList.map((_item) => (
              <KabarUsahaCard
                id={_item.id}
                key={_item.id}
                name={_item.name}
                history={_item.listChanges}
                updatedAt={_item.updated_at}
                data={_item}
                onShowView={() =>
                  setOpenKabarView({ visible: true, options: { id: _item.id } })
                }
              />
            ))}
          </div>

          <div className="flex justify-center">
            <Pagination
              count={total}
              page={params.page}
              onChange={(e, newValue) => setParams(prev => ({...prev, page: newValue}))}
            />
          </div>
        </div>

        <KabarView
          visible={openKabarView.visible}
          onClose={() =>
            setOpenKabarView(prev => ({ ...prev, visible: false}))
          }
          data={usahaList}
          id={openKabarView.options.id}
        />
        <Footer />
      </Box>
    </Page>
  );
}
