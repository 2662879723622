import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: theme.palette.grey[200],
    },
  })
);

const TextField = React.forwardRef((props, ref) => {
  const { label, required, name, type, error, errorText, className, ...others } = props;
  const [visibility, setVisibility] = React.useState(false);

  const classInput = classnames(
    "py-2 px-4 w-full font-montserrat font-medium text-sm border border-solid focus:border-blue-second rounded",
    className,
    // classes.root,
  );

  return (
    <div className="w-full">
      {label && (
        <div className="pt-4 flex">
          <div className="font-montserrat font-bold text-sm text-black-2">
            {label}
          </div>
          {required && (
            <div className="pl-1 font-sans font-bold text-xs text-pink-header">
              *
            </div>
          )}
        </div>
      )}

      <div className="relative w-full">
        <input
          name={name}
          type={visibility ? "text" : "password"}
          className={classInput}
          ref={ref}
          {...others}
        />

        {visibility ? (
          <Visibility
            className="absolute my-auto top-0 bottom-0 right-0 mr-2 cursor-pointer"
            onClick={() => setVisibility(false)}
          />
        ) : (
          <VisibilityOff
            className="absolute my-auto top-0 bottom-0 right-0 mr-2 cursor-pointer"
            onClick={() => setVisibility(true)}
          />
        )}
      </div>

      {error && <div className="text-red-500 text-xs">{errorText}</div>}
    </div>
  );
});

export default TextField;

TextField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};
