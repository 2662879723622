import React from "react";
import { Link } from "react-router-dom";
import { Box, Tabs, Tab } from "@material-ui/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import get from "lodash/get";
import { makeStyles, createStyles } from "@material-ui/styles";
import classNames from "classnames";
import endpoint from "utils/endpoint";
import axios from "axios";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: "Baloo 2",
      fontWeight: 700,
      color: "#595959",
      fontSize: 18,
    },
  })
);

export default function DataStatistikKalendar() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedYear, setSelectedYear] = React.useState(0);
  const [listDataStatistikKalendar, setDataStatistikKalendar] =
    React.useState([]);

  React.useState(() => {
    const getDataKalendar = async () => {
      const url = endpoint.statistik_kegiatan;

      const { data: response } = await axios.get(url);

      setDataStatistikKalendar(response);
    };

    getDataKalendar();
  }, []);

  const listForViews = get(listDataStatistikKalendar, [selectedYear, 'data'], []);

  React.useLayoutEffect(() => {
    var chart = am4core.create("data-statistic-kalendar", am4charts.XYChart);

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.min = 0;

    var label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.minWidth = 420;
    label.textAlign = "end"

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());

    var series2 = chart.series.push(new am4charts.ColumnSeries3D());

    series2.dataFields.categoryY = "name";
    series2.dataFields.valueX = "value";
    series2.name = "";
    series2.columns.template.strokeOpacity = 0.2;

    var bullet2 = series2.bullets.push(new am4charts.LabelBullet());
    bullet2.interactionsEnabled = false;
    bullet2.dx = -40;
    bullet2.label.text = "{valueX}";
    bullet2.label.fill = am4core.color("#000");
    bullet2.label.horizontalCenter = "left";

    var colors_list = [
      am4core.color("#A569BD"),
      am4core.color("#00A65A"),
      am4core.color("#0073B7"),
      am4core.color("#F56954"),
      am4core.color("#EB984E"),
      am4core.color("#F7DC6F"),
      am4core.color("#A569BD"),
      am4core.color("#00A65A"),
      am4core.color("#0073B7"),
      am4core.color("#F56954"),
      am4core.color("#EB984E"),
    ];
    series2.columns.template.adapter.add("fill", function (fill, target) {
      if (target.dataItem.index > 9)
        return chart.colors.getIndex(target.dataItem.index);
      return colors_list[target.dataItem.index];
    });

    chart.data = listForViews;
    categoryAxis.sortBySeries = series2;
  });

  const classDataYear =
    "flex flex-1 flex-col font-montserrat font-medium text-xs";

  return (
    <div>
      <div>
        <span className={classes.title}>Statistik Kalendar Kegiatan</span>
      </div>

      <Box boxShadow={3} className="mt-3">
        <div className="text-black-2 flex justify-center text-sm pt-4">
          PU yang Mendaftar di Kegiatan
        </div>
        <div id="data-statistic-kalendar" style={{ width: '100%', height: 500 }}></div>
        <div className="flex flex-row justify-between">
          {listDataStatistikKalendar.map((data, index) => (
            <Box
              key={index}
              className={classNames(classDataYear, "px-4 py-2", {
                "text-pink-header": selectedYear === index,
                "text-black-2": selectedYear !== index,
              })}
              onClick={() => setSelectedYear(index)}
              component="button"
              sx={{
                bgcolor: selectedYear === index ? "grey.200" : "grey.300",
              }}
            >
              <p className="text-lg font-semibold">{data?.year}</p>
              <p className="text-sm">{data?.count}</p>
            </Box>
          ))}
        </div>
      </Box>
    </div>
  );
}
