import React from "react";
import {
  ThemeProvider,
  createTheme,
  Box,
  Button,
  SvgIcon,
} from "@material-ui/core";
import theme from "theme";

import Pagination from "components/pagination";
import UploadSertifikat from "./UploadSertifikat";
import services from "../services";
import usePagination from "utils/usePagination";
import Dialog from "components/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      display: "flex",
      width: "100%",
      alignItems: "center",
    },
  })
);

export default function ListUploadSertifikat(props) {
  const [showUpload, setUpload] = React.useState(false);
  const [detailResponse, setResponse] = React.useState({
    data: [],
  });
  const { profile: user } = useSelector((state) => state.user);
  const classDialog = useStyles();

  const history = useHistory();

  const [params, setParams] = React.useState({
    page: 1,
    limit: 3,
    total: 10,
  });

  const [message, setMessage] = React.useState({
    open: false,
    severity: "",
    message: "",
  });

  const [total, setPagination] = usePagination();

  const handleGetSertifikat = () => {
    services.getSertifikat(
      {
        page_size: params.limit,
        page: params.page,
        user_id: user?.id,
      },
      {
        success: (data) => {
          setResponse((prev) => ({ ...prev, data: data.data }));
          setPagination(3, data.total);
        },
        error: (err) => {
          console.log(err);
        },
      }
    );
  };

  React.useEffect(() => {
    handleGetSertifikat();
    // eslint-disable-next-line
  }, [params]);

  // console.log(params)

  const handleDeleteSertifikat = (data) => {
    const params = {
      id: data?.id,
      vendor_business_id: data?.vendor_business_id,
      jenis_sertifikat: data?.jenis_sertifikat,
      lembaga_penerbit: data?.lembaga_penerbit,
      berlaku_mulai: data?.berlaku_mulai,
      berlaku_hingga: data?.berlaku_hingga,
      is_selamanya: data?.is_selamanya,
    };
    services.deleteSertifikat(params, (response) => {
      if (response === true) {
        setMessage({
          open: true,
          severity: "success",
          message: "Berhasil Menghapus Sertifikat!",
        });
        window.timeout = setTimeout(() => {
          window.location.reload();
        }, 1500);
        history.push(`/profile?tab=2`);
        handleGetSertifikat();
      } else {
        setMessage({
          open: true,
          severity: "error",
          message: "Gagal Menghapus Sertifikat!",
        });
      }
    });
  };

  return (
    <Box>
      <Box className="flex items-center justify-between" sx={{ px: 4, py: 4 }}>
        <div>
          <span className="text-base font-baloo2 text-black-2 font-bold">
            Ingin menambahkan sertifikasi?
          </span>
        </div>
        {/* {isLoading && <CircularProgress color="inherit" size={20} />} */}

        <ThemeProvider
          theme={createTheme({
            ...theme,
            palette: { primary: { main: "#00A65A" } },
          })}
        >
          <Button
            startIcon={
              <SvgIcon>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zm9-10v7h-2V9H6l6-6 6 6h-5z" />
              </SvgIcon>
            }
            variant="contained"
            color="primary"
            onClick={() => setUpload(true)}
          >
            Upload Sertifikat
          </Button>
        </ThemeProvider>
      </Box>
      {detailResponse?.data?.map((data) => (
        <Box
          className="flex flex-col"
          sx={{ bgcolor: "grey.200", px: 4, py: 2 }}
        >
          <Box
            className="flex items-center justify-between bg-white border border-welcome rounded-lg"
            sx={{ py: 2, px: 3 }}
          >
            <Box>
              <span className="block text-2xl font-semibold font-baloo2 text-black-2">
                Sertifikasi {data?.jenis_sertifikat}
              </span>
              <table className="text-black-2 text-base font-montserrat">
                <tr>
                  <td className="font-bold">Berlaku</td>
                  <td className="px-5">:</td>
                  <td>
                    {data?.is_selamanya ? "Selamanya" : data?.berlaku_hingga}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">Usaha</td>
                  <td className="px-5">:</td>
                  <td>{data?.vendor_business?.name}</td>
                </tr>
              </table>
            </Box>
            <Box className="flex flex-col space-y-3">
              <Button
                startIcon={
                  <SvgIcon>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm6.414 8l1.768-1.768-1.414-1.414L12 12.586l-1.768-1.768-1.414 1.414L10.586 14l-1.768 1.768 1.414 1.414L12 15.414l1.768 1.768 1.414-1.414L13.414 14zM9 4v2h6V4H9z" />
                  </SvgIcon>
                }
                variant="contained"
                color="primary"
                onClick={() => handleDeleteSertifikat(data)}
              >
                Hapus
              </Button>

              <ThemeProvider
                theme={createTheme({
                  ...theme,
                  palette: { primary: { main: "#00A65A" } },
                })}
              >
                <Button
                  startIcon={
                    <SvgIcon>
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    window.open(`${data?.file_url}`, "_blank");
                  }}
                >
                  Sertifikat
                </Button>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
      ))}
      <Box className="flex items-center justify-center" sx={{ py: 3 }}>
        <Pagination
          count={total}
          onChange={(event, page) => {
            setParams((prev) => ({ ...prev, page: page }));
          }}
        />
      </Box>

      <Dialog
        visible={message.open === true}
        onClose={message.open !== true}
        className="mt-3"
      >
        <Alert severity={message.severity} className={classDialog.dialog}>
          {message.message}
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setMessage({ open: false, error: "", message: "" });
            }}
          >
            <CloseIcon alt="" className="ml-3 self-center" />
          </IconButton>
        </Alert>
      </Dialog>

      <UploadSertifikat
        visible={showUpload}
        onHandleReload={() => handleGetSertifikat()}
        onClose={() => setUpload(false)}
      />
    </Box>
  );
}
