import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import Carousel from "components/carousel";

const settingsPranala = {
  dots: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerPadding: 30,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  dotsClass: "slick-dots-home",
  customPaging: (i) => {
    return <div></div>;
  },
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 10,
    },
    thumbnail: {
      height: 40,
      width: "auto",
    },
  })
);

export default function PranataLuar(props) {
  const classes = useStyles();

  return (
    <div className="pt-10 xl:px-20 lg:px-18 px-6">
      <div className="font-baloo2 font-bold text-3xl text-black-2">
        Pranala Luar
      </div>
      <div className="border-b border-solid border-pink-header w-1/12 pb-8" />
      <Carousel {...settingsPranala} className="py-8">
        {props?.data?.map((data, index) => (
          <Box className={classes.root} component="div" key={data.id}>
            <Box
              className="space-y-3"
              bgcolor="grey.200"
              sx={{ p: 2, borderRadius: 8 }}
            >
              <a href={data.link} target="_blank" rel="noreferrer">
                <img
                  src={data.picture_url}
                  alt=""
                  className={classes.thumbnail}
                />
                <Box sx={{ borderTop: "1px solid #ccc", pt: 2, mt: 2 }}>
                  <h5 className="font-baloo2 text-xl font-bold text-black-2">
                    {data?.title}
                  </h5>
                  <div className="font-baloo2 text-base font-normal text-black-2 mt-2">
                    {data?.link?.replace("https://", "")}
                  </div>
                </Box>
              </a>
            </Box>
          </Box>
        ))}
      </Carousel>
    </div>
  );
}
