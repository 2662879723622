/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import Footer from "components/footer";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import VDLogo from "assets/logo-vd.png";
import Image from "assets/undraw_creative_team_r90h 1.png";
import ChatLogo from "assets/chat.png";
import { useDispatch, useSelector } from "react-redux";
import { user_email } from "redux/reducers/register";
import { displayModalSiginIn } from "redux/reducers/auth";
import queryString from 'query-string'
import * as services from "./services";

function RegisterVerification() {
  const [intervalState, setIntervalState] = useState(30);

  const [idInterval, setIdInterval] = useState(null);
  const intervalNumber = useRef(30);
  const { userEmail } = useSelector((state) => state.register);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = queryString.parse(history.location.search)

  const isResend = React.useMemo(() => query.resend === 'true', [query])

  const handleClickButtonResend = () => {
    services.resendEmailVerification(userEmail, (status, data) => {
      // reset count
      if(status) {
        setIntervalState(30);
        intervalNumber.current = 30;
        setIdInterval(null);
      }
    });
  };

  useEffect(() => {
    if (!idInterval && intervalState === 30) {
      const idInterval = window.setInterval(() => {
        if (intervalNumber.current - 1 <= 0) {
          window.clearInterval(idInterval);
        }

        intervalNumber.current -= 1;
        setIntervalState(intervalNumber.current);
      }, 1000);

      setIdInterval(idInterval);
    }
  }, [intervalState, idInterval]);

  useEffect(() => {
    return () => {
      dispatch(user_email(""));
      window.clearInterval(idInterval);
    };
  }, []);

  useEffect(() => {
    if (!userEmail) {
      history.push("/");
    }

    if (isResend && userEmail) {
      handleClickButtonResend()
    }
  }, [userEmail]);

  const isBtnDisabled = intervalState > 0;

  if (!userEmail) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-col pt-5">
        <Breadcrumbs>
          <Link
            className="font-montserrat font-medium text-sm text-pink-header pl-20"
            to="/"
          >
            Beranda
          </Link>
          <Link className="font-montserrat font-medium text-sm text-black-2">
            Daftar Akun
          </Link>
          <Link className="font-montserrat font-medium text-sm text-black-2">
            Verifikasi
          </Link>
        </Breadcrumbs>
      </div>
      <div className="py-5 flex w-full justify-center">
        <img src={VDLogo} alt="" style={{ height: 70 }} />
      </div>
      <div
        className="w-10/12 mx-auto flex"
        style={{
          minHeight: 550,
          marginBottom: 120,
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05",
        }}
      >
        <div className="w-1/2 pt-4 ml-12 pr-6">
          <div className="font-baloo2 font-bold text-3xl text-black-2">
            Jadi bagian bersama pelaku usaha di seluruh Indonesia
          </div>
          <div className="pb-10 border-b border-solid border-pink-header w-1/2" />
          <div className="font-montserrat font-medium text-xs text-black-2 pt-4">
            Mulai bergabung di Bisa Pengadaan bersama jutaan pelaku usaha berpengalaman di
            bidangnya.
          </div>
          <img src={Image} alt="" className="pt-8" />
        </div>

        <div className="w-1/2 mt-4 mb-8 border-l border-solid border-welcome pl-6">
          <div className="flex justify-between w-11/12">
            <div className="font-baloo2 font-bold text-base text-black-2">
              Verifikasi email (2/2)
            </div>
            <button
              className="font-montserrat font-bold text-xs text-pink-header"
              onClick={() => dispatch(displayModalSiginIn())}
            >
              Masuk
            </button>
          </div>
          <div className="pt-5 w-11/12">
            <div
              className="mt-2 p-2 rounded text-white text-xs"
              style={{ backgroundColor: "#00A65A" }}
            >
              <svg
                className="inline"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z"
                  fill="#FEFEFE"
                />
              </svg>
              Email verifikasi berhasil dikirim
            </div>

            <div className="mt-4">
              Cek inbox email anda:
              <span className="font-bold block">{userEmail}</span>
            </div>
            <div className="mt-3">
              Lalu ikuti langkah berikut:
              <ol className="ml-5 list-decimal">
                <li className="mt-1">
                  Cari dan buka email konfirmasi dari BISA Pengadaan LKPP di
                  inbox, spam, atau trash folder anda
                </li>
                <li className="mt-1">
                  Klik pada link verifikasi yang terdapat di dalam email
                </li>
                <li className="mt-1">
                  Akun anda akan secara otomatis terverifikasi
                </li>
              </ol>
            </div>
            <div className="mt-3 font-bold">
              Tidak menemukan email verifikasi ?
            </div>
            <button
              onClick={handleClickButtonResend}
              className={`"btn font-bold p-2 w-full ${
                isBtnDisabled ? "bg-gray-300" : "bg-red-700"
              } rounded text-white mt-3`}
              disabled={isBtnDisabled}
            >
              Kirim ulang email
              {isBtnDisabled && <>({intervalState} detik)</>}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RegisterVerification;
