import axios from 'axios';
import endpoint from 'utils/endpoint';
import token from 'utils/session';

async function getCountUsahaByKriteriaUsaha(params,callback) {
  const url = endpoint.countUsahaByKriteriaUsaha;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getTerdaftarDiSikap(params,callback) {
  const url = `${endpoint.terdaftarDiSikap}`;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getJenisPengadaan(params,callback) {
  const url = `${endpoint.statisticJenisPengadaan}`;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getTableDataOption(params,callback) {
  const url = `${endpoint.tableDataOption}`;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getTableDataYear(params,callback) {
  const url = `${endpoint.tableDataYear}`;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getTableDataFilter(params,callback) {
  const url = `${endpoint.tableDataFilter}`;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getTableData(params,callback) {
  const url = `${endpoint.tableData}`;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

async function getExportTableData(params,callback) {
  const url = `${endpoint.tableData}`;
  const config = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  try {
    const response = await axios(url,config);
    return Promise.resolve(response.data);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export default {
  getCountUsahaByKriteriaUsaha,
  getTerdaftarDiSikap,
  getJenisPengadaan,
  getTableDataOption,
  getTableDataYear,
  getTableDataFilter,
  getTableData,
  getExportTableData
};
