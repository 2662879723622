import React from "react";
import { Link } from "react-router-dom";
import { Box, Tabs, Tab } from "@material-ui/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import get from "lodash/get";
import { makeStyles, createStyles } from "@material-ui/styles";
import services from "../../pages/Home/services";
import classNames from "classnames";

const useStyles = makeStyles((theme) =>
  createStyles({
    boxChart: {
      [theme.breakpoints.up("lg")]: {
        minHeight: 600,
      },
      [theme.breakpoints.down("lg")]: {
        minHeight: 300,
      },
    },
    title: {
      fontFamily: "Baloo 2",
      fontWeight: 700,
      color: "#595959",
      fontSize: 18,
    },
  })
);

export default function DataUsaha() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedYear, setSelectedYear] = React.useState(0);
  const [options, setOptions] = React.useState({
    kriteria: null,
    sikap: null,
    jenisPengadaan: null,
  });

  React.useEffect(() => {
    services.getStatisticKriteriaUsaha({}, (data) => {
      setOptions((prev) => ({ ...prev, kriteria: data }));
    });

    services.getTerdaftarDiSikap({}, (data) => {
      setOptions((prev) => ({ ...prev, sikap: data }));
    });

    services.getJenisPengadaan({}, (data) => {
      setOptions((prev) => ({ ...prev, jenisPengadaan: data }));
    });
  }, []);

  const listGraph = Object.keys(options);
  const listButtonTab = get(options, listGraph[selectedTab], []);
  const listForViews = get(listButtonTab, [selectedYear], []);

  const chart = React.useRef(null);
  React.useLayoutEffect(() => {
    let x = am4core.create("data-pelaku-usaha", am4charts.XYChart3D);

    chart.current = x;

    x.hiddenState.properties.opacity = 0;
    x.colors.list = [
      am4core.color("#ff9494"),
      am4core.color("#8bcaff"),
      am4core.color("#88c46d"),
      am4core.color("#6696d0"),
    ];
    x.data = get(listForViews, "data", []);

    let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.labels.template.rotation = 0;
    categoryAxis.renderer.labels.template.hideOversized = false;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.horizontalCenter = "middle";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.tooltip.label.rotation = 270;
    categoryAxis.tooltip.label.horizontalCenter = "right";
    categoryAxis.tooltip.label.verticalCenter = "middle";

    let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Total";
    valueAxis.title.fontWeight = "bold";

    // Create series
    var series = x.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "name";
    series.name = "Total";
    series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.8;

    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.stroke = am4core.color("#FFFFFF");

    columnTemplate.adapter.add("fill", function (fill, target) {
      return x.colors.getIndex(target.dataItem.index);
    });

    columnTemplate.adapter.add("stroke", function (stroke, target) {
      return x.colors.getIndex(target.dataItem.index);
    });

    x.cursor = new am4charts.XYCursor();
    x.cursor.lineX.strokeOpacity = 0;
    x.cursor.lineY.strokeOpacity = 0;
    x.responsive.enabled = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listForViews]);

  const title = {
    0: "Chart Kriteria Usaha",
    1: "Status Terdaftar SIKaP",
    2: "Jenis Pengadaan",
  };

  const classDataYear =
    "flex flex-1 flex-col font-montserrat font-medium text-xs";

  return (
    <div>
      <div>
        <span className={classes.title}>Data Pelaku Usaha Terkini</span>
      </div>

      <Box boxShadow={3} className="mt-3">
        <div className="mx-auto">
          <Box
            sx={{
              justifyContent: "center",
              background: "#F2F2F2",
              display: "flex",
              "& .Mui-selected": {
                background: "#FEFEFE",
                color: "#595959",
                border: "1px solid #CCCCCC",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              },
              "& .MuiTabScrollButton-root": {
                border: "1px solid #CCCCCC",
                background: "#FEFEFE",
                paddingLeft: "12px",
                paddingRight: "12px",
              },
              "& .MuiTabs-indicator": {
                background: "white !important",
              },
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={(ev, value) => setSelectedTab(value)}
              variant="scrollable"
              scrollButtons="auto"
              allowscrollbuttonsmobile="true"
              style={{
                width: "90%",
                paddingTop: "8px",
              }}
              TabIndicatorProps={{
                style: {
                  background: "#0073B7",
                  border: "1px solid white",
                  boxSizing: "border-box",
                  borderRadius: "4px 4px 0px 0px",
                },
              }}
            >
              <Tab label="Chart Kriteria Usaha" />
              <Tab label="Status Terdaftar SIKaP" />
              <Tab label="Jenis Pengadaan" />
            </Tabs>
          </Box>
        </div>
        <div className="text-black-2 flex justify-center text-sm pt-4">
          {title[selectedTab]}
        </div>
        <div id="data-pelaku-usaha" className={classes.boxChart}></div>
        <div className="flex flex-row justify-between">
          {listButtonTab?.map((data, index) => (
            <Box
              key={index}
              className={classNames(classDataYear, "px-4 py-2", {
                "text-pink-header": selectedYear === index,
                "text-black-2": selectedYear !== index,
              })}
              onClick={() => setSelectedYear(index)}
              component="button"
              sx={{
                bgcolor: selectedYear === index ? "grey.200" : "grey.300",
              }}
            >
              <p className="text-lg font-semibold">{data?.year}</p>
              <p className="text-sm">{data?.count}</p>
            </Box>
          ))}
        </div>
      </Box>
    </div>
  );
}
