import axios from 'axios';
import endpoint from 'utils/endpoint';

export async function checkUser(data, callback) {

  const url = endpoint.forgotPasswordCheck;
  const config = {
    method: 'POST',
    data,
  };

  try {
    const response = await axios(url,config);
    callback(true, response.data)
  } catch (error) {
    callback(false, error)
  }
}

export async function sendEmail(data, callback) {

  const url = endpoint.forgotPasswordSend;
  const config = {
    method: 'POST',
    data,
  };

  try {
    const response = await axios(url,config);
    callback(response.data)
  } catch (error) {
    callback(error)
  }
}

export async function changePassword(data, callback) {

  const url = endpoint.forgotPasswordReset;
  const config = {
    method: 'POST',
    data,
  };

  try {
    const response = await axios(url,config);
    callback(response.data)
  } catch (error) {
    callback(error)
  }
}