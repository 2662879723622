import React, { useState, useEffect, useMemo } from "react";
import Footer from "components/footer";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Breadcrumb from "components/Breadcrumb";
import { Link, useHistory, useLocation } from "react-router-dom";
import LeftSide from "./components/FilterLeftSide";
import ProductCard from "components/productCard";
import "../../index.css";
import Pagination from "components/pagination";
import services from "./services";
import Navigation from "components/Navigation";
import useGlobalStyles from "hooks/useGlobalStyles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import usePagination from "utils/usePagination";
import useProfile from "hooks/useProfile";
import Page from "components/Page";
import parseQueryString from "utils/parseQueryString";
import parseObjectToString from "utils/parseObjectToString";
import SidebarSearch from "./components/Sidebar";
import { actionsGlobal } from "redux/reducers/global";
import { useDispatch } from "react-redux";
import debounce from 'lodash/debounce';

function Search(props) {
  const classes = useGlobalStyles();
  const { isLogin, user } = useProfile();
  const user_id = useMemo(() => user?.id, [user]);
  const history = useHistory();
  const dispatch = useDispatch();
  const refInput = React.useRef(null)

  // const queryObject = new URLSearchParams(useLocation().search)

  const resultParams = parseQueryString(useLocation().search);

  const queryObject = React.useMemo(() => resultParams, [resultParams]);

  const [params, setParams] = useState({
    q: "",
    page: null,
    visible: false,
    focus: false,
    filter: false,
  });

  const [detailResponse, setResponse] = useState({
    data: [],
  });

  const [selectedPBJP, setselectedPBJP] = useState([]);

  const [total, setPagination] = usePagination();

  const onUserInput = (e) => {
    const textSearch = e.target.value;
    history.replace(`/search?${parseObjectToString({ q: textSearch })}`);
    setParams((prev) => ({ ...prev, q: textSearch }));
  };

  const [selectedProvince, setSelectedProvince] = useState([]);

  const [selectedBusinessType, setSelectedBusinessType] = useState([]);

  const [selectedBusinessActivity, setSelectedBusinessActivity] = useState([]);

  const [selectedBusinessCriteria, setSelectedBusinessCriteria] = useState([]);

  const [selectedProcurementType, setSelectedProcurementType] = useState([]);

  const [yearFrom, setYearFrom] = useState(null);

  const [yearTo, setYearTo] = useState(null);

  const [sort, setSort] = useState("");

  const [isOap, setIsOap] = useState("");

  const [gender, setGender] = useState("");

  const handleProvinceChange = (event) => {
    if (selectedProvince?.indexOf(event.id) < 0) {
      setSelectedProvince((prev) => [...prev, event.id]);
    } else {
      setSelectedProvince((prev) => {
        return prev.filter((i) => i !== event.id);
      });
    }
  };

  const handleBusinessTypeChange = (data) => {
    if (selectedBusinessType?.indexOf(data.id) < 0) {
      setSelectedBusinessType((prev) => [...prev, data.id]);
    } else {
      setSelectedBusinessType((prev) => {
        return prev.filter((i) => i !== data.id);
      });
    }
  };

  const handleBusinessActivityChange = (data) => {
    if (selectedBusinessActivity?.indexOf(data.id) < 0) {
      setSelectedBusinessActivity((prev) => [...prev, data.id]);
    } else {
      setSelectedBusinessActivity((prev) => {
        return prev.filter((i) => i !== data.id);
      });
    }
  };

  const handleBusinessCriteriaChange = (data) => {
    if (selectedBusinessCriteria?.indexOf(data.id) < 0) {
      setSelectedBusinessCriteria((prev) => [...prev, data.id]);
    } else {
      setSelectedBusinessCriteria((prev) => {
        return prev.filter((i) => i !== data.id);
      });
    }
  };

  const handleProcurementTypeChange = (data) => {
    if (selectedProcurementType?.indexOf(data.id) < 0) {
      setSelectedProcurementType((prev) => [...prev, data.id]);
    } else {
      setSelectedProcurementType((prev) => {
        return prev.filter((i) => i !== data.id);
      });
    }
  };

  const handlePBJPChange = (data) => {
    if (selectedPBJP?.indexOf(data?.id) < 0) {
      setselectedPBJP((prev) => [...prev, data.id]);
    } else {
      setselectedPBJP((prev) => {
        return prev.filter((i) => i !== data.id);
      });
    }
  };

  const provinceQueryAll = React.useMemo(() => {
    return selectedProvince
      ?.map((_item) => {
        if (typeof _item === "object") {
          return _item.id;
        }

        return _item;
      })
      .join(",");
  }, [selectedProvince]);

  const activityQueryAll = React.useMemo(() => {
    return selectedBusinessActivity
      ?.map((_item) => {
        if (typeof _item === "object") {
          return _item.id;
        }

        return _item;
      })
      .join(",");
  }, [selectedBusinessActivity]);

  // console.log(urlParams.get('selectedBusinessType'))
  useEffect(() => {
    services.getSearchUsaha(
      {
        q: queryObject?.q ? queryObject?.q : params.q,
        page_size: 16,
        province_id: queryObject?.selectedProvince
          ? queryObject?.selectedProvince
          : provinceQueryAll,
        business_type_id: queryObject?.selectedBusinessType
          ? queryObject?.selectedBusinessType
          : selectedBusinessType?.map((i) => i).join(","),
        business_activity_id: queryObject?.selectedBusinessActivity
          ? queryObject?.selectedBusinessActivity
          : activityQueryAll,
        business_criteria_id: queryObject?.selectedBusinessCriteria
          ? queryObject?.selectedBusinessCriteria
          : selectedBusinessCriteria?.map((i) => i)?.join(","),
        procurement_type_id: queryObject?.selectedProcurementType
          ? queryObject?.selectedProcurementType
          : selectedProcurementType?.map((i) => i)?.join(","),
        experience_year_from: queryObject?.yearFrom
          ? queryObject?.yearFrom
          : yearFrom,
        experience_year_to: queryObject?.yearTo ? queryObject?.yearTo : yearTo,
        experience_pbjp_id: queryObject?.selectedPBJP
          ? queryObject?.selectedPBJP
          : selectedPBJP?.map((i) => i)?.join(","),
        page: (params.q !== "") ? 1 : params.page,
        sort: sort,
        oap: isOap,
        jenis_kelamin: gender
      },
      (response, err) => {
        if (!err) {
          setResponse((prev) => ({ ...prev, data: response }));
          setPagination(16, response.total);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params,
    selectedProvince,
    selectedBusinessType,
    selectedBusinessActivity,
    selectedBusinessCriteria,
    selectedProcurementType,
    selectedPBJP,
    yearTo,
    yearFrom,
    sort,
    queryObject?.q,
    isOap,
    gender,
  ]);

  useEffect(() => {
    if (queryObject.length !== 0) {
      setSelectedProvince((prev) =>
        queryObject?.selectedProvince
          ? queryObject?.selectedProvince?.split(",")
          : prev
      );

      setSelectedBusinessType((prev) =>
        queryObject?.selectedBusinessType
          ? queryObject?.selectedBusinessType?.split(",")
          : prev
      );

      setSelectedBusinessActivity((prev) =>
        queryObject?.selectedBusinessActivity
          ? queryObject?.selectedBusinessActivity?.split(",")
          : prev
      );

      setSelectedBusinessCriteria((prev) =>
        queryObject?.selectedBusinessCriteria
          ? queryObject?.selectedBusinessCriteria?.split(",")
          : prev
      );

      setSelectedProcurementType((prev) =>
        queryObject?.selectedProcurementType
          ? queryObject?.selectedProcurementType?.split(",")
          : prev
      );

      setYearFrom((prev) =>
        queryObject?.yearFrom ? queryObject?.yearFrom : prev
      );

      setYearTo((prev) => (queryObject?.yearTo ? queryObject?.yearTo : prev));

      setParams((prev) =>
        queryObject?.q ? { ...prev, q: queryObject?.q } : { ...prev }
      );
    }
  }, []);
  // console.log(detailResponse?.data)
  function onEnter(e) {
    if (e.key === "Enter") {
      setParams((prev) => ({ ...prev, visible: false }));
    }
  }

  const querySearch = {
    selectedBusinessActivity: activityQueryAll,
    selectedBusinessCriteria: selectedBusinessCriteria
      ?.map((i) => i)
      ?.join(","),
    selectedBusinessType: selectedBusinessType?.map((i) => i)?.join(","),
    selectedProcurementType: selectedProcurementType?.map((i) => i)?.join(","),
    selectedPBJP: selectedPBJP?.map((i) => i)?.join(","),
    selectedProvince: provinceQueryAll,
    yearFrom: yearFrom,
    yearTo: yearTo,
    q: params.q,
    oap: isOap,
    jenis_kelamin: gender,
  };

  const activeQuerySearch = parseObjectToString(querySearch);

  function onHandleFilter() {
    history.replace(`/search?${activeQuerySearch}`);

    services.getSearchUsaha(
      {
        q: params.q,
        page_size: 16,
        province_id: provinceQueryAll,
        business_type_id: selectedBusinessType.map((i) => i).join(","),
        business_activity_id: activityQueryAll,
        business_criteria_id: selectedBusinessCriteria.map((i) => i).join(","),
        procurement_type_id: selectedProcurementType.map((i) => i).join(","),
        experience_year_from: yearFrom,
        experience_year_to: yearTo,
        experience_pbjp_id: selectedPBJP.map((i) => i).join(","),
        page: (params.q !== "") ? 1 : params.page,
        oap: isOap,
        jenis_kelamin: gender,
      },
      (response, err) => {
        if (!err) {
          setResponse((prev) => ({ ...prev, data: response }));
          setPagination(16, response.total);
        }
      }
    );
  }

  function onHandleReset() {
    history.replace(`/search`);

    setParams((prev) => ({ ...prev, q: "" }));
    setSelectedProvince([]);
    setSelectedBusinessType([]);
    setSelectedBusinessActivity([]);
    setSelectedBusinessCriteria([]);
    setSelectedProcurementType([]);
    setYearFrom("");
    setYearTo("");
    setselectedPBJP([]);
    setIsOap("");
    setGender("");

    services.getSearchUsaha({}, (response, err) => {
      if (!err) {
        setResponse((prev) => ({ ...prev, data: response }));
        setPagination(16, response.total);
      }
    });
  }

  const result = `${detailResponse?.data?.total} hasil untuk pencarian dengan kata “${params.q}”`;
  // console.log(detailResponse)
  return (
    <Page title="Cari pelaku usaha" className={classes.container}>
      <Navigation />

      <div className="flex flex-col lg:pt-4 pt-6">
        <Breadcrumb
          className="lg:px-20 px-6 pt-4"
          links={[
            { name: "Beranda", href: "/" },
            { name: "Pencarian Pelaku Usaha" },
          ]}
        />
      </div>

      <div className="flex lg:flex-row flex-col justify-between lg:mx-20 mx-6 pt-8 lg:pb-10 pb-4">
        <div className="mb-8 font-baloo2 font-bold text-3xl text-black-2">
          Pencarian Pelaku Usaha
          <div className="border-b-2 border-solid border-pink-header w-6/12 mb-8 lg:pt-10 pt-4" />
        </div>
      </div>

      <div className="flex lg:mx-20 mx-6 pb-4">
        <div className="flex lg:w-auto w-full lg:flex-row flex-col lg:space-y-0 space-y-3 p-2 lg:bg-grey-3 rounded">
          <div className="relative">
            <input
              // value={params.q}
              ref={refInput}
              defaultValue={params.q}
              name="search"
              className="flex lg:w-96 w-full rounded p-2 font-arial italic text-xs border border-gray-400 text-black-2 focus:border-pink-700"
              onChange={debounce(onUserInput, 1000)}
              onFocus={
                params.visible ? () => setParams(prev => ({...prev})) : () => setParams(prev => ({...prev, visible: true, q: ""}))
              }
              placeholder="Ketikkan keyword pencarian"
              onKeyDown={(e) => onEnter(e)}
            />
            {params.q === "" && 
              <button
                className={
                  "absolute right-0 top-1/2 bottom-1/2 my-auto w-10 h-10 flex items-center justify-center focus:outline-none"
                }
                type="submit"
                onClick={() => setParams((prev) => ({ ...prev, q: params.text }))}
              >
                <SearchIcon style={{ color: "#595959" }} />
              </button>
            }
            {params.q !== "" && 
              <button
                className={
                  "absolute right-0 top-1/2 bottom-1/2 my-auto w-10 h-10 flex items-center justify-center focus:outline-none"
                }
                onClick={() => {
                  setParams((prev) => ({ ...prev, q: "",page: 1 }))
                  history.replace(`/search`)
                  refInput.current.value = ""
                }}
              >
                <CloseIcon style={{ color: "#595959" }} />
              </button>
            }
          </div>
          <select
            onChange={(e) => setSort(e.target.value)}
            className="form-select p-2 lg:pr-10 pr-2 lg:ml-2 rounded border border-gray-400 text-black-2 text-xs font-roboto"
          >
            <option value="populer">Paling Populer</option>
            <option value="created_at:desc">Paling Terbaru</option>
            <option value="name:asc">A - Z</option>
          </select>
          <button className="text-xs font-arial font-bold lg:ml-2 px-4 py-2 border border-solid border-pink-header bg-pink-header rounded text-neutral-grey">
            Cari
          </button>
          <button
            className="text-xs font-arial font-bold lg:ml-2 px-4 py-2 border border-solid border-pink-header bg-pink-header rounded text-neutral-grey lg:hidden block"
            onClick={() => dispatch(actionsGlobal.openSearchSidebar())}
          >
            Filter
          </button>
        </div>
      </div>

      <span className="lg:px-20 px-6 font-arial text-xs text-black-2">
        {result}
      </span>

      <div className="flex flex-row lg:space-x-5 lg:px-20 px-6 my-8">
        <LeftSide
          selectedProvince={selectedProvince}
          handleProvinceChange={handleProvinceChange}
          setSelectedProvince={setSelectedProvince}
          selectedBusinessType={selectedBusinessType}
          handleBusinessTypeChange={handleBusinessTypeChange}
          selectedBusinessActivity={selectedBusinessActivity}
          handleBusinessActivityChange={handleBusinessActivityChange}
          setSelectedBusinessActivity={setSelectedBusinessActivity}
          selectedBusinessCriteria={selectedBusinessCriteria}
          handleBusinessCriteriaChange={handleBusinessCriteriaChange}
          setSelectedBusinessCriteria={setSelectedBusinessCriteria}
          setSelectedProcurementType={setSelectedProcurementType}
          selectedProcurementType={selectedProcurementType}
          handleProcurementTypeChange={handleProcurementTypeChange}
          setYearFrom={setYearFrom}
          setYearTo={setYearTo}
          yearFrom={yearFrom}
          yearTo={yearTo}
          selectedPBJP={selectedPBJP}
          setselectedPBJP={setselectedPBJP}
          handlePBJPChange={handlePBJPChange}
          gender={gender}
          setGender={setGender}
          isOap={isOap}
          setIsOap={setIsOap}
          onHandleFilter={onHandleFilter}
          onHandleReset={onHandleReset}
          className="lg:block hidden"
        />

        <div className="flex flex-col w-full">
          <div
            className={classNames(
              "grid 2xl:grid-cols-4 xl:grid-cols-3 grid-cols-1 grid-flow-row pb-3 gap-4"
            )}
          >
            {detailResponse?.data?.data?.map((data, index) => {
              return (
                <ProductCard
                  data={data}
                  key={index}
                  id={data?.id}
                  q={params.q}
                  selectedProvince={selectedProvince}
                  selectedBusinessType={selectedBusinessType}
                  selectedBusinessActivity={selectedBusinessActivity}
                  selectedBusinessCriteria={selectedBusinessCriteria}
                  selectedProcurementType={selectedProcurementType}
                  yearFrom={yearFrom}
                  yearTo={yearTo}
                  selectedPBJP={selectedPBJP}
                />
              );
            })}
          </div>
          {detailResponse?.data?.total === 0 && (
            <div className="flex flex-col items-center justify-center font-bold font-arial text-2xl text-search">
              <div>Tidak ada Hasil Pencarian</div>
              <div className="font-normal text-xl font-arial text-search">
                Apakah "{params.q}" milik anda?
                <Link
                  className="pl-2 text-pink-header"
                  to={`${
                    isLogin === true
                      ? `/add_new_business/${user_id}`
                      : `/register`
                  }`}
                >
                  Daftarkan Sekarang
                </Link>
              </div>
            </div>
          )}
          <div
            className={classNames("items-center justify-center mt-3", {
              hidden: detailResponse?.data?.data?.length === 0,
              flex: detailResponse?.data?.data?.length > 0,
            })}
          >
            <Pagination
              count={total}
              color="primary"
              shape="rounded"
              page={params.page}
              onChange={(event, page) =>
                setParams((prev) => ({ ...prev, page: page }))
              }
            />
          </div>
        </div>
      </div>

      <SidebarSearch
        selectedProvince={selectedProvince}
        handleProvinceChange={handleProvinceChange}
        setSelectedProvince={setSelectedProvince}
        selectedBusinessType={selectedBusinessType}
        handleBusinessTypeChange={handleBusinessTypeChange}
        selectedBusinessActivity={selectedBusinessActivity}
        handleBusinessActivityChange={handleBusinessActivityChange}
        setSelectedBusinessActivity={setSelectedBusinessActivity}
        selectedBusinessCriteria={selectedBusinessCriteria}
        handleBusinessCriteriaChange={handleBusinessCriteriaChange}
        setSelectedBusinessCriteria={setSelectedBusinessCriteria}
        setSelectedProcurementType={setSelectedProcurementType}
        selectedProcurementType={selectedProcurementType}
        handleProcurementTypeChange={handleProcurementTypeChange}
        setYearFrom={setYearFrom}
        setYearTo={setYearTo}
        yearFrom={yearFrom}
        yearTo={yearTo}
        selectedPBJP={selectedPBJP}
        setselectedPBJP={setselectedPBJP}
        handlePBJPChange={handlePBJPChange}
        onHandleFilter={onHandleFilter}
        onHandleReset={onHandleReset}
      />
      <Footer />
    </Page>
  );
}

export default Search;
