import React from "react";
import Footer from "components/footer";
import Navigation from "components/Navigation";
import { useFormik } from "formik";
import useGlobalStyles from "hooks/useGlobalStyles";
import KontakKami from "pages/AboutUs/components/KontakKami";
import Input from "components/TextField";
import Page from "components/Page";
import Button from "@material-ui/core/Button";
import endpoint from "utils/endpoint";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getTentangKami } from "redux/reducers/region";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Breadcrumb from "components/Breadcrumb";
import Dialog from "components/Dialog";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
    },
  })
);

function ContactUs() {
  const classDialog = useStyles();

  const dispatch = useDispatch();

  const [message, setMessage] = React.useState({
    open: false,
    severity: "",
    message: "",
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      nama_lengkap: "",
      instansi: "",
      subject: "",
      message: "",
      phone: "",
    },
    // validationSchema: newTicketValidation,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const url = endpoint.contactUs;

      setSubmitting(true);
      try {
        const data = await axios(url, { method: "POST", data: values });
        if (data.status === 200) {
          setMessage({
            open: true,
            severity: "success",
            message: data.data.message.toString(),
          });
        }
        setSubmitting(false);
        resetForm();
      } catch (error) {
        if (error.response.status === 422) {
          if (error.response.data.errors) {
            Object.keys(error.response.data.errors).forEach((attributeName) => {
              setFieldError(
                attributeName,
                error.response.data.errors[attributeName].toString()
              );
            });
            const firstMessage = Object.keys(error.response.data.errors)
              .map((attrName) =>
                error.response.data.errors[attrName].toString()
              )
              .toString();
            setMessage({
              open: true,
              severity: "error",
              message: firstMessage,
            });
          }
        }
        setSubmitting(false);
      }
    },
  });

  const { handleSubmit, values, handleChange, getFieldProps, setFieldError } =
    formik;

  const classes = useGlobalStyles();

  React.useEffect(() => {
    dispatch(getTentangKami());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Kontak Kami" className={classes.container}>
      <Navigation />

      <div className="flex flex-col pt-4">
        <Breadcrumb
          links={[{ name: "Beranda", href: "/" }, { name: "Kontak Kami" }]}
          className="lg:px-20 px-6"
        />
      </div>

      <div className="flex justify-between lg:mx-20 mx-6 py-8">
        <div className="mb-8 font-baloo2 font-bold text-3xl text-black-2">
          Kontak Kami
          <div className="border-b-2 border-solid border-pink-header w-6/12 mb-8 pt-10" />
        </div>
        <img
          src="/static/images/logo-LKPP-2.png"
          className="w-64 h-20 lg:block hidden"
          alt=""
        />
      </div>

      <div
        className="w-10/12 mx-auto pt-4 px-10"
        style={{
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="font-baloo2 font-bold text-2xl text-black-2 pb-4">
          Email ke kami
        </div>
        <div className="border border-solid border-welcome" />
        <form onSubmit={handleSubmit}>
          <div className="grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-4">
            <Input
              name="email"
              label="Email"
              required
              {...getFieldProps("email")}
            />

            <Input
              name="instansi"
              label="Instansi"
              required
              {...getFieldProps("instansi")}
            />

            <Input
              name="nama_lengkap"
              label="Nama Lengkap"
              required
              {...getFieldProps("nama_lengkap")}
            />

            <Input
              name="phone"
              label="Ponsel"
              required
              type="number"
              min="0"
              {...getFieldProps("phone")}
            />
          </div>

          <Input
            name="subject"
            label="Judul Pesan"
            required
            type=""
            {...getFieldProps("subject")}
          />

          <div className="pt-3 flex flex-col">
            <div className="flex flex-row">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pesan
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <textarea
              className="py-2 px-2 w-full font-montserrat font-medium text-sm text-black-2 border border-solid focus:border-blue-second rounded"
              value={values.message}
              name="message"
              onChange={handleChange}
              rows="8"
            />
          </div>

          <div className="py-4 flex justify-end items-end">
            <Button color="primary" type="submit" variant="contained">
              Kirim
            </Button>
          </div>
        </form>
        <Dialog
          visible={message.open === true}
          onClose={message.open !== true}
          className="mt-3 block"
        >
          <Alert
            severity={message.severity}
            className={classDialog.dialog}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMessage({ open: false, error: "", message: "" });
                }}
              >
                <CloseIcon alt="" />
              </IconButton>
            }
          >
            <div>{message.message}</div>
          </Alert>
        </Dialog>
      </div>

      <div className="mt-6 mb-20 rounded w-10/12 mx-auto">
        <KontakKami showMap />
      </div>
      <Footer />
    </Page>
  );
}

export default ContactUs;
