import React, { useCallback, useState } from "react";
import Navigation from "components/Navigation";
import Footer from "components/footer";
import useGlobalStyles from "hooks/useGlobalStyles";
// import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Box } from "@material-ui/core";
import classNames from "classnames";
import Page from "components/Page";
import Breadcrumbs from "components/Breadcrumb";
import DataTable from "react-data-table-component";
import get from "lodash/get";
import services from "../Home/services";
import classnames from "classnames";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useHistory, useLocation } from "react-router-dom";
import dashboardServices from "./services";
import debounce from "lodash/debounce";

const useStyles = makeStyles((theme) =>
  createStyles({
    boxGraph: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right 80%",
      backgroundSize: "auto 60%",

      [theme.breakpoints.up("lg")]: {
        minHeight: 150,
      },
      [theme.breakpoints.up("xl")]: {
        minHeight: 200,
      },
      [theme.breakpoints.down("lg")]: {
        minHeight: 150,
      },
    },
  })
);

export default function ViewBoard() {
  const classes = useGlobalStyles();
  const childClasses = useStyles();
  const history = useHistory();

  const [options, setOptions] = useState({
    page: 1,
    pageSize: 10,
    search: null,
  });
  const [listGraph, setListGraph] = useState([]);
  const [listBoard, setListBoard] = useState();
  const [listUsaha, setListUsaha] = useState({
    data: [],
  });

  React.useEffect(() => {
    services.getSettings({}, (data) =>
      setListGraph(get(data, "data.dashboard_landing_page"))
    );

    services.getCountPelakuUsaha({}, (data) => setListBoard(data));
  }, []);

  const listLanding = Object.keys(listGraph);

  const listCountPelakuUsaha = {
    pu_kecil: {
      title: "Usaha Kecil yang Terdaftar di sistem",
      icon: "/static/images/bag.png",
      filter: "Usaha Kecil",
      bagian: "Kecil",
    },
    pu_non_kecil: {
      title: "Usaha non Kecil yang Terdaftar di sistem",
      icon: "/static/images/calendar.png",
      filter: "Usaha Kecil",
      bagian: "Besar",
    },
    terdaftar: {
      title: "Usaha terdaftar di sistem",
      icon: "/static/images/VectorStroke.png",
      filter: "Status Terdaftar di SIKaP",
      bagian: 0,
    },
    terdaftar_setiap_bulan: {
      title: "Usaha mendaftar setiap bulannya",
      icon: "/static/images/Union.png",
      filter: null,
      bagian: null,
      jumlah: get(listBoard, ["terdaftar_setiap_bulan"], 0),
      year: new Date().getFullYear(),
    },
  };

  const lastSearch = useLocation().search;
  const query = new URLSearchParams(lastSearch);
  const view = query.get("view") || "terdaftar";

  const columns = [
    {
      name: "No",
      cell: (row) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div>{get(row, "id")}</div>

            <button
              onClick={() => history.push(`/product_detail/${get(row, "id")}`)}
              style={{ paddingLeft: "10px" }}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.66667 0V1.33333H1.33333V10.6667H10.6667V7.33333H12V11.3333C12 11.5101 11.9298 11.6797 11.8047 11.8047C11.6797 11.9298 11.5101 12 11.3333 12H0.666667C0.489856 12 0.320286 11.9298 0.195262 11.8047C0.0702379 11.6797 0 11.5101 0 11.3333V0.666667C0 0.489856 0.0702379 0.320286 0.195262 0.195262C0.320286 0.0702379 0.489856 0 0.666667 0H4.66667ZM9.724 1.33333H6.66667V0H12V5.33333H10.6667V2.276L6 6.94267L5.05733 6L9.724 1.33333Z"
                  fill="#0073B7"
                />
              </svg>
            </button>
          </div>
        );
      },
      sortable: false,
      style: {
        fontFamily: "Roboto",
        fontSize: "12px",
      },
    },
    {
      name: "Nama Usaha",
      selector: (row) => get(row, "nama_usaha"),
      sortable: true,
      grow: 1,
      style: {
        // width: "100%",
      },
    },
    {
      name: "Bentuk",
      selector: (row) => get(row, "bentuk_usaha"),
      sortable: true,
      style: {},
    },
    {
      name: "Aktivitas Usaha",
      selector: (row) => get(row, "business_activity_name"),
      grow: 2,
      sortable: true,
      style: {
        maxWidth: 400,
      },
      // onClick: (row) => setActivities({ visible: true, content: row?.business_activities?.map((data) => data?.name) }),
    },
    {
      name: "Kriteria Usaha",
      selector: (row) => get(row, "kriteria_usaha"),
      grow: 1,
      sortable: true,
      style: {},
    },
    {
      name: "Orang Papua Asli(OAP)",
      selector: (row) => {
        const isOAP = Boolean(get(row, "is_oap", false));

        if (isOAP) {
          return "OAP";
        }

        return "Non-OAP";
      },
      sortable: true,
      grow: 3,
      style: {},
    },
    {
      name: "Provinsi",
      selector: (row) => get(row, "provinsi"),
      sortable: true,
      grow: 3,
      style: {},
    },
  ];

  const handleSelected = useCallback((key) => {
    history.replace(`/dashboard_beranda?view=${key}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dashboardServices.getUsahaList(
      (list, error) => {
        if (!error) {
          setListUsaha(list);
        }
      },
      {
        chart: get(listCountPelakuUsaha, [view, "filter"]),
        filter: get(listCountPelakuUsaha, [view, "bagian"]),
        year: get(listCountPelakuUsaha, [view, "year"]),
        jumlah: get(listCountPelakuUsaha, [view, "jumlah"]),
        q: options.search,
        page_size: options.pageSize,
        page: options.page,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, options]);

  const onSearch = (event) => {
    setOptions((prev) => ({ ...prev, page: 1, search: event.target.value }));
  };

  return (
    <Page
      title="Dashboard Beranda"
      className={classNames("py-4", classes.container)}
    >
      <Navigation />

      <Breadcrumbs
        className="xl:px-20 lg:px-18 px-6"
        links={[{ name: "Beranda", href: "/" }, { name: "Dashboard Beranda" }]}
      />

      <div className="flex lg:flex-row flex-col justify-between lg:mx-20 mx-6 pt-8 pb-4">
        <div className="mb-8 font-baloo2 font-bold text-3xl text-black-2">
          Dashboard Beranda
          <div className="border-b-2 border-solid border-pink-header w-6/12 mb-8 lg:pt-10 pt-4" />
        </div>
      </div>

      <div className="xl:px-20 lg:px-18 px-6 xl:pb-12 lg:pb-10 pb-8">
        <div className="w-full grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 pt-0 px-0">
          {listLanding.map((item, index) => {
            const isSelected = item === view;
            const classFooter = classnames(
              "px-4 py-2 transition ease duration-200",
              {
                "footer-board-beranda group-hover:text-white": !isSelected,
                "footer-board-beranda-active text-white": isSelected,
              }
            );

            return (
              <div key={index}>
                <Box
                  component="button"
                  className="w-full inline-block overflow-hidden board-beranda group"
                  boxShadow={3}
                  sx={{ borderRadius: 8 }}
                  onClick={() => handleSelected(item)}
                >
                  <Box
                    className={classnames(
                      childClasses.boxGraph,
                      "bg-neutral-grey rounded pt-14 pl-6 text-left"
                    )}
                    style={{
                      backgroundImage: `url(${get(listCountPelakuUsaha[item], [
                        "icon",
                      ])})`,
                    }}
                  >
                    <span className="font-baloo2 font-bold 2xl:text-5xl xl:text-4xl text-3xl text-black-2">
                      {get(listBoard, [item])}
                    </span>
                  </Box>
                  <Box className={classFooter} sx={{ minHeight: 68 }}>
                    <p className="text-left font-montserrat font-medium xl:text-sm lg:text-xs">
                      {get(listGraph[item], ["label"])}
                    </p>
                  </Box>
                </Box>
              </div>
            );
          })}
        </div>

        <Box boxShadow={3} className="mt-8 p-6">
          <div className="py-2 flex lg:flex-row flex-col lg:space-y-0 space-y-3 lg:items-start items-center justify-between">
            <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-3 flex-1">
              <div className="flex items-center space-x-3">
                <button className="font-roboto font-normal text-sm text-black-2">
                  Show
                </button>

                <select
                  onChange={(event) =>
                    setOptions((prev) => ({
                      ...prev,
                      page: 1,
                      pageSize: event.target.value,
                    }))
                  }
                  className="px-8 py-2 border border-solid border-light-gray rounded font-roboto font-normal text-sm text-black-2 form-select focus:ring-1 focus:ring-blue-second"
                >
                  <option value="" disabled>
                    Pilih
                  </option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={25}>25</option>
                  <option value={30}>30</option>
                </select>

                <span className="pl-1 font-roboto font-normal text-sm text-black-2">
                  entries
                </span>
              </div>
            </div>

            <div className="font-roboto font-normal text-sm text-black-2 space-x-2">
              <span>Search</span>
              <input
                type="text"
                className="ml-1 px-4 py-2 border border-solid border-light-gray rounded"
                onChange={debounce(onSearch, 600)}
              />
            </div>
          </div>
          <DataTable
            columns={columns}
            data={get(listUsaha, "data", [])}
            pagination
            paginationServer
            paginationTotalRows={get(listUsaha, "total", 0)}
            onChangeRowsPerPage={(perPage, newPage) =>
              setOptions((prev) => ({ ...prev, page: 1, pageSize: perPage }))
            }
            onChangePage={(page) => setOptions((prev) => ({ ...prev, page }))}
          />
        </Box>
      </div>

      <Footer />
    </Page>
  );
}
