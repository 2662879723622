import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import services from "components/header/services";
import { getProfileSuccess } from "redux/reducers/users";
import accessToken from "utils/session";

export default function useProfile() {
  // const { accessToken } = useSelector((state) => state.auth);
  const { is_login, profile } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (accessToken && profile === null) {
      services.getProfile({ token: accessToken },
        {
          success: (response) => {
            dispatch(getProfileSuccess(response));
          },
          error: () => {
            dispatch(services.postLogout());
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, is_login]);

  return { user: profile, isLogin: is_login, accessToken };
}
