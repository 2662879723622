import React, { useState, useEffect, useRef } from "react";
import Footer from "components/footer";
import Breadcrumbs from "components/Breadcrumb";
import { Link } from "react-router-dom";
import VDLogo from "assets/logo-vd.png";
import { useParams } from "react-router-dom";
import * as ProductDetailServices from "pages/ProductDetail/services";
import * as AddNewBusinessServices from "pages/AddNewBusiness/services";
import { useFormik } from "formik";
import * as Yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateImage from "assets/date.png";
import UploadImage from "components/UploadImage";
import { useSelector, useDispatch } from "react-redux";
import {
  provinsiData,
  kecamatanData,
  kelurahanData,
  kabupatenData,
  aktifitasData,
  kriteriaData,
  jenisPengadaanData,
} from "redux/reducers/region";
import Captcha from "components/Captcha";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "components/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Map from "components/Map";
import UploadFile from "components/UploadFile";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import get from "lodash/get";
import { DateTime } from "luxon";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import InputSikap from "components/InputSikap";

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      display: "flex",
      width: "100%",
      alignItems: "center",
    },
  })
);

function CompanyEdit() {
  const dispatch = useDispatch();
  const captchaRef = useRef();
  const classDialog = useStyles();

  const [startDate, setStartDate] = useState(new Date());
  const startDay = startDate.toLocaleString("default", { day: "2-digit" });
  const startMonth = startDate.toLocaleString("default", { month: "numeric" });
  const startYear = startDate.toLocaleString("default", { year: "numeric" });
  let startDateFormat = `${startYear}-${startMonth}-${startDay}`;

  const {
    provinsi,
    kecamatan,
    kelurahan,
    kabupaten,
    aktifitas,
    kriteria,
    jenisPengadaan,
  } = useSelector((state) => state?.region);

  // const [listProvinsi, setListProvinsi] = useState([]);
  // eslint-disable-next-line
  // const [listKabupaten, setListKabupaten] = useState([]);
  // const [listKecamatan, setListKecamatan] = useState([]);
  const [listPendapatan] = useState([
    { id: 1, name: "< 1 Milyar" },
    { id: 2, name: "> 1 Milyar" },
  ]);

  // const [listAktifitas, setlistAktifitas] = useState([]);

  // const [listKriteria, setListKriteria] = useState([])

  // const [listJenisPengadaan, setListJenisPengadaan] = useState([])

  const EditVendorSchema = Yup.object().shape({
    // npwp: Yup.string().required("NPWP Wajib Diisi").nullable(),
    // nib: Yup.string().min(16).max(16).required(),
    gender: Yup.string().required("Jenis Kelamin Wajib Diisi"),
    province_id: Yup.number().required("Provinsi Wajib Diisi"),
    address: Yup.string().required("Alamat Wajib Diisi"),
    // city_id: Yup.number().required(),
    phone: Yup.number().required("No Telepon Wajib Diisi"),
    email: Yup.string().required("Email Wajib Diisi"),
    tnc: Yup.boolean().oneOf(
      [true],
      "Anda Harus Mencentang Syarat dan Ketentuan"
    ),
  });

  const [selectedBusinessActivity, setSelectedBusinessActivity] = useState([]);

  // const [selectedBusinessCriteria, setSelectedBusinessCriteria] = useState([])

  // const handleBusinessCriteriaChange = (event) => {
  //   if (selectedBusinessCriteria?.map?.(data => data.id).indexOf(event?.map(event => event.id)) < 0) {
  //     setSelectedBusinessCriteria(prev => (event))
  //   } else {
  //     setSelectedBusinessCriteria(prev => { return prev.filter(i => i !== event) })
  //   }
  // }

  const [detailResponse, setResponse] = useState({
    data: [],
    statistika: [],
  });

  const { id } = useParams();
  const [listPBJP, setlistPBJP] = useState([]);

  useEffect(() => {
    ProductDetailServices.getVendorDetail(
      {
        id: id,
      },
      (response, error) => {
        if (!error) {
          setResponse((prev) => ({ ...prev, data: response }));
        }
      }
    );

    ProductDetailServices.getStatistikaPemilihan(
      {
        id: id,
      },
      (response, error) => {
        if (!error) {
          setResponse((prev) => ({
            ...prev,
            statistika: response?.data?.original?.data,
          }));
        }
      }
    );

    AddNewBusinessServices.getProvinsi({}, (response, err) => {
      if (!err) {
        dispatch(provinsiData({ provinsi: response.data }));
      }
    });

    AddNewBusinessServices.getAktifitas({}, (response, err) => {
      if (!err) {
        dispatch(aktifitasData({ aktifitas: response.data }));
      }
    });

    AddNewBusinessServices.getKriteriaUsaha({}, (response, err) => {
      if (!err) {
        dispatch(kriteriaData({ kriteria: response.data }));
      }
    });

    AddNewBusinessServices.getJenisPengadaan({}, (response, err) => {
      if (!err) {
        dispatch(jenisPengadaanData({ jenisPengadaan: response.data }));
      }
    });

    AddNewBusinessServices.getPBJP({}, (response, err) => {
      if (!err) {
        setlistPBJP({ response });
      }
    });
    // eslint-disable-next-line
  }, []);

  const handleBusinessActivityChange = (event) => {
    if (
      selectedBusinessActivity
        ?.map?.((data) => data.id)
        .indexOf(event?.map((event) => event.id)) < 0
    ) {
      setSelectedBusinessActivity((prev) => event);
    } else {
      setSelectedBusinessActivity((prev) => {
        return prev.filter((i) => i !== event);
      });
    }
  };
  // const aktifitasDefault = detailResponse.data.business_activities ? detailResponse.data.business_activities.map(data => ({id: data.id, name: data.name, code: data.code})) : {id: 1, name: 'Pertanian, Kehutanan, dan Perikanan', code: 'A'}
  const aktifitasDefaultID = detailResponse.data.business_activities
    ? detailResponse.data.business_activities.map((data) => data.id).join(",")
    : { id: 1 };
  const [message, setMessage] = useState({
    open: false,
    severity: "",
    message: "",
  });

  useEffect(() => {
    if (detailResponse?.data?.city_id) {
      AddNewBusinessServices.getKecamatan(
        { city_id: detailResponse?.data?.city_id },
        (data) => dispatch(kecamatanData({ kecamatan: data.data }))
      );
    }

    if (detailResponse?.data?.province_id) {
      AddNewBusinessServices.getKabupaten(
        { province_id: detailResponse?.data?.province_id },
        (data) => dispatch(kabupatenData({ kabupaten: data.data }))
      );
    }

    if (detailResponse?.data?.district_id) {
      AddNewBusinessServices.getKelurahan(
        { district_id: detailResponse?.data?.district_id },
        (data) => dispatch(kelurahanData({ kelurahan: data.data }))
      );
    }
    // eslint-disable-next-line
  }, [detailResponse?.data]);
  function addDots(nStr) {
    nStr += "";
    let x = nStr?.split(".");
    let x1 = x?.[0];
    let x2 = x?.length > 1 ? "." + x?.[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx?.test?.(x1)) {
      // eslint-disable-next-line
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  }
  // eslint-disable-next-line
  const {
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
    errors,
    touched,
    // isSubmitting,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: {
      user_id: get(detailResponse, "data.user_id", ""),
      business_type_id: get(detailResponse, "data.business_type_id", ""),
      npwp: get(detailResponse, "data.npwp", ""),
      npwp_file: "",
      nib: get(detailResponse, "data.nib", ""),
      name: get(detailResponse, "data.name", ""),
      business_activity_id: aktifitasDefaultID,
      business_criteria_id: get(
        detailResponse,
        "data.business_criteria_id",
        ""
      ),
      date_established: get(detailResponse, "data.date_established", ""),
      procurement_type_id: get(detailResponse, "data.procurement_type_id", ""),
      description: get(detailResponse, "data.description", ""),
      monthly_income: get(detailResponse, "data.monthly_income", ""),
      number_branch_office: get(
        detailResponse,
        "data.number_branch_office",
        ""
      ),
      number_employee: get(detailResponse, "data.number_employee", ""),
      experience_pbjp_id: get(detailResponse, "data.experience_pbjp_id", ""),
      address: get(detailResponse, "data.address", ""),
      latitude: get(detailResponse, "data.latitude", -6.217569),
      longitude: get(detailResponse, "data.longitude", 106.833924),
      province_id: get(detailResponse, "data.province_id", ""),
      city_id: get(detailResponse, "data.city_id", ""),
      district_id: get(detailResponse, "data.district_id", ""),
      subdistrict_id: get(detailResponse, "data.subdistrict_id", ""),
      postal_code: get(detailResponse, "data.postal_code", ""),
      phone: get(detailResponse, "data.phone", ""),
      whatsapp: get(detailResponse, "data.whatsapp", ""),
      website: get(detailResponse, "data.website", ""),
      email: get(detailResponse, "data.email", ""),
      gender: "male",
      degree: "",
      last_education: "",
      status: 1,
      logo_picture: "",
      profile_file: "",
      product_1: "",
      product_2: "",
      product_3: "",
      captcha: "",
      captcha_key: "",
      tnc: false,
      "pengalaman_e-katalog_status": get(
        detailResponse,
        "data.detailPengalamanUsaha.e-katalog.status",
        0
      ),
      "pengalaman_e-katalog_count": get(
        detailResponse,
        "data.detailPengalamanUsaha.e-katalog.count",
        ""
      ),
      "pengalaman_e-katalog_nominal": get(
        detailResponse,
        "data.detailPengalamanUsaha.e-katalog.nominal",
        ""
      ),
      pengalaman_toko_daring_status: get(
        detailResponse,
        "data.detailPengalamanUsaha.toko_daring.status",
        0
      ),
      pengalaman_toko_daring_count: get(
        detailResponse,
        "data.detailPengalamanUsaha.toko_daring.count",
        ""
      ),
      pengalaman_toko_daring_nominal: get(
        detailResponse,
        "data.detailPengalamanUsaha.toko_daring.nominal",
        ""
      ),
      pengalaman_pbj_internasional_status: get(
        detailResponse,
        "data.detailPengalamanUsaha.pbj_internasional.status",
        0
      ),
      pengalaman_pbj_internasional_count: get(
        detailResponse,
        "data.detailPengalamanUsaha.pbj_internasional.count",
        ""
      ),
      pengalaman_pbj_internasional_nominal: get(
        detailResponse,
        "data.detailPengalamanUsaha.pbj_internasional.nominal",
        ""
      ),
      jumlah_penawaran: get(detailResponse, "statistika.jumlah_penawaran", ""),
      jumlah_menang_tender: get(
        detailResponse,
        "statistika.jumlah_menang_tender",
        ""
      ),
      last_update: get(detailResponse, "statistika.last_update", "")
        ? DateTime.fromISO(
          get(detailResponse, "statistika.last_update", "")
        ).toFormat("d LLLL yyyy", { locale: "id" })
        : "",
      total_nilai_kontrak_tender: get(
        detailResponse,
        "statistika.total_nilai_kontrak_tender",
        ""
      ),
    },
    validationSchema: EditVendorSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      AddNewBusinessServices.createVendor(
        { id: id, ...values },
        (isSuccess, data) => {
          captchaRef.current.reloadCaptcha();
          setFieldValue("captcha", "");
          if (isSuccess) {
            setMessage({
              open: true,
              severity: "success",
              message: "Berhasil Mensunting Usaha!",
            });
            window.timeout = setTimeout(() => {
              window.location.replace(
                `/product_detail/${detailResponse?.data?.id}/${detailResponse?.data?.id}`
              );
            }, 1500);
          } else {
            const { response } = data;
            if (response.status === 422) {
              const { errors } = response.data;
              if (errors) {
                Object.keys(errors).forEach((attributeName) => {
                  setFieldError(
                    attributeName,
                    errors[attributeName].toString()
                  );
                });
                const firstMessage = Object.keys(errors)
                  .map((attrName) => errors[attrName].toString())
                  .toString();
                setMessage({
                  open: true,
                  severity: "error",
                  message: firstMessage,
                });
              } else if (response?.data?.message) {
                setMessage({
                  open: true,
                  severity: "error",
                  message: response.data.message,
                });
              }
            }
          }
        },
        {
          success: (response) => {
            console.log(response);
          },
          error: (error) => {
            console.log(error.data);
            if (error.status === 422) {
              setMessage({
                open: true,
                severity: "error",
                message: error.data,
              });
            }
          },
        }
      );
    },
  });
  // console.log(detailResponse?.data)
  // console.log(values?.profile_file)
  // console.log(aktifitasDefault)
  const textTerms = `1. Data yang diisikan dalam Form Aplikasi merupakan tanggung jawab pengguna aplikasi;
  2. Pihak LKPP tidak bertanggung jawab terhadap keabsahan data yang diisi pengguna.`;
  const textPenawaran = `Data akan diupdate otomatis setiap bulannya sesuai perkembangan usaha anda di SIKaP`;
  // if (detailResponse.data.business_activities) {
  //   aktifitasDefault = detailResponse.data.business_activities.map(data => ({code: data.code, id: data.id, name: data.name}))
  // } else {
  //   aktifitasDefault = {id: 1, name: 'Pertanian, Kehutanan, dan Perikanan', code: 'A'}
  // }
  // console.log(errors)

  const errorHandler = () => {
    if (Object.keys(errors).length !== 0) {
      Object.keys(errors).forEach((attributeName) => {
        setFieldError(attributeName, errors[attributeName].toString());
      });
      const firstMessage = Object.keys(errors)
        .map((attrName) => errors[attrName].toString())
        .toString();
      setMessage({
        open: true,
        severity: "error",
        message: firstMessage,
      });
    }
  };

  const callbackSikap = (res) => {
    const { attributes } = res.data;
    setFieldValue("npwp", attributes.npwp);
    setFieldValue("name", attributes.name);
    setFieldValue("province_id", attributes.province_id);
    setFieldValue("city_id", attributes.city_id);
    setFieldValue("number_branch_office", attributes.number_branch_office);
    setFieldValue("number_employee", attributes.number_employee);
    setFieldValue("website", attributes.website);
    setFieldValue("address", attributes.address);
    setFieldValue("phone", attributes.phone);
    setFieldValue("nib", attributes.nib);
  };

  const max = 2000000;
  return (
    <div>
      <div className="py-10 lg:px-20 px-6 pt-5">
        <Breadcrumbs
          links={[
            { href: "/", name: "Beranda" },
            { name: "Profile Pelaku Usaha", href: `/profile` },
            { name: "Ubah Informasi Usaha" },
          ]}
        />
      </div>

      <div className="py-5 flex w-full justify-center">
        <img src={VDLogo} alt="" style={{ height: 70 }} />
      </div>
      <div
        className="w-10/12 mb-20 mx-auto flex"
        style={{
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05",
        }}
      >
        <div className="mx-auto w-full mt-4 mb-8 px-6">
          <div className="flex flex-col lg:w-8/12 w-full mx-auto">
            <span className="font-baloo2 font-bold text-base text-black-2">
              Edit Usaha
            </span>
            <span className="font-baloo2 font-bold text-lg text-black-2 pt-2">
              {detailResponse?.data?.business_type?.name}
            </span>
          </div>
          <form
            onSubmit={handleSubmit}
            className="pt-4 flex flex-col lg:w-8/12 w-full mx-auto"
          >
            <div className="mb-4 border-b border-solid border-welcome w-12/12" />
            <label className="flex text-left">
              <div className="font-montserrat font-bold text-sm text-black-2">
                {detailResponse?.data?.business_type_id === 1
                  ? "Nama Lengkap"
                  : "Nama Perusahaan"}
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </label>
            <input
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              value={values?.name}
              name="name"
              {...getFieldProps("name")}
            />

            <label className="flex text-left mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                NPWP
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </label>
            <InputMask
              mask="99.999.999.9-999.999"
              alwaysShowMask
              {...getFieldProps("npwp")}
            >
              {(inputProps) => {
                return (
                  <InputSikap
                    type="usaha"
                    field="npwp"
                    className="w-full"
                    inputProps={{
                      className:
                        "w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                      name: "npwp",
                      ...inputProps,
                    }}
                    callback={callbackSikap}
                  />
                );
              }}
            </InputMask>

            {touched.npwp && errors.npwp && (
              <div className="text-red-500 text-xs">{errors.npwp}</div>
            )}

            <label className="flex text-left mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                NIB
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </label>
            <InputSikap
              type="usaha"
              field="nib"
              className="w-full"
              inputProps={{
                className:
                  "w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                name: "nib",
                ...getFieldProps("nib"),
                // type: "number",
                id: "my-input-number",
              }}
              // onChange={NibOnchange()}
              callback={callbackSikap}
            />
            {touched.nib && errors.nib && (
              <div className="text-red-500 text-xs">{errors.nib}</div>
            )}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                {detailResponse?.data?.business_type_id === 1
                  ? "Alamat Lengkap"
                  : "Alamat Lengkap Perusahaan"}
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              value={values?.address}
              name="address"
              {...getFieldProps("address")}
            />
            <div className="border border-b border-solid border-welcome my-4" />
            <div className="flex my-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Koordinat Lokasi Perusahaan
              </div>
            </div>

            <div className="flex w-12/12 flex-row">
              <div className="flex flex-col w-6/12">
                <div className="flex items-center">
                  <div className="mt-3 font-montserrat font-bold text-sm text-black-2">
                    Latitude
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <input
                  className="w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="latitude"
                  {...getFieldProps("latitude")}
                />
                {/* {(touched.latitude && errors.latitude) && (
                  <div className="text-red-500 text-xs">{errors.latitude}</div>
                )} */}
              </div>
              <div className="flex flex-col w-1/2">
                <div className="flex items-center">
                  <div className="mt-3 font-montserrat font-bold text-sm text-black-2">
                    Longitude
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <input
                  className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="longitude"
                  {...getFieldProps("longitude")}
                />
                {/* {(touched.longitude && errors.longitude) && (
                  <div className="text-red-500 text-xs">{errors.longitude}</div>
                )} */}
              </div>
            </div>
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Cari Alamat
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>

            <Map
              lat={values.latitude}
              long={values.longitude}
              onChange={(option) => {
                setFieldValue("latitude", option.y);
                setFieldValue("longitude", option.x);
              }}
            />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Provinsi
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            {/* <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="province_id "
              {...getFieldProps("province_id")}
              value={values?.province_id}
              onChange={(ev) => {
                AddNewBusinessServices.getKabupaten(
                  { province_id: Number(ev.target.value) },
                  (data) => dispatch(kabupatenData({ kabupaten: data.data }))
                );
                setFieldValue("province_id", Number(ev.target.value));
              }}
            >
              <option value="" disabled>
                Pilih Provinsi
              </option>
              {provinsi.map((provinsi) => (
                <option key={provinsi.id} value={provinsi.id}>
                  {provinsi.name}
                </option>
              ))}
            </select> */}
            <Autocomplete
              options={provinsi}
              onChange={(ev, value) => {
                AddNewBusinessServices.getKabupaten(
                  { province_id: value.id },
                  (data) => dispatch(kabupatenData({ kabupaten: data.data }))
                );
                setFieldValue("province_id", value.id);
              }}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              getOptionSelected={(option, value) => option.id === value.id}
              defaultValue={
                provinsi?.filter((data) => data.id === values.province_id)?.[0]
              }
              key={provinsi}
              renderInput={(props) => (
                <TextField
                  {...props}
                  variant="outlined"
                  placeholder="Pilih Kota / Kabupaten"
                  style={{ width: "100%" }}
                  className="font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  size="small"
                />
              )}
            />
            {touched.province_id && errors.province_id && (
              <div className="text-red-500 text-xs">{errors.province_id}</div>
            )}

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Kota/Kabupaten
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="city_id"
              {...getFieldProps("city_id")}
              value={values?.city_id}
              onChange={(ev) => {
                AddNewBusinessServices.getKecamatan(
                  { city_id: Number(ev.target.value) },
                  (data) => dispatch(kecamatanData({ kecamatan: data.data }))
                );
                setFieldValue("city_id", Number(ev.target.value));
              }}
            >
              <option value="" disabled>
                Pilih Kabupaten
              </option>
              {kabupaten.map((kabupaten) => (
                <option key={kabupaten.id} value={kabupaten.id}>
                  {kabupaten.name}
                </option>
              ))}
            </select>
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Kecamatan
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="district_id"
              {...getFieldProps("district_id")}
              value={values?.district_id}
              onChange={(ev) => {
                AddNewBusinessServices.getKelurahan(
                  { district_id: Number(ev.target.value) },
                  (data) => dispatch(kelurahanData({ kelurahan: data.data }))
                );
                setFieldValue("district_id", Number(ev.target.value));
              }}
            >
              <option value="" disabled>
                Pilih Kecamatan
              </option>
              {kecamatan.map((kecamatan) => (
                <option key={kecamatan.id} value={kecamatan.id}>
                  {kecamatan.name}
                </option>
              ))}
            </select>
            {/* {(touched.district_id && errors.district_id) && (
              <div className="text-red-500 text-xs">{errors.district_id}</div>
            )} */}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Kelurahan
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="subdistrict_id"
              {...getFieldProps("subdistrict_id")}
              value={values?.subdistrict_id}
            >
              <option value="" disabled>
                Pilih Kelurahan
              </option>
              {kelurahan?.map((kelurahan) => (
                <option key={kelurahan?.id} value={kelurahan?.id}>
                  {kelurahan?.name}
                </option>
              ))}
            </select>
            {/* {(touched.subdistrict_id && errors.subdistrict_id) && (
              <div className="text-red-500 text-xs">{errors.subdistrict_id}</div>
            )} */}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Kode Pos
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="postal_code"
              {...getFieldProps("postal_code")}
            />
            {/* {(touched.postal_code && errors.postal_code) && (
              <div className="text-red-500 text-xs">{errors.postal_code}</div>
            )} */}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Nomor telepon
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="phone"
              {...getFieldProps("phone")}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Nomor WhatsApp
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="whatsapp"
              {...getFieldProps("whatsapp")}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Website (setelah www.)
              </div>
              {/* <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div> */}
            </div>
            <input
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="website"
              {...getFieldProps("website")}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Email
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="email"
              {...getFieldProps("email")}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Aktifitas Usaha
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            {detailResponse?.data?.business_activities && (
              <Box
                sx={{
                  "& .MuiChip-root": {
                    background: "#F2F2F2",
                    border: "1px solid #CCCCCC",
                    boxSizing: "border-box",
                    borderRadius: "4px",
                  },
                  "& .MuiInputBase-root": {
                    background: "#f2f2f2",
                    border: "1px solid #CCCCCC",
                    boxSizing: "border-box",
                    borderRadius: "4px",
                    width: "100%",
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    color: "rgba(89, 89, 89, var(--tw-text-opacity))",
                  },
                  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
                  {
                    padding: "1px",
                  },
                }}
              >
                <Autocomplete
                  multiple
                  options={aktifitas}
                  getOptionLabel={(option) => option?.name}
                  filterSelectedOptions
                  // onChange={(event, value) => handleBusinessActivityChange(value)}
                  getOptionSelected={(option, value) => option.id === value.id}
                  // defaultValue={detailResponse?.data?.business_activity?.name}
                  onChange={(event, value) =>
                    setFieldValue(
                      "business_activity_id",
                      value?.map((i) => i.id).join(",")
                    )
                  }
                  defaultValue={detailResponse.data.business_activities.map(
                    (data) => ({
                      id: data.id,
                      name: data.name,
                      code: data.code,
                    })
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Aktivitas Usaha"
                    />
                  )}
                />
              </Box>
            )}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Kriteria Usaha
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="business_criteria_id"
              {...getFieldProps("business_criteria_id")}
            >
              <option value="" disabled>
                Pilih Kriteria Usaha
              </option>
              {kriteria.map((kriteria) => (
                <option key={kriteria.id} value={kriteria.id}>
                  {kriteria.name}
                </option>
              ))}
            </select>
            {/* {(touched.business_criteria_id && errors.business_criteria_id) && (
              <div className="text-red-500 text-xs">{errors.business_criteria_id}</div>
            )} */}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Jenis Pengadaan
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="procurement_type_id"
              {...getFieldProps("procurement_type_id")}
            >
              <option value="" disabled>
                Pilih Jenis Pengadaan
              </option>
              {jenisPengadaan.map((jenis) => (
                <option key={jenis.id} value={jenis.id}>
                  {jenis.name}
                </option>
              ))}
            </select>
            {/* <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Komoditas Usaha
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <Box
              sx={{
                '& .MuiChip-root': {
                  background: '#F2F2F2',
                  border: '1px solid #CCCCCC',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                },
                '& .MuiInputBase-root': {
                  background: '#f2f2f2',
                  border: '1px solid #CCCCCC',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                  width: '92%',
                  fontWeight: '500',
                  fontFamily: "Montserrat",
                  fontSize: '0.75rem',
                  lineHeight: '1rem',
                  color: 'rgba(89, 89, 89, var(--tw-text-opacity))',
                }, 
                '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                  padding: '1px',
                }
              }}
            >
              <Autocomplete
                multiple
                options={kriteria}
                getOptionLabel={(option) => (option?.name)}
                filterSelectedOptions
                onChange={(event, value) => handleBusinessCriteriaChange(value)}
                getOptionSelected={(option, value) => option.id === value.id}
                value={selectedBusinessCriteria}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Komoditas Usaha"
                  />
                )}
              />
            </Box> */}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Informasi perusahaan
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <div
              contentEditable="true"
              className="w-full h-28 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="description"
              {...getFieldProps("description")}
              dangerouslySetInnerHTML={{
                __html: values?.description?.replace(/( |<([^>]+)>)/gi, " "),
              }}
            />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Jumlah kantor cabang
              </div>
              {/* <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div> */}
            </div>
            <input
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="number_branch_office"
              type="number"
              min="0"
              {...getFieldProps("number_branch_office")}
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Tanggal Mulai
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                const startDay = date?.toLocaleString("default", {
                  day: "2-digit",
                });
                const startMonth = date?.toLocaleString("default", {
                  month: "numeric",
                });
                const startYear = date?.toLocaleString("default", {
                  year: "numeric",
                });
                let startDateFormated = `${startYear}-${startMonth}-${startDay}`;
                setStartDate(date);
                setFieldValue("date_established", startDateFormated);
              }}
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              maxDate={new Date()}
              dropdownMode="select"
              customInput={
                <div className="flex flex-row justify-between items-center bg-neutral-grey rounded-lg py-2 pr-3 font-semibold text-base font-custom w-full">
                  <input
                    type="text"
                    id="custom"
                    placeholder={`${startDateFormat}`}
                    className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border-0"
                  />
                  <img src={dateImage} alt="" className="h-5" />
                </div>
              }
            />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Jumlah Karyawan
              </div>
              {/* <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div> */}
            </div>
            <input
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="number_employee"
              type="number"
              min="0"
              {...getFieldProps("number_employee")}
            />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Penghasilan Perbulan
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="monthly_income"
              {...getFieldProps("monthly_income")}
            >
              {listPendapatan.map((pendapatan) => (
                <option key={pendapatan.id} value={pendapatan.name}>
                  {pendapatan.name}
                </option>
              ))}
            </select>

            <div className="border-b border-solid border-welcome py-4" />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pengalaman E-katalog
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className=" font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="pengalaman_e-katalog_status"
              {...getFieldProps("pengalaman_e-katalog_status")}
              onChange={(ev) =>
                setFieldValue(
                  "pengalaman_e-katalog_status",
                  Number(ev.target.value)
                )
              }
            >
              <option value="" disabled>
                Pilih Pengalaman
              </option>
              <option value={1}>Pernah</option>
              <option value={0}>Tidak Pernah</option>
            </select>

            {values["pengalaman_e-katalog_status"] !== 0 && (
              <>
                {" "}
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Jumlah Melakukan Transaksi / Kontrak (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <input
                  className=" font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="pengalaman_e-katalog_count"
                  {...getFieldProps("pengalaman_e-katalog_count")}
                  onChange={(ev) =>
                    setFieldValue("pengalaman_e-katalog_count", ev.target.value)
                  }
                  type="number"
                />
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Nilai Akumulasi kontrak dalam 1 tahun (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <NumberFormat
                  value={values?.["pengalaman_e-katalog_nominal"]}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value, props) => (
                    <input
                      className="w-full no-spinner font-montserrat p-3 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                      name="pengalaman_e-katalog_nominal"
                      {...props}
                      value={value}
                      onChange={(ev) =>
                        setFieldValue(
                          "pengalaman_e-katalog_nominal",
                          ev.target.value.replaceAll(",", "")
                        )
                      }
                    />
                  )}
                />
              </>
            )}
            <div className="border-b border-solid border-welcome py-4 " />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pengalaman Toko Daring
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className=" font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="pengalaman_toko_daring_status"
              {...getFieldProps("pengalaman_toko_daring_status")}
              onChange={(ev) =>
                setFieldValue(
                  "pengalaman_toko_daring_status",
                  Number(ev.target.value)
                )
              }
            >
              <option value="" disabled>
                Pilih Pengalaman
              </option>
              <option value={1}>Pernah</option>
              <option value={0}>Tidak Pernah</option>
            </select>

            {values.pengalaman_toko_daring_status !== 0 && (
              <>
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Jumlah Melakukan Transaksi / Kontrak (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <input
                  className=" font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="pengalaman_toko_daring_count"
                  {...getFieldProps("pengalaman_toko_daring_count")}
                  onChange={(ev) =>
                    setFieldValue(
                      "pengalaman_toko_daring_count",
                      ev.target.value
                    )
                  }
                  type="number"
                />

                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Nilai Akumulasi kontrak dalam 1 tahun (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <NumberFormat
                  value={values?.["pengalaman_toko_daring_nominal"]}
                  displayType={"text"}
                  thousandsGroupStyle="thousand"
                  thousandSeparator={true}
                  renderText={(value, props) => (
                    <input
                      className="w-full no-spinner font-montserrat p-3 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                      name="pengalaman_toko_daring_nominal"
                      {...props}
                      value={value}
                      onChange={(ev) =>
                        setFieldValue(
                          "pengalaman_toko_daring_nominal",
                          ev.target.value.replaceAll(",", "")
                        )
                      }
                    />
                  )}
                />
              </>
            )}

            <div className="border-b border-solid border-welcome py-4 " />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pengalaman PBJ Internasional
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className=" font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="pengalaman_pbj_internasional_status"
              {...getFieldProps("pengalaman_pbj_internasional_status")}
              onChange={(ev) =>
                setFieldValue(
                  "pengalaman_pbj_internasional_status",
                  Number(ev.target.value)
                )
              }
            >
              <option value="" disabled>
                Pilih Pengalaman
              </option>
              <option value={1}>Pernah</option>
              <option value={0}>Tidak Pernah</option>
            </select>

            {values.pengalaman_pbj_internasional_status !== 0 && (
              <>
                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Jumlah Melakukan Transaksi / Kontrak (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <input
                  className=" font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  name="pengalaman_pbj_internasional_count"
                  {...getFieldProps("pengalaman_pbj_internasional_count")}
                  onChange={(ev) =>
                    setFieldValue(
                      "pengalaman_pbj_internasional_count",
                      ev.target.value
                    )
                  }
                  type="number"
                />

                <div className="flex mt-3">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Nilai Akumulasi kontrak dalam 1 tahun (YTD)
                  </div>
                  <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                    *
                  </div>
                </div>
                <NumberFormat
                  value={values?.["pengalaman_pbj_internasional_nominal"]}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value, props) => (
                    <input
                      className="w-full no-spinner font-montserrat p-3 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                      name="pengalaman_pbj_internasional_nominal"
                      {...props}
                      value={value}
                      onChange={(ev) =>
                        setFieldValue(
                          "pengalaman_pbj_internasional_nominal",
                          ev.target.value.replaceAll(",", "")
                        )
                      }
                    />
                  )}
                />
              </>
            )}

            <div className="border-b border-solid border-welcome py-4 " />

            {/* <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pengalaman PBJP
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <div className="inline-block relative w-full">
              <select
                className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                name="experience_pbjp_id"
                {...getFieldProps('experience_pbjp_id')}
                value={values?.experience_pbjp_id}
                
              >
                {listPBJP?.response?.data?.map(pbjp => (
                  <option key={pbjp.id} value={pbjp.id}>
                    {pbjp.name}
                  </option>
                ))}
              </select>
              <input
                className=" font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                name="experience_pbjp_id"
                type="number"
                min="0"
                {...getFieldProps('experience_pbjp_id')}
              />
              <span id="my-suffix" className="font-montserrat text-xs">Tahun</span>
            </div> */}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Upload Company Profile (maks 2 MB)
              </div>
            </div>
            <UploadFile
              message="File Lebih Besar Dari 2 MB!"
              max={max}
              inputProps={{
                name: "profile_file",
                onChange: (e) =>
                  setFieldValue(
                    "profile_file",
                    e.target.files.length ? e.target.files[0] : null
                  ),
              }}
              classWidth="w-full"
            />
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Upload logo (maks 500kb)
              </div>
              {/* <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div> */}
            </div>
            <UploadImage
              preview={detailResponse?.data?.logo_picture_url}
              inputProps={{
                name: "logo_picture",
                file: values?.logo_picture,
                onChange: (e) =>
                  setFieldValue(
                    "logo_picture",
                    e.target.files.length ? e.target.files[0] : null
                  ),
              }}
              byteSize={500000}
              byteSizeName={"500 kb"}
            />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Produk 1 (maks 500kb)
              </div>
            </div>
            <UploadImage
              preview={detailResponse?.data?.product_1_url}
              inputProps={{
                name: "product_1",
                onChange: (e) =>
                  setFieldValue(
                    "product_1",
                    e.target.files.length ? e.target.files[0] : null
                  ),
              }}
              byteSize={500000}
              byteSizeName={"500 kb"}
            />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Produk 2 (maks 500kb)
              </div>
            </div>
            <UploadImage
              preview={detailResponse?.data?.product_2_url}
              inputProps={{
                name: "product_2",
                onChange: (e) =>
                  setFieldValue(
                    "product_2",
                    e.target.files.length ? e.target.files[0] : null
                  ),
              }}
              byteSize={500000}
              byteSizeName={"500 kb"}
            />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Produk 3 (maks 500kb)
              </div>
            </div>
            <UploadImage
              preview={detailResponse?.data?.product_3_url}
              inputProps={{
                name: "product_3",
                onChange: (e) =>
                  setFieldValue(
                    "product_3",
                    e.target.files.length ? e.target.files[0] : null
                  ),
              }}
              byteSize={500000}
              byteSizeName={"500 kb"}
            />

            <div className="border-b border-solid border-welcome py-4" />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Jumlah memasukkan penawaran (YTD)
              </div>
            </div>
            <input
              className="font-montserrat p-2 font-medium text-xs text-black-2 bg-search border border-solid border-welcome rounded no-spinner"
              {...getFieldProps("jumlah_penawaran")}
              disabled
            />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Jumlah memenangkan Kontrak (YTD)
              </div>
            </div>
            <input
              className="font-montserrat p-2 font-medium text-xs text-black-2 bg-search border border-solid border-welcome rounded no-spinner"
              {...getFieldProps("jumlah_menang_tender")}
              disabled
            />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Total nilai kontrak Kontrak (Rp)
              </div>
            </div>
            <InputMask
              mask="999.999.999.999.999"
              alwaysShowMask
              {...getFieldProps("total_nilai_kontrak_tender")}
              className="font-montserrat p-2 font-medium text-xs text-black-2 bg-search border border-solid border-welcome rounded no-spinner"
              disabled
            ></InputMask>
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Terakhir diupdate
              </div>
            </div>
            <input
              className="font-montserrat p-2 font-medium text-xs text-black-2 bg-search border border-solid border-welcome rounded no-spinner"
              {...getFieldProps("last_update")}
              disabled
            />

            <div className="whitespace-pre-line	pt-5 font-medium font-montserrat text-xs text-black-2">
              {textPenawaran}
            </div>

            <div className="border-b border-solid border-welcome py-4" />

            <div className="pt-3">
              <Captcha
                ref={captchaRef}
                inputProps={{
                  className:
                    "w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                  ...getFieldProps("captcha"),
                }}
                callback={(option) => {
                  setFieldValue("captcha_key", option.key);
                }}
              />
            </div>

            <div className="whitespace-pre-line	pt-5 font-medium font-montserrat text-xs text-black-2">
              {textTerms}
            </div>

            <div className="py-5 flex">
              <input
                className="font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                type="checkbox"
                {...getFieldProps("tnc")}
              />
              <label className="pl-4 font-montserrat font-medium text-xs text-black-2">
                Dengan ini saya telah menyetujui
                <a
                  href="/syarat_dan_ketentuan"
                  className="text-pink-header px-1"
                >
                  Syarat dan Ketentuan
                </a>
                yang berlaku
              </label>
            </div>

            <div className="w-full flex ">
              <button
                onClick={() => resetForm()}
                type="button"
                className="w-full py-2 font-montserrat text-xs font-medium text-border-on-pink bg-black-2 rounded border-solid border border-white"
              >
                Batalkan
              </button>
              <button
                onClick={errorHandler}
                type="submit"
                className="w-full py-2 font-montserrat text-xs font-medium text-border-on-pink bg-pink-header rounded border-solid border border-pink-header"
              >
                Simpan Perubahan
              </button>
            </div>
          </form>
          <Dialog
            visible={message.open === true}
            onClose={message.open !== true}
            className="mt-3"
          >
            <Alert severity={message.severity} className={classDialog.dialog}>
              {message.message}
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMessage({ open: false, error: "", message: "" });
                }}
              >
                <CloseIcon alt="" className="ml-3 self-center" />
              </IconButton>
            </Alert>
          </Dialog>
          {errors && (
            <Dialog
              visible={message.open === true}
              onClose={message.open !== true}
              className="mt-3"
            >
              <Alert severity={message.severity} className={classDialog.dialog}>
                {message.message}
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setMessage({ open: false, error: "", message: "" });
                  }}
                >
                  <CloseIcon alt="" className="ml-3 self-center" />
                </IconButton>
              </Alert>
            </Dialog>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CompanyEdit;
