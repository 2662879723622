import React, { useRef } from "react";
import PropTypes from "prop-types";
import Footer from "components/footer";
import Box from "@material-ui/core/Box";
import UploadImage from "components/UploadImage";
import Breadcrumb from "components/Breadcrumb";
import InputSikap from "components/InputSikap";
import InputMask from "react-input-mask";
import Ticket from "../components/Ticket";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import Captcha from "components/Captcha";

export default function BuatDaftarKegiatan() {
  // const formik = useFormik()

  const captchaRef = useRef();
  // const { getFieldProps, setFieldValue } = formik

  return (
    <Box>
      <Box className="w-9/12 mx-auto py-10">
        <Breadcrumb
          links={[
            { href: "/", name: "Beranda" },
            { href: "/kalendar", name: "Kalendar Kegiatan" },
            { name: "Daftar Kegiatan" },
          ]}
        />

        <Box className="min-h-screen">
          <div className="flex items-center justify-center">
            <img
              src="/static/images/logo-vd.png"
              alt=""
              style={{ height: 70 }}
            />
          </div>

          <Box
            className="px-10 py-4 my-4 rounded-lg flex items-start space-x-5"
            boxShadow={3}
          >
            <div className="w-1/2 pr-3">
              <div>
                <span className="text-black-2 font-baloo2 text-4xl font-bold">
                  Tingkat Credit Score Usaha Anda!
                </span>

                <div className="border-b-2 border-pink-header w-4/12 mt-10"></div>
              </div>
              <div className="my-4">
                <p className="text-black-2 font-baloo2 text-lg">
                  Peningkatan Kapasitas Pelaku Usaha merupakan kegiatan untuk
                  memberikan wawasan dan pelatihan kepada pelaku usaha dalam
                  Pengadaan Barang/Jasa Pemerintah.
                </p>
              </div>

              <Ticket classLeft="w-1/2" classRight="w-1/2" />
            </div>
            <div className="w-1/2 pl-3 border-l border-gray-200">
              <div className="border-b pb-3 border-gray-200">
                <h3 className="font-baloo2 whitespace-pre font-bold text-lg text-black-2">
                  Formulir daftar kegiatan
                </h3>
                <span className="font-baloo2 whitespace-pre font-bold text-4xl text-black-2">
                  Kegiatan PKP
                </span>
              </div>

              <form className="space-y-3 py-3">
                <div>
                  <span className="text-black-2 text-sm font-semibold">
                    Cek jika perusahaan anda sudah terdaftar di SIKap dan LPSE
                  </span>
                </div>
                <div className="input-container">
                  <div className="flex">
                    <div className="font-montserrat font-bold text-sm text-black-2">
                      NPWP
                    </div>
                  </div>
                  <InputMask
                    mask="99.999.999.9-999.999"
                    alwaysShowMask
                    // {...getFieldProps("npwp")}
                  >
                    {(inputProps) => {
                      return (
                        <InputSikap
                          type="usaha"
                          field="npwp"
                          className="w-full"
                          inputProps={{
                            className:
                              "w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                            name: "npwp",
                            ...inputProps,
                          }}
                          // callback={callbackSikap}
                        />
                      );
                    }}
                  </InputMask>
                </div>

                <div className="flex">
                  <div className="font-montserrat font-bold text-sm text-black-2">
                    Upload NPWP (maks 250kb)
                  </div>
                  {/* <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div> */}
                </div>
                <UploadImage
                  inputProps={{
                    name: "npwp_file",
                    // onChange: (e) =>
                    //   setFieldValue(
                    //     "npwp_file",
                    //     e.target.files.length ? e.target.files[0] : null
                    //   ),
                  }}
                  byteSize={250000}
                  byteSizeName={"250 kb"}
                />
                {/* {touched.gender && errors.gender && (
              <div className="text-red-500 text-xs">{errors.gender}</div>
            )} */}
                <div className="input-container">
                  <div className="flex">
                    <div className="font-montserrat font-bold text-sm text-black-2">
                      NIB
                    </div>
                  </div>
                  <InputSikap
                    type="usaha"
                    field="nib"
                    className="w-full"
                    inputProps={{
                      className:
                        "w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                      name: "nib",
                      // ...getFieldProps("nib"),
                      type: "number",
                      id: "my-input-number",
                    }}
                    // onChange={NibOnchange()}
                    // callback={callbackSikap}
                  />
                  {/* {touched.nib && errors.nib && (
                    <div className="text-red-500 text-xs">{errors.nib}</div>
                  )} */}
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    Nama Lengkap
                  </span>
                  <input
                    className="w-full form-input px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300"
                    placeholder="Masukkan nama lengkap"
                  />
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    Email
                  </span>
                  <input
                    type="email"
                    className="w-full form-input px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300"
                    placeholder="Email"
                  />
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    Alamat
                  </span>
                  <input
                    className="w-full form-input px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300"
                    placeholder="Masukkan alamat"
                  />
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    No Telpon (WhatsApp)
                  </span>
                  <input
                    className="w-full form-input px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300"
                    placeholder="Masukkan no telpon (WhatsApp)"
                    type="tel"
                  />
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    Nama badan usaha / perusahaan
                  </span>
                  <input
                    className="w-full form-input px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300"
                    placeholder="Masukkan nama badan usaha / perusahaan"
                  />
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    Pilih jenis usaha
                  </span>
                  <select className="w-full form-select px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300">
                    <option value="">Pilih jenis usaha</option>
                    <option value={1}>Usaha Mikro / Kecil (UMK)</option>
                  </select>
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    Jabatan
                  </span>
                  <input
                    className="w-full form-input px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300"
                    placeholder="Masukkan jabatan"
                  />
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    Pilih usaha
                  </span>
                  <select className="w-full form-select px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300">
                    <option value="">Pilih jenis usaha</option>
                    <option value={1}>Usaha Mikro / Kecil (UMK)</option>
                  </select>
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    Pilih jenis usaha berdasarkan kategori pengadaan
                  </span>
                  <select className="w-full form-select px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300">
                    <option value="">
                      Pilih jenis usaha berdasarkan kategori pengadaan
                    </option>
                    <option value={1}>Barang</option>
                  </select>
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    Pernah daftar sebagai pelaku usaha di BISA Pengadaan
                  </span>
                  <select className="w-full form-select px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300">
                    <option value="">Pilih</option>
                    <option value={1}>Ya</option>
                    <option value={0}>Tidak</option>
                  </select>
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    Pernah mengikuti kegiatan PKP oleh LKPP?
                  </span>
                  <select className="w-full form-select px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300">
                    <option value="">Pilih</option>
                    <option value={1}>Ya</option>
                    <option value={0}>Tidak</option>
                  </select>
                </div>

                <div>
                  <span className="text-sm font-semibold font-montserrat label-required block text-black-2">
                    Pernah mengikuti Pengadaan Barang / Jasa Pemerintah?
                  </span>
                  <select className="w-full form-select px-4 py-2 bg-gray-200 rounded-lg text-sm font-montserrat border border-gray-300">
                    <option value="">Pilih</option>
                    <option value={1}>Ya</option>
                    <option value={0}>Tidak</option>
                  </select>
                </div>

                <div>
                  <Captcha
                    ref={captchaRef}
                    inputProps={{
                      className:
                        "w-11/12 font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                      // ...getFieldProps("captcha"),
                    }}
                    // callback={(option) => {
                    //   setFieldValue("captcha_key", option.key);
                    // }}
                  />
                </div>

                <ul className="list-decimal text-black-2 px-4">
                  <li className="text-sm font-montserrat">
                    Data yang diisikan dalam Form Aplikasi merupakan tanggung
                    jawab pengguna aplikasi;
                  </li>
                  <li className="text-sm font-montserrat">
                    Pihak LKPP tidak bertanggung jawab terhadap keabsahan data
                    yang diisi pengguna.
                  </li>
                </ul>

                <div className="py-5 flex items-start">
                  <input
                    className="font-montserrat p-2 font-medium text-sm text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                    type="checkbox"
                  />
                  <label className="pl-4 font-montserrat font-medium text-sm text-black-2">
                    Dengan ini saya telah menyetujui
                    <a
                      href="/syarat_dan_ketentuan"
                      className="text-pink-header px-1"
                    >
                      Syarat dan Ketentuan
                    </a>
                    yang berlaku
                  </label>
                </div>
                <div>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Simpan Perusahaan dan Daftar PKP
                  </Button>
                </div>
              </form>
            </div>
          </Box>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}
