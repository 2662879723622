import { createSlice } from "@reduxjs/toolkit";
import endpoint from "utils/endpoint";
import axios from "axios";
import faker from "faker/locale/id_ID";

const initialState = {
  isLoad: false,
  isError: false,
  error: null,
  kalendar: [],
};

export const kalendarSlice = createSlice({
  name: "kalendar",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoad = true;
    },
    hasError: (state, { payload }) => {
      state.isLoad = false;
      state.isError = true;
      state.error = payload;
    },
    getKalendarSuccess: (state, { payload }) => {
      state.isLoad = false;
      state.kalendar = payload;
    },
  },
});

export default kalendarSlice.reducer;

export function getKalendar(params) {
  return async (dispatch) => {
    const url = endpoint.kalendar;
    const config = {
      headers: {
        Authorization: `Bearer ${params?.token}`,
      },
      params,
    };
    try {
      dispatch(kalendarSlice.actions.startLoading());
      const response = await axios.get(url, config);
      // const response = await axios.get(url, { params });

      // const textRand = {
      //   1: "#00A65A",
      //   2: "#0073B7",
      //   3: "#595959",
      // };

      // const mock = {
      //   current_page: 1,
      //   per_page: 15,
      //   total: 15,
      //   data: [...Array(10)].map(() => ({
      //     title: faker.name.jobTitle(),
      //     start: faker.date.between("2022-01-01", "2022-12-30"),
      //     end: faker.date.between("2022-01-01", "2022-12-30"),
      //     textColor: textRand[Math.floor(Math.random() * 3)],
      //     updated_at: faker.date.recent(),
      //   })),
      // };

      dispatch(kalendarSlice.actions.getKalendarSuccess(response.data));
    } catch (error) {
      dispatch(kalendarSlice.actions.hasError(error.response));
    }
  };
}
