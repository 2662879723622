import axios from "axios";
import endpoint from "utils/endpoint";

export async function checkUser(data, callback) {
  const url = endpoint.forgotPasswordCheck;
  const config = {
    method: "POST",
    data,
  };

  try {
    const response = await axios(url, config);
    callback(true, response.data);
  } catch (error) {
    callback(false, error);
  }
}

export async function checkKegiatan(data, callback) {
  const url = endpoint.checkKegiatanAttendance;
  const config = {
    method: "POST",
    data,
  };

  try {
    const response = await axios(url, config);
    callback(true, response.data);
  } catch (error) {
    callback(false, error);
  }
}
