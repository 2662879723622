import React, { useState, useEffect, useRef } from "react";
import Footer from "components/footer";
// import classNames from 'classnames';
import ChatLogo from "assets/chat.png";
import Pencil from "assets/pencil.png";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
// import Example from 'assets/example.png';
import Checklist from "assets/checklist.png";
import Carousel from "components/carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductCard from "components/productCard";
import { useSelector } from "react-redux";
import "../../index.css";
import { useParams, useLocation } from "react-router-dom";
import * as services from "./services";
import Card from "components/card";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import Phone from "assets/phoneFooter.png";
import Mail from "assets/mail.png";
import WWW from "assets/world.png";
import Point from "assets/pinpoint.png";
import Navigation from "components/Navigation";
import { DateTime } from "luxon";
import useGlobalStyles from "hooks/useGlobalStyles";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import get from "lodash/get";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import parseQueryString from "utils/parseQueryString";
import useProfile from "hooks/useProfile";
import Dialog from "components/Dialog";
import { styled } from "@material-ui/core/styles";
import usePagination from "utils/usePagination";
import ProfileServices from "pages/Profile/services";
import Pagination from "components/pagination";
import {
  ThemeProvider,
  createTheme,
  Button,
  SvgIcon,
  Divider,
} from "@material-ui/core";
import theme from "theme";
import { format } from "date-fns";
import { id } from "date-fns/locale";

const useStyles = makeStyles((theme) =>
  createStyles({
    productInformation: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    pdp_profile_photo: {
      position: "sticky",
    },
  })
);

function ProductDetail() {
  const classes = useGlobalStyles();
  const refCarouselTop = useRef();
  const refCarouselBottom = useRef();
  const resultParams = parseQueryString(useLocation().search);
  const queryObject = React.useMemo(() => resultParams, [resultParams]);

  const { selectedBusinessActivity, selectedProcurementType } = queryObject;

  const [params, setParams] = React.useState({
    page: 1,
    pageAct: 1,
    limit: 3,
    total: 10,
  });

  const [total, setPagination] = usePagination();
  const [totalAct, setPaginationAct] = usePagination();

  const { user } = useProfile();
  const [detailResponse, setResponse] = useState({
    data: [],
    serupa: [],
    profileDetail: [],
    sertifikat: [],
    statistika: [],
    sertifikatKegiatan: [],
  });

  const DialogBox = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
      margin: "32px",
      position: "relative",
      overflowY: "auto",
    },
  }));

  const DialogBoxCertificate = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
      margin: "32px",
      position: "relative",
      overflowY: "auto",
      width: "100%",
    },
  }));

  const [orderList, setOrderList] = useState(false);
  const [certificateList, setCertificateList] = useState(false);
  const [actCertificateList, setActCertificateList] = useState(false);
  const { id } = useParams();
  const { profile } = useParams();

  const [carousel, setCarousel] = React.useState(0);
  const { is_login } = useSelector((state) => state.user);

  useEffect(() => {
    services.getVendorDetail(
      {
        id: id,
      },
      (response, error) => {
        if (!error) {
          setResponse((prev) => ({ ...prev, data: response }));
        }
      }
    );

    services.getStatistikaPemilihan(
      {
        id: id,
      },
      (response, error) => {
        if (!error) {
          setResponse((prev) => ({ ...prev, statistika: response?.data }));
        }
      }
    );

    services.getVendorSerupa(
      {
        page_size: 3,
        sort: "random",
        procurement_type_id: selectedProcurementType,
        business_activity_id: selectedBusinessActivity,
      },
      (response, error) => {
        if (!error) {
          setResponse((prev) => ({ ...prev, serupa: response }));
        }
      }
    );
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    services.getSertifikatKegiatan(
      {
        vendor_business_id: id,
        page_size: params.limit,
        page: params.pageAct,
      },
      (response, error) => {
        if (!error) {
          setResponse((prev) => ({
            ...prev,
            sertifikatKegiatan: response,
          }));
          setPaginationAct(3, response.total);
        }
      }
    );
  }, [params]);

  useEffect(() => {
    ProfileServices.getSertifikat(
      {
        page_size: params.limit,
        page: params.page,
        vendor_business_id: id,
      },
      {
        success: (data) => {
          setResponse((prev) => ({ ...prev, sertifikat: data }));
          setPagination(3, data.total);
        },
        error: (err) => {
          console.log(err);
        },
      }
    );
  }, [params]);
  // console.log(detailResponse?.sertifikatKegiatan?.data);
  const classProduct = useStyles();

  const sameID = detailResponse?.data?.user_id === get(user, "id");
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    dotsClass: "",
  };

  function addDots(nStr) {
    nStr += "";
    let x = nStr?.split(".");
    let x1 = x?.[0];
    let x2 = x?.length > 1 ? "." + x?.[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx?.test?.(x1)) {
      // eslint-disable-next-line
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  }

  function titleCase(str) {
    var splitStr = str?.toLowerCase()?.split(" ");
    for (var i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr?.[i]?.charAt(0)?.toUpperCase() + splitStr[i]?.substring(1);
    }
    return splitStr?.join(" ");
  }

  const createdTime = DateTime?.fromISO(
    detailResponse?.data?.user?.created_at
  )?.toFormat("dd LLL yyyy");
  const updatedTime = DateTime?.fromISO(
    detailResponse?.data?.user?.updated_at
  )?.toFormat("dd LLL yyyy");
  // console.log(get(detailResponse, "data.detailPengalamanUsaha", ""));
  return (
    <div className={classnames("py-3", classes.container)}>
      <Navigation />
      <div className="flex flex-col">
        <Breadcrumbs>
          <Link
            className="font-montserrat font-medium text-sm text-pink-header pl-20"
            to="/"
          >
            Beranda
          </Link>
          <Link
            to="/search"
            className="font-montserrat font-medium text-sm text-pink-header"
          >
            Pencarian Vendor
          </Link>
          <Link
            to="/search"
            className="font-montserrat font-medium text-sm text-black-2"
          >
            {detailResponse?.data?.name}
          </Link>
        </Breadcrumbs>
      </div>

      <div className="py-8 px-20 flex justify-between w-full">
        <div className="flex self-start">
          <img
            src={detailResponse?.data?.logo_picture_url}
            alt=""
            className="pr-6 w-32 border-r border-solid border-welcome"
          />
          <span className="ml-6 font-baloo2 font-bold text-3xl text-black-2 border-b-2 border-solid border-pink-header pb-10">
            {detailResponse?.data?.name}
          </span>
        </div>
        {is_login === true && sameID && (
          <Link to={`/company_edit/${detailResponse?.data?.id}`}>
            <div className="flex w-46 h-12 items-center font-montserrat text-txt-white text-xs font-medium bg-black-2 rounded py-2 px-4">
              <img src={Pencil} alt="" />
              <span className="ml-2">Ubah Informasi Usaha</span>
            </div>
          </Link>
        )}
      </div>

      <div
        className={classnames(
          classProduct.productInformation,
          "flex px-20 mb-10 max-h-192 overflow-y-auto"
        )}
      >
        <div
          className="2xl:w-3/12 xl:w-4/12"
          style={{
            top: "5px",
            position: "sticky",
          }}
        >
          <Box
            className="px-5 py-6"
            style={{
              boxShadow: "0px 4px 8px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
            }}
          >
            <Carousel
              {...settings}
              classNameSlickDots=""
              className="px-5 py-3"
              infinite
              innerRef={(slider) => (refCarouselTop.current = slider)}
            >
              <div className="px-3">
                <img
                  className="object-contain mx-auto"
                  src={detailResponse.data.product_1_url}
                  alt=""
                />
              </div>
              <div className="px-3">
                <img
                  className="object-contain mx-auto"
                  src={detailResponse.data.product_2_url}
                  alt=""
                />
              </div>
              <div className="px-3">
                <img
                  className="object-contain mx-auto"
                  src={detailResponse.data.product_3_url}
                  alt=""
                />
              </div>
            </Carousel>
            <div className="flex items-center justify-around space-x-2">
              <Box
                sx={
                  carousel === 0
                    ? { bgcolor: "grey.200", borderRadius: "50%" }
                    : {
                        bgcolor: "primary.main",
                        borderRadius: "50%",
                      }
                }
              >
                <IconButton
                  disabled={carousel === 0}
                  onClick={() => {
                    setCarousel((prev) => prev - 1);

                    refCarouselTop.current.slickPrev();
                  }}
                >
                  <KeyboardArrowLeftIcon
                    style={{ color: carousel === 0 ? "inherit" : "white" }}
                  />
                </IconButton>
              </Box>

              <div className="flex items-center space-x-2">
                {[...Array(3)].map((item, index) => {
                  const productUrl = `product_${index + 1}_url`;

                  return (
                    <button
                      className={classnames("rounded-md", {
                        "ring-2 ring-pink-header": index === carousel,
                      })}
                      onClick={() => {
                        setCarousel(index);
                        refCarouselTop.current.slickGoTo(index);
                      }}
                    >
                      <img
                        className="object-cover rounded-md"
                        src={detailResponse.data?.[productUrl]}
                        alt=""
                        style={{ width: 78, height: 78 }}
                      />
                    </button>
                  );
                })}
              </div>

              <Box
                sx={
                  carousel === 2
                    ? { bgcolor: "grey.200", borderRadius: "50%" }
                    : {
                        bgcolor: "primary.main",
                        borderRadius: "50%",
                      }
                }
              >
                <IconButton
                  disabled={carousel === 2}
                  onClick={() => {
                    setCarousel((prev) => prev + 1);

                    refCarouselTop.current.slickNext();
                  }}
                >
                  <KeyboardArrowRightIcon
                    style={{ color: carousel === 2 ? "inherit" : "white" }}
                  />
                </IconButton>
              </Box>
            </div>
          </Box>
        </div>

        <div
          className={classnames(
            classProduct.productInformation,
            "flex flex-col px-10 py-1 2xl:w-9/12 xl:w-8/12"
          )}
        >
          <div
            className="p-4 mb-10 w-full"
            style={{
              boxShadow: "0px 4px 8px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
            }}
          >
            <div className="font-baloo2 font-bold text-black-2 text-lg">
              Biodata Usaha
            </div>
            <div className="flex py-5 px-8 border-b border-solid border-welcome divide-x divide-gray-200">
              <div className="table pr-4 pb-4 w-full">
                <table>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Bentuk
                    </td>
                    <td className="px-3">:</td>
                    <td>{detailResponse?.data?.business_type?.name}</td>
                  </tr>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Aktivitas
                    </td>
                    <td className="px-3">:</td>
                    <td
                      className={classnames(
                        "inline-flex items-center",
                        detailResponse?.data?.business_activities?.length > 1 ||
                          detailResponse?.data?.business_activities?.[0]?.name
                            ?.length > 20
                          ? "cursor-pointer"
                          : ""
                      )}
                    >
                      <td className="line-clamp-1 overflow-hidden">
                        {detailResponse?.data?.business_activities?.[0]?.name}
                      </td>
                      {(detailResponse?.data?.business_activities?.length > 1 ||
                        detailResponse?.data?.business_activities?.[0]?.name
                          ?.length > 20) && (
                        <div
                          onClick={() => setOrderList(!orderList)}
                          className="font-montserrat font-medium text-pink-header text-sm pl-3"
                        >
                          ...lihat
                        </div>
                      )}
                    </td>
                    <DialogBox
                      visible={orderList === true}
                      onClose={orderList !== true}
                      onBackdropClick={() => setOrderList(!orderList)}
                    >
                      <Box className="py-2 px-4">
                        <div className="flex items-center justify-end">
                          <CloseIcon
                            className="cursor-pointer"
                            color="grey.200"
                            onClick={() => setOrderList(!orderList)}
                          />
                        </div>
                        <div className="p-4">
                          <div className="mb-6">
                            <span className="font-baloo2 font-bold text-lg text-black-2">
                              Aktivitas Usaha
                            </span>
                          </div>

                          <div className="bg-welcome p-2">
                            <ol className="list-decimal pl-4 pt-4">
                              {detailResponse?.data?.business_activities?.map(
                                (data) => (
                                  <li className="font-montserrat text-black-2 font-medium text-sm pb-1">
                                    {data?.name}
                                  </li>
                                )
                              )}
                            </ol>
                          </div>
                        </div>
                      </Box>
                    </DialogBox>
                  </tr>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      KBLI
                    </td>
                    <td className="px-3">:</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Kriteria
                    </td>
                    <td className="px-3">:</td>
                    <td>{detailResponse?.data?.business_criteria?.name}</td>
                  </tr>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Pengalaman
                    </td>
                    <td className="px-3">:</td>
                    <td>
                      {get(detailResponse, "data.tahun_pengalaman")} tahun
                    </td>
                  </tr>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Jenis Pengadaan
                    </td>
                    <td className="px-3">:</td>
                    <td>{detailResponse?.data?.procurement_type?.name}</td>
                  </tr>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Komoditas
                    </td>
                    <td className="px-3">:</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Sertifikasi
                    </td>
                    <td className="px-3">:</td>
                    <td
                      className="font-montserrat font-medium text-neon-blue cursor-pointer"
                      onClick={() => setCertificateList(!certificateList)}
                    >
                      {detailResponse?.data?.sertifikat_non_pkp_count}{" "}
                      sertifikasi
                    </td>
                  </tr>

                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Sertifikasi Kegiatan
                    </td>
                    <td className="px-3">:</td>
                    <td
                      className="font-montserrat font-medium text-neon-blue cursor-pointer"
                      onClick={() => setActCertificateList(!actCertificateList)}
                    >
                      {detailResponse?.data?.sertifikat_kegiatan_count}{" "}
                      sertifikasi
                    </td>
                  </tr>

                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Status
                    </td>
                    <td className="px-3">:</td>
                    <td>{detailResponse?.data?.status_text}</td>
                  </tr>
                </table>
              </div>
              <div className="px-8 font-baloo2 font-bold text-base">
                <div className="mb-2">Integrasi SIKaP</div>

                {detailResponse?.data?.registered_on_sikap === 1 && (
                  <div className="inline-flex items-center space-x-2 border border-solid border-welcome whitespace-nowrap rounded box-border p-2 font-montserrat font-medium text-neon-green">
                    <DoneIcon />
                    <span>Sudah terintegrasi</span>
                  </div>
                )}
                {detailResponse?.data?.registered_on_sikap === 0 && (
                  <div className="inline-flex items-center space-x-2 border border-solid border-welcome whitespace-nowrap rounded box-border p-2 font-montserrat font-medium text-welcome">
                    <CloseIcon />
                    <span>Belum terintegrasi</span>
                  </div>
                )}
              </div>
            </div>

            <div className="pt-5">
              <div className="font-baloo2 font-bold text-black-2 text-lg pb-5">
                Info lainnya
              </div>
              <div className="flex px-8 w-full border-b border-solid border-welcome pb-4">
                <div className="table pr-4 pb-4 border-r border-solid box-border border-welcome w-1/2">
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pb-2 font-arial font-bold text-black-2 text-sm">
                      NIB usaha
                    </span>
                    :<span className="pl-2">{detailResponse?.data?.nib}</span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pb-2 font-arial font-bold text-black-2 text-sm">
                      Penghasilan perbulan
                    </span>
                    :
                    <span className="pl-2">
                      Rp. {addDots(detailResponse?.data?.monthly_income)}
                    </span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pr-2 pb-2 font-arial font-bold text-black-2 text-sm">
                      Jumlah kantor cabang
                    </span>
                    :
                    <span className="pl-2">
                      {detailResponse?.data?.number_branch_office}
                    </span>
                  </div>
                </div>
                <div className="pl-4 w-1/2">
                  <div className="table">
                    <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                      <span className="table-cell pb-2 font-arial font-bold text-black-2 text-sm">
                        Status KSWP
                      </span>
                      :
                      <span className="pl-2">
                        {detailResponse?.data?.status_kswp === null
                          ? "Tidak Aktif"
                          : "Aktif"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="font-baloo2 font-bold text-black-2 text-lg pt-4">
                  Company profile
                </div>

                <a
                  href={`${detailResponse?.data?.profile_file_url}`}
                  download
                  target="_blank"
                  rel="noreferrer"
                  className="py-4 font-montserrat font-medium text-xs text-neon-blue px-4"
                >
                  {detailResponse?.data?.name}
                </a>
              </div>
            </div>
          </div>

          <Card childrenName="Informasi Perusahaan">
            <div
              dangerouslySetInnerHTML={{
                __html: detailResponse?.data?.description?.replace(
                  /( |<([^>]+)>)/gi,
                  " "
                ),
              }}
              className="font-baloo2 font-medium text-base text-black-2"
            ></div>
          </Card>

          <Card childrenName={`Hubungi Kami`}>
            <div className="block w-full">
              {detailResponse?.data?.longitude &&
                detailResponse?.data?.latitude && (
                  <MapContainer
                    className="block"
                    style={{ height: "200px", width: "100%", zIndex: 1 }}
                    center={[
                      detailResponse?.data?.latitude,
                      detailResponse?.data?.longitude,
                    ]}
                    zoom={12}
                    scrollWheelZoom={false}
                  >
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                      position={[
                        detailResponse?.data?.latitude,
                        detailResponse?.data?.longitude,
                      ]}
                    >
                      <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                      </Popup>
                    </Marker>
                  </MapContainer>
                )}

              <div className="pt-3 w-10/12 font-baloo2 font-medium text-base text-black-2 text-left">
                {detailResponse?.data?.address}
              </div>
              <div className="flex justify-between">
                <div>
                  <div className="flex flex-row items-center pt-2 font-arial font-normal text-xs text-black-2">
                    <img src={Point} alt="" className="h-2 w-2" />
                    <div className="font-medium pl-2">
                      {titleCase(detailResponse?.data?.city?.name)},{" "}
                      {titleCase(detailResponse?.data?.province?.name)}
                    </div>
                  </div>
                  <div className="flex flex-row items-center pt-2">
                    <img src={Phone} alt="" />
                    <a
                      href={`tel:${detailResponse?.data?.phone}`}
                      className="px-2 font-arial font-normal text-xs text-black-2"
                    >
                      {detailResponse?.data?.phone}
                    </a>
                  </div>
                </div>
                <div className="pl-5 border-l border-solid border-welcome">
                  <div className="flex underline flex-row items-center pt-2">
                    <img src={Mail} alt="" />
                    <a
                      href={`mailto:${detailResponse?.data?.email}`}
                      className="px-2 font-arial font-normal text-xs text-black-2"
                      style={{ color: "#8C8C8C" }}
                    >
                      {detailResponse?.data?.email}
                    </a>
                  </div>

                  <div className="flex underline flex-row items-center pt-2">
                    <img src={WWW} alt="" className="" />
                    <a
                      href={`http://www.${detailResponse?.data?.website}`}
                      className="px-2 font-arial font-normal text-xs text-black-2"
                      style={{ color: "#8C8C8C" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {detailResponse?.data?.website}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <Card childrenName={"Info Biodata Usaha"}>
            <div className="flex w-full align-top justify-between pb-4">
              <div className="table w-full">
                <div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pb-2 font-arial font-bold text-black-2 text-sm">
                      Pemilik
                    </span>
                    :
                    <span className="pl-2">
                      {get(detailResponse, "data.user.name_business_owner", "")}
                    </span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell w-32 pb-2 font-arial font-bold text-black-2 text-sm">
                      Jenis Kelamin Pemilik Usaha
                    </span>
                    :
                    <span className="pl-2 pr-5 w-full">
                      {get(detailResponse, "data.user.gender", "") === "male"
                        ? "Laki-laki"
                        : "Perempuan"}
                    </span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell w-32 pb-2 font-arial font-bold text-black-2 text-sm">
                      Orang Asli Papua (OAP)
                    </span>
                    :
                    <span className="pl-2 pr-5 w-full">
                      {Boolean(get(detailResponse, "data.user.is_oap", 0))
                        ? "Orang Asli Papua"
                        : "Bukan" }
                    </span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell w-32 pb-2 font-arial font-bold text-black-2 text-sm">
                      Didaftarkan
                    </span>
                    :<span className="pl-2 pr-5 w-full">{createdTime}</span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pr-2 pb-2 font-arial font-bold text-black-2 text-sm">
                      Diverifikasi
                    </span>
                    :
                    <span className="pl-2">
                      {get(detailResponse, "data.user.verified_at")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="table w-full pl-4 border-l border-solid border-welcome">
                <div className="">
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell w-32 pr-5 pb-2 font-arial font-bold text-black-2 text-sm">
                      Tanggal Diubah
                    </span>
                    :<span className="table-cell pl-2 w-32">{updatedTime}</span>
                  </div>
                  <div className="table-row font-montserrat font-medium text-black-2 text-sm">
                    <span className="table-cell pb-2 font-arial font-bold text-black-2 text-sm">
                      Diubah Oleh
                    </span>
                    :
                    <span className="pl-2">
                      {get(detailResponse, "data.user.update_by")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div
            className="p-4 mb-10 w-full"
            style={{
              boxShadow: "0px 4px 8px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
            }}
          >
            <div className="font-baloo2 font-bold text-black-2 text-lg">
              Pengalaman PBJP
            </div>
            <div className="flex py-5 px-8 divide-x divide-gray-200">
              <div className="table pr-4 pb-4 w-1/2">
                <table>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Jumlah Memasukkan Penawaran (YTD)
                    </td>
                    <td className="px-3">:</td>
                    <td>
                      {get(detailResponse, "statistika.jumlah_penawaran", "0")}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Jumlah Memenangkan Kontrak (YTD)
                    </td>
                    <td className="px-3">:</td>
                    <td className="inline-flex items-center">
                      <td className="line-clamp-1 overflow-hidden">
                        {get(
                          detailResponse,
                          "statistika.jumlah_menang_tender",
                          "0"
                        )}
                      </td>
                    </td>
                  </tr>
                </table>
              </div>

              <div className="table pb-4 w-1/2 px-8">
                <table>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Total Nilai Kontrak Tender (Rp)
                    </td>
                    <td className="px-3">:</td>
                    <td>
                      Rp.{" "}
                      {addDots(
                        get(
                          detailResponse,
                          "statistika.total_nilai_kontrak_tender",
                          "0"
                        )
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-arial font-bold text-black-2 text-sm">
                      Terakhir Diupdate
                    </td>
                    <td className="px-3">:</td>
                    <td className="inline-flex items-center">
                      <td className="">
                        {get(detailResponse, "statistika.last_update", "")}
                      </td>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div
            className="p-4 mb-10 w-full"
            style={{
              boxShadow: "0px 4px 8px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
            }}
          >
            <div className="font-baloo2 pb-4 font-bold text-black-2 text-lg">
              Pengalaman E-Katalog
            </div>
            {get(
              detailResponse,
              "data.detailPengalamanUsaha.e-katalog.count",
              ""
            ) !== null && (
              <div className="flex py-5 px-8 divide-x divide-gray-200">
                <div className="table pr-4 pb-4 w-full">
                  <table>
                    <tr>
                      <td className="font-arial font-bold text-black-2 text-sm">
                        Jumlah Melakukan transaksi / kontrak (YTD)
                      </td>
                      <td className="px-3">:</td>
                      <td>
                        {get(
                          detailResponse,
                          "data.detailPengalamanUsaha.e-katalog.count",
                          "0"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-arial font-bold text-black-2 text-sm">
                        Nilai Akumulasi kontrak dalam 1 Tahun (YTD)
                      </td>
                      <td className="px-3">:</td>
                      <td className={classnames("inline-flex items-center")}>
                        <td className="line-clamp-1 overflow-hidden">
                          Rp.{" "}
                          {addDots(
                            get(
                              detailResponse,
                              "data.detailPengalamanUsaha.e-katalog.nominal",
                              "0"
                            )
                          )}
                        </td>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            )}

            <div className="">
              <div className="font-baloo2 font-bold text-black-2 text-lg py-5 border-t border-solid border-welcome">
                Pengalaman Toko Daring
              </div>
              {get(
                detailResponse,
                "data.detailPengalamanUsaha.toko_daring.count",
                ""
              ) !== null && (
                <div className="flex py-3 px-8 divide-x divide-gray-200">
                  <div className="table pr-4 pb-4 w-full">
                    <table>
                      <tr>
                        <td className="font-arial font-bold text-black-2 text-sm">
                          Jumlah Melakukan transaksi / kontrak (YTD)
                        </td>
                        <td className="px-3">:</td>
                        <td>
                          {get(
                            detailResponse,
                            "data.detailPengalamanUsaha.toko_daring.count",
                            "0"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-arial font-bold text-black-2 text-sm">
                          Nilai Akumulasi kontrak dalam 1 Tahun (YTD)
                        </td>
                        <td className="px-3">:</td>
                        <td className={classnames("inline-flex items-center")}>
                          <td className="line-clamp-1 overflow-hidden">
                            Rp.{" "}
                            {addDots(
                              get(
                                detailResponse,
                                "data.detailPengalamanUsaha.toko_daring.nominal",
                                "0"
                              )
                            )}
                          </td>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              )}

              <div className="flex flex-col border-t border-solid border-welcome">
                <div className="font-baloo2 font-bold text-black-2 text-lg pt-4">
                  Pengalaman PBJ Internasional
                </div>
                {get(
                  detailResponse,
                  "data.detailPengalamanUsaha.pbj_internasional.count",
                  ""
                ) !== null && (
                  <div className="flex py-3 px-8 divide-x divide-gray-200">
                    <div className="table pr-4 pb-4 w-full">
                      <table>
                        <tr>
                          <td className="font-arial font-bold text-black-2 text-sm">
                            Jumlah Melakukan transaksi / kontrak (YTD)
                          </td>
                          <td className="px-3">:</td>
                          <td>
                            {get(
                              detailResponse,
                              "data.detailPengalamanUsaha.pbj_internasional.count",
                              "0"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-arial font-bold text-black-2 text-sm">
                            Nilai Akumulasi kontrak dalam 1 Tahun (YTD)
                          </td>
                          <td className="px-3">:</td>
                          <td
                            className={classnames("inline-flex items-center")}
                          >
                            <td className="line-clamp-1 overflow-hidden">
                              Rp.{" "}
                              {addDots(
                                get(
                                  detailResponse,
                                  "data.detailPengalamanUsaha.pbj_internasional.nominal",
                                  ""
                                )
                              )}
                            </td>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Card childrenName="Detail Pengalaman">
            {!get(detailResponse, "data.pengalaman", [])?.length && (
              <div
                className="text-center font-bold pb-5"
                style={{ color: "#CCCCCC" }}
              >
                Belum ada pengalaman
              </div>
            )}

            <div>
              {get(detailResponse, "data.pengalaman", [])?.map((pgl, index) => (
                <div className="border-t border-gray-200 pt-8 pb-8">
                  <table className="w-full" key={index}>
                    <tr className="align-top">
                      <td className="w-1/2 pr-6 border-r border-gray-200">
                        <tr>
                          <td
                            className="font-arial font-bold text-black-2 text-sm"
                            style={{ minWidth: 120 }}
                          >
                            Nomor Kontrak
                          </td>
                          <td className="font-arial font-bold text-black-2 text-sm w-auto px-4">
                            :
                          </td>
                          <td className="w-auto whitespace-normal break-all">
                            {pgl.no_kontrak}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="font-arial font-bold text-black-2 text-sm"
                            style={{ minWidth: 120 }}
                          >
                            Nama Kontrak
                          </td>
                          <td className="font-arial font-bold text-black-2 text-sm px-4">
                            :
                          </td>
                          <td className="w-auto break-all">{pgl.nama}</td>
                        </tr>
                        <tr>
                          <td
                            className="font-arial font-bold text-black-2 text-sm"
                            style={{ minWidth: 120 }}
                          >
                            Kategori Pekerjaan
                          </td>
                          <td className="font-arial font-bold text-black-2 text-sm px-4">
                            :
                          </td>
                          <td className="w-auto">{pgl.kategori}</td>
                        </tr>
                        <tr>
                          <td
                            className="font-arial font-bold text-black-2 text-sm"
                            style={{ minWidth: 120 }}
                          >
                            Nilai Kontrak (Rp)
                          </td>
                          <td className="font-arial font-bold text-black-2 text-sm px-4">
                            :
                          </td>
                          <td className="w-auto">
                            {pgl.nilai_rupiah} <br /> ({pgl.nilai_terbilang})
                          </td>
                        </tr>
                      </td>

                      <td className="w-1/2 pl-6">
                        <tr>
                          <td className="font-arial font-bold text-black-2 text-sm">
                            Durasi Pelaksanaan Kontrak (Mulai)
                          </td>
                          <td className="font-arial font-bold text-black-2 text-sm px-4">
                            :
                          </td>
                          <td>{pgl.tanggal_mulai}</td>
                        </tr>
                        <tr>
                          <td className="font-arial font-bold text-black-2 text-sm">
                            Durasi Pelaksanaan Kontrak (Akhir)
                          </td>
                          <td className="font-arial font-bold text-black-2 text-sm px-4">
                            :
                          </td>
                          <td>{pgl.tanggal_akhir}</td>
                        </tr>
                        <tr>
                          <td className="font-arial font-bold text-black-2 text-sm">
                            Tanggal Serah Terima
                          </td>
                          <td className="font-arial font-bold text-black-2 text-sm px-4">
                            :
                          </td>
                          <td>{pgl.tanggal_serah_terima}</td>
                        </tr>
                      </td>
                    </tr>
                  </table>
                </div>
              ))}
            </div>
          </Card>
          <div className="pb-1" />
        </div>
      </div>
      <DialogBoxCertificate
        visible={certificateList === true}
        onClose={certificateList !== true}
        onBackdropClick={() => setCertificateList(!certificateList)}
      >
        <div className="flex justify-between pt-4 px-4">
          <div className="text-xl font-semibold font-baloo2 text-black-2">
            Sertifikat
          </div>
          <CloseIcon
            className="cursor-pointer items-center justify-end"
            color="grey.200"
            onClick={() => setCertificateList(!certificateList)}
          />
        </div>
        {detailResponse?.sertifikat?.data?.map((data) => (
          <Box className="flex flex-col" sx={{ px: 3, py: 2 }}>
            <Box
              className="flex items-center justify-between bg-white border border-welcome rounded-lg"
              sx={{ py: 2, px: 3 }}
            >
              <Box>
                <span className="block text-xl font-semibold font-baloo2 text-black-2">
                  Sertifikasi {data?.jenis_sertifikat}
                </span>
                <table className="text-black-2 text-base font-montserrat">
                  <tr>
                    <td className="font-bold">Berlaku</td>
                    <td className="px-5">:</td>
                    <td>
                      {data?.is_selamanya ? "Selamanya" : data?.berlaku_hingga}
                    </td>
                  </tr>
                </table>
              </Box>
              <Box className="flex flex-col justify-end">
                <ThemeProvider
                  theme={createTheme({
                    ...theme,
                    palette: { primary: { main: "#00A65A" } },
                  })}
                >
                  <Button
                    startIcon={
                      <SvgIcon>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
                      </SvgIcon>
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.open(`${data?.file_url}`, "_blank");
                    }}
                  >
                    Sertifikat
                  </Button>
                </ThemeProvider>
              </Box>
            </Box>
          </Box>
        ))}
        <Box className="py-4 px-4 justify-center self-center">
          <Pagination
            count={total}
            page={params?.page}
            onChange={(event, page) => {
              setParams((prev) => ({ ...prev, page: page }));
            }}
          />
        </Box>
      </DialogBoxCertificate>

      <DialogBoxCertificate
        visible={actCertificateList === true}
        onClose={actCertificateList !== true}
        onBackdropClick={() => setActCertificateList(!actCertificateList)}
      >
        <div className="flex justify-between pt-4 px-4">
          <div className="text-xl font-semibold font-baloo2 text-black-2">
            Sertifikat Kegiatan
          </div>
          <CloseIcon
            className="cursor-pointer items-center justify-end"
            color="grey.200"
            onClick={() => setActCertificateList(!actCertificateList)}
          />
        </div>
        {detailResponse?.sertifikatKegiatan?.data?.map((data) => (
          <Box className="flex flex-col" sx={{ px: 3, py: 2 }}>
            <Box
              className="flex items-center justify-between bg-white border border-welcome rounded-lg"
              sx={{ py: 2, px: 3 }}
            >
              <Box>
                <span className="block text-xl font-semibold font-baloo2 text-black-2">
                  [{data?.jenis_kegiatan.toUpperCase()}] {data?.nama}
                </span>
                <table className="text-black-2 text-base font-montserrat">
                  <tr>
                    <td className="font-bold">Tanggal</td>
                    <td className="px-5">:</td>
                    <td>
                      {format(new Date(data?.waktu_mulai), "dd-MM-yyyy")} s/d{" "}
                      {format(new Date(data?.waktu_akhir), "dd-MM-yyyy")}
                    </td>
                  </tr>
                </table>
              </Box>
              {/* <Box className="flex flex-col justify-end">
                <ThemeProvider
                  theme={createTheme({
                    ...theme,
                    palette: { primary: { main: "#00A65A" } },
                  })}
                >
                  <Button
                    startIcon={
                      <SvgIcon>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
                      </SvgIcon>
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.open(`${data?.file_url}`, "_blank");
                    }}
                  >
                    Sertifikat
                  </Button>
                </ThemeProvider>
              </Box> */}
            </Box>
          </Box>
        ))}
        <Box className="py-4 px-4 justify-center self-center">
          <Pagination
            count={totalAct}
            page={params?.pageAct}
            onChange={(event, page) => {
              setParams((prev) => ({ ...prev, pageAct: page }));
            }}
          />
        </Box>
      </DialogBoxCertificate>

      {!profile && (
        <div className="flex flex-col w-full font-baloo2 font-bold text-2xl text-black-2 px-20">
          <div>Pelaku Usaha Serupa</div>
          <div className="xl:ml-40 lg:mr-10 grid xl:grid-cols-3 2xl:grid-cols-4 grid-flow-row gap-16 pt-8 pb-20">
            {detailResponse?.serupa?.data?.map((data, index) => {
              return <ProductCard data={data} key={index} id={data?.id} />;
            })}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default ProductDetail;
