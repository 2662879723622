// buat convert dari object ke query string
export const parseObjectToString = (query) => {
  // buat array yang isi-nya key dari object
  const queryKeys = Object.keys(query);

  // variable buat tampung object sementara
  const queryAll = {};

  // mapping buat check string atau array
  queryKeys.map((_item) => {
    // check jika string
    // check jika ada
    if (typeof query[_item] === "string" && query[_item]) {
      // masukin object ke queryAll
      Object.assign(queryAll, { [_item]: query[_item] });

      // check jika array
      // check jika array mempunyai item lebih dari 0
    } else if (Array.isArray(query[_item]) && query[_item].length > 0) {
      // masukin object ke queryAll
      Object.assign(queryAll, { [_item]: query[_item] });
    }
  });

  // convert queryAll ke query string
  return new URLSearchParams(queryAll).toString();
};

export default parseObjectToString