import axios from 'axios';
import endpoint from 'utils/endpoint';
// import token from 'utils/session';

export async function verifyEmail(code,callback) {
  const url = endpoint.verification;
  const config = {
    method: 'POST',
    data: {
      verification_code: code,
    },
  };

  try {
    const response = await axios(url,config);
    callback(response.data)
  } catch (error) {
    console.log(error)
  }
}
