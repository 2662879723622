import React from "react";
import { Box, ButtonGroup, Button, Typography } from "@material-ui/core";
import { fDate } from "utils/formatTime";

export default function CalendarToolbar(props) {
  const { date, view, onBack, onNext, onToday, onChangeView } = props;

  const VIEW_OPTIONS = [
    { value: "dayGridMonth", label: "Month" },
    { value: "timeGridWeek", label: "Week" },
    { value: "timeGridDay", label: "Day" },
    { value: "listWeek", label: "Agenda" },
  ];

  return (
    <div className="flex lg:flex-row flex-col items-center lg:space-y-0 space-y-3 justify-between mb-10">
      <Box>
        <ButtonGroup>
          <Button onClick={onToday}>Today</Button>
          <Button onClick={onBack}>Back</Button>
          <Button onClick={onNext}>Next</Button>
        </ButtonGroup>
      </Box>
      <Box>
        <Typography variant="h5">{fDate(date)}</Typography>
      </Box>
      <Box>
        <ButtonGroup>
          {VIEW_OPTIONS.map((_item, index) => {
            const isSelected = _item.value === view;

            return (
              <Button
                onClick={() => onChangeView(_item.value)}
                color="secondary"
                variant={isSelected && "contained"}
              >
                {_item.label}
              </Button>
            );
          })}
        </ButtonGroup>
      </Box>
    </div>
  );
}
