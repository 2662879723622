import axios from "axios";
import endpoint from "utils/endpoint";
import { omitBy } from "lodash";
// import token from 'utils/session';

async function getProvinsi(params, callback) {
  const url = endpoint.provinsi;
  const config = {
    method: "GET",
  };

  try {
    const response = await axios(url, config);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
}

async function getSearchUsaha(params, callback) {
  const url = endpoint.search;
  const config = {
    method: "GET",
    params: { ...omitBy(params, (ix) => ix === ""), is_verified: 1 },
  };
  try {
    const response = await axios(url, config);
    callback(response.data);
    // console.log(response)
  } catch (error) {
    console.log(error);
  }
}

async function getAktifitas(params, callback) {
  const url = endpoint.aktifitas;
  const config = {
    method: "GET",
  };

  try {
    const response = await axios(url, config);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
}

async function getBentukUsaha(params, callback) {
  const url = endpoint.usaha;
  const config = {
    method: "GET",
  };

  try {
    const response = await axios(url, config);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
}

async function getKriteriaUsaha(params, callback) {
  const url = endpoint.kriteria;
  const config = {
    method: "GET",
  };

  try {
    const response = await axios(url, config);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
}

async function getJenisPengadaan(params, callback) {
  const url = endpoint.pengadaan;
  const config = {
    method: "GET",
  };

  try {
    const response = await axios(url, config);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
}

async function getPBJP(params, callback) {
  const url = endpoint.pbjp;
  const config = {
    method: "GET",
  };

  try {
    const response = await axios(url, config);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
}

export default {
  getProvinsi,
  getSearchUsaha,
  getAktifitas,
  getBentukUsaha,
  getJenisPengadaan,
  getKriteriaUsaha,
  getPBJP,
};
