import React from "react";
import Menu from "../Menu";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";

export default function Shorcut() {
  return (
    <Menu
      content={
        <Box component="ul" className="py-2" sx={{ bgcolor: "grey.200" }}>
          <li>
            <Link
              to="/kabar_pelaku_usaha"
              className="font-montserrat text-sm px-4 py-2 block"
            >
              Kabar Pelaku Usaha
            </Link>
          </li>
          <li>
            <Link
              to="/lokasi_usaha"
              className="font-montserrat text-sm px-4 py-2 font-medium block"
            >
              Lokasi Usaha
            </Link>
          </li>
          <li>
            <Link
              to="/kalendar"
              className="font-montserrat text-sm px-4 py-2 font-medium block"
            >
              Kalendar Kegiatan
            </Link>
          </li>
          <li>
            <Link
              to="/news"
              className="font-montserrat text-sm px-4 py-2 font-medium block"
            >
              Berita Usaha
            </Link>
          </li>
        </Box>
      }
    >
      <span className="text-sm">Tautan Pintas</span>
    </Menu>
  );
}
