import React from "react";
import PropTypes from "prop-types";
import Dialog from "components/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import { ThemeProvider, createTheme, Button } from "@material-ui/core";
import theme from "theme";
import DateIcon from "@material-ui/icons/Today";
import DatePicker from "react-datepicker";
import UploadFile from "components/UploadFile";
import classnames from "classnames";
// import { useSelector } from "react-redux";
import services from "../services";
import Alert from "@material-ui/lab/Alert";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      display: "flex",
      width: "100%",
      alignItems: "center",
    },
  })
);
export default function UploadSertifikat(props) {
  const { visible, onClose } = props;
  // const { usaha } = useSelector((state) => state.region);
  const classDialog = useStyles();
  const { profile: user } = useSelector((state) => state.user);

  const history = useHistory();

  const [message, setMessage] = React.useState({
    open: false,
    severity: "",
    message: "",
  });
  const [checked, setChecked] = React.useState("0");

  const uploadSertifikatSchema = Yup.object().shape({
    is_selamanya: Yup.string(),
    berlaku_hingga: Yup.string()
     .when('is_selamanya', {
       is: (is_selamanya) => is_selamanya === "0",
       then: Yup.string()
         .required('Akhir Sertifikat Harus Diisi!')
     })
 });

  const [detailResponse, setResponse] = React.useState({
    vendor: [],
  });

  React.useEffect(() => {
    services.getVendorDetail(
      {
        user_id: user?.id,
      },
      {
        success: (response) => {
          setResponse((prev) => ({ ...prev, vendor: response }));
        },
      },
      {
        error: (err) => {
          console.log(err)
        }
      }
    );
  }, [])

  const formik = useFormik({
    initialValues: {
      jenis_sertifikat: "",
      berlaku_mulai: "",
      berlaku_hingga: "",
      vendor_business_id: "",
      file_upload: "",
      lembaga_penerbit: "",
      is_selamanya: checked,
    },
    validationSchema: uploadSertifikatSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      services.postSertifikat(
        {...values },
        (isSuccess, data) => {
          if (isSuccess) {
            setMessage({
              open: true,
              severity: "success",
              message: "Berhasil Upload Sertifikat",
            });
            window.timeout = setTimeout(() => {
              window.location.reload();
            }, 1500);
            history.push(`/profile?tab=2`)
          } else {
            const { response } = data;
            if (response.status === 422) {
              const { errors } = response.data;
              if (errors) {
                Object.keys(errors).forEach((attributeName) => {
                  setFieldError(
                    attributeName,
                    errors[attributeName].toString()
                  );
                  setMessage({
                    open: true,
                    severity: "error",
                    message: errors[attributeName].toString(),
                  });
                });
              }
            }
          }
        },
      );
    },
  });

  const max = 500000;
  var fiveYear = new Date();
  fiveYear.setFullYear(fiveYear.getFullYear() + 5);

  const { getFieldProps, handleSubmit, setFieldValue, setFieldError, errors } = formik;
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  return (
    <Dialog visible={visible} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="flex items-center justify-end">
        <button onClick={onClose} className="px-4 py-2">
          <CloseIcon />
        </button>
      </div>

      <div className="bg-white w-full pt-2">
        <div className="border-b border-gray-200 block px-6 pb-3">
          <h5 className="font-baloo2 font-bold text-2xl text-black-2">
            Upload Sertifikat
          </h5>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="px-6 py-3 space-y-3">
            <div>
              <span className="text-sm font-montserrat font-semibold text-black-2 label-required block">
                Jenis sertifikat
              </span>
              <input
                name="jenis_sertifikat"
                {...getFieldProps("jenis_sertifikat")}
                className="form-input px-4 py-2 text-sm font-montserrat w-full rounded-lg border border-gray-200"
                type="text"
              />
            </div>

            <div>
              <span className="text-sm font-montserrat font-semibold text-black-2 label-required block">
                Lembaga Penerbit
              </span>
              <input
                type="text"
                name="lembaga_penerbit"
                {...getFieldProps("lembaga_penerbit")}
                className="form-input px-4 py-2 text-sm font-montserrat w-full rounded-lg border border-gray-200"
              />
            </div>

            <div className="w-full flex items-start space-x-3">
              <div className="w-full">
                <span className="text-sm font-montserrat font-semibold text-black-2 label-required block">
                  Berlaku mulai
                </span>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    const startDay = date?.toLocaleString("default", {
                      day: "2-digit",
                    });
                    const startMonth = date?.toLocaleString("default", {
                      month: "numeric",
                    });
                    const startYear = date?.toLocaleString("default", {
                      year: "numeric",
                    });
                    let startDateFormated = `${startYear}-${startMonth}-${startDay}`;
                    setStartDate(date);
                    setFieldValue("berlaku_mulai", startDateFormated);
                  }}
                  dateFormat="dd-MM-yyyy"
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={
                    <div className="flex flex-row justify-between items-center rounded-lg py-2 pr-3 font-semibold text-base font-custom w-11/12 text-black-2 border border-solid border-neutral-grey">
                      <input
                        name="berlaku_mulai"
                        {...getFieldProps("berlaku_mulai")}
                        placeholder="Pilih tanggal"
                        className="w-full font-montserrat p-2 font-medium text-xs"
                      />
                      <DateIcon className="text-black-2" />
                    </div>
                  }
                  // {...getFieldProps("berlaku_mulai")}
                />
              </div>

              <div className="w-full">
                <span className="text-sm font-montserrat font-semibold text-black-2 label-required block">
                  Berlaku hingga
                </span>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    const startDay = date?.toLocaleString("default", {
                      day: "2-digit",
                    });
                    const startMonth = date?.toLocaleString("default", {
                      month: "numeric",
                    });
                    const startYear = date?.toLocaleString("default", {
                      year: "numeric",
                    });
                    let startDateFormated = `${startYear}-${startMonth}-${startDay}`;
                    setEndDate(date);
                    setFieldValue("berlaku_hingga", startDateFormated);
                  }}
                  dateFormat="dd-MM-yyyy"
                  maxDate={fiveYear}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  readOnly={checked === "1" ? true : false}
                  customInput={
                    <div className={classnames("flex flex-row justify-between items-center rounded-lg py-2 pr-3 font-semibold text-base font-custom w-11/12 text-black-2 border border-solid border-neutral-grey", checked === "1" ? "bg-neutral-grey" : "")}>
                      <input
                        name="berlaku_hingga"
                        {...getFieldProps("berlaku_hingga")}
                        placeholder="Pilih tanggal"
                        className="w-full font-montserrat p-2 font-medium text-xs"
                        disabled={checked === "1" ? true : false}
                      />
                      <DateIcon className="text-black-2" />
                    </div>
                  }
                  // {...getFieldProps("berlaku_hingga")}
                />
                {errors.berlaku_hingga && (
                  <div className="text-red-500 text-sm">
                    {errors.berlaku_hingga}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center">
              <input
                className="font-montserrat p-2 font-medium text-xs text-black-2 bg-pink-header border border-solid border-welcome rounded"
                type="checkbox"
                id="checkSertif"
                onClick={(e) => {
                    setChecked(e.target.checked === true ? "1" : "0")
                    setFieldValue("is_selamanya", e.target.checked === true ? "1" : "0" )
                  }
                }
                // {...getFieldProps("is_selamanya")}
              />
              <label className="pl-2 font-montserrat font-medium text-sm text-black-2">
                Berlaku selamanya
              </label>
            </div>
            <div>
              <span className="text-sm font-montserrat font-semibold text-black-2 label-required block">
                Untuk usaha
              </span>
              <select
                type="text"
                name="vendor_business_id"
                {...getFieldProps("vendor_business_id")}
                className="form-select px-4 py-2 text-sm font-montserrat w-full rounded-lg border border-gray-200"
              >
                <option value="" disabled>Pilih Usaha</option>
                {
                // usaha?.usaha?.length !== 0 ? 
                //   usaha?.usaha?.map(usaha => (
                //     <option value={usaha.id}>
                //       {usaha.name}
                //     </option>
                //   )) : 
                  detailResponse?.vendor?.data?.map(usaha => (
                    <option value={usaha.id}>
                      {usaha.name}
                    </option>
                  ))
                }
              </select>
            </div>

            <div>
              <span className="text-sm font-montserrat font-semibold text-black-2 label-required block">
                Upload file (maks 500 kB)
              </span>
              <UploadFile
                max={max}
                message="File Lebih Besar Dari 500kb!"
                inputProps={{
                  name: "file",
                  onChange: (e) =>
                    setFieldValue(
                      "file",
                      e.target.files.length && (e.target.files[0].size < max) ? e.target.files[0] : null
                    ),
                  }}
                classWidth="w-full"
              />
            </div>
          </div>
          <div className="border-t border-gray-200 block py-4 px-6">
            <ThemeProvider
              theme={createTheme({
                ...theme,
                palette: { primary: { main: "#00A65A" } },
              })}
            >
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Upload
              </Button>
            </ThemeProvider>
          </div>
        </form>
        <Dialog
          visible={message.open === true}
          onClose={message.open !== true}
          className="mt-3 block"
        >
          <Alert
            severity={message.severity}
            className={classDialog.dialog}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMessage({ open: false, error: "", message: "" });
                }}
              >
                <CloseIcon alt="" />
              </IconButton>
            }
          >
            <div>{message.message}</div>
          </Alert>
        </Dialog>
      </div>
    </Dialog>
  );
}

UploadSertifikat.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
