import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/styles";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import classnames from 'classnames';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: "Baloo 2",
      fontWeight: 700,
      color: "#595959",
      fontSize: 18,
    },
  })
);

export default function DataKriteriaUsaha(props) {
  const { data } = props;
  const classes = useStyles();

  const colors = {
    0: 'bg-neon-red',
    1: 'bg-neon-green',
    2: 'bg-neon-blue',
    3: 'bg-blue-second'
  }

  return (
    <div>
      <div>
        <span className={classes.title}>Data Kriteria Usaha</span>
      </div>
      <div className="grid lg:grid-cols-4 grid-cols-2 mt-3 rounded-md overflow-hidden">
        {data.map((item, index) => {
          // const convertDate = format(item.date, 'dd MMM yyyy - HH:mm', { locale: id })
          const classItem = classnames('pt-6 px-4 pb-4', colors[index])

          return (
            <div className={classItem}>
              <div className="font-sans font-bold text-3xl text-txt-white">
                {item.total}
              </div>
              <div className="font-sans font-bold text-3xl text-txt-white pb-3">
                {item.percent} %
              </div>
              <div className="w-9/12 font-sans font-normal text-base text-txt-white">
                {item.label}
              </div>
              {/* <div className="font-sans font-normal text-base text-txt-white">
                {convertDate}
              </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}

DataKriteriaUsaha.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      total: PropTypes.number,
      percent: PropTypes.number,
      label: PropTypes.string,
      date: PropTypes.string,
    })
  ),
};

DataKriteriaUsaha.defaultProps = {
  data: []
}