import React, { useEffect, useRef, useState } from "react";
import "./UploadImage.css";

export default function UploadImage(props) {
  // eslint-disable-next-line
  const { preview, disabled } = props;
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    setPreviewImage(preview);
  }, [preview]);

  function handleChangeFile(e) {
    const { files } = e.target;

    if (files[0]?.name.match(/.(jpg|jpeg|png)$/i)) {
      if (files.length && files[0].size < props.byteSize) {
        const url = URL.createObjectURL(files[0]);
        setPreviewImage(url);
        setFile(files[0]);
      } else if (files.length && files[0].size > 2) {
        alert(`File Lebih Besar dari ${props.byteSizeName}`);
        setFile(null);
      }

      if (typeof props.inputProps.onChange === "function") {
        props.inputProps.onChange(e);
      }
    } else {
      alert("Maaf file yang anda upload belum sesuai");
    }
  }

  const inputProps = {
    ...props.inputProps,
    // onChange: handleChangeFile,
  };

  const openFileBrowser = () => {
    inputRef.current.click();
  };

  const removeFile = () => {
    inputRef.current.value = "";
    const event = new Event("change");
    inputRef.current.dispatchEvent(event);

    setFile(null);
    setPreviewImage(null);
  };

  return (
    <div className="preview-image-container">
      <div
        className="preview-image-area"
        style={{ backgroundImage: `url(${previewImage})` }}
      ></div>
      {!disabled && (
        <>
          <input
            ref={inputRef}
            className="preview-image-input"
            type="file"
            style={{ display: "none" }}
            accept=".png, .jpg, .jpeg"
            {...inputProps}
            onChange={handleChangeFile}
          />
          <div className="preview-image-button-list">
            {file || preview ? (
              <>
                <button
                  onClick={openFileBrowser}
                  type="button"
                  className="text-white p-2 rounded btn-warning btn-preview-update-file"
                >
                  Ubah
                </button>
                <button
                  onClick={removeFile}
                  type="button"
                  className="ml-2 text-white p-2 rounded btn-danger btn-preview-remove-file"
                >
                  Hapus
                </button>
              </>
            ) : (
              <button
                onClick={openFileBrowser}
                type="button"
                className="text-white p-2 rounded btn-primary btn-preview-choose-file"
              >
                Pilih File
              </button>
            )}
          </div>
          {/* <div className="mt-2">
              <i className="fa fa-info-circle"></i> File maksimal {{$maxsize}}kb
          </div> */}
        </>
      )}
    </div>
  );
}

UploadImage.propTypes = {};

UploadImage.defaultProps = {};
