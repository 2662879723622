import axios from 'axios';
import endpoint from 'utils/endpoint';
// import token from 'utils/session';

export async function getCarousel(params,callback) {
  const url = endpoint.carouselBeritaUsaha;
  const config = {
    method: 'GET',
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    callback(error);
  }
}

export async function getBeritaUsaha(params,callback) {
  const url = `${endpoint.beritaUsaha}`;
  const config = {
    method: 'GET',
    params
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    callback(error);
  }
}

export async function getBeritaUsahaDetail(params,callback) {
  const url = `${endpoint.beritaUsaha}/${params?.id}`;
  const config = {
    method: 'GET',
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    callback(error);
  }
}

export async function getBeritaUsahaRandom(params,callback) {
  const url = `${endpoint.beritaUsaha}?sort=random`;
  const config = {
    method: 'GET',
    params
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    callback(error);
  }
}