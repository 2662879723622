import React from "react";
import { Box } from '@material-ui/core'
import ItemPesan from './ItemPesan'
import Pagination from 'components/pagination'
import * as services from "../services";
import PropTypes from "prop-types";
import parseQueryString from "utils/parseQueryString";
import { useLocation } from 'react-router-dom'
import { debounce } from "lodash";

export default function ListPesan(props) {
  const urlTab = parseQueryString(useLocation().search)?.tab;

  const {
    totalPagination,
    setParams,
    page,
    data,
    tab,
    params,
    setPagination,
    setResponse,
  } = props;

  // const [response, setResponse] = React.useState({
  //   data: []
  // })

  // React.useEffect(() => {

  //   services.getBroadCastCount({
  //     status: urlTab
  //   }, {
  //     success: (response) => {
  //       setResponse(prev => ({...prev, data: response}))
  //     }, 
  //     error: (err) => {
  //       console.log(err);
  //     }
  //   })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Box>
      <Box
        className="border-t-2 border-b-2 border-gray-200 flex items-center justify-between flex-col sm:flex-row"
        sx={{ px: 3, py: 4 }}
      >
        <div>
          <span className="text-base font-baloo2 font-semibold text-black-2">
            Menampilkan {data?.from}-{data?.to} pesan dari {data?.total} pesan
          </span>
        </div>

        <div className="flex flex-col w-full md:w-8/12 lg:w-6/12 lg:flex-row sm:items-center sm:space-x-2">
          <div className="lg:space-x-2">
            <span>Pencarian</span>
            <input
              className="form-input w-full lg:w-8/12 md:px-4 md:py-2 rounded-md border border-gray-200"
              placeholder="Cari"
              onChange={debounce((e) => setParams(prev => ({...prev, q: e.target.value})))}
            />
          </div>
          <select onChange={(e) => setParams(prev => ({...prev, type: e.target.value}))} className="form-select md:w-11/12 lg:w-4/12 pl-4 pr-8 py-2 mt-2 lg:mt-0 rounded-md border border-gray-200">
            <option value="" disabled selected>Pilih Jenis Pesan</option>
            <option value="all">Semua</option>
            <option value="Broadcast">Broadcast</option>
            <option value="Approval">Approval</option>
          </select>
        </div>
      </Box>

      <Box className="space-y-3" sx={{ bgcolor: "grey.200", px: 3, py: 3 }}>
        {data?.data?.map((_item, index) => (
          <ItemPesan 
            data={_item}
            key={index}
            params={params}
            tab={tab}
            setResponse={setResponse}
            setPagination={setPagination}
          />
        ))}
      </Box>
      <Box
        className="border-t-2 border-gray-200 flex items-center justify-center"
        sx={{ py: 3 }}
      >
        <Pagination count={totalPagination} page={page} onChange={(ev, page) => setParams(prev => ({...prev, page: page}))} />
      </Box>
    </Box>
  );
}

ListPesan.propTypes = {
  totalPagination: PropTypes.number,
  setParams: PropTypes.func,
  page: PropTypes.number,
  data: PropTypes.object,
  params: PropTypes.object,
};

ListPesan.defaultProps = {
  totalUsaha: 0,
  setParams: () => {},
  page: 0,
  data: {},
  q: "",
};
