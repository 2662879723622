import axios from 'axios';
import endpoint from 'utils/endpoint';
// import token from 'utils/session';

export async function resendEmailVerification(email,callback) {
  const url = endpoint.resendVerification;
  const config = {
    method: 'POST',
    data: {
      email
    }
  };

  try {
    const response = await axios(url,config);
    callback(true, response.data)
  } catch (error) {
    callback(false, error)
    console.log(error)
  }
}