import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Pagination from "components/pagination";
import classNames from "classnames";
import PlusIcon from "@material-ui/icons/Add";
import UsahaCard from "components/UsahaCard";
export default function BiodataUsaha(props) {
  const { biodata, listUsaha, count, onChange } = props;

  const listBiodataUsaha = React.useMemo(() => listUsaha, [listUsaha]);

  return (
    <div>
      <div className="bg-white py-12 lg:px-12 px-6 flex w-full items-center justify-between">
        <div className="font-baloo2 font-bold text-base text-black-2">
          Punya usaha yang belum terdaftar?
        </div>
        <Link to={`/add_new_business/${biodata?.id}`}>
          <button className="items-center flex space-x-2 py-2 px-4 rounded font-montserrat font-medium text-xs text-border-on-pink bg-neon-green">
            <PlusIcon />
            <span>Daftarkan Usaha Baru</span>
          </button>
        </Link>
      </div>

      <div
        className={classNames(
          "bg-neutral-grey p-6 flex-col items-center justify-center",
          {
            hidden: listUsaha?.length > 0,
            flex: listUsaha?.length === 0,
          }
        )}
        style={{ minHeight: 300 }}
      >
        <p className="font-bold font-arial text-2xl text-search">
          Belum ada usaha yang didaftarkan
        </p>
      </div>

      {listBiodataUsaha.map((data, index) => {
        return <UsahaCard profileLink={data?.id} data={data} key={index} />;
      })}

      <div
        className={classNames("w-full py-5  items-center justify-center", {
          hidden: listUsaha?.length === 0,
          flex: listUsaha?.length > 0,
        })}
      >
        <Pagination count={count} onChange={onChange} />
      </div>
    </div>
  );
}

BiodataUsaha.propTypes = {
  biodata: PropTypes.any,
  listUsaha: PropTypes.array,
};

BiodataUsaha.defaultProps = {
  listUsaha: [],
};
