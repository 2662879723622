import React from "react";
import Footer from "components/footer";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import VDLogo from "assets/logo-vd.png";
import VDText from "assets/VendorDirektori.png";
import { useHistory, useParams } from "react-router-dom";

function AddNewBusiness() {
  let history = useHistory();
  const { user_id } = useParams();

  return (
    <div>
      <div className="flex pt-5 flex-col">
        <Breadcrumbs>
          <Link
            className="font-montserrat font-medium text-sm text-pink-header lg:pl-20 pl-8"
            to="/"
          >
            Beranda
          </Link>
          <Link
            className="font-montserrat font-medium text-sm text-pink-header"
            to={window.location.pathname}
          >
            Daftarkan Usaha Baru
          </Link>
          <Link
            className="font-montserrat font-medium text-sm text-black-2"
            to={window.location.pathname}
            hash="/#"
          >
            Bentuk Usaha
          </Link>
        </Breadcrumbs>
      </div>
      <div className="py-5 flex w-full justify-center">
        <img src={VDLogo} alt="" style={{height: 70}} />
      </div>
      <div
        className="w-10/12 mx-auto flex mb-20"
        style={{
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05",
        }}
      >
        <div className="w-full">
          <div className="pt-4 text-black-2 font-baloo2 font-bold text-3xl ml-12 mb-4">
            Perluas kesempatan bekerja sama untuk usaha anda
            <div className="border-b-2 w-1/12 border-solid border-pink-header pb-10 " />
          </div>
          <div className="text-black-2 font-montserrat font-medium text-xs ml-12 pb-10">
            Pilih bentuk usaha anda terlebih dahulu
          </div>

          <div className="flex lg:flex-row flex-col px-12 justify-between w-full mb-8 lg:space-x-6 space-x-0 lg:space-y-0 space-y-4">
            <div
              onClick={() => history.push(`/register_business/1/${user_id}`)}
              className="lg:w-1/2 w-full h-full border border-grey-200 rounded-sm overflow-hidden cursor-pointer"
            >
              <div className="px-6 py-6">
                <img
                  src="/static/images/perseorangan.png"
                  alt=""
                  className="object-contain w-full select-bisnis"
                />
              </div>
              <div className="font-baloo2 text-black-2 text-3xl font-bold text-center pb-6">
                Perseorangan
              </div>
              <div className="border-b-4 border-pink-header" />
            </div>

            <div
              role="button"
              onClick={() => history.push(`/register_business/2/${user_id}`)}
              className="lg:w-1/2 w-full h-full border border-grey-200 rounded-sm overflow-hidden cursor-pointer"
            >
              <div className="px-6 py-6">
                <img
                  src="/static/images/badan_usaha.png"
                  alt=""
                  className="object-contain w-full select-bisnis"
                />
              </div>
              <div className="font-baloo2 text-black-2 text-3xl font-bold text-center pb-6">
                Badan Usaha
              </div>
              <div className="border-b-4 border-pink-header" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AddNewBusiness;
