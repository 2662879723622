import React from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import last from "lodash/last";
import { Typography } from "@material-ui/core";

export default function Breadcrumb(props) {
  const { links, className, classLast } = props;
  const currentLink = last(links).href;
  const history = useHistory();

  const listDefault = links.map((link) => {
    if (link.href !== currentLink) {
      if (link.href === "goback") {
        return (
          <button onClick={history.goBack}
            className="font-montserrat font-medium text-sm text-pink-header"
          >
            {link.name}
          </button>
        )
      }

      return (
        <Link
          to={link.href}
          className="font-montserrat font-medium text-sm text-pink-header"
        >
          {link.name}
        </Link>
      );
    }

    return (
      <Typography variant="body2" className={classLast}>
        {link.name}
      </Typography>
    );
  });

  return <Breadcrumbs className={className}>{listDefault}</Breadcrumbs>;
}

Breadcrumb.propTypes = {
  className: PropTypes.string,
  classLast: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

Breadcrumb.defaultProps = {
  links: [],
};
