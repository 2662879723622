import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import EditProfile from "./EditProfile";
import get from "lodash/get";
import parseQueryString from "utils/parseQueryString";
import { useSelector } from "react-redux";

export default function BiodataDiri(props) {
  const { biodata: sourceBiodata } = props;
  const { profile: user } = useSelector((state) => state.user);

  const biodata = React.useMemo(() => sourceBiodata, [sourceBiodata]);

  const [profileType, setProfileType] = useState(0);

  const isEditProfile = React.useMemo(() => {
    const params = parseQueryString(window.location.search);

    return get(params, "edit-profile", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  useEffect(() => {
    if (isEditProfile) {
      setProfileType(1);
    } else {
      setProfileType(0);
    }
  }, [isEditProfile]);

  const handleChangeAuth = (ev) => {
    setProfileType(ev);
  };

  const profile = {
    0: "not edit",
    1: "edit",
  };

  const userType = get(user, "type");

  const title = {
    pelaku_usaha: "Pelaku Usaha",
    klpd: "K/L/PD",
  };

  return (
    <div className="p-6 bg-neutral-grey flex flex-col text-left">
      {profile[profileType] === "not edit" && (
        <div>
          <div className="flex items-center justify-between w-full pb-6 border-b border-solid border-welcome">
            <div className="flex font-baloo2 font-bold text-base text-black-2">
              Biodata akun {title[userType]} anda
            </div>

            <Button
              startIcon={<CreateIcon />}
              variant="contained"
              color="secondary"
              onClick={() => handleChangeAuth(1)}
            >
              Ubah
            </Button>
          </div>

          <div className="pt-4 self-start font-baloo2 font-bold text-base text-black-2 pb-6">
            Biodata
          </div>

          <div className="pt-6 flex">
            <table className="inline-block text-justify">
              <tbody>
                <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                  <td className="text-right pb-7 font-bold text-black-2 text-sm">
                    Nama lengkap :
                  </td>
                  <td className="pb-7 font-medium">{biodata?.full_name}</td>
                </tr>

                {userType === "pelaku_usaha" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      Nama Pemilik Usaha :
                    </td>
                    <td className="pl-1 pb-7 font-medium">
                      {biodata?.name_business_owner}
                    </td>
                  </tr>
                )}

                {userType === "pelaku_usaha" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      Jenis kelamin pemilik usaha :
                    </td>
                    <td className="pl-1 pb-7 font-medium">
                      {biodata?.gender === "male" ? "Laki-Laki" : "Perempuan"}
                    </td>
                  </tr>
                )}

                <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                  <td className="text-right pb-7 font-bold text-black-2 text-sm">
                    NIK :
                  </td>
                  <td className="pl-1 pb-7 font-medium">{biodata?.nik}</td>
                </tr>

                {userType === "klpd" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      NIP :
                    </td>
                    <td className="pl-1 pb-7 font-medium">{biodata?.nip}</td>
                  </tr>
                )}

                {userType === "klpd" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      Organisasi :
                    </td>
                    <td className="pl-1 pb-7 font-medium capitalize">
                      {biodata?.instansi}
                    </td>
                  </tr>
                )}

                {userType === "klpd" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      Instansi :
                    </td>
                    <td className="pl-1 pb-7 font-medium capitalize">
                      {get(biodata, "instansi_bagian.name")}
                    </td>
                  </tr>
                )}

                {userType === "pelaku_usaha" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      NPWP :
                    </td>
                    <td className="pl-1 pb-7 font-medium">{biodata?.npwp}</td>
                  </tr>
                )}

                {/* {userType === "pelaku_usaha" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      NIB :
                    </td>
                    <td className="pl-1 pb-7 font-medium">{biodata?.nib}</td>
                  </tr>
                )} */}

                {userType === "pelaku_usaha" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      Status KSWP :
                    </td>
                    <td className="pl-1 pb-7 font-medium">
                      {biodata?.status_kswp === null ? "Tidak Aktif" : "Aktif"}
                    </td>
                  </tr>
                )}

                {userType === "pelaku_usaha" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      Pendidikan terakhir pemilik usaha :
                    </td>
                    <td className="pl-1 pb-7 font-medium">
                      {biodata?.last_education}
                    </td>
                  </tr>
                )}

                {userType === "pelaku_usaha" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      Provinsi :
                    </td>
                    <td className="pl-1 pb-7 font-medium">
                      {biodata?.province?.name}
                    </td>
                  </tr>
                )}

                {userType === "pelaku_usaha" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      Kabupaten/kota :
                    </td>
                    <td className="pl-1 pb-7 font-medium">
                      {biodata?.city?.name}
                    </td>
                  </tr>
                )}

                {userType === "pelaku_usaha" && (
                  <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                    <td className="text-right pb-7 font-bold text-black-2 text-sm">
                      Pelaku Usaha Orang Asli Papua (OAP) :
                    </td>
                    <td className="pl-1 pb-7 font-medium">
                      {Boolean(biodata?.is_oap)
                        ? "Orang Asli Papua"
                        : "Bukan"}
                    </td>
                  </tr>
                )}

                <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                  <td className="text-right pb-7 font-bold text-black-2 text-sm">
                    No telepon :
                  </td>
                  <td className="pl-1 pb-7 font-medium">{biodata?.phone}</td>
                </tr>

                <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                  <td className="text-right pb-7 font-bold text-black-2 text-sm">
                    Email :
                  </td>
                  <td className="pl-1 pb-7 font-medium">{biodata?.email}</td>
                </tr>

                <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                  <td className="text-right pb-7 font-bold text-black-2 text-sm">
                    Password :
                  </td>
                  <td className="pl-1 pb-7 font-medium">
                    Diperbarui 1 hari lalu
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {profile[profileType] === "edit" && (
        <EditProfile biodata={biodata} handleChangeAuth={handleChangeAuth} />
      )}
    </div>
  );
}
