import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import classnames from "classnames";
import { Link as RouterLink } from "react-router-dom";
import get from "lodash/get";

const useStyles = makeStyles((theme) =>
  createStyles({
    boxGraph: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right 80%",
      backgroundSize: "auto 60%",

      [theme.breakpoints.up("lg")]: {
        minHeight: 150,
      },
      [theme.breakpoints.up("xl")]: {
        minHeight: 200,
      },
      [theme.breakpoints.down("lg")]: {
        minHeight: 150,
      },
    },
  })
);

export default function Welcome(props) {
  const dashboard_landing_page = get(
    props,
    "settings.dashboard_landing_page",
    null
  );
  const listCountPelakuUsaha = [
    {
      title: "Usaha terdaftar di sistem",
      icon: "/static/images/bag.png",
      key: "terdaftar",
      total:
        dashboard_landing_page?.terdaftar?.value === "show"
          ? props?.data?.terdaftar
          : "",
    },

    {
      title: "Usaha mendaftar setiap bulannya",
      icon: "/static/images/calendar.png",
      key: "terdaftar_setiap_bulan",
      total:
        dashboard_landing_page?.terdaftar_setiap_bulan?.value === "show"
          ? props?.data?.terdaftar_setiap_bulan
          : "",
    },
    {
      title: "Usaha Kecil yang Terdaftar di sistem",
      icon: "/static/images/VectorStroke.png",
      key: "pu_kecil",
      total:
        dashboard_landing_page?.pu_kecil?.value === "show"
          ? props?.data?.pu_kecil
          : "",
    },
    {
      title: "Usaha non Kecil yang Terdaftar di sistem",
      icon: "/static/images/Union.png",
      key: "pu_non_kecil",
      total:
        dashboard_landing_page?.pu_non_kecil?.value === "show"
          ? props?.data?.pu_non_kecil
          : "",
    },
  ];

  const classes = useStyles();

  return (
    <div
      className="pt-10 xl:px-20 lg:px-18 px-6 flex xl:flex-row flex-col"
      id="introduction"
    >
      <div className="xl:w-4/12 w-full">
        <img src="/static/images/logo-LKPP-2.png" alt="" className="w-64" />
        <div className="font-baloo2 font-bold text-3xl text-black-2 pt-6 pb-10">
          {get(props, "settings.konten_dinamis.judul.value")}
        </div>
        <div className="border-b border-solid border-pink-header w-5/12 mb-4" />
        <div
          className="font-montserrat font-medium xl:text-base lg:text-xs xl:w-10/12 lg:w-7/12 text-justify extend-welcome"
          dangerouslySetInnerHTML={{
            __html: get(props, "settings.konten_dinamis.deskripsi.value"),
          }}
        ></div>
        {/* <div className="text-left">
          Informasi lebih lanjut silakan klik{" "}
          <a
            href="https://bit.ly/SuratAppBisaPengadaan"
            className="text-blue-second"
            target="_blank"
            rel="noreferrer"
          >
            disini
          </a>
        </div> */}

        <div className="flex w-10/12 mb-10">
          <RouterLink
            to="/register"
            className="mt-8 bg-pink-header px-4 py-2 text-neutral-grey font-montserrat font-medium text-xs rounded"
          >
            Daftar Sekarang
          </RouterLink>
          <RouterLink
            to="/about_us"
            className="mt-8 ml-6 bg-black-2 px-4 py-2 text-neutral-grey font-montserrat font-medium text-xs rounded"
          >
            Tentang Kami
          </RouterLink>
        </div>
      </div>

      <div className="xl:w-8/12 w-full grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 lg:pt-24 pt-0 px-0">
        {listCountPelakuUsaha.map((item, index) => (
          <div>
            <Box
              className="w-full inline-block overflow-hidden"
              boxShadow={3}
              sx={{ borderRadius: 8 }}
              component={RouterLink}
              to={`/dashboard_beranda?view=${item.key}`}
            >
              <Box
                className={classnames(
                  classes.boxGraph,
                  "bg-neutral-grey rounded pt-14 pl-6"
                )}
                style={{
                  backgroundImage: `url(${item.icon})`,
                }}
              >
                <span className="font-baloo2 font-bold 2xl:text-5xl xl:text-4xl text-3xl text-black-2">
                  {item.total}
                </span>
              </Box>
              <Box
                className="px-4 py-2 bg-grey-3 border-b-4 border-solid border-pink-header"
                sx={{ minHeight: 68 }}
              >
                <p className="font-montserrat font-medium xl:text-sm lg:text-xs">
                  {item.title}
                </p>
              </Box>
            </Box>
          </div>
        ))}
      </div>
    </div>
  );
}
