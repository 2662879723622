/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line
import React, { useState, useRef, useEffect } from "react";
import Footer from "components/footer";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import VDLogo from "assets/logo-vd.png";
import Image from "assets/undraw_creative_team_r90h 1.png";
import ChatLogo from "assets/chat.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import Captcha from "components/Captcha";
import * as services from "./services";
import UploadImage from "components/UploadImage";
import InputSikap from "components/InputSikap";
import _ from "lodash";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { user_email } from "redux/reducers/register";
import TextField from "components/TextField";
import TextFieldPassword from "components/TextField/Password";
import { size } from "lodash";
import { displayModalSiginIn } from "redux/reducers/auth";
import { IconButton } from "@material-ui/core";
import Dialog from "components/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextFieldMaterial from "@material-ui/core/TextField";

const RegisterSchema = Yup.object().shape({
  npwp: Yup.string().required("NPWP Wajib diisi"),
  nik: Yup.string().test(
    "len",
    "NIK harus memiliki 16 Karakter",
    (val) => size(val) === 16
  ),
  full_name: Yup.string().required("Nama Lengkap Wajib diisi"),
  name_business_owner: Yup.string().required("Nama Pemilik Usaha Wajib diisi").matches(/^[aA-zZ\s]+$/, "Hanya boleh berisi huruf alfabet"),
  gender: Yup.string().required("Jenis Kelamin Wajib diisi"),
  province_id: Yup.number().required("Provinsi Wajib diisi"),
  city_id: Yup.number().required("Kabupaten Wajib diisi"),
  phone: Yup.number().required("Nomor Handphone Wajib diisi"),
  email: Yup.string().required("Email Wajib diisi"),
  password: Yup.string().required("Password Wajib diisi"),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password harus sama"
  ),
  is_oap: Yup.string().when("province_id", {
    is: (val) => /^(91|94)$/.test(val),
    then: Yup.string().required("Orang Asli Papua Harus Diisi"),
  }),
  tnc: Yup.boolean().oneOf(
    [true],
    "Anda Harus Mencentang Syarat dan Ketentuan"
  ),
});

function Register() {
  // eslint-disable-next-line
  const history = useHistory();
  const dispatch = useDispatch();
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKabupaten, setListKabupaten] = useState([]);
  const [listPendidikan, setListPendidikan] = useState([]);

  const captchaRef = useRef();

  useEffect(() => {
    services.getProvinsi({}, (data) => setListProvinsi(data.data));
    services.getPendidikan({}, (data) => setListPendidikan(data.data));
  }, []);

  const [message, setMessage] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const {
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
    errors,
    touched,
    isSubmitting,
    setFieldError,
  } = useFormik({
    initialValues: {
      type_user: "pelaku_usaha",
      npwp: "",
      npwp_file: "",
      nik: "",
      full_name: "",
      name_business_owner: "",
      gender: "",
      last_education: "",
      province_id: "",
      city_id: "",
      phone: "",
      email: "",
      password: "",
      captcha: "",
      captcha_key: "",
      tnc: false,
      is_oap: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, form) => {
      return services.register(
        { type_user: "pelaku_usaha", ...values },
        (isSuccess, data) => {
          captchaRef.current.reloadCaptcha();
          setFieldValue("captcha", "");
          if (isSuccess) {
            dispatch(user_email(values.email));
            history.push("/register/verification");
            form.setSubmitting(false);
          } else {
            const { response } = data;
            if (response.status === 422) {
              const { errors } = response.data;
              if (errors) {
                Object.keys(errors).forEach((attributeName) => {
                  setFieldError(
                    attributeName,
                    errors[attributeName].toString()
                  );
                });
                // const firstMessage = errors[Object.keys(errors)[0]].toString();
                const firstMessage = Object.keys(errors)
                  .map((attrName) => errors[attrName].toString())
                  .toString();
                setMessage({
                  open: true,
                  severity: "error",
                  message: firstMessage,
                });
              } else if (response?.data?.message) {
                setMessage({
                  open: true,
                  severity: "error",
                  message: response.data.message,
                });
              }
            }
            form.setSubmitting(false);
          }
        },
        {
          success: (response) => {
            console.log(response);
          },
          error: (error) => {
            console.log(error.data);
            if (error.status === 422) {
              setMessage({
                open: true,
                severity: "error",
                message: error.data,
              });
            }
          },
        }
      );
    },
  });

  useEffect(() => {
    if (values.province_id) {
      services.getKabupaten({ province_id: values.province_id }, (data) =>
        setListKabupaten(data.data)
      );
      setFieldValue("city_id", "");
    }
  }, [values.province_id]);

  const callbackSikap = (res) => {
    const { attributes } = res.data;
    setFieldValue("nik", attributes.nik);
    setFieldValue("name_business_owner", attributes.name_business_owner);
    setFieldValue("npwp", attributes.npwp);
    setFieldValue("province_id", attributes.province_id);
    setFieldValue("city_id", attributes.city_id);
    setFieldValue("email", attributes.email);
  };

  return (
    <div>
      <div className="flex flex-col pt-5">
        <Breadcrumbs>
          <Link
            className="font-montserrat font-medium text-sm text-pink-header lg:pl-20 pl-8"
            to="/"
          >
            Beranda
          </Link>
          <Link
            className="font-montserrat font-medium text-sm text-black-2"
            to={window.location.pathname}
            hash="/#"
          >
            Daftar Akun
          </Link>
        </Breadcrumbs>
      </div>
      <div className="py-5 flex w-full justify-center">
        <img src={VDLogo} alt="" style={{ height: 70 }} />
      </div>
      <div
        className="w-10/12 mx-auto flex lg:flex-row flex-col"
        style={{
          marginBottom: 120,
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05",
        }}
      >
        <div className="lg:w-1/2 w-full pt-4 lg:pl-12 pl-6 pr-6">
          <div className="font-baloo2 font-bold text-3xl text-black-2">
            Jadi bagian bersama pelaku usaha di seluruh Indonesia
          </div>
          <div className="pb-10 border-b border-solid border-pink-header w-1/2" />
          <div className="font-montserrat font-medium text-xs text-black-2 pt-4">
            Mulai bergabung di BISA Pengadaan bersama jutaan pelaku usaha
            berpengalaman di bidangnya.
          </div>
          <img src={Image} alt="" className="pt-8 w-full h-auto" />
        </div>

        <div className="lg:w-1/2 w-full mt-4 mb-8 border-l border-solid border-welcome px-6">
          <div className="flex justify-between ">
            <div className="font-baloo2 font-bold text-base text-black-2">
              Daftar Akun (1/2)
            </div>
            <button
              className="font-montserrat font-bold text-xs text-pink-header"
              onClick={() => dispatch(displayModalSiginIn())}
            >
              Masuk
            </button>
          </div>
          <div className="pt-5 border-b border-solid border-welcome " />
          <form className="pt-4">
            <TextField
              label="Nama Lengkap"
              name="full_name"
              required
              error={Boolean(touched.full_name && errors.full_name)}
              errorText={errors.full_name}
              {...getFieldProps("full_name")}
            />

            <TextField
              label="Nama pemilik usaha"
              name="name_business_owner"
              required
              error={Boolean(
                touched.name_business_owner && errors.name_business_owner
              )}
              errorText={errors.name_business_owner}
              {...getFieldProps("name_business_owner")}
            />

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Jenis kelamin pemilik usaha
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="gender"
              {...getFieldProps("gender")}
            >
              <option value="">Pilih</option>
              <option value="male">Laki-Laki</option>
              <option value="female">Perempuan</option>
            </select>
            {touched.gender && errors.gender && (
              <div className="text-red-500 text-xs">{errors.gender}</div>
            )}

            <div className="input-container">
              <div className="flex mt-3">
                <div className="font-montserrat font-bold text-sm text-black-2">
                  NPWP
                </div>
                <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                  *
                </div>
              </div>
              <InputMask
                mask="99.999.999.9-999.999"
                alwaysShowMask
                {...getFieldProps("npwp")}
              >
                {(inputProps) => {
                  return (
                    <InputSikap
                      field="npwp"
                      type="pelaku_usaha"
                      className=""
                      inputProps={{
                        className:
                          "w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                        name: "npwp",
                        ...inputProps,
                      }}
                      callback={callbackSikap}
                    />
                  );
                }}
              </InputMask>
              {touched.npwp && errors.npwp && (
                <div className="text-red-500 text-xs">{errors.npwp}</div>
              )}
            </div>
            <div>
              <div className="flex mt-3">
                <div className="font-montserrat font-bold text-sm text-black-2">
                  Upload NPWP
                </div>
                <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                  *
                </div>
              </div>
              <UploadImage
                inputProps={{
                  name: "npwp_file",
                  onChange: (e) =>
                    setFieldValue(
                      "npwp_file",
                      e.target.files.length &&
                        !e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)
                        ? e.target.files[0]
                        : null
                    ),
                }}
                byteSize={250000}
                byteSizeName={"250 kb"}
              />
              {touched.npwp_file && errors.npwp_file && (
                <div className="text-red-500 text-xs">{errors.npwp_file}</div>
              )}
            </div>

            <div className="input-container">
              <div className="flex mt-3">
                <div className="font-montserrat font-bold text-sm text-black-2">
                  NIK
                </div>
                <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                  *
                </div>
              </div>
              <InputSikap
                className=""
                field="ktp"
                type="pelaku_usaha"
                inputProps={{
                  className:
                    "w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                  name: "nik",
                  type: "number",
                  min: "0",
                  ...getFieldProps("nik"),
                }}
                callback={callbackSikap}
              />
              {touched.nik && errors.nik && (
                <div className="text-red-500 text-xs">{errors.nik}</div>
              )}
            </div>

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Pendidikan terakhir pemilik usaha
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="last_education "
              {...getFieldProps("last_education")}
            >
              <option value="" disabled>
                Pilih
              </option>
              {listPendidikan.map((data) => (
                <option value={data}>{data}</option>
              ))}
            </select>
            {touched.last_education && errors.last_education && (
              <div className="text-red-500 text-xs">
                {errors.last_education}
              </div>
            )}

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Provinsi
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            {/* <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="province_id "
              {...getFieldProps("province_id")}
              onChange={(ev) => {
                setFieldValue("province_id", Number(ev.target.value));
              }}
            >
              <option value="" disabled>
                Pilih Provinsi
              </option>
              {listProvinsi.map((provinsi) => (
                <option value={provinsi.id}>{provinsi.name}</option>
              ))}
            </select> */}
            <Autocomplete
              options={listProvinsi}
              onChange={(ev, value) => {
                setFieldValue("city_id", "");
                setFieldValue("province_id", value.id);
              }}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              getOptionSelected={(option, value) => option.id === value.id}
              key={listProvinsi}
              renderInput={(props) => (
                <TextFieldMaterial
                  {...props}
                  className="w-full font-montserrat font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                  variant="outlined"
                  placeholder="Pilih Kota / Kabupaten"
                  style={{ width: "100%" }}
                  size="small"
                />
              )}
            />
            {touched.province_id && errors.province_id && (
              <div className="text-red-500 text-xs">{errors.province_id}</div>
            )}

            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Kabupaten/kota
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="city_id "
              {...getFieldProps("city_id")}
            >
              <option value="" disabled>
                Pilih Kabupaten
              </option>
              {listKabupaten.map((kabupaten) => (
                <option value={kabupaten.id}>{kabupaten.name}</option>
              ))}
            </select>
            {touched.city_id && errors.city_id && (
              <div className="text-red-500 text-xs">{errors.city_id}</div>
            )}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                Orang Asli Papua (OAP)
              </div>
            </div>
            <select
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded "
              name="is_oap"
              disabled={
                values?.province_id !== 91 && values?.province_id !== 94
              }
              {...getFieldProps("is_oap")}
              onChange={(ev) => {
                setFieldValue("is_oap", Number(ev.target.value));
              }}
            >
              <option value="" disabled>
                Pilih
              </option>
              <option value={1}>Orang Asli Papua</option>
              <option value={0}>Bukan</option>
            </select>
            {touched.is_oap && errors.is_oap && (
              <div className="text-red-500 text-xs">{errors.is_oap}</div>
            )}
            <div className="flex mt-3">
              <div className="font-montserrat font-bold text-sm text-black-2">
                No telepon
              </div>
              <div className="pl-1 font-sans font-bold text-xs text-pink-header">
                *
              </div>
            </div>
            <input
              className="w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
              name="phone "
              type="number"
              min="0"
              {...getFieldProps("phone")}
            />
            {touched.phone && errors.phone && (
              <div className="text-red-500 text-xs">{errors.phone}</div>
            )}

            <TextField
              label="Email"
              name="email"
              required
              error={Boolean(touched.email && errors.email)}
              errorText={errors.email}
              {...getFieldProps("email")}
            />

            <TextFieldPassword
              label="Password"
              name="password"
              required
              error={Boolean(touched.password && errors.password)}
              errorText={errors.password}
              autocomplete="new-password"
              {...getFieldProps("password")}
            />

            <TextFieldPassword
              label="Ketik ulang password"
              name="password_confirmation"
              required
              error={Boolean(
                touched.password_confirmation && errors.password_confirmation
              )}
              errorText={errors.password_confirmation}
              autocomplete="new-password"
              {...getFieldProps("password_confirmation")}
            />

            <div className="pt-3">
              <Captcha
                ref={captchaRef}
                inputProps={{
                  className:
                    "w-full font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
                  ...getFieldProps("captcha"),
                }}
                callback={(option) => {
                  setFieldValue("captcha_key", option.key);
                }}
              />
            </div>

            <div className="px-5 pt-5 font-medium font-montserrat text-xs text-black-2">
              <ol className="list-decimal">
                <li className="mb-2">
                  Data yang diisikan dalam Form Aplikasi merupakan tanggung
                  jawab pengguna aplikasi;
                </li>
                <li className="mb-2">
                  Pihak LKPP tidak bertanggung jawab terhadap keabsahan data
                  yang diisi pengguna.
                </li>
              </ol>
            </div>

            <div className="py-5 flex">
              <input
                className="font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded"
                type="checkbox"
                {...getFieldProps("tnc")}
              />
              <label className="pl-4 font-montserrat font-medium text-xs text-black-2">
                Dengan ini saya telah menyetujui
                <a
                  href="/syarat_dan_ketentuan"
                  className="text-pink-header px-1"
                >
                  Syarat dan Ketentuan
                </a>
                yang berlaku
              </label>
            </div>

            {/* <div className=""> */}
            <button
              onClick={handleSubmit}
              type="submit"
              className={`w-full py-2 font-montserrat text-xs font-medium ${
                isSubmitting
                  ? "bg-gray-300"
                  : "text-border-on-pink bg-pink-header border-pink-header"
              } rounded border-solid border`}
            >
              Daftar
            </button>
          </form>
          <Dialog
            visible={message.open === true}
            onClose={message.open !== true}
            className="mt-3"
          >
            <Alert severity={message.severity} className="flex items-center">
              {message.message}
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMessage({ open: false, error: "", message: "" });
                }}
              >
                <CloseIcon alt="" className="ml-3 self-center" />
              </IconButton>
            </Alert>
          </Dialog>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
