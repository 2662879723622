import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
  displaySiginIn: false,
  rememberMe: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    displayModalSiginIn: (state) => {
      state.displaySiginIn = true;
    },
    hideModalSignIn: (state) => {
      state.displaySiginIn = false;
    },
    autoSignIn: (state, { payload }) => {
      state.accessToken = payload.accessToken;
    },
    signIn: (state, { payload }) => {
      state.accessToken = payload.accessToken;
    },
    logout: (state, { payload }) => {
      state.accessToken = "";
    },
    rememberMeButton: (state) => {
      state.rememberMe = !state.rememberMe;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  autoSignIn,
  signIn,
  logout,
  displayModalSiginIn,
  hideModalSignIn,
  rememberMeButton
} = authSlice.actions;

export default authSlice.reducer;
