// parse query string ke object
export const parseQueryString = (queryString) => {
  // variable buat tampung object
  const query = {};

  /**
   * check jika query string punya awal ? atau tidak,
   * dan merubahnya ke array pake split
   */
  const pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");

  /**
   * mapping array yg sudah di split.
   * karena key dan value masih satu string, maka di split lagi
   * agar membedakan key dan value.
   * setelah itu key dan value di decode, karena masih kebaca symbol dari url
   */
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }

  // return object
  return query;
};

export default parseQueryString;