import { createSlice } from "@reduxjs/toolkit";
import endpoint from "utils/endpoint";
import axios from "axios";

const initialState = {
  isLoad: false,
  isError: false,
  error: null,
  lokasiUsaha: [],
  lokasiPerGroup: [],
};

export const lokasiSlice = createSlice({
  name: "lokasi",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoad = true;
    },
    hasError: (state, { payload }) => {
      state.isLoad = false;
      state.isError = true;
      state.error = payload;
    },
    getLokasiUsahaSuccess: (state, { payload }) => {
      state.isLoad = false;
      state.lokasiUsaha = payload;
    },
    getLokasiUsahaGroupSuccess: (state, { payload }) => {
      state.isLoad = false;
      state.lokasiPerGroup = payload;
    },
  },
});

export default lokasiSlice.reducer;

export function getLokasiUsaha(params) {
  return async (dispatch) => {
    const url = endpoint.search;

    try {
      dispatch(lokasiSlice.actions.startLoading());
      const response = await axios.get(url, {
        params: { ...params, is_verified: 1 },
      });

      dispatch(lokasiSlice.actions.getLokasiUsahaSuccess(response.data));
    } catch (error) {
      dispatch(lokasiSlice.actions.hasError(error.response));
    }
  };
}

export function getLokasiGroupUsaha() {
  return async (dispatch) => {
    const url = endpoint.lokasiUsahaGroup;

    try {
      dispatch(lokasiSlice.actions.startLoading());
      const response = await axios.get(url);

      dispatch(
        lokasiSlice.actions.getLokasiUsahaGroupSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(lokasiSlice.actions.hasError(error.response));
    }
  };
}
