import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  provinsiData,
  kabupatenData,
  getOrganisasi,
  getViewOrganisasi,
} from "redux/reducers/region";
import * as AddNewBusinessServices from "pages/AddNewBusiness/services";
import ChangePassword from "./ChangePassword";
import UploadImage from "components/UploadImage";
import services from "../services";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "components/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import get from "lodash/get";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

function ProfileChange(props) {
  const { biodata: sourceBiodata } = props;

  const biodata = React.useMemo(() => sourceBiodata, [sourceBiodata]);

  const [listPendidikan, setListPendidikan] = useState([]);

  const [message, setMessage] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const dispatch = useDispatch();
  const { provinsi, kabupaten, organisasi, instansi } = useSelector(
    (state) => state?.region
  );

  useEffect(() => {
    AddNewBusinessServices.getProvinsi({}, (response, err) => {
      if (!err) {
        dispatch(provinsiData({ provinsi: response.data }));
      }
    });

    AddNewBusinessServices.getPendidikan({}, (data) =>
      setListPendidikan(data.data)
    );
  }, []);

  const userType = React.useMemo(() => get(biodata, "type"), [biodata]);

  // eslint-disable-next-line
  const {
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
    errors,
    setFieldError,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      full_name: biodata?.full_name,
      name_business_owner: biodata?.name_business_owner,
      gender: biodata?.gender,
      status_kswp: biodata?.status_kswp === null ? "Tidak Valid" : "Valid",
      last_education: biodata?.last_education,
      province_id: biodata?.province?.id,
      city_id: biodata?.city?.id,
      phone: biodata?.phone,
      instansi: biodata?.instansi,
      instansi_bagian_id: biodata?.instansi_bagian_id,
      is_oap: Boolean(biodata?.is_oap) ? 1 : 0,
    },
    // validationSchema: user_id === "1" ? AddBusinessSchema : AddBusinessEntitySchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      const updateByUserType = {
        pelaku_usaha: {
          name_business_owner: values.name_business_owner,
          gender: values.gender,
          last_education: values.last_education,
          province_id: values.province_id,
          city_id: values.city_id,
          is_oap: values?.is_oap,
        },
        klpd: {
          instansi: values.instansi,
          instansi_bagian_id: values.instansi_bagian_id,
        },
      };

      services.postProfile(
        {
          action: "profile",
          full_name: values.full_name,
          phone: values.phone,
          file_picture: values?.file_picture,
          ...updateByUserType[userType],
        },
        (isSuccess, data) => {
          if (isSuccess) {
            setMessage({
              open: true,
              severity: "success",
              message: "Berhasil Sunting Profile",
            });
            window.timeout = setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            const { response } = data;
            if (response.status === 422) {
              const { errors } = response.data;
              if (errors) {
                Object.keys(errors).forEach((attributeName) => {
                  setFieldError(
                    attributeName,
                    errors[attributeName].toString()
                  );
                  setMessage({
                    open: true,
                    severity: "error",
                    message: errors[attributeName].toString(),
                  });
                });
              }
            }
          }
        },
        {
          success: (response) => {
            console.log(response);
          },
          error: (err) => {
            console.log(err);
          },
        }
      );
    },
  });

  useEffect(() => {
    if (values.province_id) {
      AddNewBusinessServices.getKabupaten(
        { province_id: values.province_id },
        (data) => dispatch(kabupatenData({ kabupaten: data.data }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.province_id]);

  const typeOrganisasi = React.useMemo(
    () => get(biodata, "instansi"),
    [biodata]
  );

  useEffect(() => {
    if (userType === "klpd") {
      dispatch(getOrganisasi());
      dispatch(getViewOrganisasi(typeOrganisasi));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  const errorHandler = () => {
    if (Object.keys(errors).length !== 0) {
      Object.keys(errors).forEach((attributeName) => {
        setFieldError(attributeName, errors[attributeName].toString());
      });
      const firstMessage = Object.keys(errors)
        .map((attrName) => errors[attrName].toString())
        .toString();
      setMessage({
        open: true,
        severity: "error",
        message: firstMessage,
      });
    } else {
      handleSubmit();
    }
  };

  return (
    <div>
      <div className="bg-neutral-grey flex flex-col text-left">
        <div className="flex items-center justify-between w-full pb-6 border-b border-solid border-welcome">
          <div className="flex font-baloo2 font-bold text-base text-black-2">
            Pengaturan
          </div>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => props?.handleChangeAuth(0)}
          >
            Kembali
          </Button>
        </div>

        <div className="pt-4 self-start font-baloo2 font-bold text-base text-black-2 pb-6">
          Biodata
        </div>

        <form onSubmit={handleSubmit} className="pb-5 w-9/12">
          <table className="w-full">
            <tbody className="inline-block w-full">
              <tr className="font-montserrat font-medium text-black-2 text-sm">
                <td className="text-right font-bold text-black-2 text-sm">
                  Profile Picture :
                </td>
                <td className="pl-3 py-2">
                  <UploadImage
                    preview={biodata?.profile_picture_url}
                    inputProps={{
                      name: "file_picture",
                      onChange: (e) =>
                        setFieldValue(
                          "file_picture",
                          e.target.files.length &&
                            !e.target.files[0].name.match(
                              /.(jpg|jpeg|png|gif)$/i
                            )
                            ? e.target.files[0]
                            : null
                        ),
                    }}
                    byteSize={500000}
                    byteSizeName={"500 kb"}
                  />
                </td>
              </tr>

              <tr className="font-montserrat font-medium text-black-2 text-sm">
                <td className="text-right w-32 font-bold text-black-2 text-sm">
                  <span className="label-required">Nama lengkap :</span>
                </td>
                <td className="py-2 pl-3">
                  <input
                    className="pl-3 form-input font-medium w-9/12 lg:w-full border border-solid border-light-gray rounded px-2 py-2"
                    value={values?.full_name}
                    {...getFieldProps("full_name")}
                  />
                </td>
              </tr>

              {userType === "pelaku_usaha" && (
                <tr className="font-montserrat font-medium text-black-2 text-sm">
                  <td className="text-right  font-bold text-black-2 text-sm label-required">
                    Nama Pemilik Usaha :
                  </td>
                  <td className="py-2 pl-3">
                    <input
                      className="pl-3 font-medium w-full border border-solid border-light-gray rounded px-2 py-2"
                      value={values?.name_business_owner}
                      {...getFieldProps("name_business_owner")}
                    />
                  </td>
                </tr>
              )}

              {userType === "pelaku_usaha" && (
                <tr className="font-montserrat font-medium text-black-2 text-sm">
                  <td className="text-right  font-bold text-black-2 text-sm label-required">
                    Jenis kelamin pemilik usaha :
                  </td>
                  <td className="py-2 pl-3">
                    <select
                      className="flex text-sm form-select pl-3 font-medium w-full  border border-solid border-light-gray rounded"
                      name="gender"
                      {...getFieldProps("gender")}
                    >
                      <option value="" disabled>
                        Pilih Jenis Kelamin
                      </option>
                      <option value="male">Laki-Laki</option>
                      <option value="female">Perempuan</option>
                    </select>
                  </td>
                </tr>
              )}

              <tr className="font-montserrat font-medium text-black-2 text-sm">
                <td className="text-right font-bold text-black-2 text-sm">
                  NIK :
                </td>
                <td className="pl-3 font-medium py-2">{biodata?.nik}</td>
              </tr>

              {userType === "klpd" && (
                <tr className="font-montserrat font-medium text-black-2 text-sm">
                  <td className="text-right font-bold text-black-2 text-sm">
                    NIP :
                  </td>
                  <td className="pl-3 font-medium py-2">{biodata?.nip}</td>
                </tr>
              )}

              {userType === "klpd" && (
                <tr className="font-montserrat font-medium text-black-2 text-sm">
                  <td className="text-right  font-bold text-black-2 text-sm">
                    <span className="label-required">Organisasi :</span>
                  </td>
                  <td className="py-2 pl-3">
                    <select
                      className="flex text-sm form-select pl-3 font-medium w-full  border border-solid border-light-gray rounded"
                      name="instansi"
                      onBlur={handleBlur("instansi")}
                      onChange={(ev) => {
                        handleChange(ev, "instansi");
                        dispatch(getViewOrganisasi(ev.currentTarget.value));
                      }}
                      value={values.instansi}
                    >
                      <option value="" disabled>
                        Pilih Organisasi
                      </option>
                      {Object.keys(organisasi).map((_item, index) => (
                        <option value={_item} key={index}>
                          {organisasi[_item]}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              )}

              {userType === "klpd" && (
                <tr className="font-montserrat font-medium text-black-2 text-sm">
                  <td className="text-right font-bold text-black-2 text-sm">
                    <span className="label-required">Instansi :</span>
                  </td>
                  <td className="py-2 pl-3">
                    <select
                      className="flex text-sm form-select pl-3 font-medium w-full  border border-solid border-light-gray rounded"
                      name="instansi_bagian_id"
                      {...getFieldProps("instansi_bagian_id")}
                    >
                      <option value="" disabled>
                        Pilih n
                      </option>

                      {instansi?.map((_item) => (
                        <option value={_item.id} key={_item.id}>
                          {_item.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              )}

              {userType === "pelaku_usaha" && (
                <tr className="font-montserrat font-medium text-black-2 text-sm">
                  <td className="text-right font-bold text-black-2 text-sm">
                    NPWP :
                  </td>
                  <td className="pl-3 font-medium py-2">{biodata?.npwp}</td>
                </tr>
              )}

              {userType === "pelaku_usaha" && (
                <tr className="font-montserrat font-medium text-black-2 text-sm">
                  <td className="text-right font-bold text-black-2 text-sm">
                    NIB :
                  </td>
                  <td className="pl-3 font-medium py-2">{biodata?.npwp}</td>
                </tr>
              )}

              {userType === "pelaku_usaha" && (
                <tr className="font-montserrat font-medium text-black-2 text-sm">
                  <td className="text-right font-bold text-black-2 text-sm">
                    Status KSWP :
                  </td>
                  <td className="py-2 pl-3">
                    <input
                      className="pl-3 font-medium w-full  border border-solid border-light-gray rounded px-2 py-2"
                      value={values?.status_kswp}
                      disabled
                      {...getFieldProps("status_kswp")}
                    />
                  </td>
                </tr>
              )}

              {userType === "pelaku_usaha" && (
                <tr className="font-montserrat font-medium text-black-2 text-sm">
                  <td className="text-right  font-bold text-black-2 text-sm">
                    Pendidikan terakhir pemilik usaha :
                  </td>
                  <td className="py-2 pl-3">
                    <select
                      className="flex text-sm form-select pl-3 font-medium w-full  border border-solid border-light-gray rounded"
                      name="last_education "
                      {...getFieldProps("last_education")}
                    >
                      <option value="" disabled>
                        Pilih Pendidikan Terakhir
                      </option>
                      {listPendidikan.map((data) => (
                        <option key={data.id} value={data}>
                          {data}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              )}

              {userType === "pelaku_usaha" && (
                <tr className="font-montserrat font-medium text-black-2 text-sm">
                  <td className="text-right font-bold text-black-2 text-sm label-required">
                    Provinsi :
                  </td>
                  <td className="py-2 pl-3">
                    {/* <select
                      className="flex form-select text-sm capitalize pl-3 font-medium w-full border border-solid border-light-gray rounded"
                      name="province_id "
                      {...getFieldProps("province_id")}
                      onChange={(ev) => {
                        AddNewBusinessServices.getKabupaten(
                          { province_id: Number(ev.target.value) },
                          (data) =>
                            dispatch(kabupatenData({ kabupaten: data.data }))
                        );
                        setFieldValue("province_id", Number(ev.target.value));
                      }}
                    >
                      <option value="" disabled>
                        Pilih Provinsi
                      </option>
                      {provinsi.map((provinsi) => (
                        <option
                          key={provinsi.id}
                          value={provinsi.id}
                          className="capitalize"
                        >
                          {provinsi.name}
                        </option>
                      ))}
                    </select> */}
                    <Autocomplete
                      options={provinsi}
                      onChange={(ev, value) => {
                        AddNewBusinessServices.getKabupaten(
                          { province_id: value.id },
                          (data) =>
                            dispatch(kabupatenData({ kabupaten: data.data }))
                        );
                        setFieldValue("province_id", value.id);

                        if ([91, 94].indexOf(value.id) >= 0) {
                          setFieldValue("is_oap", 1);
                        } else {
                          setFieldValue("is_oap", 0);
                        }
                      }}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      defaultValue={
                        provinsi?.filter(
                          (data) => data.id === values.province_id
                        )?.[0]
                      }
                      key={provinsi}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          variant="outlined"
                          placeholder="Pilih Kota / Kabupaten"
                          style={{ width: "100%" }}
                          className="flex bg-white text-sm capitalize pl-3 font-medium w-full border border-solid border-light-gray rounded"
                          size="small"
                        />
                      )}
                    />
                  </td>
                </tr>
              )}

              {userType === "pelaku_usaha" && (
                <tr className="font-montserrat font-medium text-black-2 text-sm">
                  <td className="text-right font-bold text-black-2 text-sm label-required">
                    Kabupaten/kota :
                  </td>
                  <td className="py-2 pl-3">
                    <select
                      className="flex form-select text-sm capitalize pl-3 font-medium w-full border border-solid border-light-gray rounded"
                      name="city_id"
                      {...getFieldProps("city_id")}
                      onChange={(ev) => {
                        setFieldValue("city_id", Number(ev.target.value));
                      }}
                      value={values?.city_id}
                    >
                      {kabupaten.map((kabupaten) => (
                        <option key={kabupaten.id} value={kabupaten.id}>
                          {kabupaten.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              )}

              {userType === "pelaku_usaha" && (
                <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                  <td className="text-right font-bold text-black-2 text-sm lg:w-6/12">
                    Pelaku Usaha Orang Asli Papua (OAP) :
                  </td>
                  <td className="py-2 pl-3">
                    <select
                      className="flex form-select text-sm capitalize pl-3 font-medium w-full border border-solid border-light-gray rounded"
                      name="is_oap"
                      disabled={
                        values?.province_id !== 91 && values?.province_id !== 94
                      }
                      {...getFieldProps("is_oap")}
                      onChange={(ev) => {
                        setFieldValue("is_oap", Number(ev.target.value));
                      }}
                    >
                      <option value="" disabled>
                        Pilih
                      </option>
                      <option value={1}>Orang Asli Papua</option>
                      <option value={0}>Bukan</option>
                    </select>
                  </td>
                </tr>
              )}

              <tr className="font-montserrat font-medium text-black-2 text-sm">
                <td className="text-right font-bold text-black-2 text-sm">
                  <span className="label-required">No telepon :</span>
                </td>
                <td className="py-2 pl-3">
                  <input
                    className="pl-3 font-medium w-full border border-solid border-light-gray rounded px-2 py-2"
                    value={values?.phone}
                    {...getFieldProps("phone")}
                  />
                </td>
              </tr>

              <tr className="font-montserrat items-center font-medium text-black-2 text-sm">
                <td className="text-right font-bold text-black-2 text-sm">
                  Email :
                </td>
                <div className="ml-3 font-medium py-4">{biodata?.email}</div>
              </tr>

              <tr>
                <td style={{ minWidth: 250 }}></td>
                <td className="pl-3 py-3">
                  <button
                    type="submit"
                    onClick={errorHandler}
                    className="bg-pink-header rounded px-4 py-2 text-white font-montserrat font-medium text-sm"
                  >
                    Simpan
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
        <div className="border border-solid border-welcome mb-4" />
        <ChangePassword biodata={biodata} />
        <Dialog
          visible={message.open === true}
          onClose={message.open !== true}
          className="mt-3"
        >
          <Alert severity={message.severity} className="flex items-center">
            {message.message}
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setMessage({ open: false, error: "", message: "" });
              }}
            >
              <CloseIcon alt="" className="pl-3 self-center" />
            </IconButton>
          </Alert>
        </Dialog>
      </div>
    </div>
  );
}

export default ProfileChange;
