import React from "react";
import { Box, Chip, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Link, useLocation, useHistory } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import useProfile from "hooks/useProfile";
import { get } from "lodash";
import { DateTime } from "luxon";
import * as services from "../services";
import PropTypes from "prop-types";
import parseQueryString from "utils/parseQueryString";

export default function ItemPesan(props) {
  const { user } = useProfile();
  const userName = get(user, "full_name");
  const urlSearch = useLocation().search;
  const history = useHistory()

  const { 
    data,
    params,
    tab,
    setResponse: setResponseData,
    setPagination,
  } = props;

  const onDeleteMessage = () => {
    services.deleteBroadCast({
      id: data?.id,
    } , {
      success: (response) => {
        console.log(response);
      },
      error: (err) => {
        console.log(err);
      }
    })

    services.getBroadCast({
      status: tab,
      page: params.page,
      page_size: 5,
      q: params.q,
      type: params.type
    }, {
      success: (response) => {
        setResponseData(prev => ({...prev, data: response}));
        setPagination(5, response.total);
      },
      error: (err) => {
        console.log(err);
      }
    })

    history.replace(`/pesan?tab=${parseQueryString(urlSearch)?.tab}`)
  }
  return (
    <Box
      className="rounded-lg border border-gray-300"
      sx={{ bgcolor: "white", px: 3, py: 1 }}
    >
      <Box className="flex flex-col sm:flex-row items-center justify-between border-b border-gray-300 pb-2">
        <Box className="flex flex-col sm:flex-row items-center space-x-3">
          <Box className="font-montserrat sm:p-2 p-1 text-pink-header text-xs sm:text-sm border border-solid border-pink-header">
            {data?.type}
          </Box>
          <p className="text-sm pt-2 sm:pt-0 sm:text-base font-bold text-black-2 font-baloo2">
            {data?.subject}
          </p>
        </Box>
        <Box 
          className="sm:space-x-3 flex flex-row py-2 sm:py-0 items-center"
          sx={{
            "& .MuiChip-root": {
              background: "#00A65A",
              color: "#FAFAFA",
            },
          }}
        >
          {data?.has_read === false && <Chip size="small" label="Belum dibaca" /> }
          <IconButton onClick={onDeleteMessage}>
            <CloseIcon className="text-gray-200" />
          </IconButton>
        </Box>
      </Box>
      <Link
        to={`/pesan?tab=all&messageId=${data?.id}`}
        className=""
      >
        <Box className="space-y-3 py-2">
          <p className="font-montserrat text-xs sm:text-base">Kepada {data?.recipient_type_text}</p>


          <p
            className="font-montserrat text-base"
            dangerouslySetInnerHTML={{ __html: data?.body }}
          ></p>
        </Box>
        <Box className="flex flex-col sm:flex-row items-center justify-between">
          <div className="divide-x-2 divide-gray-300">
            <span className="inline pr-2 text-xs sm:text-sm text-gray-300 font-montserrat">
              {data?.created_by?.full_name}
            </span>
            <p className="inline pl-2 text-xs sm:text-sm text-gray-300 font-montserrat">
              {DateTime.fromISO(data?.published_at).toFormat("dd LLLL yyyy", {locale: "id"})}
            </p>
          </div>
          <div className="flex items-center space-x-2 text-blue-second">
            <span className="text-sm font-montserrat font-semibold">
              Selengkapnya
            </span>
            <ArrowRightAltIcon color="inherit" />
          </div>
        </Box>
      </Link>
    </Box>
  );
}

ItemPesan.propTypes = {
  data: PropTypes.object,
  params: PropTypes.object,
  tab: PropTypes.string,
  setResponse: PropTypes.func,
  setPagination: PropTypes.func,
};

ItemPesan.defaultProps = {
  params: {},
  data: {},
  tab: "",
  setResponse: () => {},
  setPagination: () => {},
};