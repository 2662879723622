import axios from 'axios';
import endpoint from 'utils/endpoint';

export async function cekSikap(data, callback) {

  const url = endpoint.cekSikap;
  const config = {
    method: 'POST',
    data,
  };

  try {
    const response = await axios(url,config);
    callback(response.data)
  } catch (error) {
    callback(error)
  }
}