import React from "react";
import Footer from "components/footer";
import Navigation from "components/Navigation";
import useGlobalStyles from "hooks/useGlobalStyles";
import Breadcrumb from "components/Breadcrumb";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";
import interactionPlugin from "@fullcalendar/interaction";
import CalendarStyle from "components/Calendar/CalenderStyle";
import CalendarToolbar from "components/Calendar/CalendarToolbar";
import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import VDLogo from "assets/logo-vd.png";
import { format, getUnixTime } from "date-fns";
import { id } from "date-fns/locale";
import KegiatanView from "./components/KegiatanView";
import { getKalendar } from "redux/reducers/kalendarKegiatan";
import useParallaxHeader from "hooks/useParallaxHeader";
import Page from "components/Page";
import token from "utils/session";

export default function KalendarKegiatan(props) {
  const classes = useGlobalStyles();

  const dispatch = useDispatch();

  const { kalendar: listKalendar } = useSelector((state) => state.kalendar);

  const calendarRef = React.useRef(null);
  const [date, setDate] = React.useState(new Date());
  const [view, setView] = React.useState("dayGridMonth");
  const [openViewCalendar, setOpenViewCalendar] = React.useState({
    visible: false,
    options: null,
  });

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickEvent = (arg) => {
    if (
      format(
        new Date(arg.event.extendedProps.waktu_akhir),
        "yyyy-MM-dd HH:mm",
        { locale: id }
      ) > format(Date.now(), "yyyy-MM-dd HH:mm", { locale: id })
    ) {
      setOpenViewCalendar({
        visible: true,
        options: {
          ...arg.event.extendedProps,
          id: Number(arg.event.id),
          start: new Date(arg.event.extendedProps.waktu_mulai),
          end: new Date(arg.event.extendedProps.waktu_akhir),
        },
      });
    }
  };

  React.useEffect(() => {
    dispatch(
      getKalendar({
        month: format(date, "MM"),
        year: format(date, "yyyy"),
        token: token,
        status: 1,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const background = useParallaxHeader()?.map((data) => data)[3]?.file_url;

  return (
    <Page title="Kalendar" className={classes.container}>
      <Navigation />

      <div
        className="flex flex-col py-4 lg:px-20 px-6"
        style={{
          backgroundImage: `url("${background}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          scrollMarginTop: "120px",
        }}
      >
        <Breadcrumb
          classLast="text-white"
          links={[
            { href: "/", name: "Beranda" },
            { name: "Kalendar Kegiatan" },
          ]}
        />

        <div className="mt-10">
          <div className="mb-8 font-baloo2 font-bold text-3xl text-white">
            Kalendar Kegiatan
            <div className="border-b-2 border-solid border-pink-header w-1/12 mb-8 pt-10" />
          </div>
        </div>
      </div>

      <Box className="my-10 lg:mx-20 mx-6 lg:px-16 px-6 py-10" boxShadow={5}>
        <div className="flex lg:flex-row flex-col items-center justify-between mb-5">
          <div>
            <img src={VDLogo} alt="" style={{ height: 70 }} />
          </div>
          <div className="flex items-center">
            <div className="px-2 border-r-2 border-grey-200">
              <span className="font-baloo2 font-semibold text-lg block text-black-2">
                {format(new Date(), "eeee", { locale: id })}
              </span>
              <span className="font-baloo2 font-semibold text-lg block text-black-2">
                {format(new Date(), "dd MMM yyyy", { locale: id })}
              </span>
            </div>
            <div className="px-2">
              <span className="font-baloo2 font-semibold text-3xl block text-black-2">
                {format(new Date(), "HH:mm aa", { locale: id })}
              </span>
            </div>
          </div>
        </div>

        <CalendarStyle>
          <CalendarToolbar
            date={date}
            view={view}
            onBack={handleClickDatePrev}
            onNext={handleClickDateNext}
            onToday={handleClickToday}
            onChangeView={handleChangeView}
          />
          <FullCalendar
            ref={calendarRef}
            plugins={[
              listPlugin,
              dayGridPlugin,
              timelinePlugin,
              timeGridPlugin,
              interactionPlugin,
            ]}
            displayEventTime={false}
            initialView="dayGridMonth"
            headerToolbar={false}
            events={listKalendar
              ?.filter((item) => item.status)
              ?.map((item) => ({
                ...item,
                id: item.id,
                title: item.nama,
                start: item.waktu_mulai,
                end: item.waktu_akhir,
                backgroundColor:
                  format(new Date(item?.waktu_akhir), "yyyy-MM-dd HH:mm", {
                    locale: id,
                  }) < format(Date.now(), "yyyy-MM-dd HH:mm", { locale: id })
                    ? "#595959"
                    : item?.jenis_kegiatan === "pkp"
                    ? "#00B894"
                    : "#0073B7",
                textColor: "white",
                display: "block",
                className: "cursor-pointer",
              }))}
            eventClick={handleClickEvent}
          />

          <div className="flex lg:flex-row flex-col lg:items-center items-start lg:space-y-0 space-y-3 justify-around mt-6">
            {/* <div className="flex items-center space-x-2">
              <div className="bg-neon-green w-6 h-6 rounded-md"></div>
              <p className="text-sm text-gray-500 font-montserrat font-semibold">
                Kegiatan sudah diikuti
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <div className="bg-blue-second w-6 h-6 rounded-md"></div>
              <p className="text-sm text-gray-500 font-montserrat font-semibold">
                Kegiatan yang sudah terdaftar
              </p>
            </div> */}
            <div className="flex items-center space-x-2">
              <div className="bg-black-2 w-6 h-6 rounded-md"></div>
              <p className="text-sm text-gray-500 font-montserrat font-semibold">
                Kegiatan yang sudah lewat
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <div className="bg-new-green w-6 h-6 rounded-md"></div>
              <p className="text-sm text-gray-500 font-montserrat font-semibold">
                Kegiatan PKP
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <div className="bg-blue-second w-6 h-6 rounded-md"></div>
              <p className="text-sm text-gray-500 font-montserrat font-semibold">
                Kegiatan Non-PKP
              </p>
            </div>
          </div>
        </CalendarStyle>
      </Box>

      <KegiatanView
        options={openViewCalendar.options}
        visible={openViewCalendar.visible}
        onClose={() => setOpenViewCalendar({ visible: false, options: null })}
      />
      <Footer />
    </Page>
  );
}
