import axios from "axios";
import endpoint from "utils/endpoint";
import token from "utils/session";
import Cookie from "js-cookie";
import { logout } from "redux/reducers/auth";

async function getProfile(params, callback) {
  const url = endpoint.profile;
  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  };

  try {
    const response = await axios(url, config);
    callback.success(response.data)
  } catch (error) {
    callback.error(error)
  }
}

function postLogout(callback) {
  const url = endpoint.logout;
  const config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      await axios(url, config);
      dispatch(logout())
      Cookie.remove("SID_LKPP");
      window.location.replace("/");
    } catch (error) {
      console.log(error);
    }
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getProfile,
  postLogout,
};
