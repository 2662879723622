import React, { useMemo } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";
import useProfile from "hooks/useProfile";
import classnames from "classnames";
import get from "lodash/get";
import Link from "./Link";
import Menu from "components/Menu";
import { useDispatch, useSelector } from "react-redux";
import { displayModalSiginIn, rememberMeButton } from "redux/reducers/auth";
import { getPranalaLuar } from "redux/reducers/region";
import services from "components/header/services";
import Shortcut from "./Shortcut";

import * as servicesMessage from "pages/Pesan/services";

import { isMessageNotRead } from "redux/reducers/users";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: 7,
      paddingBottom: 7,
    },
    content: {
      background: theme.palette.grey[200],
    },
    contentProfile: {
      background: theme.palette.grey[200],
      minWidth: 160,
    },
  })
);

export default function Menus(props) {
  const { isLogin, user } = useProfile();
  const { message } = useSelector((state) => state.user);

  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { pranalaLuar } = useSelector((state) => state.region);

  const classLeftNav = classnames(
    classes.root,
    "items-center justify-between divide-x-2 font-montserrat"
  );

  const fullname = useMemo(() => get(user, "full_name"), [user]);

  // console.log(user)
  const onLogout = React.useCallback(() => {
    dispatch(services.postLogout());
    dispatch(rememberMeButton());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menus = useMemo(
    () => [
      { name: "Profile", to: "/profile" },
      { name: "Pesan Masuk", to: "/pesan" },
      { name: "Log Akses", to: "/log_akses" },
      { name: "Ubah Profile", to: "/profile?edit-profile=true" },
      { name: "Logout", onClick: () => onLogout() },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  const onShowLogin = () => {
    dispatch(displayModalSiginIn());
  };

  const isHomepage = React.useMemo(
    () => location.pathname === "/",
    [location.pathname]
  );

  React.useEffect(() => {
    dispatch(getPranalaLuar());
    servicesMessage.getBroadCast(
      {
        status: "not_read",
      },
      {
        success: (response) => {
          dispatch(isMessageNotRead(response));
        },
        error: (err) => {
          console.log(err);
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center justify-between bg-white lg:px-10 px-4 lg:py-0 py-3">
      {isHomepage ? (
        <div className={classnames(classLeftNav, "lg:flex hidden")}>
          <Link href="/#welcome">Selamat Datang</Link>
          <Link href="/#introduction">Perkenalan</Link>
          <Link href="/#data">Berita Usaha</Link>
          <Link href="/#chance">Peluang Pasar PBJP</Link>
          <Link href="/#question">FAQ & Kontak Kami</Link>
          <Menu
            content={
              <ul className={classnames(classes.content, "py-2")}>
                {pranalaLuar.map((item, index) => (
                  <li>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className="font-montserrat text-sm px-4 py-2 block"
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            }
          >
            <span className="text-sm">Pranala Luar</span>
          </Menu>
        </div>
      ) : (
        <div className={classLeftNav}>
          <Shortcut />
        </div>
      )}
      {isHomepage && <div className="lg:hidden block"></div>}

      {!isLogin && (
        <div className="items-center flex justify-between">
          <button
            className="text-sm px-4 py-2 border border-solid border-pink-header rounded text-pink-header"
            onClick={onShowLogin}
          >
            Masuk
          </button>
          <RouterLink to="/register">
            <button className="text-sm ml-2 px-4 py-2 border border-solid border-pink-header bg-pink-header rounded text-white">
              Daftar
            </button>
          </RouterLink>
        </div>
      )}

      {isLogin && (
        <Menu
          content={
            <ul className={classnames(classes.contentProfile, "py-2")}>
              {menus.map(({ to: href, name, onClick }, index) => {
                if (onClick) {
                  return (
                    <li role="button" key={index} onClick={onClick}>
                      <span className="font-montserrat text-sm px-4 py-2 font-medium block">
                        {name}
                      </span>
                    </li>
                  );
                } else if (name === "Pesan Masuk") {
                  return (
                    <li key={index}>
                      <RouterLink
                        to={href}
                        className="font-montserrat text-sm px-4 py-2 font-medium flex justify-between"
                      >
                        <div>{name}</div>
                        <div className="bg-pink-header px-2 text-white rounded-xl">
                          {message?.total > 99 ? "+99" : message?.total}
                        </div>
                      </RouterLink>
                    </li>
                  );
                }
                return (
                  <li key={index}>
                    <RouterLink
                      to={href}
                      className="font-montserrat text-sm px-4 py-2 font-medium block"
                    >
                      {name}
                    </RouterLink>
                  </li>
                );
              })}
            </ul>
          }
        >
          <div className="flex items-center">
            <div className="relative">
              <img
                src={get(user, "profile_picture_url")}
                alt=""
                className="w-6 h-6 rounded-full mr-2"
              />

              {message?.total > 0 && (
                <span
                  className="absolute top-0 right-0 w-2 h-2 rounded-full bg-pink-header"
                  style={{ marginTop: -3, marginRight: 5 }}
                />
              )}
            </div>
            <span className="text-sm">{fullname}</span>
          </div>
        </Menu>
      )}
    </div>
  );
}
