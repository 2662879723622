import { createSlice } from "@reduxjs/toolkit";
import endpoint from "utils/endpoint";
import axios from "axios";

const newsSlices = createSlice({
  name: "news",
  initialState: {
    isLoad: false,
    error: null,
    isError: false,
    beritaLainya: [],
    bacaJuga: [],
  },
  reducers: {
    startLoading: (state) => {
      state.isLoad = true;
    },
    hasError: (state, { payload }) => {
      state.isLoad = false;
      state.error = payload;
    },
    getBeritaLainnyaSuccess: (state, { payload }) => {
      state.isLoad = false;
      state.beritaLainya = payload;
    },
    getBacaJugaSuccess: (state, { payload }) => {
      state.isLoad = false;
      state.bacaJuga = payload;
    },
  },
});

export default newsSlices.reducer;

export function getBeritaLainnya(newsId) {
  return async (dispatch) => {
    const url = endpoint.beritaUsaha;
    const config = {
      method: "get",
      params: {
        sort: "random",
        page_size: 3,
        except: newsId,
      },
    };
    dispatch(newsSlices.actions.startLoading());

    try {
      const response = await axios(url, config);
      dispatch(newsSlices.actions.getBeritaLainnyaSuccess(response.data.data));
    } catch (error) {
      dispatch(newsSlices.actions.hasError(error.response.data));
    }
  };
}

export function getBacaJuga() {
  return async (dispatch) => {
    const url = endpoint.beritaUsaha;
    const config = {
      method: "get",
      params: {
        sort: "random",
        page_size: 4,
      },
    };

    dispatch(newsSlices.actions.startLoading());

    try {
      const response = await axios(url, config);
      dispatch(newsSlices.actions.getBacaJugaSuccess(response.data.data));
    } catch (error) {
      dispatch(newsSlices.actions.hasError(error.response.data));
    }
  };
}
