import axios from 'axios';
import endpoint from 'utils/endpoint';

export async function getFAQ(params,callback) {
  const url = `${endpoint.faq}`;
  const config = {
    method: 'GET',
    params,
  };

  try {
    const response = await axios(url,config);
    await callback(response.data);
  } catch (error) {
    console.log(error);
    await callback(error);
  }
}

export async function getTutorialSystem(callback, params) {
  const url = endpoint.tutorialSystem
  const config = {
    method: 'get',
    params,
  }

  try {
    const response = await axios(url, config)
    callback(response.data, null)
  } catch (error) {
    callback(null, error)
  }
}