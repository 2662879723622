import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Dialog from "components/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import id from "faker/lib/locales/id_ID";
import { makeStyles, createStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => createStyles({
  filter: {
    minWidth: 500,

    [theme.breakpoints.down('md')]: {
      minWidth: '100%'
    },
  },
}))

function SearchProvince(props) {
  const { visible, onClose } = props;

  const classes = useStyles()

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const handleProvinceChange = (event) => {
    if (
      props?.selectedProvince
        ?.map?.((data) => data.id)
        .indexOf(event?.map((event) => event.id)) < 0
    ) {
      props?.setSelectedProvince((prev) => event.map(i => i.id));
    } else {
      props?.setSelectedProvince((prev) => {
        return prev.filter((i) => event.map(ix => ix.id).indexOf(i) >= 0);
      });
    }
  };

  return (
    <Dialog visible={visible} onClose={onClose}>
      <Box className="px-6 py-4">
        <div className="flex items-center justify-end">
          <CloseIcon className="cursor-pointer" color="grey.200" onClick={onClose} />
        </div>

        <div>
          <span className="font-baloo2 font-bold text-lg text-black-2">
            Filter Provinsi
          </span>
        </div>

        <p className="font-montserrat font-medium text-sm text-black-2">
          Ketikan minimal 3 huruf untuk mencari
        </p>

        <p className="pt-3 font-montserrat font-bold text-sm text-black-2">
          Provinsi
        </p>
        <Box
          className="mt-2"
          sx={{
            "& .MuiChip-root": {
              background: "#F2F2F2",
              border: "1px solid #CCCCCC",
              boxSizing: "border-box",
              borderRadius: "4px",
            },
            "& .MuiInputBase-root": {
              background: "#f2f2f2",
              border: "1px solid #CCCCCC",
              boxSizing: "border-box",
              borderRadius: "4px",
              padding: "2px 4px",
            },
            marginBottom: "16px",
          }}
        >
          <Autocomplete
            multiple
            options={props?.data}
            getOptionLabel={(option) => option?.name?.toLowerCase()}
            filterSelectedOptions
            onChange={(event, value) => handleProvinceChange(value)}
            getOptionSelected={(option, value) => option.id === value.id}
            defaultValue={props?.data?.filter(ix => props?.selectedProvince.map(i => Number(i)).indexOf(ix.id) >= 0 )}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Provinsi"
                className={classes.filter}
              />
            )}
          />
        </Box>
      </Box>
    </Dialog>
  );
}

export default SearchProvince;
