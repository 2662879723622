import { createSlice } from "@reduxjs/toolkit";
import endpoint from "utils/endpoint";
import axios from "axios";
import faker from 'faker/locale/id_ID'

const initialState = {
  isLoad: false,
  isError: false,
  error: null,
  kabarUsaha: [],
};

export const kabarUsahaSlice = createSlice({
  name: "lokasi",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoad = true;
    },
    hasError: (state, { payload }) => {
      state.isLoad = false;
      state.isError = true;
      state.error = payload;
    },
    getKabarUsahaSuccess: (state, { payload }) => {
      state.isLoad = false;
      state.kabarUsaha = payload;
    },
  },
});

export default kabarUsahaSlice.reducer

export function getKabarUsaha(params) {
  return async (dispatch) => {
    const url = endpoint.kabarPelakuUsaha;

    try {
      dispatch(kabarUsahaSlice.actions.startLoading());
      const response = await axios.get(url, { params });

      const mock = {
        current_page: 1,
        per_page: 15,
        total: 15,
        data: [...Array(15)].map(() => ({
          name: faker.company.companyName(),
          history: [
            { id: 1, description: 'Foto produk baru' },
            { id: 2, description: 'Nama perusahaan baru' }
          ],
          updated_at: faker.date.recent(),
        })),
      }

      dispatch(kabarUsahaSlice.actions.getKabarUsahaSuccess(response.data));
    } catch (error) {
      dispatch(kabarUsahaSlice.actions.hasError(error.response))
    }
  };
}
