const palette = {
  primary: {
    main: "#DE1B51",
  },
  secondary: {
    main: "#595959",
  },
  success: {
    main: "#54D62C",
  },
  grey: {
    200: "#F2F2F2",
    500: "#ABABAB",
  },
};

export default palette;
