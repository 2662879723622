import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

export default function UploadFile(props) {
  const { max, message, classContainer } = props;
  // eslint-disable-next-line
  const [file, setFile] = useState(null);
  const [previewName, setPreviewName] = useState(null);
  const inputRef = useRef();

  function handleChangeFile(e) {
    const { files } = e.target;

    const fileExt = files[0]?.name.split(".").pop();

    if (["pdf"].indexOf(fileExt) >= 0) {
      if (files.length && files[0].size < max) {
        const url = URL.createObjectURL(files[0]);
        setPreviewName(url);
        setFile(files[0]);
      } else if (files.length && files[0].size > max) {
        alert(message);
      } else {
        setFile(null);
        setPreviewName(null);
      }
    } else {
      alert(`hanya file dengan ekstensi pdf yang diperbolehkan`);
    }

    if (typeof props.inputProps.onChange === "function") {
      props.inputProps.onChange(e);
      console.log(
        "🚀 ~ file: index.js ~ line 24 ~ handleChangeFile ~ this",
        this
      );
    }
  }

  const inputProps = {
    ...props.inputProps,
    // onChange: handleChangeFile,
  };

  const openFileBrowser = () => {
    inputRef.current.click();
  };

  const removeFile = () => {
    inputRef.current.value = "";
    const event = new Event("change");
    inputRef.current.dispatchEvent(event);

    setFile(null);
    setPreviewName(null);
  };

  return (
    <div className={classNames(classContainer, 'flex')}>
      <div
        className={classNames(
          "flex overflow-x-auto whitespace-nowrap font-montserrat p-2 font-medium text-xs text-black-2 bg-neutral-grey border border-solid border-welcome rounded",
          props?.classWidth
        )}
      >
        <input
          ref={inputRef}
          className="hidden"
          type="file"
          accept="application/pdf"
          {...inputProps}
          onChange={handleChangeFile}
        />
        {file ? (
          <div className="flex w-full items-center justify-between font-montserrat font-medium text-sm text-black-2">
            <div>{previewName}</div>
            <button onClick={removeFile}>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.00048 4.05781L8.30048 0.757812L9.24315 1.70048L5.94315 5.00048L9.24315 8.30048L8.30048 9.24315L5.00048 5.94315L1.70048 9.24315L0.757812 8.30048L4.05781 5.00048L0.757812 1.70048L1.70048 0.757812L5.00048 4.05781Z"
                  fill="#595959"
                />
              </svg>
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="">
        <button
          onClick={openFileBrowser}
          type="button"
          className="text-white p-2 px-5 rounded bg-black-2"
        >
          Browse
        </button>
      </div>
    </div>
  );
}

UploadFile.propTypes = {
  max: PropTypes.number,
  message: PropTypes.string,
};

UploadFile.defaultProps = {
  max: 1000000,
  message: "File Lebih Besar Dari 1 MiB!",
};
