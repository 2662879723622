import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as services from './services';
import _ from 'lodash';

const InputSikap = React.forwardRef((props, ref) => {
  const [sikapResponse, setSikapResponse] = useState({
    status: false,
    data: '',
  });
  const {field, type} = props;
  const inputRef = useRef();
  // console.log("🚀 ~ file: index.js ~ line 12 ~ InputSikap ~ inputRef", inputRef)
  const inputProps = {
    className: '',
    ...props.inputProps
  }
  
  const handleClickBtn = () => {
    const text = inputRef.current.value;
    if(text) {
      setSikapResponse({status: false});
      
      services.cekSikap({q: text, field, type}, (res) => {
        setSikapResponse(res);
        
        if(res.data.is_relevant && typeof props.callback == 'function') {
          props.callback(res);
        }
      })
    }
  }

  function getColorAlert() {
    let bgColorAlert = null;

    if(_.get(sikapResponse, 'data.is_relevant')) {
      bgColorAlert = '#00A65A'
    } else {
      bgColorAlert = '#FA8C16'
    }

    if(!_.get(sikapResponse, 'data.is_registered')) {
      bgColorAlert = '#F56954'
    }

    return bgColorAlert;
  }
  
  
  return (
    <div className={`sikapInput-container ${props.className}`}>
      <div className="flex" style={{gap: 15}}>
        <div className="w-8/12">
          <input ref={refObj => {
            if(ref) {
              ref(refObj)
            }

            inputRef.current = refObj;
          }} {...props} {...inputProps}/>
        </div>
        <div className="w-4/12">
          <button className="flex p-2 text-xs rounded text-white w-full text-center justify-center items-center" style={{backgroundColor: '#595959', border: '1px solid #595959'}} type="button" onClick={handleClickBtn}>
            <svg className="inline mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.0213 10.0777L13.8767 12.9323L12.9333 13.8757L10.0787 11.0203C9.01648 11.8718 7.69532 12.3349 6.33398 12.333C3.02198 12.333 0.333984 9.64501 0.333984 6.33301C0.333984 3.02101 3.02198 0.333008 6.33398 0.333008C9.64598 0.333008 12.334 3.02101 12.334 6.33301C12.3359 7.69434 11.8728 9.0155 11.0213 10.0777ZM9.68398 9.58301C10.5301 8.71293 11.0026 7.54663 11.0007 6.33301C11.0007 3.75434 8.91198 1.66634 6.33398 1.66634C3.75532 1.66634 1.66732 3.75434 1.66732 6.33301C1.66732 8.91101 3.75532 10.9997 6.33398 10.9997C7.5476 11.0016 8.71391 10.5291 9.58398 9.68301L9.68398 9.58301Z" fill="#FEFEFE"/>
            </svg>

            Cek SIKaP
          </button>
        </div>
      </div>

      {_.get(sikapResponse, 'status') !== false && (
        <div className="mt-2 p-2 rounded text-white text-xs" style={{backgroundColor: getColorAlert()}}>
          {_.get(sikapResponse, 'data.is_relevant') ? (
            <svg className="inline" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z" fill="#FEFEFE"/>
          </svg>
          ) : (
            <svg className="inline" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.00048 7.05781L11.3005 3.75781L12.2431 4.70048L8.94315 8.00048L12.2431 11.3005L11.3005 12.2431L8.00048 8.94315L4.70048 12.2431L3.75781 11.3005L7.05781 8.00048L3.75781 4.70048L4.70048 3.75781L8.00048 7.05781Z" fill="#FEFEFE"/>
            </svg>
          )}
          {_.get(sikapResponse, 'data.msg')}
        </div>
      )}
    </div>
  );
})

InputSikap.defaultProps = {
}

InputSikap.propTypes = {
  className: PropTypes.string,
  inputProps: PropTypes.object,
  callback: PropTypes.func,
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default InputSikap;