/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line
import React, { useState, useRef, useEffect, createRef } from "react";
import Footer from "components/footer";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import VDLogo from "assets/logo-vd.png";
import { verifyEmail } from "./services";
import { CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { displayModalSiginIn } from "redux/reducers/auth";
import TextField from "components/TextField";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {resendEmailVerification} from '../RegisterVerification/services'

function Verification() {
  const [intervalState, setIntervalState] = useState(30);
  const [idInterval, setIdInterval] = useState(null);
  const intervalNumber = useRef(30);

  const { verificationCode } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(null);
  const [isResentEmail, setIsResentEmail] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (verificationCode) {
      setIsLoading(true);
      verifyEmail(verificationCode, (data) => {
        if (data.status === "E") {
          setIsVerified(false);
        } else {
          setIsVerified(true);
        }
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!idInterval && intervalState === 30) {
      const idInterval = window.setInterval(() => {
        if (intervalNumber.current - 1 <= 0) {
          window.clearInterval(idInterval);
        }

        intervalNumber.current -= 1;
        setIntervalState(intervalNumber.current);
      }, 1000);

      setIdInterval(idInterval);
    }
  }, [intervalState, idInterval]);

  useEffect(() => {
    return () => {
      window.clearInterval(idInterval);
    };
  }, []);

  const {getFieldProps, touched, errors, handleSubmit, isSubmitting, setFieldError, values, setSubmitting} = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required()
    }),
    onSubmit: (values, form) => {
      resendEmailVerification(values.email, (status, resp) => {
        if(status) {
          setIsResentEmail(true);
          setIntervalState(30);
          intervalNumber.current = 30;
          setIdInterval(null);
        } else {
          const message = resp.response.data.message || resp.response.statusText;
          setFieldError('email', message);
        }

        setSubmitting(false)
      })
    }
  });
  
  const handleClickButtonResend = () => {
    resendEmailVerification(values.email, () => {
      // reset count
      setIntervalState(30);
      intervalNumber.current = 30;
      setIdInterval(null);
    });
  };
  
  const isBtnDisabled = intervalState > 0;
  
  return (
    <div>
      <div className="flex flex-col pt-5">
        <Breadcrumbs>
          <Link
            className="font-montserrat font-medium text-sm text-pink-header pl-20"
            to="/"
          >
            Beranda
          </Link>
          <Link className="font-montserrat font-medium text-sm text-black-2">
            Daftar Akun
          </Link>
          <Link className="font-montserrat font-medium text-sm text-black-2">
            Verifikasi
          </Link>
        </Breadcrumbs>
      </div>

      <div className="py-5 flex w-full justify-center">
        <img src={VDLogo} alt="" style={{ height: 70 }} />
      </div>
      <div
        className="w-10/12 mx-auto flex"
        style={{
          minHeight: 400,
          marginBottom: 120,
          boxShadow:
            "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05",
        }}
      >
        <div className="w-1/2 pt-4 ml-12 pr-6 flex items-center justify-center">
          <img src="/undraw_conference_call.svg" alt="icon" />
        </div>

        <div className="w-1/2 mt-4 mb-8 border-l border-solid border-welcome pl-6">
          {isLoading ? (
            <div
              className="flex justify-center items-center"
              style={{ height: 300 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {
                !isResentEmail ? (
                  <>
                  <div className="p-5 flex justify-between w-11/12">
                <div className="font-baloo2 font-bold text-3xl text-black-2">
                  {isVerified
                    ? "Verifikasi email berhasil !"
                    : "Link verifikasi Kedaluwarsa!"}
                </div>
              </div>
              <div className="mt-7 p-5">
                <hr
                  className="mb-2"
                  style={{ borderBottom: "2px solid #DE1B51", minWidth: 200 }}
                />
                {isVerified
                  ? "Masuk ke Vendori Direktori dan mulai bergabung di VD bersama jutaan pelaku usaha berpengalaman di bidangnya."
                  : "Link verifikasi akun anda sudah kadaluarsa. Ketikan kembali email anda di bawah ini untuk mengirim ulang email verifikasi."}

                {!isVerified && (
                    <TextField
                      type="email"
                      label="Email"
                      name="email"
                      required
                      error={Boolean(touched.email && errors.email)}
                      errorText={errors.email}
                      {...getFieldProps("email")}
                    />
                  )}
                  <div className="mt-5">
                    <Link to="/">
                      <button
                        type="button"
                        className="px-3 py-2 bg-black rounded text-white"
                      >
                        Ke Beranda
                      </button>
                    </Link>

                    {isVerified ? (
                      <button
                        onClick={() => dispatch(displayModalSiginIn())}
                        type="button"
                        className="ml-3 px-3 py-2 bg-red-500 rounded text-white"
                      >
                        Masuk ke BISA Pengadaan
                      </button>
                    ) : (
                      <button
                        onClick={handleSubmit}
                        type="button"
                        className={`ml-3 px-3 py-2 ${
                          isSubmitting ? "bg-gray-300" : "bg-red-700"
                        } rounded text-white`}
                      >
                        Kirim ulang email verifikasi
                      </button>
                    )}
                  </div>
                </div>
                  </>
                ) : (
                  <>
                    <div className="pt-5 w-11/12">
                      <div
                        className="mt-2 p-2 rounded text-white text-xs"
                        style={{ backgroundColor: "#00A65A" }}
                      >
                        <svg
                          className="inline"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.66649 10.115L12.7945 3.98633L13.7378 4.92899L6.66649 12.0003L2.42383 7.75766L3.36649 6.81499L6.66649 10.115Z"
                            fill="#FEFEFE"
                          />
                        </svg>
                        Email verifikasi berhasil dikirim
                      </div>

                      <div className="mt-4">
                        Cek inbox email anda:
                        <span className="font-bold block">{values.email}</span>
                      </div>
                      <div className="mt-3">
                        Lalu ikuti langkah berikut:
                        <ol className="ml-5 list-decimal">
                          <li className="mt-1">
                            Cari dan buka email konfirmasi dari BISA Pengadaan LKPP di
                            inbox, spam, atau trash folder anda
                          </li>
                          <li className="mt-1">
                            Klik pada link verifikasi yang terdapat di dalam email
                          </li>
                          <li className="mt-1">
                            Akun anda akan secara otomatis terverifikasi
                          </li>
                        </ol>
                      </div>
                      <div className="mt-3 font-bold">
                        Tidak menemukan email verifikasi ?
                      </div>
                      <button
                        onClick={handleClickButtonResend}
                        className={`"btn font-bold p-2 w-full ${
                          isBtnDisabled ? "bg-gray-300" : "bg-red-700"
                        } rounded text-white mt-3`}
                        disabled={isBtnDisabled}
                      >
                        Kirim ulang email
                        {isBtnDisabled && <>({intervalState} detik)</>}
                      </button>
                    </div>
                  </>
                )
              }
              
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Verification;
